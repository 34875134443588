import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    alert: {
      type: '',
      message: '',
      showing: false,
    },
    windowSize: window.innerWidth,
    loading: true,
    loadingMessage: '',
    hydrationData: {},
  },
  reducers: {
    setAlert(state, { payload }) {
      state.alert = payload;
    },
    manuallyCallAlert(state, { payload }) {},
    closeAlert(state, action) {
      state.alert.showing = false;
    },
    setWindowSize(state, { payload }) {
      state.windowSize = payload;
    },
    startAppLoading(state, { payload }) {
      state.loading = true;
      state.loadingMessage = payload || '';
    },
    stopAppLoading(state) {
      state.loading = false;
      state.loadingMessage = '';
    },
    fetchHydrationData(state, action) {},
    storeHydrationData(state, { payload }) {
      state.hydrationData = payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = appSlice;

// Extract and export each action creator by name
export const { setAlert, closeAlert, setWindowSize, startAppLoading, stopAppLoading, fetchHydrationData, storeHydrationData, manuallyCallAlert } = actions;

// Export the reducer, either as a default or named export
export default reducer;
