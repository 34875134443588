import { Drawer, Typography, Button, Alert } from '@mui/material';

import { DrawerContainer, DrawerHeaderContainer, DrawerContentContainer, DrawerActionsContainer } from 'components/Drawer';

const SettingsDrawer = ({ handleClose, open, formie, handleSubmit }) => {
  const { values } = formie;
  const disabled =
    !values?.advancedSettings?.late_fee_type ||
    (!values?.advancedSettings?.late_fee_amount && values?.advancedSettings?.late_fee_amount !== 0) ||
    !values?.advancedSettings?.grace_period ||
    (!values?.advancedSettings?.nsf_fee && values?.advancedSettings?.nsf_fee !== 0) ||
    !values?.advancedSettings?.timezone;

  return (
    <Drawer onClose={handleClose} open={open} anchor="right" ModalProps={{ disableEscapeKeyDown: true }}>
      {/* Used to set the width of the drawer.  */}
      <DrawerContainer>
        <DrawerHeaderContainer>
          <Typography align="center" variant="h5">
            Configure Advanced Settings
          </Typography>
        </DrawerHeaderContainer>

        <DrawerContentContainer>
          <Typography variant="h6" sx={{ mb: '8px' }}>
            Late Fee
          </Typography>
          <Alert severity="info">
            Late fees can be a flat dollar amount or a percentage of the missed payment. If a payment is missed the system will wait until the grace period has
            elapsed and then apply the fee.
          </Alert>
          {formie.buildFields({
            fields: [
              { dataPath: 'advancedSettings.late_fee_type' },
              { dataPath: 'advancedSettings.late_fee_amount' },
              { dataPath: 'advancedSettings.grace_period' },
            ],
            fieldsPerRow: 2,
          })}

          <Typography variant="h6" sx={{ mb: '8px', mt: '32px' }}>
            NSF Fee
          </Typography>
          <Alert severity="info">If a payment is returned due to insufficient funds, this fee will automatically apply to the loan.</Alert>
          {formie.buildFields({
            fields: [{ dataPath: 'advancedSettings.nsf_fee' }],
            fieldsPerRow: 1,
          })}

          <Typography variant="h6" sx={{ mb: '8px', mt: '32px' }}>
            Timezone
          </Typography>
          {formie.buildFields({
            fields: [{ dataPath: 'advancedSettings.timezone' }],
            fieldsPerRow: 1,
          })}

          <Typography variant="h6" sx={{ mb: '8px', mt: '32px' }}>
            Types
          </Typography>
          {formie.buildFields({
            fields: [{ dataPath: 'advancedSettings.reporting_type' }, { dataPath: 'advancedSettings.category' }],
            fieldsPerRow: 2,
          })}
          {formie.buildFields({
            fields: [{ dataPath: 'advancedSettings.type' }],
            fieldsPerRow: 1,
          })}
        </DrawerContentContainer>

        <DrawerActionsContainer>
          <Button onClick={handleClose} sx={{ mr: '16px' }}>
            Cancel
          </Button>

          <Button onClick={handleSubmit} disabled={disabled} variant="contained">
            Edit
          </Button>
        </DrawerActionsContainer>
      </DrawerContainer>
    </Drawer>
  );
};

export default SettingsDrawer;
