import { createAsyncThunk } from '@reduxjs/toolkit';

import { LoanCollateralAPI } from 'store/api';

export const updateCollateralThunk = createAsyncThunk('loanCollateral/update', async ({ collateralId, data, loanId }, thunkAPI) => {
  try {
    const { collateral } = await LoanCollateralAPI.updateLoanCollateral({
      data,
      collateralId,
      loanId,
    });

    /**
     * This will dispatch action creator with type of "loanCollateral/update/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { collateral };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loanCollateral/update/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchLoanCollateralThunk = createAsyncThunk('loanCollateral/fetchLoanCollateral', async ({ loanId }, thunkAPI) => {
  try {
    const { collaterals } = await LoanCollateralAPI.fetchLoanCollateral({
      loanId,
    });

    const collateral = collaterals && collaterals?.length && collaterals?.length > 0 ? collaterals[0] : {};

    /**
     * This will dispatch action creator with type of "loanCollateral/fetchLoanCollateral/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { collateral };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loanCollateral/fetchLoanCollateral/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteTitleImgThunk = createAsyncThunk('loanCollateral/deleteTitleImg', async ({ loanId, collateralId }, thunkAPI) => {
  try {
    const { collateral } = await LoanCollateralAPI.deleteTitleImg({
      loanId,
      collateralId,
    });

    /**
     * This will dispatch action creator with type of "loanCollateral/deleteTitleImg/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { collateral };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loanCollateral/deleteTitleImg/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const uploadTitleImgThunk = createAsyncThunk('loanCollateral/uploadTitleImg', async ({ collateralId, file, loanId }, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const { collateral } = await LoanCollateralAPI.uploadTitleImg({
      formData,
      collateralId,
      loanId,
    });

    /**
     * This will dispatch action creator with type of "loanCollateral/uploadTitleImg/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { collateral };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loanCollateral/uploadTitleImg/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});
