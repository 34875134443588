import { parseISO, format } from 'date-fns';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { PLAID_AUTH_STATUS } from 'constants/plaid';
import { formatCreditCard } from 'constants/formatters';

const { VERIFIED } = PLAID_AUTH_STATUS;

const CCPaymentProfile = ({ width, data }) => {
  const { xs, sm, md, lg, xl } = width ?? {};
  const { profileType, institutionName, institutionPrimaryColor, accountName, accountNumber, expiryDate, cvvCode, cardZip, verificationStatus } = data ?? {};

  return (
    <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardHeader
          sx={{ backgroundColor: institutionPrimaryColor ? institutionPrimaryColor : '#5301FF', minHeight: '60px' }}
          avatar={
            institutionName == 'American Express' ? (
              <Avatar sx={{ width: 52, height: 52, backgroundColor: '#fff' }} src="/images/american_express.svg" aria-label="Bank logo" />
            ) : institutionName == 'Visa' ? (
              <Avatar
                sx={{ width: 52, height: 52, paddingLeft: '2px', paddingRight: '2px', backgroundColor: '#fff' }}
                src="/images/visa.svg"
                aria-label="Bank logo"
              />
            ) : institutionName == 'Mastercard' ? (
              <Avatar sx={{ width: 58, height: 52, backgroundColor: '#fff' }} src="/images/master_card.svg" aria-label="Bank logo" />
            ) : institutionName == 'Discover' ? (
              <Avatar sx={{ width: 52, height: 52, backgroundColor: '#fff' }} src="/images/discover.webp" aria-label="Bank logo" />
            ) : institutionName == 'JCB' ? (
              <Avatar sx={{ width: 52, height: 52, backgroundColor: '#fff' }} src="/images/jcb.svg" aria-label="Bank logo" />
            ) : (
              <Avatar sx={{ width: 52, height: 52, backgroundColor: '#fff' }} aria-label="Bank logo">
                <AccountBalanceIcon sx={{ width: 38, height: 38, color: institutionPrimaryColor ? institutionPrimaryColor : '#5301FF' }} />
              </Avatar>
            )
          }
          title={
            <Typography sx={{ color: '#fff' }} variant="subtitle2" gutterBottom>
              {institutionName}
            </Typography>
          }
          subheader={
            profileType === 1 && [VERIFIED].includes(verificationStatus) ? (
              <Stack spacing={1} direction={'row'}>
                <Chip size="small" label="Primary" color="success" />
              </Stack>
            ) : null
          }
        />

        <CardContent>
          <Typography variant="body1">{accountName.replace('|', ' ')}</Typography>

          <Typography variant="body2">
            <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" gutterBottom>
              Credit Card
            </Typography>
          </Typography>

          <Typography variant="body2">
            <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" display="inline" gutterBottom>
              Card Number #:
            </Typography>{' '}
            {formatCreditCard(accountNumber)}
          </Typography>

          <Stack direction="row" spacing={1}>
            <Typography variant="body2">
              <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" display="inline" gutterBottom>
                Expiration:
              </Typography>{' '}
              {format(parseISO(expiryDate), 'MM/yy')}
            </Typography>

            <Typography variant="body2">
              <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" display="inline" gutterBottom>
                CVV:
              </Typography>{' '}
              {cvvCode}
            </Typography>

            <Typography variant="body2">
              <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" display="inline" gutterBottom>
                Zip:
              </Typography>{' '}
              {cardZip}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CCPaymentProfile;
