import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Button, CircularProgress, Backdrop } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';

import { useFormie } from 'components/Formie';
import { FlexRow } from 'components/Layouts';
import { setAlert } from 'store/sagas/app';
import SettingsDrawer from './SettingsDrawer';
import { COLORS } from 'constants/styles';
import Accordion from 'components/Accordion';
import { DetailsList } from '../LoanTerms/helpers';
import { currencyFormatter, percentageFormatterV2 } from 'constants/formatters';
import { configureAdvancedSettings } from '../formInfo';
import { updateLoanThunk } from 'store/thunks/loan';

const parseLateFeeType = (type) => {
  let prettyType = '-';

  if (type === 'percent-of-payment') prettyType = 'Percent of Late Payment';
  if (type === 'dollars') prettyType = 'Flat Fee';

  return prettyType;
};

const parseLateFeeAmount = (type, amount) => {
  let prettyAmount = '-';

  if (type === 'percent-of-payment' && amount) prettyAmount = percentageFormatterV2(amount);
  if (type === 'dollars' && amount) prettyAmount = currencyFormatter.format(amount);

  return prettyAmount;
};

const AdvancedSettings = ({ loan, rehydrateOnboarding, readyToActivate }) => {
  const dispatch = useDispatch();

  const { loanId } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      resetForm();
    }
  };

  const updateLoan = async () => {
    setLoading(true);
    try {
      await dispatch(
        updateLoanThunk({
          loanId,
          data: {
            grace_period: formie?.values?.advancedSettings?.grace_period,
            late_fee_amount: formie?.values?.advancedSettings?.late_fee_amount,
            late_fee_type: formie?.values?.advancedSettings?.late_fee_type,
            nsf_fee: formie?.values?.advancedSettings?.nsf_fee,
            timezone: formie?.values?.advancedSettings?.timezone,
          },
        }),
      ).unwrap();
      await rehydrateOnboarding();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to update settings. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const formie = useFormie({
    configuration: configureAdvancedSettings(loan?.late_fee_type || 'percent-of-payment'),
    initialValues: {
      advancedSettings: {
        late_fee_type: loan?.late_fee_type,
        late_fee_amount: loan?.late_fee_amount && Number(loan?.late_fee_amount),
        grace_period: loan?.grace_period && Number(loan?.grace_period),
        nsf_fee: loan?.nsf_fee && Number(loan?.nsf_fee),
        type: loan?.type,
        reporting_type: loan?.reporting_type,
        category: loan?.category,
        timezone: loan?.timezone,
      },
    },
    setLoading,
    onSubmit: updateLoan,
  });

  useEffect(() => {
    replaceConfiguration(configureAdvancedSettings(formie?.values?.advancedSettings?.late_fee_type || 'percent-of-payment'));
  }, [formie?.values?.advancedSettings?.late_fee_type]);

  const { handleSubmit, resetForm, replaceConfiguration } = formie;

  return (
    <div style={{ margin: 0, padding: readyToActivate ? '0px 0px 60px' : '0px' }}>
      <FlexRow padding="0px" margin="32px 0px 10px" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">How should we handle the advanced settings?</Typography>
        <Button onClick={() => setOpen(true)} startIcon={<ConstructionOutlinedIcon />}>
          Configure
        </Button>
      </FlexRow>

      <Accordion defaultExpanded summaryTitle="Advanced Settings">
        <Grid container spacing={2}>
          <DetailsList
            list={[
              { label: 'Late Fee Type', value: parseLateFeeType(loan?.late_fee_type) },
              { label: 'Late Fee Amount', value: parseLateFeeAmount(loan?.late_fee_type, loan?.late_fee_amount) },
              { label: 'Grace Period', value: loan?.grace_period ? `${loan?.grace_period} days` : '-' },
              { label: 'NSF Fee', value: loan?.nsf_fee ? currencyFormatter.format(loan?.nsf_fee) : '-', noDivider: true },
            ]}
          />
          <DetailsList
            list={[
              { label: 'Loan Type', value: loan?.type === 'installment' ? 'Installment' : '-' },
              { label: 'Reporting Type', value: loan?.reporting_type === 'installment' ? 'Installment' : '-' },
              { label: 'Category', value: loan?.category === 'automobile' ? '00 - Automobile' : '-' },
              { label: 'Timezone', value: loan?.timezone, noDivider: true },
            ]}
          />
        </Grid>
      </Accordion>

      <SettingsDrawer open={open} handleClose={handleClose} handleSubmit={handleSubmit} formie={formie} />

      <Backdrop sx={{ color: COLORS.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default AdvancedSettings;
