import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { fetchApplicationById } from 'store/sagas/applications';
import { updateApplicationReferenceThunk } from 'store/thunks/application';
import { useFormie } from 'components/Formie';
import { updateReferenceFormConfig } from './forminfo';

const UpdateReferenceFormModal = ({ applicationId, referenceId, firstName, lastName, phone, email, onClose }) => {
  const dispatch = useDispatch();

  const [hasErrors, setHasErrors] = useState(false);
  const [saving, setSaving] = useState(false);

  const formie = useFormie({
    configuration: updateReferenceFormConfig,
    initialValues: { first_name: firstName, last_name: lastName, phone: phone, email: email },
  });

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={true}>
      <DialogTitle>
        <Stack direction={'row'} spacing={1}>
          <AccountCircleIcon sx={{ fontSize: '2rem' }} />
          <Typography variant="h6">Update Reference</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        {hasErrors && <Alert severity="error">Failed to update reference.</Alert>}

        {formie.buildFields({
          fields: [{ dataPath: 'first_name' }, { dataPath: 'last_name' }, { dataPath: 'phone' }, { dataPath: 'email' }],
          fieldsPerRow: 2,
        })}
      </DialogContent>

      <DialogActions>
        {typeof onClose === 'function' && (
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
        )}

        <LoadingButton
          loading={saving}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={async () => {
            try {
              setHasErrors(false);
              setSaving(true);

              await dispatch(updateApplicationReferenceThunk({ applicationId, referenceId, data: formie.values })).unwrap();

              setSaving(false);

              dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

              onClose();
            } catch (err) {
              setSaving(false);
              setHasErrors(true);
              console.log('Updating application reference error: ', err);
            }
          }}
        >
          {saving ? 'Saving...' : 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateReferenceFormModal;
