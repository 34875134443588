import { useDispatch } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import { addLendingOption, updateLendingOption } from 'store/sagas/applications';
import { useFormie } from 'components/Formie';

import { prepareLendingOptionConfiguation } from './forminfo';

const LendingOptionModal = ({ applicationId, lendingOptionId, lenderId, initialValues, open, onClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (lendingOptionId) {
      // Update
      dispatch(updateLendingOption({ ...values, applicationId, lendingOptionId, lender_id: lenderId }));
    } else {
      // Create
      dispatch(addLendingOption({ ...values, applicationId, lender_id: lenderId }));
    }

    onClose();
  };

  const formie = useFormie({
    configuration: prepareLendingOptionConfiguation,
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={open}>
      <DialogTitle>{lendingOptionId ? 'Update Lending Option' : 'Add New Lending Option'}</DialogTitle>

      <DialogContent>
        {formie.buildFields({
          fields: [{ dataPath: 'max_amount_loaned' }, { dataPath: 'min_percent_down' }, { dataPath: 'term' }, { dataPath: 'apr' }],
          fieldsPerRow: 2,
        })}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={formie.handleSubmit} variant="contained">
          {lendingOptionId ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LendingOptionModal;
