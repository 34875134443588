import { Alert, Typography, LinearProgress, Avatar, Divider, CircularProgress, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { CalendarMonth } from '@mui/icons-material';

import { FlexRow } from 'components/Layouts';
import { currencyFormatter, formatDateOnly } from 'constants/formatters';
import ListItem from 'components/ListItem';
import { COLORS } from 'constants/styles';

const Overview = ({
  period,
  periodIsComplete,
  loading,
  paymentsEnteredDuringPaymentPeriod,
  firstDayLoanMetrics,
  lastDayLoanMetrics,
  dueDayLoanMetrics,
  hasPaid,
  dateFulfilled,
}) => {
  const paymentMetrics = paymentsEnteredDuringPaymentPeriod
    ? paymentsEnteredDuringPaymentPeriod.reduce(
        (acc, curr) => {
          return {
            totalPaymentAmount: acc.totalPaymentAmount + Number(curr.payment_amount),
            totalInterestAmount: acc.totalInterestAmount + Number(curr.interest_payment_amount),
            totalPrincipalAmount: acc.totalPrincipalAmount + Number(curr.principal_payment_amount),
            totalFeesAmount: acc.totalFeesAmount + Number(curr.fees_payment_amount),
          };
        },
        { totalPaymentAmount: 0, totalInterestAmount: 0, totalPrincipalAmount: 0, totalFeesAmount: 0 },
      )
    : { totalPaymentAmount: '-', totalInterestAmount: '-', totalPrincipalAmount: '-', totalFeesAmount: '-' };

  const originalMetrics = {
    title: 'Original Terms',
    startingPrincipal: period?.starting_principal_amount ? currencyFormatter.format(Number(period.starting_principal_amount)) : '-',
    accruedInterest: period?.accrued_interest ? currencyFormatter.format(Number(period.accrued_interest)) : '-',
    paymentAmount: period?.payment_amount ? currencyFormatter.format(Number(period.payment_amount)) : '-',
    interestAmount: period?.interest_payment_amount ? currencyFormatter.format(Number(period.interest_payment_amount)) : '-',
    principalAmount: period?.principal_payment_amount ? currencyFormatter.format(Number(period.principal_payment_amount)) : '-',
    feesAmount: currencyFormatter.format(0),
    endingPrincipal: period?.ending_principal_amount ? currencyFormatter.format(Number(period.ending_principal_amount)) : '-',
  };

  let otherMetrics = periodIsComplete
    ? {
        title: 'Actual',
        startingPrincipal: firstDayLoanMetrics ? currencyFormatter.format(Number(firstDayLoanMetrics.total_running_principal_amount)) : '-',
        accruedInterest: lastDayLoanMetrics ? currencyFormatter.format(Number(lastDayLoanMetrics.total_running_accrued_interest)) : '-',
        paymentAmount: currencyFormatter.format(paymentMetrics.totalPaymentAmount),
        interestAmount: currencyFormatter.format(paymentMetrics.totalInterestAmount),
        principalAmount: currencyFormatter.format(paymentMetrics.totalPrincipalAmount),
        feesAmount: currencyFormatter.format(paymentMetrics.totalFeesAmount),
        endingPrincipal: dueDayLoanMetrics ? currencyFormatter.format(Number(dueDayLoanMetrics.total_running_principal_amount)) : '-',
      }
    : {
        title: 'Forecast',
        startingPrincipal: '-',
        accruedInterest: '-',
        paymentAmount: '-',
        interestAmount: '-',
        principalAmount: '-',
        feesAmount: '-',
        endingPrincipal: '-',
      };

  const data = [originalMetrics, otherMetrics];
  return (
    <>
      <FlexRow padding="0px" margin="0px 0px 24px" flexColumn justifyContent="center" alignItems="center">
        <Typography variant="h4">{period?.payment_amount ? currencyFormatter.format(Number(period.payment_amount)) : '-'}</Typography>
        {/* {statusOfPeriod} */}
        <Chip size="small" sx={{ ml: '10px' }} color={hasPaid ? 'success' : 'error'} label={hasPaid ? `Fully Paid: ${dateFulfilled}` : 'Not Fully Paid'} />
      </FlexRow>

      <div style={{ padding: '0px 80px' }}>
        <LinearProgress sx={{ height: '4px', borderRadius: '8px', mt: '12px' }} variant="determinate" value={100} />

        <div style={{ position: 'relative', top: '-22px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Avatar sx={{ height: 34, width: 34, bgcolor: COLORS.primary }}>
            <CalendarMonth fontSize="small" />
          </Avatar>

          <Avatar sx={{ height: 34, width: 34, bgcolor: COLORS.primary }}>
            <CalendarMonth fontSize="small" />
          </Avatar>
        </div>

        <FlexRow padding="0px" margin="-20px 0px 20px" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{period?.from_date ? `Start: ${formatDateOnly(period.from_date)}` : '-'}</Typography>
          <Typography variant="body2">{period?.no_of_days ? `Days: ${period?.no_of_days}` : '-'}</Typography>
          <Typography variant="body2">{period?.due_date ? `Due: ${formatDateOnly(period.due_date)}` : '-'}</Typography>
        </FlexRow>
      </div>

      <Grid container spacing={2}>
        {data.map((section) => {
          return (
            <Grid key={section.title} xs={6}>
              <div style={{ padding: '10px', borderRadius: '8px', background: COLORS.backgroundColor }}>
                <Typography sx={{ fontWeight: 700, textAlign: 'center' }}>{section.title}</Typography>
                <Divider sx={{ m: '5px 0px 10px' }} />
                {loading && (section.title === 'Actual' || section.title === 'Forecast') ? (
                  <FlexRow margin="0px" padding="81px 0px" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </FlexRow>
                ) : (
                  [
                    { label: 'Starting Principal', value: section.startingPrincipal },
                    { label: 'Accrued Interest', value: section.accruedInterest },
                    { label: 'Payment Amount', value: section.paymentAmount },
                    { label: 'Principal', labelPrepend: ' ', value: section.principalAmount },
                    { label: 'Interest', labelPrepend: ' ', value: section.interestAmount },
                    { label: 'Fees', labelPrepend: ' ', value: section.feesAmount },
                    { label: 'Ending Principal', value: section.endingPrincipal },
                  ].map(({ label, value, labelPrepend }) => (
                    <ListItem key={label} margin="0px 0px 5px" label={label} value={value} labelPrepend={labelPrepend} />
                  ))
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>

      <Alert sx={{ m: '12px 0px' }} severity="info" color="primary">
        {periodIsComplete
          ? 'Actual numbers look at activity that happened during the payment period. The payments section below looks at payments that were applied to this period regardless of date.'
          : 'Forecast numbers assume payments will be made on time in the future.'}
      </Alert>
    </>
  );
};

export default Overview;
