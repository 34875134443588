import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SnowmobileIcon from '@mui/icons-material/Snowmobile';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { COLORS } from 'constants/styles';

/**
 * Get applicant icon.
 */
export const getApplicantIcon = ({ hasCoApplicant, size }) => {
  return hasCoApplicant ? (
    <PeopleAltTwoToneIcon fontSize={size} sx={{ color: 'primary.main' }} />
  ) : (
    <PersonIcon fontSize={size} sx={{ color: 'primary.main' }} />
  );
};

/**
 * Get application type icon.
 */
export const getApplicationTypeIcon = ({ type, tradeIn, size }) => {
  switch (type) {
    case 'Motorcycle':
    case 'ATV':
    case 'UTV':
      return tradeIn ? (
        <>
          <TwoWheelerIcon fontSize={size} sx={{ color: 'primary.main' }} />
          <CurrencyExchangeIcon fontSize={size} sx={{ color: COLORS.primaryPale, marginLeft: '0px !important' }} />
        </>
      ) : (
        <TwoWheelerIcon fontSize={size} sx={{ color: 'primary.main' }} />
      );

    case 'Personal Watercraft':
      return tradeIn ? (
        <>
          <DirectionsBoatIcon fontSize={size} sx={{ color: 'primary.main' }} />
          <CurrencyExchangeIcon fontSize={size} sx={{ color: COLORS.primaryPale, marginLeft: '0px !important' }} />
        </>
      ) : (
        <DirectionsBoatIcon fontSize={size} sx={{ color: 'primary.main' }} />
      );

    case 'Snowmobile':
      return tradeIn ? (
        <>
          <SnowmobileIcon fontSize={size} sx={{ color: 'primary.main' }} />
          <CurrencyExchangeIcon fontSize={size} sx={{ color: COLORS.primaryPale, marginLeft: '0px !important' }} />
        </>
      ) : (
        <SnowmobileIcon fontSize={size} sx={{ color: 'primary.main' }} />
      );

    case 'Sport Utility Trailer':
      return tradeIn ? (
        <>
          <AirportShuttleIcon fontSize={size} sx={{ color: 'primary.main' }} />
          <CurrencyExchangeIcon fontSize={size} sx={{ color: COLORS.primaryPale, marginLeft: '0px !important' }} />
        </>
      ) : (
        <AirportShuttleIcon fontSize={size} sx={{ color: 'primary.main' }} />
      );

    case 'Automobile':
      return tradeIn ? (
        <>
          <DirectionsCarIcon fontSize={size} sx={{ color: 'primary.main' }} />
          <CurrencyExchangeIcon fontSize={size} sx={{ color: COLORS.primaryPale, marginLeft: '0px !important' }} />
        </>
      ) : (
        <DirectionsCarIcon fontSize={size} sx={{ color: 'primary.main' }} />
      );

    default:
      return tradeIn ? (
        <>
          <DirectionsCarIcon fontSize={size} sx={{ color: 'primary.main' }} />
          <CurrencyExchangeIcon fontSize={size} sx={{ color: COLORS.primaryPale, marginLeft: '0px !important' }} />
        </>
      ) : (
        <DirectionsCarIcon fontSize={size} sx={{ color: 'primary.main' }} />
      );
  }
};

/**
 * Get application status icon.
 */
export const getApplicationStatusIcon = ({ statusObj, size }) => {
  if (!statusObj || !statusObj.label) return '';

  return (
    <Chip
      label={statusObj.label}
      color={statusObj.color}
      size={size}
      icon={statusObj.icon}
      variant={statusObj.outlined ? 'outlined' : 'filled'}
      style={{ cursor: 'pointer', minWidth: 155 }}
    />
  );
};
