import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { sourceCompanyConfiguration, sourceCompanyInitialValues } from './formInfo';
import { useFormie } from 'components/Formie';

const AddSourceCompany = ({ open, setOpen, createCompany, addingCompanyError, setAddingCompanyError }) => {
  const formie = useFormie({
    configuration: sourceCompanyConfiguration,
    initialValues: sourceCompanyInitialValues,
  });
  const { values } = formie;

  const handleClose = () => {
    setOpen(false);
    setAddingCompanyError(false);
  };

  return (
    <Dialog onClose={handleClose} fullWidth maxWidth="xs" scroll="paper" open={open}>
      <DialogTitle>Add Source Company</DialogTitle>

      <DialogContent>
        {formie.buildField('name')}
        {addingCompanyError && <Typography variant="caption">Source company names must be unique.</Typography>}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => createCompany(values.name)} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSourceCompany;
