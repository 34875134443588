import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ErrorRoute = () => {
  const user = useSelector((state) => state.user);

  if (!user || !user.id) {
    return <Navigate to="/login" />;
  }

  const entityType = user.active_profile?.dealer ? 'dealer' : 'lender';

  if (!!entityType) {
    return <Navigate to={`/app/${entityType}/dashboard`} />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ErrorRoute;
