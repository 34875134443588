import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_LOAN_FEES: ({ loanId }) => `/servicing/loans/${loanId}/fees`,
  CREATE_LOAN_FEE: ({ loanId }) => `/servicing/loans/${loanId}/fees`,
  REVERSE_LOAN_FEE: ({ loanId, feeId }) => `/servicing/loans/${loanId}/fees/${feeId}`,
  EDIT_LOAN_FEE: ({ loanId, feeId }) => `/servicing/loans/${loanId}/fees/${feeId}`,
};

export default {
  fetchLoanFees: ({ queryString, loanId }) =>
    request({
      url: `${URIS.FETCH_LOAN_FEES({ loanId })}${queryString || ''}`,
      method: METHODS.GET,
    }),

  reverseLoanFee: ({ loanId, feeId }) =>
    request({
      url: URIS.REVERSE_LOAN_FEE({ loanId, feeId }),
      method: METHODS.DELETE,
    }),

  createLoanFee: ({ loanId, data }) =>
    request({
      url: URIS.CREATE_LOAN_FEE({ loanId }),
      method: METHODS.POST,
      data,
    }),

  editLoanFee: ({ loanId, feeId, data }) =>
    request({
      url: URIS.EDIT_LOAN_FEE({ loanId, feeId }),
      method: METHODS.PATCH,
      data,
    }),
};
