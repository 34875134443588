import { get } from 'lodash';
import { useRef, useEffect } from 'react';
import { applicationFormConfiguration } from './formInfo';

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const countRequiredFields = (obj) => {
  return obj.filter((field) => field.required).length;
};

/**
 * We create our own dedicated function to count required fields for applicant/co_applicant
 * because it's required fields changes depending on the employment status.
 *
 * This is necessary to properly set the progress bar.
 */
const countRequiredApplicantFields = (type, obj, values) => {
  if (values && values[type] && ['Employed', 'Self Employed'].includes(values[type].employment_status)) {
    return obj.filter((field) => field.required).length;
  } else {
    return obj.filter((field) => field.required).filter((field) => ![`${type}.title`, `${type}.employer_phone`].includes(field.dataPath)).length;
  }
};

const countFilledFields = (obj, values, errors) => {
  const count = obj.filter((field) => {
    const value = get(values, field?.dataPath);
    const error = get(errors, field?.dataPath);
    const hasValue = value === 0 || !!value;
    const hasError = !!error;
    const isRequired = field.required;
    return hasValue && !hasError && isRequired;
  }).length;

  return count;
};

/**
 * We create our own dedicated function to count the necessary filled fileds for applicant/co_applicant
 * because the filled fields to include in the count depends on the current required fields which depends
 * on the employment status.
 *
 * This is necessary to properly set the progress bar.
 */
const countFieldApplicantFields = (type, obj, values, errors) => {
  if (values && values[type] && ['Employed', 'Self Employed'].includes(values[type].employment_status)) {
    return obj.filter((field) => {
      const value = get(values, field?.dataPath);
      const error = get(errors, field?.dataPath);
      const hasValue = value === 0 || !!value;
      const hasError = !!error;
      const isRequired = field.required;
      return hasValue && !hasError && isRequired;
    }).length;
  } else {
    return obj
      .filter((field) => ![`${type}.title`, `${type}.employer_phone`].includes(field.dataPath))
      .filter((field) => {
        const value = get(values, field?.dataPath);
        const error = get(errors, field?.dataPath);
        const hasValue = value === 0 || !!value;
        const hasError = !!error;
        const isRequired = field.required;
        return hasValue && !hasError && isRequired;
      }).length;
  }
};

export const calculateSavability = ({ values, hasCoApplicant, hasTradeIn }) => {
  const configuration = applicationFormConfiguration({});

  const hasValidApplicationType = configuration?.type?.id?.validator(values?.type?.id);
  const hasValidLenderId = configuration?.lender?.id?.validator(values?.lender?.id);
  const hasValidApplicantEmail = configuration?.applicant?.email?.validator(values?.applicant?.email);
  const hasValidCoApplicantEmail = configuration?.co_applicant?.email?.validator(values?.co_applicant?.email);
  const hasNoUnitVIN = !values?.unit?.vin;
  const hasValidUnitVIN = configuration?.unit?.vin?.validator(values?.unit?.vin);
  const hasNoTradeInVIN = !values?.trade_unit?.vin;
  const hasValidTradeInVIN = configuration?.trade_unit?.vin?.validator(values?.trade_unit?.vin);

  const basicInfoCheck = hasValidApplicationType && hasValidLenderId;
  const coApplicantCheck = !hasCoApplicant || hasValidCoApplicantEmail;
  const unitCheck = hasNoUnitVIN || hasValidUnitVIN;
  const tradeInCheck = !hasTradeIn || hasNoTradeInVIN || hasValidTradeInVIN;

  const canSave = basicInfoCheck && hasValidApplicantEmail && coApplicantCheck && unitCheck && tradeInCheck;

  return [canSave, hasValidUnitVIN, hasValidTradeInVIN];
};

export const calculateProgress = ({ values = {}, errors = {}, hasTradeIn, hasCoApplicant }) => {
  const configuration = applicationFormConfiguration({});

  const lenderFields = Object.values(configuration?.lender);
  const typeFields = Object.values(configuration?.type);
  const applicantFields = Object.values(configuration?.applicant);
  const coApplicantFields = Object.values(configuration?.co_applicant);
  const unitFields = Object.values(configuration?.unit);
  const tradeUnitFields = Object.values(configuration?.trade_unit);

  const numOfLenderFields = countRequiredFields(lenderFields);
  const numOfTypeFields = countRequiredFields(typeFields);
  const numOfApplicantFields = countRequiredApplicantFields('applicant', applicantFields, values);
  const numOfCoApplicantFields = countRequiredApplicantFields('co_applicant', coApplicantFields, values);
  const numOfUnitFields = countRequiredFields(unitFields);
  const numOfTradeUnitFields = countRequiredFields(tradeUnitFields);
  const numOfBasicInfoFields = numOfLenderFields + numOfTypeFields;

  const numOfFilledLenderFields = countFilledFields(lenderFields, values, errors);
  const numOfFilledTypeFields = countFilledFields(typeFields, values, errors);
  const numOfFilledApplicantFields = countFieldApplicantFields('applicant', applicantFields, values, errors);
  const numOfFilledCoApplicantFields = countFieldApplicantFields('co_applicant', coApplicantFields, values, errors);
  const numOfFilledUnitFields = countFilledFields(unitFields, values, errors);
  const numOfFilledTradeUnitFields = countFilledFields(tradeUnitFields, values, errors);
  const numOfFilledBasicInfoFields = numOfFilledLenderFields + numOfFilledTypeFields;

  const totalFields =
    numOfBasicInfoFields + numOfApplicantFields + numOfUnitFields + hasCoApplicant * numOfCoApplicantFields + hasTradeIn * numOfTradeUnitFields;

  const totalFilledFields =
    numOfFilledBasicInfoFields +
    numOfFilledApplicantFields +
    numOfFilledUnitFields +
    hasCoApplicant * numOfFilledCoApplicantFields +
    hasTradeIn * numOfFilledTradeUnitFields;

  return {
    basicInfoProgress: numOfFilledBasicInfoFields / numOfBasicInfoFields,
    applicantProgress: numOfFilledApplicantFields / numOfApplicantFields,
    coApplicantProgress: hasCoApplicant ? numOfFilledCoApplicantFields / numOfCoApplicantFields : 1,
    unitProgress: numOfFilledUnitFields / numOfUnitFields,
    tradeInProgress: hasTradeIn ? numOfFilledTradeUnitFields / numOfTradeUnitFields : 1,
    totalProgress: totalFilledFields / totalFields,
  };
};
