import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import get from 'lodash/get';

import { INPUTS } from './inputs';
import { FlexRow } from 'components/Layouts';

const copyObject = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    if (Array.isArray(obj)) {
      return [];
    } else {
      return null;
    }
  }

  let copy = {};
  for (let key in obj) {
    copy[key] = copyObject(obj[key]);
  }

  return copy;
};

/**
 * @param {object} configuration - required
 * This is used to inform Formie about what fields will be present in the form and what their attributes will be.
 * The structure should match the structure of your initialValues object.
 *
 * {
 *  type, - What type of field will this be. Accepts values from the TYPES object. Required.
 *  dataPath, - Where in the values object does this field's data reside? Accepts dot or bracket notation. Required.
 *  required, - Whether or not the field is required. Optional.
 *  validator, - How to validate the field. Validator should return true if value is valid and false if it is invalid. Optional.
 *  label, - What should the label of the field be. Optional, defaults to "".
 *  options, - If the type is select or basicSelect, this will be used to supply options to the field. Optional, defaults to [].
 *  mask, - If the type is masked, this is required.
 *  disabled, - Optional.
 * }
 *
 * @param {object} initialValues - required
 * This is used to inform Formie about what the initial values of the form will be.
 *
 * * @param {function} setLoading - optional
 * This is used so Formie can set a loader on behalf of the form, when necessary. Optional unless you are using any of the extract functions.
 *
 * @param {function} onSubmit - optional
 * This is passed as a callback to handleSubmit. If all checks in handleSubmit pass, this function will be called.
 */
export const useFormie = ({ configuration: incomingConfiguration = {}, initialValues = {}, setLoading, onSubmit }) => {
  const [configurationManuallyOverridden, setConfigurationManuallyOverridden] = useState(false);
  const [configuration, setConfiguration] = useState(incomingConfiguration);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(copyObject(initialValues));
  const [checkedSSNs, setCheckedSSNs] = useState({});
  const dirty = !isEqual(values, initialValues);

  useEffect(() => {
    if (!isEqual(incomingConfiguration, configuration) && !configurationManuallyOverridden) setConfiguration(incomingConfiguration);
  }, [incomingConfiguration]);

  const replaceConfiguration = (newConfiguration) => {
    if (newConfiguration && typeof newConfiguration === 'object') {
      setConfigurationManuallyOverridden(true);
      setConfiguration(newConfiguration);
    }
  };

  const validateNestedObject = (obj) => {
    const errors = {};
    let isValid = true;
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        if (obj[key].hasOwnProperty('dataPath') && obj[key].hasOwnProperty('validator')) {
          const { dataPath, validator, required } = obj[key];
          const value = get(values, dataPath);
          const result = validator(value);
          if (!required && !value) {
            errors[dataPath] = false;
          } else if (!result) {
            errors[dataPath] = true;
            isValid = false;
          } else {
            errors[dataPath] = false;
          }
        } else {
          const result = validateNestedObject(obj[key]);
          if (!result.isValid) {
            errors[key] = true;
            isValid = false;
          } else {
            errors[key] = false;
          }
        }
      }
    }
    return { isValid, errors };
  };

  const handleSubmit = () => {
    if (!onSubmit) return null;

    const { isValid, errors } = validateNestedObject(configuration);

    if (!isValid) {
      setErrors(errors);
    } else {
      onSubmit(values);
    }
  };

  /**
   * This helper is used to reset the form to its original state and clear all errors.
   */
  const resetForm = () => {
    setValues(initialValues);
    setErrors(copyObject(initialValues));
  };

  /**
   * This helper is used so the parent component can set initialValues, but then reset all of those values later in the lifecycle if needed.
   */
  const setAllValues = (values) => {
    setValues(values);
  };

  /**
   * This helper is used so the parent component can directly set the value of a speciifc field, one at a time.
   */
  const handleChange = (dataPath, value) => {
    let copyOfValues = structuredClone(values);
    set(copyOfValues, dataPath, value);
    setValues(copyOfValues);
  };

  /**
   * This is a helper solely for the prepareContract view.
   */
  const removeCustomFieldValues = (num) => {
    let copyOfValues = structuredClone(values);
    copyOfValues.contract_values[`custom_field_${num}_cost`] = null;
    copyOfValues.contract_values[`custom_field_${num}_for`] = null;
    copyOfValues.contract_values[`custom_field_${num}_to`] = null;

    setValues(copyOfValues);
  };

  /**
   * This is passed to the inputs themselves to run the validator function and trigger an error in the case of invalid inputs.
   */
  const handleBlur = (dataPath) => {
    const field = get(configuration, dataPath);
    const validator = field?.validator;
    let copyOfErrors = structuredClone(errors);

    if (!validator) {
      set(copyOfErrors, dataPath, false);
      setErrors(copyOfErrors);
    } else {
      const value = get(values, dataPath);
      const valid = validator(value);
      set(copyOfErrors, dataPath, !valid);
      setErrors(copyOfErrors);
    }
  };

  /**
   * This is used for the address lookup (GooglePlaces) component and utilized by the editApp component.
   */
  const handleAddressValues = (address, dataPath) => {
    let copyOfValues = structuredClone(values);
    Object.entries(address).forEach(([key, val]) => {
      set(copyOfValues, `${dataPath}.${key}`, val);
    });

    setValues(copyOfValues);
  };

  /**
   * This is used by the vinLookup input. Extracts the values from the VIN. Utilized by editApp component.
   */
  const handleVinBlur = (dataPath, foundData) => {
    handleBlur(dataPath);

    if (foundData) {
      let copyOfValues = structuredClone(values);
      set(copyOfValues, dataPath.split('.')[0], { ...copyOfValues[dataPath.split('.')[0]], ...foundData });
      setValues(copyOfValues);
    }
  };

  /**
   * Used by the editApp component to lookup applicant data by ssn on blur.
   */
  const handleSSNLookupBlur = (dataPath, foundData) => {
    handleBlur(dataPath);
    const checkedSSNsCopy = structuredClone(checkedSSNs);
    set(checkedSSNsCopy, dataPath.split('.')[0], true);
    setCheckedSSNs(checkedSSNsCopy);

    if (foundData) {
      let copyOfValues = structuredClone(values);
      set(copyOfValues, dataPath.split('.')[0], foundData);
      setValues(copyOfValues);
    }
  };

  const resetCheckedSSNs = (obj) => {
    setCheckedSSNs(obj);
  };

  /**
   * Used to build a single field with no formatting and simpler syntax.
   */
  const buildField = (dataPath, props = {}) => {
    const configurationObject = get(configuration, dataPath);

    if (!configurationObject || !configurationObject.type || !configurationObject.dataPath) return null;

    const type = configurationObject.type;

    return INPUTS[type]({
      configuration: { ...configurationObject, ...props, handleBlur, handleChange, checkedSSNs, handleSSNLookupBlur, setLoading, handleVinBlur },
      errors,
      values,
    });
  };

  /**
   * Used to build any number of fields in a pre-formatted way. Allows for passing extra props to the individual inputs.
   * Expects: {
   *  fields: [{
   *    dataPath: [insert path to data in dot or bracket notation here] - required,
   *    extraProps: pass an object of extraProps here - optional
   * }]
   * }
   */
  const buildFields = ({ fields, fieldsPerRow }) => {
    const inputWidth = fieldsPerRow ? `calc(${100 / fieldsPerRow}% - ${fieldsPerRow * 3}px)` : `100%`;

    return (
      <FlexRow margin="12px 0px 0px" padding="0px" justifyContent="flex-start" width="100%" flexWrap={true} gap="12px 12px">
        {fields.map((field) => {
          const { dataPath, extraProps = {} } = field;
          const props = { ...extraProps, inputWidth };

          return buildField(dataPath, props);
        })}
      </FlexRow>
    );
  };

  return {
    errors,
    values,
    handleChange,
    handleBlur,
    dirty,
    buildField,
    buildFields,
    checkedSSNs,
    handleAddressValues,
    setAllValues,
    resetCheckedSSNs,
    removeCustomFieldValues,
    handleSubmit,
    resetForm,
    replaceConfiguration,
  };
};

// Still need submit functionality to validate all of the values before submitting?
