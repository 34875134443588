import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import { integerFormatter } from 'constants/formatters';
import { configuration } from '../formInfo';
import { useFormie } from 'components/Formie';
import { updateCollateralThunk } from 'store/thunks/loanCollateral';
import { setAlert } from 'store/sagas/app';

const Collateral = ({ collateral: incomingCollateral = {}, rehydrateTopLevel }) => {
  const dispatch = useDispatch();

  const { loanId } = useParams();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collateral, setCollateral] = useState(incomingCollateral);
  const { vin, year, make, model, condition, mileage, id } = collateral || {};
  const initialValues = {
    collateral: {
      vin,
      year,
      make,
      model,
      condition,
      mileage,
    },
  };

  const updateCollateral = async () => {
    setLoading(true);
    try {
      const { collateral: updatedCollateral = {} } = await dispatch(
        updateCollateralThunk({ data: { ...formie?.values?.collateral }, loanId, collateralId: id }),
      ).unwrap();
      setCollateral(updatedCollateral);
      rehydrateTopLevel();
      setEditing(false);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to update collateral. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const formie = useFormie({
    configuration,
    initialValues,
    setLoading,
    onSubmit: updateCollateral,
  });

  const { values, handleSubmit, resetForm } = formie;
  const disabled =
    !values?.collateral?.vin ||
    !values?.collateral?.year ||
    !values?.collateral?.make ||
    !values?.collateral?.model ||
    !values?.collateral?.condition ||
    !values?.collateral?.mileage;

  const cancelEdit = () => {
    setEditing(false);
    resetForm();
  };

  return (
    <>
      <Typography variant="h5">What is the collateral?</Typography>
      {editing ? (
        <FlexRow flexColumn padding="10px 0px" margin="8px 0px 0px" outlined rounded backgroundColor={COLORS.surfaceColor}>
          <FlexRow padding="0px 10px" margin="0px">
            {formie.buildFields({
              fields: [
                { dataPath: 'collateral.vin' },
                { dataPath: 'collateral.year' },
                { dataPath: 'collateral.make' },
                { dataPath: 'collateral.model' },
                { dataPath: 'collateral.condition' },
                { dataPath: 'collateral.mileage' },
              ],
              fieldsPerRow: 2,
            })}
          </FlexRow>

          <FlexRow borderedTop margin="16px 0px 0px" padding="8px 8px 0px" alignItems="center" justifyContent="flex-end">
            <Button sx={{ mr: '16px' }} onClick={cancelEdit}>
              Cancel
            </Button>
            <Button disabled={disabled} variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </FlexRow>

          <Backdrop sx={{ color: COLORS.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </FlexRow>
      ) : (
        <FlexRow alignItems="center" padding="10px" margin="8px 0px 0px" outlined rounded backgroundColor={COLORS.surfaceColor}>
          <DirectionsCarOutlinedIcon fontSize="large" color="primary" sx={{ mr: '10px' }} />

          <FlexRow margin="0px" padding="0px" flexColumn>
            <Typography variant="body1">{`${collateral?.condition} ${collateral?.year} ${collateral?.make} ${collateral?.model}`}</Typography>
            <Typography variant="caption">{`VIN: ${collateral?.vin} || Mileage: ${integerFormatter.format(collateral?.mileage)}`}</Typography>
          </FlexRow>

          <Button onClick={() => setEditing(true)}>Edit</Button>
        </FlexRow>
      )}
    </>
  );
};

export default Collateral;
