import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    document_templates: [],
    stipulation_templates: [],
    application_shape: [],
    users: [],
    lender: {},
    loading: {
      document_templates: false,
      stipulation_templates: false,
      application_shape: false,
      users: false,
      lender: false,
    },
  },
  reducers: {
    fetchDocumentTemplates(state, action) {},
    storeDocumentTemplates(state, { payload }) {
      state.document_templates = payload;
    },
    startLoader(state, { payload }) {
      state.loading[payload] = true;
    },
    stopLoader(state, { payload }) {
      state.loading[payload] = false;
    },
    saveDocumentTemplate(state, action) {},
    createDocumentTemplate(state, action) {},
    archiveDocumentTemplate(state, action) {},
    fetchStipulationTemplates(state, action) {},
    storeStipulationTemplates(state, { payload }) {
      state.stipulation_templates = payload;
    },
    createStipulationTemplate(state, action) {},
    updateStipulationTemplate(state, action) {},
    archiveStipulationTemplate(state, action) {},
    fetchApplicationShape(state, action) {},
    storeApplicationShape(state, { payload }) {
      state.application_shape = payload;
    },
    addDealerUser(state, { payload }) {},
    updateDealerUser(state, { payload }) {},
    fetchDealerUsers(state, { payload }) {},
    storeDealerUsers(state, { payload }) {
      state.users = payload;
    },
    updateLender(state, { payload }) {},
    fetchLender(state, { payload }) {},
    storeLender(state, { payload }) {
      state.lender = payload;
    },
    addLenderUser(state, { payload }) {},
    updateLenderUser(state, { payload }) {},
    fetchLenderUsers(state, { payload }) {},
    storeLenderUsers(state, { payload }) {
      state.users = payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = adminSlice;

// Extract and export each action creator by name
export const {
  fetchDocumentTemplates,
  storeDocumentTemplates,
  startLoader,
  stopLoader,
  saveDocumentTemplate,
  createDocumentTemplate,
  archiveDocumentTemplate,
  fetchStipulationTemplates,
  storeStipulationTemplates,
  fetchApplicationShape,
  storeApplicationShape,
  createStipulationTemplate,
  updateStipulationTemplate,
  archiveStipulationTemplate,
  addDealerUser,
  updateDealerUser,
  fetchDealerUsers,
  storeDealerUsers,
  updateLender,
  fetchLender,
  storeLender,
  addLenderUser,
  updateLenderUser,
  fetchLenderUsers,
  storeLenderUsers,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
