import { METHODS, request } from 'services/axios';

const URIS = {
  LOGIN: () => `/auth/login`,
  LOGOUT: () => `/auth/logout`,
};

export default {
  login: ({ data }) =>
    request({
      url: URIS.LOGIN(),
      method: METHODS.POST,
      data,
    }),

  logout: () =>
    request({
      url: URIS.LOGOUT(),
      method: METHODS.POST,
    }),
};
