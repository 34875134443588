import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { Card, FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import { currencyFormatter } from 'constants/formatters';

const OtherCharges = ({
  allValues,
  showESC,
  setShowESC,
  showPPM,
  setShowPPM,
  showGAP,
  setShowGAP,
  showCustomFields,
  setShowCustomFields,
  customFieldsArray,
  addCustomField,
  removeCustomField,
  formie,
}) => {
  const {
    contract_values: { total_other_charges },
    helper_values: { net_trade_in_if_negative },
  } = allValues;
  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Other Charges
        </Typography>
        <Typography variant="h5">{currencyFormatter.format(total_other_charges)}</Typography>
      </FlexRow>

      {net_trade_in_if_negative > 0 && (
        <FlexRow margin="12px 0px 0px" padding="0">
          <Alert sx={{ width: '100%' }} severity="info">
            <AlertTitle>Negative Trade Equity</AlertTitle>
            {`This deal has negative trade equity. ${currencyFormatter.format(net_trade_in_if_negative)} is being added to Other Charges.`}
          </Alert>
        </FlexRow>
      )}

      <Typography variant="overline" display="block" style={{ marginTop: '20px', color: COLORS.primary }}>
        Government Fees
      </Typography>
      {formie.buildFields({
        fields: [{ dataPath: 'contract_values.title_fees' }, { dataPath: 'contract_values.doc_fee' }],
        fieldsPerRow: 2,
      })}
      {formie.buildFields({
        fields: [{ dataPath: 'contract_values.license_reg_fees' }],
      })}

      {[
        { title: 'Extended Service Contract', open: showESC, handleToggle: setShowESC, pathPrefix: 'esc' },
        { title: 'Prepaid Maintenance Contract', open: showPPM, handleToggle: setShowPPM, pathPrefix: 'ppm' },
        { title: 'GAP Contract', open: showGAP, handleToggle: setShowGAP, pathPrefix: 'gap' },
      ].map(({ title, open, handleToggle, pathPrefix }) => (
        <Fragment key={title}>
          <FlexRow padding="0px" margin="20px 0px 0px" justifyContent="space-between" alignItems="center">
            <Typography variant="overline" display="block" style={{ color: COLORS.primary }}>
              {title}
            </Typography>

            <FormControlLabel style={{ margin: '0px' }} control={<Switch checked={open} onChange={(e) => handleToggle(e.target.checked)} />} />
          </FlexRow>

          {open && (
            <>
              {formie.buildFields({
                fields: [{ dataPath: `contract_values.${pathPrefix}_cost` }, { dataPath: `contract_values.${pathPrefix}_term` }],
                fieldsPerRow: 2,
              })}
              {formie.buildFields({
                fields: [{ dataPath: `contract_values.${pathPrefix}_company` }],
              })}
            </>
          )}
        </Fragment>
      ))}

      <FlexRow padding="0px" margin="20px 0px 0px" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" display="block" style={{ color: COLORS.primary }}>
          Custom Fields
        </Typography>
        <FormControlLabel style={{ margin: '0px' }} control={<Switch checked={showCustomFields} onChange={(e) => setShowCustomFields(e.target.checked)} />} />
      </FlexRow>

      {showCustomFields &&
        customFieldsArray.map((_, i) => {
          const num = i + 1;
          return (
            <Fragment key={`custom_field_${num}`}>
              {formie.buildFields({
                fields: [
                  { dataPath: `contract_values.custom_field_${num}_for` },
                  { dataPath: `contract_values.custom_field_${num}_to` },
                  { dataPath: `contract_values.custom_field_${num}_cost` },
                ],
                fieldsPerRow: 3,
              })}
            </Fragment>
          );
        })}

      {showCustomFields && (
        <Divider style={{ marginTop: '20px' }}>
          <ButtonGroup variant="contained">
            {
              <Button disabled={!(customFieldsArray.length > 1)} onClick={removeCustomField} size="small">
                <RemoveIcon />
              </Button>
            }
            {
              <Button disabled={!(customFieldsArray.length < 4)} onClick={addCustomField} size="small">
                <AddIcon />
              </Button>
            }
          </ButtonGroup>
        </Divider>
      )}
    </Card>
  );
};

export default OtherCharges;
