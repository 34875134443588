import * as Yup from 'yup';
import { useFormik } from 'formik';

import { FlexRow } from 'components/Layouts';
import inputs from './inputs';

export const useBuildFormik = ({ formInfo, onSubmit, values, validate, customValidationObj }) => {
  const validationObj = customValidationObj
    ? customValidationObj
    : formInfo.filter((field) => field.validator).reduce((obj, item) => ((obj[item.parameterName] = item.validator), obj), {});
  const initialValues = values || formInfo.reduce((obj, item) => ((obj[item.parameterName] = item.initialValue), obj), {});

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(validationObj),
    onSubmit,
    validate,
  });

  return formik;
};

export const buildFormikFields = ({ formik, fields, fieldsPerRow }) => {
  const inputWidth = fieldsPerRow ? `calc(${100 / fieldsPerRow}% - ${fieldsPerRow * 3}px)` : `100%`;

  return (
    <FlexRow margin="12px 0px 0px" padding="0px" justifyContent="flex-start" width="100%" flexWrap={true} gap="12px 12px">
      {fields.map((field) => {
        const fieldProps = {
          ...field,
          formik,
          inputWidth,
        };

        return inputs[field.type](fieldProps);
      })}
    </FlexRow>
  );
};
