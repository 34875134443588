import { createAsyncThunk } from '@reduxjs/toolkit';

import { PastDueAPI } from 'store/api';

export const fetchLoanPastDuesThunk = createAsyncThunk(
  'pastDues/fetchLoanPastDues',
  async ({ subdata, loanId, limit = 30, sort = 'created_at|ASC', offset = 1 }, thunkAPI) => {
    try {
      let queryString;

      if (!subdata || !subdata?.length || subdata.length < 1) {
        queryString = `?limit=${limit}&sort=${sort}&offset=${offset}`;
      } else {
        queryString = `?subdata=${subdata.join(',')}&limit=${limit}&sort=${sort}&offset=${offset}`;
      }

      const { loanPastDues, metadata } = await PastDueAPI.fetchLoanPastDues({ queryString, loanId });

      /**
       * This will dispatch action creator with type of "pastDues/fetchLoanPastDues/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return { pastDues: loanPastDues, metadata };
    } catch (err) {
      /**
       * This will dispatch action creator with type of "pastDues/fetchLoanPastDues/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);
