import { METHODS, request } from 'services/axios';

const URIS = {
  FIND_APPLICANT_BY_SSN: () => `/applicants/find-by-ssn`,
  TRIGGER_APPLICANT_OTP: ({ applicantId }) => `/applicants/${applicantId}/verify/trigger`,
  CHECK_APPLICANT_OTP: ({ applicantId }) => `/applicants/${applicantId}/verify/check`,
  FETCH_CREDIT_REPORT: ({ applicantId }) => `/applicants/${applicantId}/credit-report`,
};

export default {
  fetchApplicantBySSN: ({ data }) =>
    request({
      url: URIS.FIND_APPLICANT_BY_SSN(),
      method: METHODS.POST,
      data,
    }),

  tiggerApplicantOTP: ({ applicantId, data }) =>
    request({
      url: URIS.TRIGGER_APPLICANT_OTP({ applicantId }),
      method: METHODS.POST,
      data,
    }),

  checkApplicantOtp: ({ applicantId, data }) =>
    request({
      url: URIS.CHECK_APPLICANT_OTP({ applicantId }),
      method: METHODS.POST,
      data,
    }),

  fetchApplicantCreditReport: ({ applicantId }) =>
    request({
      url: URIS.FETCH_CREDIT_REPORT({ applicantId }),
      method: METHODS.GET,
    }),
};
