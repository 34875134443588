import { Typography } from '@mui/material';

import { buildFormikFields } from 'components/Formiks - Deprecated';
import { FlexRow } from 'components/Layouts';
import { triggerFormInfo } from './formInfo';

const Trigger = ({ position, applicationShape, formik, values }) => {
  const showCompareValue = !!(values?.operation !== 'exists' && values?.operation !== 'does not exist');
  const fields = triggerFormInfo(applicationShape, position, showCompareValue);
  const { attribute, operation } = fields;

  return (
    <FlexRow padding="0px 20px" margin="0" flexColumn>
      <Typography sx={{ margin: '5px 0px -10px', fontWeight: 500 }} variant="body1">
        {position === 0 ? 'IF' : 'AND'}
      </Typography>
      {buildFormikFields({
        formik,
        fields: showCompareValue ? Object.values(fields) : [attribute, operation],
        fieldsPerRow: showCompareValue ? 3 : 2,
      })}
    </FlexRow>
  );
};

export default Trigger;
