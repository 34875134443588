import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import copy from 'copy-to-clipboard';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { LINKS } from 'constants/menus';
import { formatSSN, formatPhoneNumber, currencyFormatter } from 'constants/formatters';
import { Card } from 'components/Layouts';
import DataListItem from './../dataListItem';

const Address = ({ applicant }) => {
  if (!applicant) return '';
  const { address_1, address_2, city, state, zip } = applicant;
  return (
    <div>
      {address_1 && city && state && zip ? (
        <Fragment>
          <Typography variant="body1">{address_1}</Typography>
          {address_2 && <Typography variant="body1">{address_2}</Typography>}
          <Typography variant="body1">{`${city}, ${state} ${zip}`}</Typography>
        </Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

const Applicant = ({ applicant, isCoApplicant, appId, creditScore }) => {
  const navigate = useNavigate();

  const [copyTooltipText, setCopyTooltipText] = useState('Click to copy');

  return (
    <Fragment>
      <Card margin="0px 0px 20px" padding="20px 20px 0px" fullwidth variant="outlined">
        <Typography style={{ margin: '0px' }} variant="h5">
          {isCoApplicant ? 'Co-Applicant' : 'Applicant'}
        </Typography>

        <List>
          {[
            { title: 'Name', data: `${applicant?.first_name || ''} ${applicant?.last_name || ''}` },
            {
              title: 'SSN',
              data: applicant?.ssn && (
                <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                  <Typography>{formatSSN(applicant.ssn)}</Typography>
                  <Tooltip title={copyTooltipText} onOpen={() => setCopyTooltipText('Click to copy')}>
                    <IconButton
                      size="small"
                      aria-label="copy"
                      onClick={(e) => {
                        copy(formatSSN(applicant.ssn));
                        setCopyTooltipText('Copied');
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <ContentCopyIcon fontSize="16px" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ),
            },
            {
              title: 'Credit Score',
              data: creditScore,
            },
            { title: 'Birthday', data: applicant?.dob && format(parseISO(applicant.dob), 'MM/dd/yyyy') },
            {
              title: 'Email',
              data: applicant?.email && (
                <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                  <Typography>{applicant.email}</Typography>
                  <Tooltip title={copyTooltipText} onOpen={() => setCopyTooltipText('Click to copy')}>
                    <IconButton
                      size="small"
                      aria-label="copy"
                      onClick={(e) => {
                        copy(applicant.email);
                        setCopyTooltipText('Copied');
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <ContentCopyIcon fontSize="16px" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ),
            },
            {
              title: 'Phone',
              data: applicant?.phone && (
                <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                  <Typography>{formatPhoneNumber(applicant.phone)}</Typography>
                  <Tooltip title={copyTooltipText} onOpen={() => setCopyTooltipText('Click to copy')}>
                    <IconButton
                      size="small"
                      aria-label="copy"
                      onClick={(e) => {
                        copy(formatPhoneNumber(applicant.phone));
                        setCopyTooltipText('Copied');
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <ContentCopyIcon fontSize="16px" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ),
            },
            { title: 'Address', data: <Address applicant={applicant} /> },
            { title: 'Address Duration', data: applicant?.address_months_at ? `${applicant.address_months_at} months` : '' },
            { title: 'Home Ownership', data: applicant?.home_ownership },
            {
              title: applicant?.home_ownership === 'Own' ? 'Monthly Mortgage' : applicant?.home_ownership === 'Rent' ? 'Monthly Rent' : 'Home Monthly Payment',
              data: applicant?.home_monthly_payment && currencyFormatter.format(applicant.home_monthly_payment),
            },
            { title: 'Employment Status', data: applicant?.employment_status },
            { title: 'Title', data: applicant?.title },
            { title: 'Income', data: applicant?.annual_income && currencyFormatter.format(applicant.annual_income) },
            { title: 'Employer', data: applicant?.employer },
            {
              title: 'Employer Phone',
              data: applicant?.employer_phone && formatPhoneNumber(applicant.employer_phone),
            },
            { title: 'Employer Duration', data: applicant?.employer_months_at ? `${applicant.employer_months_at} months` : '', noDivider: true },
          ].map(({ title, data, noDivider, onClick, tooltip }, i) => (
            <DataListItem key={`${title}-${i}`} title={title} data={data} noDivider={noDivider} onClick={onClick} tooltip={tooltip} />
          ))}
        </List>
      </Card>
    </Fragment>
  );
};

export default Applicant;
