import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaymentProfileAPI } from 'store/api';

export const fetchLoanPaymentProfilesThunk = createAsyncThunk('payment-profiles/getLoanPaymentProfiles', async ({ data, loanId }, thunkAPI) => {
  try {
    const { paymentProfiles } = await PaymentProfileAPI.fetchLoanPaymentProfiles({ loanId });

    /**
     * This will dispatch action creator with type of "payment-profiles/getLoanPaymentProfiles/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { paymentProfiles };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payment-profiles/getLoanPaymentProfiles/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const addBankPaymentProfileThunk = createAsyncThunk('payment-profiles/addBankPaymentProfile', async ({ data, loanId }, thunkAPI) => {
  try {
    const { paymentProfile } = await PaymentProfileAPI.addBankPaymentProfile({ data, loanId });

    /**
     * This will dispatch action creator with type of "payment-profiles/addBankPaymentProfile/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { paymentProfile };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payment-profiles/addBankPaymentProfile/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const addCreditCardPaymentProfileThunk = createAsyncThunk('payment-profiles/addCreditCardPaymentProfile', async ({ data, loanId }, thunkAPI) => {
  try {
    const { paymentProfile } = await PaymentProfileAPI.addCreditCardPaymentProfile({ data, loanId });

    /**
     * This will dispatch action creator with type of "payment-profiles/addCreditCardPaymentProfile/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { paymentProfile };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payment-profiles/addCreditCardPaymentProfile/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const editPaymentProfileThunk = createAsyncThunk('payment-profiles/editPaymentProfile', async ({ data, paymentProfileId, customerId }, thunkAPI) => {
  try {
    const { paymentProfile } = await PaymentProfileAPI.editPaymentProfile({ data, paymentProfileId, customerId });

    /**
     * This will dispatch action creator with type of "payment-profiles/editPaymentProfile/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { paymentProfile };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payment-profiles/editPaymentProfile/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const swapPaymentProfileThunk = createAsyncThunk('payment-profiles/swapPaymentProfile', async ({ data, paymentProfileId, loanId }, thunkAPI) => {
  try {
    const { paymentProfile } = await PaymentProfileAPI.updatePaymentProfilePosition({ data, paymentProfileId, loanId });

    /**
     * This will dispatch action creator with type of "payment-profiles/swapPaymentProfile/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { paymentProfile };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payment-profiles/swapPaymentProfile/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const unlinkPaymentProfileThunk = createAsyncThunk('payment-profiles/unlinkPaymentProfile', async ({ paymentProfileId, loanId }, thunkAPI) => {
  try {
    const { success } = await PaymentProfileAPI.unlinkPaymentProfile({ paymentProfileId, loanId });

    if (!success) throw 'Failed to unlink payment profile';

    /**
     * This will dispatch action creator with type of "payment-profiles/unlinkPaymentProfile/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { success };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payment-profiles/unlinkPaymentProfile/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const linkExistingPaymentProfileThunk = createAsyncThunk(
  'payment-profiles/linkExistingPaymentProfile',
  async ({ customerId, paymentProfileId, loanId }, thunkAPI) => {
    try {
      const { paymentProfile } = await PaymentProfileAPI.linkExistingPaymentProfile({
        data: { customer_id: customerId, payment_profile_id: paymentProfileId },
        loanId,
      });

      /**
       * This will dispatch action creator with type of "payment-profiles/linkExistingPaymentProfile/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return { paymentProfile };
    } catch (err) {
      /**
       * This will dispatch action creator with type of "payment-profiles/linkExistingPaymentProfile/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);
