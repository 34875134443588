import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Card } from 'components/Layouts';

const CardTable = ({ headers, data }) => {
  return (
    <Card variant="outlined" background>
      <Table size="small" sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {headers.map((heading, i) => (
              <TableCell key={`${heading?.heading}-${i}`}>{heading?.heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((entry, i) => (
            <TableRow key={`${entry?.key}-${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {headers.map((heading, i) => (
                <TableCell key={`${heading?.dataKey}-${i}`}>{entry[heading?.dataKey]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default CardTable;
