import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';

import { DocumentAPI } from 'store/api';
import { startAppLoading, stopAppLoading } from 'store/sagas/app';
import { uploadDocumentToStipulation, deleteDocumentFromStipulation, updateStipulation } from 'store/sagas/applications';
import { Card, FlexRow, ListItem } from 'components/Layouts';
import DragAndDropFileUpload from 'components/FileUpload';
import { FileContainer } from 'components/Layouts';
import DoubleConfirm from 'components/DoubleConfirm';
import { getApplicationStatusIcon } from 'components/Icon';
import { LINKS } from 'constants/menus';
import { STATUS } from 'constants/status';

const Documents = ({ SECTIONS, currentApplication: { status, lender, stipulations, id: applicationId }, overviewShouldShow }) => {
  const uploads = useSelector((state) => state.applications?.uploads);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const downloadDocument = async ({ id }) => {
    try {
      dispatch(startAppLoading());
      const { link } = await DocumentAPI.downloadDocumentFromS3({ documentId: id });
      dispatch(stopAppLoading());
      window.open(link);
    } catch (err) {
      console.log(err);
      dispatch(stopAppLoading());
    }
  };

  const sortedStipulations = structuredClone(stipulations).sort((a, b) => a.stipulation_template.name.localeCompare(b.stipulation_template.name));

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="Stips and documents.">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography style={{ margin: '0px' }} variant="h5">
            Stips & Documents
          </Typography>
          <Typography
            style={{ margin: '0px 0px 10px' }}
            variant="body1"
          >{`All of these stipulations must be met before ${lender.name} will fund this loan.`}</Typography>
        </Grid>

        {!overviewShouldShow && (
          <Grid item xs={4} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'flex-end' }}>
            {getApplicationStatusIcon({ statusObj: STATUS.find((stat) => stat.label === status.name), size: 'medium' })}
          </Grid>
        )}
      </Grid>

      <List>
        <Divider />
        {sortedStipulations.map((stipulation) => {
          const { fulfilled, stipulation_template, linked_document, id: stipulationId } = stipulation;
          const { document_template } = stipulation_template;
          const stipDocSigned = linked_document && linked_document.signed;

          return (
            <ListItem
              onClick={overviewShouldShow ? () => navigate(LINKS.DEALER.VIEW_APPLICATION(applicationId, SECTIONS.STIPS_AND_DOCS)) : null}
              key={stipulationId}
              primaryText={stipulation_template?.name}
              secondaryText={stipulation_template?.description}
              stackArray={
                overviewShouldShow
                  ? []
                  : [
                      {
                        display: fulfilled === true,
                        label: 'Completed',
                        icon: <CheckCircleOutlinedIcon />,
                        color: 'success',
                        tip: 'Success! This stipulation has been fulfilled.',
                      },
                      {
                        display: fulfilled === false,
                        label: 'Document Rejected',
                        icon: <DoDisturbOnOutlinedIcon />,
                        color: 'error',
                        tip: 'This stipulation is rejected.',
                      },
                      {
                        display: fulfilled === null || fulfilled === undefined,
                        label: 'Incomplete',
                        icon: <DoDisturbIcon />,
                        color: 'default',
                        tip: 'This stipulation is not yet fulfilled. You cannot get funded until all stips have cleared.',
                      },
                      {
                        display: !linked_document?.s3_id,
                        label: 'Not Uploaded',
                        icon: <DoDisturbIcon />,
                        color: 'default',
                        tip: 'No document has been uploaded to fulfill this stipulation yet.',
                      },
                      {
                        display: linked_document?.s3_id,
                        label: 'Uploaded',
                        icon: <CheckCircleOutlinedIcon />,
                        color: 'primary',
                        tip: 'This stipulation has an uploaded document.',
                      },
                      {
                        display: document_template.is_signable,
                        label: stipDocSigned ? 'Signed' : 'Signable',
                        icon: <HistoryEduIcon />,
                        color: stipDocSigned ? 'primary' : 'default',
                        tip: stipDocSigned ? 'This document has already been signed' : 'This document will be generated and signed on the signing page.',
                      },
                    ]
              }
              icon={<InsertDriveFileIcon color="primary" sx={{ fontSize: '40px' }} />}
            >
              {linked_document?.s3_id && !overviewShouldShow ? (
                <FileContainer variant="permanent">
                  <FlexRow padding="0" margin="0" alignItems="center">
                    <InsertDriveFileIcon color="primary" />
                    <Typography noWrap variant="body1" style={{ marginLeft: '10px' }}>
                      {linked_document?.name}
                    </Typography>
                  </FlexRow>

                  <IconButton disabled={!linked_document?.id} onClick={() => downloadDocument(linked_document)}>
                    <VisibilityIcon color={'primary'} />
                  </IconButton>
                  {!fulfilled && (
                    <DoubleConfirm
                      baseButton={
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      }
                      title="Are you sure?"
                      description="Deleting this file is irreversible."
                      action={() => dispatch(deleteDocumentFromStipulation({ stipulationId, applicationId }))}
                    />
                  )}
                </FileContainer>
              ) : (
                !document_template?.is_signable &&
                !overviewShouldShow && (
                  <DragAndDropFileUpload
                    uploading={uploads.includes(stipulationId)}
                    uploadFn={({ file }) => dispatch(uploadDocumentToStipulation({ file, stipulationId, applicationId }))}
                  />
                )
              )}
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};

export default Documents;
