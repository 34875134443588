import styled from 'styled-components';

export const FloatingActionBar = styled.div`
  padding: 10px 20px;
  margin: 0px;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
