import { Typography } from '@mui/material';

import { FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';

const ListItem = ({ label, value, labelPrepend, margin = '0px', valueColor }) => {
  let colorForValue = 'inherit';
  if (valueColor && COLORS[`${valueColor}`]) colorForValue = COLORS[`${valueColor}`];
  return (
    <FlexRow padding="0x" margin={margin} justifyContent="space-between" alignItems="center">
      {labelPrepend ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ mr: '10px', width: '10px' }}>
            {labelPrepend}
          </Typography>
          <Typography variant="body2">{label}</Typography>
        </div>
      ) : (
        <Typography variant="body2">{label}</Typography>
      )}
      <Typography sx={{ color: colorForValue }}>{value}</Typography>
    </FlexRow>
  );
};

export default ListItem;
