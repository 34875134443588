export const COLORS = {
  logoFont: 'Bebas Neue, serif',
  primaryColorContrastText: '#FFF',
  primaryColorContrastTextFaded: 'rgba(255, 255, 255, 0.9)',
  backgroundColor: '#F2F6F9',
  surfaceColor: '#FFF',
  textColor: '#000',
  fadedTextColor: 'rgba(0, 0, 0, 0.6)',
  sidebarBackground: 'rgba(25, 118, 210, 0.04)',
  primary: 'rgba(83,0,255,1)', // Purple
  primaryFaded: 'rgba(83,0,255,0.05)', // Purple Faded
  primaryFadedNonTransparent: '#F7F2FF',
  primaryPale: '#7e57c2', // Purple Pale
  secondary: '#ACFF00', // Lime Green
  error: 'rgb(211, 47, 47)', // Red
  fadedError: '#FCEDEC',
  warning: 'rgb(237, 108, 2)', // Yellow
  info: 'rgb(2, 136, 209)', // Blue
  fadedInfo: '#E5F5FD',
  success: 'rgb(46, 125, 50)', // Green
  disabledColor: '#D2D2D2',
  gradientBackground: 'rgb(2,0,36)',
  gradientPrimary: 'linear-gradient(270deg, rgba(2,0,36,1) 0%, rgba(103,114,229,1) 35%, rgba(83,0,255,1) 100%)',
  gradientDataCards: 'linear-gradient(45deg, hsla(260, 100%, 50%, 1) 0%, hsla(260, 91%, 78%, 1) 100%)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
  boxShadowSidebar: '2px 0px 5px 0px rgba(0,0,0,0.15)',
  borderColor: 'rgba(0, 0, 0, 0.12)',
};
