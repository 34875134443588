import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { parse } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import { fetchApplicationById } from 'store/sagas/applications';
import { createApplicationCreditCardPaymentProfileThunk } from 'store/thunks/application';
import { useFormie } from 'components/Formie';
import { configuration, initialValues } from './forminfo';

const CCPaymentProfileFormModal = ({ applicationId, accountOwner, firstName, lastName, onSuccess = null, onError = null, onClose = null }) => {
  const [hasFormError, setHasFormError] = useState(false);
  const [hasVerificationError, setHasVerificationError] = useState(false);
  const [verificationErrorMsg, setVerificationErrorMsg] = useState(null);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const formie = useFormie({ configuration, initialValues: { ...initialValues, first_name: firstName, last_name: lastName } });
  const { first_name, last_name, card_number, expiry_date, cvv_code, card_zip } = formie.values;
  const {
    first_name: first_name_error,
    last_name: last_name_error,
    card_number: card_number_error,
    expiry_date: expiry_date_error,
    cvv_code: cvv_code_error,
    card_zip: card_zip_error,
  } = formie.errors;

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={true}>
      <DialogTitle>
        <Stack direction={'row'} spacing={1}>
          <CreditCardIcon sx={{ fontSize: '2rem' }} />
          <Typography variant="h6">Credit/Debit Card Details</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        {hasFormError && <Alert severity="error">Please fill the form properly.</Alert>}

        {hasVerificationError && (
          <Alert severity="error">
            {verificationErrorMsg ? verificationErrorMsg : 'Failed to verify credit card. Please confirm that the data you enter is correct.'}
          </Alert>
        )}

        {formie.buildFields({
          fields: [
            { dataPath: 'first_name' },
            { dataPath: 'last_name' },
            { dataPath: 'card_number' },
            { dataPath: 'cvv_code' },
            { dataPath: 'expiry_date' },
            { dataPath: 'card_zip' },
          ],
          fieldsPerRow: 2,
        })}
      </DialogContent>

      <DialogActions>
        {typeof onClose === 'function' && (
          <Button variant="outlined" color="error" disabled={saving} onClick={onClose}>
            Cancel
          </Button>
        )}

        <LoadingButton
          disabled={
            !first_name ||
            !last_name ||
            !card_number ||
            !expiry_date ||
            !cvv_code ||
            !card_zip ||
            first_name_error ||
            last_name_error ||
            card_number_error !== false ||
            expiry_date_error !== false ||
            cvv_code_error !== false ||
            card_zip_error !== false
          }
          loading={saving}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={async () => {
            setHasFormError(false);
            setVerificationErrorMsg(null);
            setHasVerificationError(false);
            setSaving(true);

            let data = { account_owner: accountOwner };

            if (first_name && last_name && card_number && expiry_date && cvv_code && card_zip) {
              data['card_number'] = card_number;
              data['cvv_code'] = cvv_code;
              data['expiry_date'] = parse(expiry_date, 'MMyy', new Date());
              data['first_name'] = first_name;
              data['last_name'] = last_name;
              data['card_zip'] = card_zip;
            } else {
              setHasFormError(true);
              setSaving(false);
              return;
            }

            try {
              await dispatch(createApplicationCreditCardPaymentProfileThunk({ applicationId, data })).unwrap();
              await dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

              setSaving(false);

              if (typeof onSuccess === 'function') {
                onSuccess({ data });
              }
            } catch (error) {
              console.log('Saving payment profile form error: ', error);

              setVerificationErrorMsg(error.response.data.error.message);
              setHasVerificationError(true);
              setSaving(false);

              if (typeof onError === 'function') {
                onError({ error });
              }
            }
          }}
        >
          {saving ? 'Saving...' : 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CCPaymentProfileFormModal;
