import Typography from '@mui/material/Typography';

import { Card, FlexRow } from 'components/Layouts';
import Button from '@mui/material/Button';

const BasicInfo = ({ progress, continueFn, activeSection, section, formie }) => {
  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined">
      <Typography style={{ margin: '0px' }} variant="h5">
        Basic Info
      </Typography>
      {formie.buildFields({
        fields: [{ dataPath: 'type.id' }, { dataPath: 'lender.id' }],
        fieldsPerRow: 2,
      })}

      {activeSection === section && (
        <FlexRow padding="0px" margin="20px 0px 0px" alignItems="center" justifyContent="flex-end">
          <Button onClick={continueFn} disabled={progress < 1} variant="contained">
            Continue
          </Button>
        </FlexRow>
      )}
    </Card>
  );
};

export default BasicInfo;
