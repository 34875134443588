import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Alert,
  AlertTitle,
  Chip,
  CircularProgress,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { differenceInCalendarDays } from 'date-fns';
import { CalendarMonth } from '@mui/icons-material';
import { formatInTimeZone } from 'date-fns-tz';

import { setAlert } from 'store/sagas/app';
import { Card, FlexRow } from 'components/Layouts';
import { fetchLoanPastDuesThunk } from 'store/thunks/pastDue';
import { currencyFormatter, roundAccurately, formatDateOnly } from 'constants/formatters';
import ListItem from 'components/ListItem';

const findStatistics = (details, timezone) => {
  const paid = !!details?.fully_settled_date;
  const status = !paid ? 'Due' : 'Paid';
  const originalAmountDue = details?.amount ? currencyFormatter.format(Number(details.amount)) : currencyFormatter.format(Number(0));
  const amountPaid = details?.amount_settled ? currencyFormatter.format(Number(details.amount_settled)) : currencyFormatter.format(Number(0));
  const amountRemaining =
    details?.amount && details?.amount_settled
      ? currencyFormatter.format(roundAccurately(Number(details.amount) - Number(details.amount_settled), 2))
      : currencyFormatter.format(0);
  const dueDate = details?.payment_due_date ? formatDateOnly(details.payment_due_date) : '-';
  const paidDate = paid ? formatDateOnly(details.fully_settled_date) : '-';
  const pastDueDate = details?.past_due_date ? formatDateOnly(details.past_due_date) : '-';

  const todayInBrowserTimeZone = new Date();
  const todayInLoansTimeZone = formatInTimeZone(todayInBrowserTimeZone, timezone, 'MM/dd/yy');

  const daysLate = paid
    ? differenceInCalendarDays(new Date(paidDate), new Date(dueDate)) // paidDate and dueDate are both formatted as 'MM/dd/yy'. They are already in loan timezone.
    : differenceInCalendarDays(new Date(todayInLoansTimeZone), new Date(dueDate)); //dueDate is already in loan timezone and is formatted as 'MM/dd/yy', so I have formatted todayInLoansTimeZone as 'MM/dd/yy' as well.

  return {
    paid,
    status,
    originalAmountDue,
    amountPaid,
    amountRemaining,
    dueDate,
    pastDueDate,
    paidDate,
    daysLate,
  };
};

const PastDues = ({ timezone }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [pastDues, setPastDues] = useState([]);
  const [pastDueDetailId, setPastDueDetailId] = useState(null);
  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { pastDues } = await dispatch(
        fetchLoanPastDuesThunk({
          subdata: ['due-payments'],
          loanId,
          limit: 1000,
        }),
      ).unwrap();
      setPastDues(pastDues);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch past dues. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const handleCloseDetails = () => {
    setPastDueDetailId(null);
  };

  const sortedPastDues = pastDues.sort((a, b) => b.payment_period_number - a.payment_period_number);
  const pastDueForDetailModal = pastDues.find((pastDue) => pastDue.id === pastDueDetailId);
  const statistics = pastDueForDetailModal ? findStatistics(pastDueForDetailModal, timezone) : {};

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <>
      <Typography sx={{ mb: '8px' }} variant="h4">
        Past Dues
      </Typography>

      {sortedPastDues && sortedPastDues.length && sortedPastDues.length > 0 ? (
        <Grid container spacing={2}>
          {sortedPastDues.map(({ id, payment_period_number, ...rest }) => {
            const stats = findStatistics(rest, timezone);

            return (
              <Grid key={id} xs={4}>
                <Card onClick={() => setPastDueDetailId(id)} padding="0px" variant="outlined">
                  <FlexRow borderBottom margin="0px" padding="10px 10px 5px" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ fontWeight: 700 }}>{`Payment Period ${payment_period_number}`}</Typography>
                    <Chip size="small" label={stats.status} color={stats.status === 'Due' ? 'error' : 'success'} />
                  </FlexRow>

                  <FlexRow flexColumn margin="0px" padding="10px" justifyContent="center" alignItems="center">
                    <Typography variant="h5">{stats.amountRemaining}</Typography>
                    <Typography variant="caption">Amount Due</Typography>
                  </FlexRow>

                  <div style={{ padding: '0px 10px 10px' }}>
                    <ListItem label="Original Due" value={stats.originalAmountDue} />
                    <ListItem label="Paid" value={stats.amountPaid} />
                    <ListItem label="Still Due" value={stats.amountRemaining} />

                    <Divider sx={{ m: '10px 0px' }} />

                    <ListItem label="Due On" value={stats.dueDate} />
                    <ListItem label="Paid On" value={stats.paidDate} />
                    <ListItem label="Days Late" value={stats.daysLate} />
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Alert color="primary">
          <AlertTitle>No Past Dues!</AlertTitle>
          This loan has never had any past due payments.
        </Alert>
      )}

      <Dialog scroll="paper" fullWidth maxWidth="xs" onClose={handleCloseDetails} open={!!pastDueForDetailModal}>
        <DialogTitle>Past Due Details</DialogTitle>
        <IconButton
          onClick={handleCloseDetails}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Stepper nonLinear orientation="vertical">
            <Step completed>
              <StepLabel icon={<CalendarMonth />}>{`Payment Due: ${statistics.dueDate}`}</StepLabel>
            </Step>
            <Step completed>
              <StepLabel error>{`Payment Past Due: ${statistics.pastDueDate}`}</StepLabel>
            </Step>
            <Step active={!statistics.paid} completed={statistics.paid}>
              <StepLabel error={!statistics.paid}>{`Payment Made: ${
                statistics.paid
                  ? `${statistics.paidDate} (${statistics.daysLate} ${statistics.daysLate === 1 ? 'day' : 'days'} late)`
                  : `N/A (${statistics.daysLate} ${statistics.daysLate === 1 ? 'day' : 'days'} late)`
              }`}</StepLabel>
            </Step>
          </Stepper>

          <Divider sx={{ m: '16px 0px' }} />

          <ListItem label="Original Due" value={statistics.originalAmountDue} />
          <ListItem label="Amount Paid" value={statistics.amountPaid} />
          <ListItem label="Amount Due" value={statistics.amountRemaining} />

          <Divider sx={{ m: '16px 0px' }} />

          <ListItem label="Id" value={pastDueForDetailModal?.id} />

          {!!pastDueForDetailModal?.loan_past_due_payments &&
            !!pastDueForDetailModal.loan_past_due_payments.length &&
            !!pastDueForDetailModal.loan_past_due_payments.length > 0 && (
              <>
                <Divider sx={{ m: '16px 0px' }} />
                <Typography variant="h6">Applied Payments</Typography>
                {pastDueForDetailModal?.loan_past_due_payments.map((payment, i) => {
                  return (
                    <div key={payment?.id}>
                      <Typography sx={{ fontWeight: 700, mt: '16px' }}>{`Payment ${i + 1}`}</Typography>
                      <ListItem
                        label="Amount"
                        value={payment?.amount_settled ? currencyFormatter.format(Number(payment.amount_settled)) : currencyFormatter.format(0)}
                      />
                      <ListItem label="Id" value={payment?.id} />
                    </div>
                  );
                })}
              </>
            )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PastDues;
