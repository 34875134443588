import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import SearchIcon from '@mui/icons-material/Search';
import { FlexRow } from '../Layouts';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps({ addressExtractFn }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, document.querySelector('head'), 'google-maps');
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ['address'] }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Box component="div">
      <Autocomplete
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        fullWidth
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);

          if (newValue) {
            const request = {
              placeId: newValue.place_id,
              fields: ['address_components'],
            };
            const service = new window.google.maps.places.PlacesService(document.getElementById('attribution'));

            service.getDetails(request, (place, status) => {
              const { address_components } = place;

              const address_1 = `${
                address_components.find((comp) => comp.types.includes('street_number'))
                  ? address_components.find((comp) => comp.types.includes('street_number')).long_name
                  : ''
              } ${
                address_components.find((comp) => comp.types.includes('route')) ? address_components.find((comp) => comp.types.includes('route')).long_name : ''
              }`;
              const address_2 = address_components.find((comp) => comp.types.includes('subpremise'))
                ? address_components.find((comp) => comp.types.includes('subpremise')).long_name
                : '';
              const city = address_components.find((comp) => comp.types.includes('locality'))
                ? address_components.find((comp) => comp.types.includes('locality')).long_name
                : '';
              const state = address_components.find((comp) => comp.types.includes('administrative_area_level_1'))
                ? address_components.find((comp) => comp.types.includes('administrative_area_level_1')).long_name
                : '';
              const zip = address_components.find((comp) => comp.types.includes('postal_code'))
                ? address_components.find((comp) => comp.types.includes('postal_code')).long_name
                : '';

              addressExtractFn({
                address_1,
                address_2,
                city,
                state,
                zip,
              });
            });
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            style={{
              margin: '16px 0px 0px',
              width: '100%',
            }}
            label={
              <FlexRow padding="0px" margin="0px">
                <SearchIcon sx={{ margin: '0px 5px 0px 0px', padding: '0px' }} />
                Find an address
              </FlexRow>
            }
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <div id="attribution" />
    </Box>
  );
}
