import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Fab from '@mui/material/Fab';

import { Card, FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import { getApplicationTypeIcon } from 'components/Icon';

const VIN_DETAILS_VIEW = {
  /**
   * This means that we already checked what the VIN details should be and
   * its not CARD, FIELDS or ERROR but we don't want to set it as null as
   * it means it has not been checked yet.
   */
  INIT: 'INIT',
  // Has some error on the VIN
  ERROR: 'ERROR',
  // Show VIN details in CARD view
  CARD: 'CARD',
  // Show VIN details in individiual fields
  FIELDS: 'FIELDS',
};

const Unit = ({ showTradeIn, setShowTradeIn, isTradeIn, activeSection, section, continueFn, progress, formie }) => {
  const applicationTypes = useSelector((state) => state?.applications?.application_types);

  const [vinDetailsView, setVinDetailsView] = useState(null);

  const prefix = isTradeIn ? 'trade_unit' : 'unit';
  const { values } = formie;
  const selectedType = values?.type?.id && applicationTypes?.length > 0 ? applicationTypes.find((type) => type.id === values.type.id) : null;

  useEffect(() => {
    /**
     * This code logic is about first loading the component and populating its fields with
     * data coming from server.
     */
    if (values[prefix]?.vin && vinDetailsView === null) {
      if (values[prefix]?.year && values[prefix]?.make && values[prefix]?.model) {
        // Meaning have proper vin and year, make and model fields all have proper values.
        setVinDetailsView(VIN_DETAILS_VIEW.CARD);
      } else if ([values[prefix]?.year, values[prefix]?.make, values[prefix]?.model].some((val) => val)) {
        // Meaning have proper vin but only some of the fields (year, make and model) have proper values (this occurs for partially invalid VIN).
        setVinDetailsView(VIN_DETAILS_VIEW.FIELDS);
      } else {
        // Default, we set the view to this so that this effect won't get executed on every VIN change.
        setVinDetailsView(VIN_DETAILS_VIEW.INIT);
      }
    }
  }, [values[prefix]?.vin]);

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography style={{ margin: '0px' }} variant="h5">
          {isTradeIn ? 'Trade-In' : 'Unit'}
        </Typography>
        {isTradeIn && (
          <FormControlLabel style={{ margin: '0px' }} control={<Switch checked={showTradeIn} onChange={(e) => setShowTradeIn(e.target.checked)} />} />
        )}
      </FlexRow>

      {(showTradeIn || !isTradeIn) && (
        <>
          {formie.buildFields({
            fields: [
              {
                dataPath: `${prefix}.vin`,
                extraProps: {
                  blurCallback: (validVin, year, make, model) => {
                    if (validVin && year && make && model) {
                      setVinDetailsView(VIN_DETAILS_VIEW.CARD);
                    } else if (validVin && (!year || !make || !model)) {
                      setVinDetailsView(VIN_DETAILS_VIEW.FIELDS);
                    } else {
                      // This means VIN is invalid.
                      setVinDetailsView(VIN_DETAILS_VIEW.ERROR);
                    }
                  },
                },
              },
            ],
          })}

          {vinDetailsView === VIN_DETAILS_VIEW.CARD && (
            <FlexRow
              padding="10px"
              margin="12px 0px 0px"
              outlined
              rounded
              backgroundColor={COLORS.backgroundColor}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1}>
                {getApplicationTypeIcon({ type: selectedType?.name, tradeIn: false, size: 'small' })}
                <Typography variant="body1" sx={{ marginLeft: '10px' }}>{`${values[prefix].year} ${values[prefix].make} ${values[prefix].model}`}</Typography>
              </Stack>

              <IconButton aria-label="Switch vin details to field view" size="small" onClick={() => setVinDetailsView(VIN_DETAILS_VIEW.FIELDS)}>
                <EditIcon fontSize="inherit" />
              </IconButton>
            </FlexRow>
          )}

          {vinDetailsView === VIN_DETAILS_VIEW.FIELDS && (
            <>
              <FlexRow margin="0" padding="0" alignItems="center" justifyContent="space-between">
                {formie.buildFields({
                  fields: [{ dataPath: `${prefix}.year` }, { dataPath: `${prefix}.make` }, { dataPath: `${prefix}.model` }],
                  fieldsPerRow: 3,
                })}
              </FlexRow>
              {values[prefix]?.year && values[prefix]?.make && values[prefix]?.model && (
                <FlexRow margin="8px 0px 0px" padding="0" justifyContent="flex-end">
                  <Fab
                    sx={{ borderRadius: '5px' }}
                    aria-label="Switch vin details to card view"
                    size="small"
                    onClick={() => setVinDetailsView(VIN_DETAILS_VIEW.CARD)}
                    variant="extended"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    <CheckIcon color="primary" />
                  </Fab>
                </FlexRow>
              )}
            </>
          )}

          <Typography variant="overline" display="block" style={{ marginTop: '20px', color: COLORS.primary }}>
            Unit Details
          </Typography>
          {formie.buildFields({
            fields: [{ dataPath: `${prefix}.condition` }, { dataPath: `${prefix}.mileage` }],
            fieldsPerRow: 2,
          })}

          <Typography variant="overline" display="block" style={{ marginTop: '20px', color: COLORS.primary }}>
            Value
          </Typography>
          {isTradeIn ? (
            <>
              {formie.buildFields({
                fields: [{ dataPath: `${prefix}.trade_allowance` }, { dataPath: `${prefix}.payoff_amount` }],
                fieldsPerRow: 2,
              })}
              {formie.buildFields({
                fields: [{ dataPath: `${prefix}.lienholder` }],
              })}
            </>
          ) : (
            <>
              {formie.buildFields({
                fields: [{ dataPath: `${prefix}.price` }, { dataPath: `${prefix}.value` }],
                fieldsPerRow: 2,
              })}
              {formie.buildFields({
                fields: [{ dataPath: `${prefix}.value_source` }],
              })}
            </>
          )}
        </>
      )}
      {activeSection === section && (
        <FlexRow padding="0px" margin="20px 0px 0px" alignItems="center" justifyContent="flex-end">
          <Button onClick={continueFn} disabled={progress < 1} variant="contained">
            Continue
          </Button>
        </FlexRow>
      )}
    </Card>
  );
};

export default Unit;
