import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import { FlexRow } from 'components/Layouts';

const DataListItem = ({ title, data, noDivider, adornment, onClick, tooltip }) => {
  return (
    <Fragment>
      <Tooltip placement="top" title={tooltip}>
        {typeof onClick === 'function' ? (
          <ListItem disablePadding onClick={onClick}>
            <ListItemButton>
              <Typography style={{ width: '200px' }} variant="body2">
                {title}
              </Typography>
              <FlexRow padding="0" justifyContent="space-between" alignItems="center">
                {typeof data == 'string' || typeof data == 'number' ? <Typography variant="body1">{data}</Typography> : data}
                {!data && adornment && <Typography variant="body1"></Typography>}
                {adornment}
              </FlexRow>
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem>
            <Typography style={{ width: '200px' }} variant="body2">
              {title}
            </Typography>
            <FlexRow padding="0" justifyContent="space-between" alignItems="center">
              {typeof data == 'string' || typeof data == 'number' ? <Typography variant="body1">{data}</Typography> : data}
              {!data && adornment && <Typography variant="body1"></Typography>}
              {adornment}
            </FlexRow>
          </ListItem>
        )}
      </Tooltip>
      {!noDivider && <Divider sx={{ margin: '0px' }} />}
    </Fragment>
  );
};

export default DataListItem;
