import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';

import { PLAID_AUTH_STATUS } from 'constants/plaid';
import { fetchApplicationById } from 'store/sagas/applications';
import { generateApplicationAccessTokenThunk, updateApplicationAccessTokenThunk, connectApplicationPaymentProfileThunk } from 'store/thunks/application';
import PaymentProfileFormModal from './PaymentProfileFormModal';
import Alert from './Alert';

const { PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED, VERIFICATION_FAILED } =
  PLAID_AUTH_STATUS;

const PlaidLink = ({
  applicationId,
  applicantId,
  /**
   * 'applicant'
   * 'co-applicant'
   */
  accountOwner,
  /**
   * Name payment profile account name.
   * The one who owns the payment profile.
   * This could either be applicant name or co-applicant name.
   */
  accountName,
  linkToken,
  /**
   * ! Important
   * When supplied, it means that this plaid link is used to manually verify an already existing payment profile (access token).
   * When NOT supplied, it means that this plaid link is used to generate NEW access token (which in turn generate a payment profile to associate it with).
   */
  profileType = null,
  /**
   * Callback to execute when plaid link pop-up is opened.
   */
  onOpen = null,
  /**
   * Callback to execute when there is an error occured.
   * Errors occur when:
   *
   * * Verification status returned by plaid link is not we expected.
   * * Catch block is reached.
   * * Plaid throws an Error event.
   */
  onError = null,
  /**
   * Callback to execute when plaid link is prematurely closed.
   * Meaning the process did not complete and the end user closed it.
   */
  onExit = null,
  /**
   * Callback to execute when verification status return by plaid link are pending automatic/manual verification.
   */
  onPendingVerification = null,
  /**
   * Callback to execute when end user sucessfully entered the unverified payment profile into the payment profile form modal.
   */
  onInputPaymentProfileDataSuccess = null,
  /**
   * Callback to execute when end user failed to enter the unverified payment profile into the payment profile form modal.
   */
  onInputPaymentProfileDataFail = null,
  /**
   * Callback to execute when successfully connected payment profile to an application.
   */
  onPaymentProfileConnectSuccess = null,
  /**
   * Callback to execute when failed to connect payment profile to an application.
   */
  onPaymentProfileConnectFail = null,
  /**
   * Callback to execute when manual verification is successful.
   */
  onManualVerificationSuccess = null,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [fullModalForm, setFullModalForm] = useState(false);
  const [generatedProfileType, setGeneratedProfileType] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const dispatch = useDispatch();

  const { open, ready } = usePlaidLink({
    token: linkToken,

    /**
     * Reference:
     * https://plaid.com/docs/link/web/#onsuccess
     *
     * The onSuccess callback is called when a user successfully links an Item. It takes two arguments: the public_token and a metadata object.
     */
    onSuccess: async (publicToken, metadata) => {
      /**
       * If null is returned from plaid, it means its verified via automatic verification or instant match verification.
       * Therefore if null, then we set the verificationStatus to 'verified'.
       *
       * ! Important
       * Do not be confused by the "AUTOMATICALLY_VERIFIED" here, they are not the same.
       * This means that plaid automatically verified this because the auth type is instant same-day micro-deposit.
       */

      /**
       * ! Very Important
       * We also send in the institution id and name (bank name) here during the access token generation (if we generate access token).
       * Just to guarantee that we have all necessary data of a payment profile even in the event where the /institution api of Plaid fails to
       * return institution data of a given institution.
       *
       * Note that these fields could still be null though specially for cases of manual verification. Plaid has no data about the institution on that case.
       */
      const institutionId = metadata.institution?.institution_id ?? null;
      const institutionName = metadata.institution?.name ?? null;
      const verificationStatus = metadata.account.verification_status ?? VERIFIED;
      const accountId = metadata.account.id;

      try {
        if ([PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION, AUTOMATICALLY_VERIFIED, VERIFIED].includes(verificationStatus)) {
          /**
           * If the verification status returned by plaid are in these statuses, then let us generate a new access token.
           * Note that in the process, it will also generate the payment profile to associate the access token with.
           * Hence it will return the generated profile type.
           */

          // TODO: Add comment as to why we are sending in accountName
          // TODO: Send in correct account id too (applicant id or co-applicant id), comment this as well.

          const { profile_type: generatedProfileType } = await dispatch(
            generateApplicationAccessTokenThunk({
              applicationId,
              applicantId,
              accountOwner,
              accountName,
              publicToken,
              accountId,
              institutionId,
              institutionName,
              verificationStatus,
            }),
          ).unwrap();

          if ([PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION].includes(verificationStatus)) {
            /**
             * If the verification status are in pending verification then we do not connect the payment profile yet since it is not yet verified.
             * However, due to us needing the account data now to generate documents and not having any way to extract the data the applicant
             * inputed in the plaid link, then we need the applicant to re-enter the account data again.
             *
             * Therefore we show a modal to let them enter the account data.
             * Note this updates the unverified payment profile in the API with these inputted data but still the payment profile stays unverified.
             * And yes we generate documents even on unverified account data.
             */

            setGeneratedProfileType(generatedProfileType);
            setShowModal(true);
            setFullModalForm(true);

            if (typeof onPendingVerification === 'function') {
              onPendingVerification({ generatedProfileType });
            }
          } else if ([AUTOMATICALLY_VERIFIED, VERIFIED].includes(verificationStatus)) {
            /**
             * If the verification status are automatically verified (automatic micro-deposit) and verified (automatic verification or instant verification)
             * then we connect the payment profile to the application.
             */

            const response = await dispatch(connectApplicationPaymentProfileThunk({ applicationId, profileType: generatedProfileType })).unwrap();

            if (response.success) {
              await dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

              if (typeof onPaymentProfileConnectSuccess === 'function') {
                onPaymentProfileConnectSuccess();
              }
            } else {
              if (typeof onPaymentProfileConnectFail === 'function') {
                onPaymentProfileConnectFail({ response });
              }
            }
          }
        } else if (verificationStatus === MANUALLY_VERIFIED) {
          /**
           * If the verification status returned by plaid is manually verified, then this means that the plaid link was used to manually verify
           * an already existing payment profile that used same-day micro-deposit auth type.
           *
           * We expect that the profileType is supplied and therefore update the verification status of that profile type together with
           * its underlying access token.
           */
          await dispatch(updateApplicationAccessTokenThunk({ applicationId, profileType, verificationStatus })).unwrap();

          if (typeof onManualVerificationSuccess === 'function') {
            onManualVerificationSuccess();
          }

          /**
           * We then connect the newly manually verified payment profile.
           */
          const response = await dispatch(connectApplicationPaymentProfileThunk({ applicationId, profileType })).unwrap();

          if (response.success) {
            await dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

            if (typeof onPaymentProfileConnectSuccess === 'function') {
              onPaymentProfileConnectSuccess();
            }
          } else {
            if (typeof onPaymentProfileConnectFail === 'function') {
              onPaymentProfileConnectFail({ response });
            }
          }
        } else {
          if (typeof onError === 'function') {
            onError({ verificationStatus });
          }
        }
      } catch (error) {
        if (error.response?.status === 409) {
          setAlertTitle('Duplicate Payment Profile');
          setAlertMessage('The payment profile is already in use in the current application. Please add a new payment profile.');
          setShowAlert(true);
        } else {
          if (typeof onError === 'function') {
            onError({ error });
          }
        }
      }
    },

    /**
     * Reference:
     * https://plaid.com/docs/link/web/#onevent
     *
     * The onEvent callback is called at certain points in the Link flow. It takes two arguments, an eventName string and a metadata object.
     *
     * The metadata parameter is always present, though some values may be null. Note that new eventNames,
     * metadata keys, or view names may be added without notice.
     *
     * The onEvent callback is not guaranteed to fire exactly at the time of a user action in Link. If you need to determine the exact time when
     * an event happened, use the timestamp in the metadata.
     *
     * The following callback events are stable, which means that they will not be deprecated or changed: OPEN, EXIT, HANDOFF,
     * SELECT_INSTITUTION, ERROR, BANK_INCOME_INSIGHTS_COMPLETED, IDENTITY_VERIFICATION_PASS_SESSION, IDENTITY_VERIFICATION_FAIL_SESSION.
     * The remaining callback events are informational and subject to change.
     */
    onEvent: async (event, metadata) => {
      if (event === 'OPEN') {
        if (typeof onOpen === 'function') {
          onOpen({ event, metadata });
        }
      } else if (event === 'ERROR') {
        if (
          metadata.error_code === 'INVALID_FIELD' &&
          metadata.error_type === 'INVALID_REQUEST' &&
          metadata.error_message === 'this account is not eligible for verification'
        ) {
          /**
           * This means that the current payment profile is not valid for verification anymore.
           * Meaning lets mark this payment profile as verification failed.
           */
          await dispatch(updateApplicationAccessTokenThunk({ applicationId, profileType, verificationStatus: VERIFICATION_FAILED })).unwrap();

          await dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

          if (typeof onError === 'function') {
            onError({ metadata });
          }
        } else {
          /**
           * Reference:
           * https://plaid.com/docs/link/web/#link-web-onevent-eventName-ERROR
           *
           * A recoverable error occurred in the Link flow, see the error_code metadata.
           *
           * This is a recoverable error so don't bother trigger on error here.
           */
        }
      } else if (event === 'EXIT' && metadata.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
        /**
         * This means that the end user fails to verify the said account.
         * Plaid will invalidate this account, therefore we need to mark this payment profile as verification failed.
         * This payment profile is not usable anymore and the end user would need to re-input their account details again.
         * All we can do with this payment profile now is to purge it.
         */
        await dispatch(updateApplicationAccessTokenThunk({ applicationId, profileType, verificationStatus: VERIFICATION_FAILED })).unwrap();

        await dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

        if (typeof onError === 'function') {
          onError({ metadata });
        }
      }
    },

    /**
     * Reference:
     * https://plaid.com/docs/link/web/#onexit
     *
     * The onExit callback is called when a user exits Link without successfully linking an Item, or when an error occurs during Link initialization.
     * It takes two arguments, a nullable error object and a metadata object. The metadata parameter is always present, though some values may be null.
     */
    onExit: async (error, metadata) => {
      if (typeof onExit === 'function') {
        onExit({ error, metadata });
      }
    },
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready]);

  return (
    <>
      {
        /**
         * The purpose of this is for cases when the plain link returns pending verification status.
         * That means that we won't have the account data for a while (minimum of 24 hours).
         * However, we need these data right here right now for us to generate documents for signing.
         * Therefore we need to show a modal form for them to enter these account data for document generation purposes.
         * And yes we generate these documents even before these account data are verified.
         *
         * !Important
         * We do not pass in onClose function into the PaymentProfileModal to make the modal undismissable.
         * We want the applicant to enter these data right here right now.
         * The only way to dismiss this modal is to close the browser/tab.
         */
        showModal && generatedProfileType && (
          <PaymentProfileFormModal
            applicationId={applicationId}
            profileType={generatedProfileType}
            fullForm={fullModalForm}
            onSuccess={({ data }) => {
              if (typeof onInputPaymentProfileDataSuccess === 'function') {
                onInputPaymentProfileDataSuccess({ data });
              }
            }}
            onError={({ error }) => {
              if (typeof onInputPaymentProfileDataFail === 'function') {
                onInputPaymentProfileDataFail({ error });
              }
            }}
          />
        )
      }

      {showAlert && alertTitle && alertMessage && (
        <Alert
          title={alertTitle}
          message={alertMessage}
          open={showAlert}
          handleClose={() => {
            setShowAlert(false);
            setAlertTitle(null);
            setAlertMessage(null);

            if (typeof onError === 'function') {
              onError({ message: alertMessage });
            }
          }}
        />
      )}
    </>
  );
};

export default PlaidLink;
