import { addDays, addMonths, differenceInDays, isValid, parseISO, formatISO } from 'date-fns';

import { LINKS } from 'constants/menus';
import { roundAccurately } from 'constants/formatters';
import { prepareContractFormConfiguration } from './formInfo';
import { get } from 'lodash';

export const buildBreadcrumbs = ({ currentApplication }) => [
  currentApplication?.id ? LINKS.DEALER.VIEW_APPLICATION(currentApplication.id) : '',
  [
    { text: 'Home', link: LINKS.DEALER.DASHBOARD },
    { text: 'Applications', link: LINKS.DEALER.ALL_APPLICATIONS },
    {
      text:
        currentApplication?.id && currentApplication?.applicant?.first_name && currentApplication?.applicant?.last_name
          ? `${currentApplication.applicant.first_name} ${currentApplication.applicant.last_name}`
          : currentApplication?.id && currentApplication?.applicant?.email
          ? currentApplication.applicant.email
          : 'View Application',
      link: currentApplication?.id ? LINKS.DEALER.VIEW_APPLICATION(currentApplication.id) : '',
    },
    { text: 'Prepare Contract', link: '' },
  ],
];

//This is also used independently in the back end. Update back end if updating this.
export const calculateLoanTerms = ({ apr, term, amountFinanced, contractDate, firstPaymentDate }) => {
  const parsedContractDate = parseISO(contractDate);
  const parsedFirstPaymentDate = parseISO(firstPaymentDate);

  if (apr && term && amountFinanced && isValid(parsedContractDate) && isValid(parsedFirstPaymentDate)) {
    const dateDifference = differenceInDays(parsedFirstPaymentDate, parsedContractDate);
    const paidDays = dateDifference - 30;

    const dailyInterest = (amountFinanced * apr) / 360;
    const realAmountFinanced = amountFinanced + paidDays * dailyInterest;
    const monthlyRate = apr / 12;
    const x = Math.pow(1 + monthlyRate, term);
    const monthlyPayment = (realAmountFinanced * x * monthlyRate) / (x - 1);
    const totalPayment = monthlyPayment * term;
    return {
      financeCharge: roundAccurately(totalPayment - amountFinanced, 2),
      monthlyPayment: roundAccurately(monthlyPayment, 2),
      totalPayment: roundAccurately(totalPayment, 2),
      realAmountFinanced: roundAccurately(realAmountFinanced, 2),
    };
  } else return {};
};

export const calculateProgress = ({ values, showESC, showPPM, showGAP, showCustomFields, customFieldsArray }) => {
  const hasTradeUnit = !!values?.trade_unit?.id;
  const fieldsArray = Object.values(prepareContractFormConfiguration).reduce((prev, curr) => [...prev, ...Object.values(curr)], []);

  const activeRequiredFields = fieldsArray.filter((field) => {
    if (!field.relatedTo) return field;
    else if (field.relatedTo === 'esc' && showESC) return field;
    else if (field.relatedTo === 'ppm' && showPPM) return field;
    else if (field.relatedTo === 'gap' && showGAP) return field;
    else if (field.relatedTo === 'trade_unit' && hasTradeUnit) return field;
    else if (field.relatedTo === 'custom_1' && showCustomFields && customFieldsArray.length >= 1) return field;
    else if (field.relatedTo === 'custom_2' && showCustomFields && customFieldsArray.length >= 2) return field;
    else if (field.relatedTo === 'custom_3' && showCustomFields && customFieldsArray.length >= 3) return field;
    else if (field.relatedTo === 'custom_4' && showCustomFields && customFieldsArray.length >= 4) return field;
    return null;
  });

  const completedActiveRequiredFields = [];
  const incompleteActiveRequiredFields = [];

  activeRequiredFields.forEach((field) => {
    const fieldHasValidValue = field?.validator(get(values, field.dataPath));
    if (fieldHasValidValue) {
      completedActiveRequiredFields.push(field);
    } else {
      incompleteActiveRequiredFields.push(field);
    }
  });

  return {
    progress: roundAccurately(completedActiveRequiredFields.length / activeRequiredFields.length, 4),
    completedActiveRequiredFields,
    incompleteActiveRequiredFields,
  };
};

const nullCustomFieldsObj = {
  custom_field_1_to: null,
  custom_field_1_for: null,
  custom_field_1_cost: null,
  custom_field_2_to: null,
  custom_field_2_for: null,
  custom_field_2_cost: null,
  custom_field_3_to: null,
  custom_field_3_for: null,
  custom_field_3_cost: null,
  custom_field_4_to: null,
  custom_field_4_for: null,
  custom_field_4_cost: null,
};

export const createAllValues = ({ values, showESC, showPPM, showGAP, showCustomFields, selectedLendingOption, progress }) => {
  const net_trade_in = roundAccurately(values?.trade_unit?.trade_allowance - values?.trade_unit?.payoff_amount, 2);
  const net_trade_in_if_positive = net_trade_in >= 0 ? net_trade_in : 0;
  const net_trade_in_if_negative = net_trade_in < 0 ? roundAccurately(net_trade_in * -1, 2) : 0;
  const payment_start_date = addDays(parseISO(values?.dates?.contract_date), values?.helper_values?.days_to_first_payment);
  const cash_price = roundAccurately(values?.unit?.price + values?.contract_values?.sales_tax, 2);
  const total_down_payment = roundAccurately(net_trade_in_if_positive + values?.contract_values?.cash_down, 2);
  const required_amount_down = roundAccurately(selectedLendingOption?.min_percent_down * cash_price, 2);
  const last_payment_date = !!(values?.dates?.contract_date && values?.helper_values?.days_to_first_payment && selectedLendingOption?.term)
    ? addMonths(payment_start_date, selectedLendingOption?.term - 1)
    : null;

  let allValues = {
    dates: {
      ...values?.dates,
      payment_start_date: isValid(payment_start_date) ? formatISO(payment_start_date) : payment_start_date,
      last_payment_date: isValid(last_payment_date) ? formatISO(last_payment_date) : last_payment_date,
    },
    contract_values: {
      ...values?.contract_values,
      ...(!showESC ? { esc_term: null, esc_company: null, esc_cost: null } : {}),
      ...(!showGAP ? { gap_term: null, gap_company: null, gap_cost: null } : {}),
      ...(!showPPM ? { ppm_term: null, ppm_company: null, ppm_cost: null } : {}),
      ...(!showCustomFields ? nullCustomFieldsObj : {}),
      cash_price,
      vsi_cost: null,
      vsi_term: null,
      apr: selectedLendingOption?.apr,
      required_amount_down,
      total_down_payment,
      unpaid_balance: roundAccurately(cash_price - total_down_payment, 2),
    },
    unit: {
      ...values?.unit,
    },
    trade_unit: {
      ...values?.trade_unit,
    },
    signing: {},
    helper_values: {
      ...values?.helper_values,
      net_trade_in: net_trade_in_if_positive,
      net_trade_in_if_negative,
      max_amount_loaned: selectedLendingOption?.max_amount_loaned,
      total_down_fits_approval: total_down_payment >= required_amount_down,
    },
  };

  const {
    contract_values: {
      vsi_cost,
      esc_cost,
      gap_cost,
      ppm_cost,
      license_reg_fees,
      title_fees,
      doc_fee,
      custom_field_1_cost,
      custom_field_2_cost,
      custom_field_3_cost,
      custom_field_4_cost,
    },
  } = allValues;

  allValues.contract_values.total_other_charges = roundAccurately(
    net_trade_in_if_negative +
      vsi_cost +
      esc_cost +
      gap_cost +
      ppm_cost +
      license_reg_fees +
      title_fees +
      doc_fee +
      custom_field_1_cost +
      custom_field_2_cost +
      custom_field_3_cost +
      custom_field_4_cost,
    2,
  );

  allValues.contract_values.amount_financed = roundAccurately(allValues.contract_values.total_other_charges + allValues.contract_values.unpaid_balance, 2);
  allValues.helper_values.amount_financed_fits_approval = allValues.contract_values.amount_financed <= selectedLendingOption.max_amount_loaned;
  allValues.helper_values.ready_to_continue =
    progress == 1 &&
    selectedLendingOption.selected_by_dealer &&
    allValues.helper_values.amount_financed_fits_approval &&
    allValues.helper_values.total_down_fits_approval;

  const { financeCharge, monthlyPayment, totalPayment } = calculateLoanTerms({
    apr: selectedLendingOption.apr,
    term: selectedLendingOption.term,
    amountFinanced: allValues.contract_values.amount_financed,
    contractDate: allValues.dates.contract_date,
    firstPaymentDate: allValues.dates.payment_start_date,
  });

  allValues.contract_values.finance_charge = financeCharge;
  allValues.contract_values.payment_amount = monthlyPayment;
  allValues.contract_values.total_of_payments = totalPayment;
  allValues.contract_values.total_sale_price = roundAccurately(totalPayment + allValues.contract_values.total_down_payment, 2);

  return allValues;
};
