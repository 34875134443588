import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StoreOutlined from '@mui/icons-material/StoreOutlined';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import Chip from '@mui/material/Chip';

import { FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import { fetchLoanThunk } from 'store/thunks/loan';
import { setAlert } from 'store/sagas/app';
import Collateral from './Collateral';
import Customers from './Customers';
import Onboarding from './Onboarding';
import Reporting from './Reporting';
import Servicing from './Servicing';
import Settings from './Settings';
import Summary from './Summary';
import Terms from './Terms';
import { TabsContainer } from './styled-components';
import { LINKS } from 'constants/menus';
import { LOAN_STATUSES } from '../AllLoans/helpers';
import { Circle } from '@mui/icons-material';

const SECTIONS = {
  ONBOARDING: 'onboarding',
  SUMMARY: 'summary',
  SERVICING: 'servicing',
  CUSTOMERS: 'customers',
  COLLATERAL: 'collateral',
  TERMS: 'terms',
  REPORTING: 'reporting',
  SETTINGS: 'settings',
};

const chips = [
  { status: LOAN_STATUSES.DRAFT, chip: <Chip label="Draft" color="default" /> },
  { status: LOAN_STATUSES.OPEN, chip: <Chip label="Open" color="success" /> },
  { status: LOAN_STATUSES.CLOSED, chip: <Chip label="Closed" color="default" /> },
  { status: LOAN_STATUSES.PAID_OFF, chip: <Chip label="Paid Off" color="primary" /> },
  { status: LOAN_STATUSES.CHARGED_OFF, chip: <Chip label="Charged Off" color="error" /> },
];

const ViewLoan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [loan, setLoan] = useState({});
  const [isOnboarding, setIsOnboarding] = useState(false);
  const { loanId, section } = useParams();

  const hydrateData = useCallback(async () => {
    setLoading(true);
    try {
      const { loan } = await dispatch(
        fetchLoanThunk({
          subdata: ['customers', 'source-company', 'collateral'],
          loanId,
        }),
      ).unwrap();
      if (!loan) {
        setLoan({});
      } else {
        const { status } = loan;
        if (status === 'draft') {
          setIsOnboarding(true);
          if (section !== SECTIONS.ONBOARDING) navigate(LINKS.LENDER.LOANS.VIEW_ONE(loanId, SECTIONS.ONBOARDING));
        } else {
          setIsOnboarding(false);
          if (
            !Object.values(SECTIONS)
              .filter((s) => s !== 'onboarding')
              .includes(section)
          ) {
            navigate(LINKS.LENDER.LOANS.VIEW_ONE(loanId, SECTIONS.SUMMARY));
          }
        }

        setLoan(loan);
      }
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch this loan. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [setLoading, dispatch, setLoan, setIsOnboarding, loanId]);

  const handleTabChange = (e, newValue) => {
    navigate(LINKS.LENDER.LOANS.VIEW_ONE(loanId, newValue));
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId, hydrateData]);

  const { collateral, customers, source_company, status } = loan;
  const primaryCustomer = customers && customers.length ? customers.find((customer) => customer?.loans_customers?.customer_role === 'primary-debtor') : null;

  const sections = useMemo(
    () => [
      {
        label: 'Onboarding',
        show: isOnboarding,
        component: <Onboarding rehydrateTopLevel={hydrateData} />,
        disabled: false,
        value: SECTIONS.ONBOARDING,
      },
      {
        label: 'Summary',
        show: !isOnboarding,
        component: <Summary timezone={loan?.timezone} />,
        disabled: false,
        value: SECTIONS.SUMMARY,
      },
      {
        label: 'Servicing',
        show: !isOnboarding,
        component: <Servicing contractDate={loan?.contract_date} timezone={loan?.timezone} />,
        disabled: false,
        value: SECTIONS.SERVICING,
      },
      {
        label: 'Customers',
        show: !isOnboarding,
        component: <Customers rehydrateTopLevel={hydrateData} />,
        disabled: false,
        value: SECTIONS.CUSTOMERS,
      },
      {
        label: 'Collateral',
        show: !isOnboarding,
        component: <Collateral rehydrateTopLevel={hydrateData} />,
        disabled: false,
        value: SECTIONS.COLLATERAL,
      },
      {
        label: 'Terms',
        show: !isOnboarding,
        component: <Terms />,
        disabled: false,
        value: SECTIONS.TERMS,
      },
      {
        label: 'Reporting',
        show: !isOnboarding,
        component: <Reporting />,
        disabled: false,
        value: SECTIONS.REPORTING,
      },
      {
        label: 'Settings',
        show: !isOnboarding,
        component: <Settings />,
        disabled: false,
        value: SECTIONS.SETTINGS,
      },
    ],
    [isOnboarding, hydrateData, loan?.contract_date],
  );

  const showableSections = sections.filter((section) => section.show);
  const currentSection = showableSections.find((obj) => obj.value == section);
  const StatusChip = chips.find((chip) => chip.status === status);

  if (loading) {
    return (
      <FlexRow style={{ background: COLORS.backgroundColor }} justifyContent="center" alignItems="center" padding="20px" fullHeight>
        <CircularProgress />
      </FlexRow>
    );
  }

  return (
    // Used to give a background and center the width container
    <FlexRow style={{ background: COLORS.backgroundColor }} flexColumn fullHeight padding="0px" margin="0px">
      {/* Loan name and identifying details */}
      <FlexRow padding="20px 20px 0px" margin="0px" flexColumn>
        {/* Main debtor's name or New Loan */}
        <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{primaryCustomer?.first_name ? `${primaryCustomer?.first_name} ${primaryCustomer?.last_name}` : 'New Loan'}</Typography>
          {StatusChip?.chip}
        </FlexRow>

        {/* Identifying loan details */}
        <FlexRow margin="0px" padding="0px" alignItems="center">
          <Chip size="small" label={loan?.active ? 'Active' : 'Inactive'} icon={<Circle color={loan?.active ? 'success' : 'error'} fontSize="small" />} />
          <Chip
            sx={{ ml: '10px' }}
            size="small"
            label={`${collateral?.year} ${collateral?.make} ${collateral?.model}`}
            icon={<DirectionsCarOutlinedIcon color="primary" fontSize="small" />}
          />
          <Chip size="small" sx={{ ml: '10px' }} label={source_company?.name || ''} icon={<StoreOutlined color="primary" fontSize="small" />} />
        </FlexRow>
      </FlexRow>

      {/* Tabbed Navigation */}
      <TabsContainer>
        <Tabs sx={{ minHeight: '32px' }} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile value={section} onChange={handleTabChange}>
          {showableSections.map((section) => (
            <Tab
              sx={{
                textTransform: 'none',
                borderRadius: '8px',
                minHeight: '32px',
                p: 0,
                '&:hover': { backgroundColor: COLORS.primaryFaded, color: COLORS.primary },
              }}
              key={`${section.label}-tab`}
              label={section.label}
              disabled={section.disabled}
              value={section.value}
            />
          ))}
        </Tabs>
      </TabsContainer>

      <>{currentSection?.component}</>
    </FlexRow>
  );
};

export default ViewLoan;
