import * as Yup from 'yup';

const validators = {
  textField: Yup.string(),
  email: Yup.string().email(),
  phone: Yup.string().matches(/^(\+?1)?([2-9][0-8][0-9])\d{7}$/),
  date: Yup.date(),
  select: Yup.mixed().nullable(false),
  integer: Yup.number().integer(),
  float: Yup.number().positive(),

  required: {
    textField: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string()
      .matches(/^(\+?1)?([2-9][0-8][0-9])\d{7}$/)
      .required(),
    date: Yup.date().required(),
    select: Yup.mixed().nullable(false).required(),
    integer: Yup.number().integer().required(),
    float: Yup.number().positive().required(),
  },

  nullable: {
    select: Yup.mixed().nullable(true),
  },
};

export default validators;
