import { useSearchParams } from 'react-router-dom';

import { SectionContainer, SectionContentContainer, ViewLoanPageLayout } from '../styled-components';
import PersonalInformation from './PersonalInformation';
import PaymentProfiles from './PaymentProfiles';

const Customers = ({ rehydrateTopLevel }) => {
  const [searchParams] = useSearchParams();

  const SECTIONS = {
    PERSONAL_INFORMATION: 'personal-info',
    REFERENCES: 'references',
    PAYMENT_PROFILES: 'payment_profiles',
    COMMUNICATION: 'communicaiton',
  };

  const sectionObjs = [
    { label: 'Personal Info', value: SECTIONS.PERSONAL_INFORMATION, component: <PersonalInformation rehydrateTopLevel={rehydrateTopLevel} /> },
    { label: 'Payment Profiles', value: SECTIONS.PAYMENT_PROFILES, component: <PaymentProfiles /> },
    { label: 'References', value: SECTIONS.REFERENCES },
    { label: 'Communication', value: SECTIONS.COMMUNICATION },
  ];

  const defaultSection = SECTIONS.PERSONAL_INFORMATION;
  const activeSection = searchParams.get('section') || defaultSection;
  const activeComponent = sectionObjs.find((section) => section.value === activeSection)?.component;

  return (
    <ViewLoanPageLayout defaultItem={defaultSection} menuItems={sectionObjs}>
      <SectionContainer>
        <SectionContentContainer fullWidth={sectionObjs.find((section) => section.value === activeSection)?.fullWidth}>
          {activeComponent ? activeComponent : <div>Error</div>}
        </SectionContentContainer>
      </SectionContainer>
    </ViewLoanPageLayout>
  );
};

export default Customers;
