import { METHODS, request } from 'services/axios';

export const URIS = {
  FETCH_DEALER_APPLICATIONS: ({ dealerId }) => `/dealers/${dealerId}/applications`,
  ADD_DEALER_USER: ({ dealerId }) => `/dealers/${dealerId}/users`,
  UPDATE_DEALER_USER: ({ dealerId, userId }) => `/dealers/${dealerId}/users/${userId}`,
  FETCH_USERS_FOR_DEALER: ({ dealerId }) => `/dealers/${dealerId}/users`,
};

export default {
  fetchDealerApplications: ({ dealerId, ...params }) =>
    request({
      url: URIS.FETCH_DEALER_APPLICATIONS({ dealerId }),
      method: METHODS.GET,
      params,
    }),

  addDealerUser: ({ dealerId, data }) =>
    request({
      url: URIS.ADD_DEALER_USER({ dealerId }),
      method: METHODS.POST,
      data,
    }),

  updateDealerUser: ({ dealerId, userId, data }) =>
    request({
      url: URIS.UPDATE_DEALER_USER({ dealerId, userId }),
      method: METHODS.PATCH,
      data,
    }),

  fetchUsersForDealer: ({ dealerId }) =>
    request({
      url: URIS.FETCH_USERS_FOR_DEALER({ dealerId }),
      method: METHODS.GET,
    }),
};
