import validators from './validators';

export const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const YEARS = Array.from({ length: 51 }, (_, i) => new Date().getFullYear() + 1 - i).map((num) => num.toString());

export const fieldTypes = {
  text: 'text',
  emailLookup: 'emailLookup',
  phone: 'tel',
  date: 'date',
  select: 'select',
  basicSelect: 'basicSelect',
  integer: 'integer',
  percentage: 'percentage',
  currency: 'currency',
  password: 'password',
};

export const baseValues = {
  text: '',
  email: '',
  phone: '',
  date: null,
  select: '',
  basicSelect: '',
  integer: null,
  percentage: null,
  currency: null,
  boolean: false,
};

export const baseTextField = {
  validator: validators.required.textField,
  initialValue: baseValues.text,
  required: true,
  type: fieldTypes.text,
  errorText: '',
};

export const basePasswordField = {
  initialValue: baseValues.text,
  type: fieldTypes.password,
};

export const baseEmailField = {
  ...baseTextField,
  type: fieldTypes.text,
  validator: validators.required.email,
  errorText: 'Please provide a valid email.',
};

export const baseEmailLookupField = {
  ...baseEmailField,
  type: fieldTypes.emailLookup,
};

export const basePhoneField = {
  ...baseTextField,
  type: fieldTypes.phone,
  validator: validators.required.phone,
  mask: '999-999-9999',
  errorText: 'Please provide a valid phone number.',
};

export const baseDateField = {
  ...baseTextField,
  initialValue: baseValues.date,
  type: fieldTypes.date,
  validator: validators.required.date,
  errorText: 'Please provide a valid date.',
};

export const baseSelect = {
  initialValue: baseValues.select,
  type: fieldTypes.select,
  required: true,
  validator: validators.required.select,
};

export const baseBasicSelect = {
  initialValue: baseValues.basicSelect,
  type: fieldTypes.basicSelect,
  required: true,
  validator: validators.required.select,
};

export const baseInteger = {
  initialValue: baseValues.integer,
  type: fieldTypes.integer,
  validator: validators.required.integer,
  required: true,
  errorText: 'Please provide a valid number.',
};

export const basePercentage = {
  initialValue: baseValues.percentage,
  type: fieldTypes.percentage,
  validator: validators.required.float,
  required: true,
  errorText: 'Please provide a valid percentage.',
};

export const baseCurrency = {
  initialValue: baseValues.currency,
  validator: validators.required.float,
  type: fieldTypes.currency,
  required: true,
  errorText: 'Please provide a valid number.',
};
