import { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CategoryIcon from '@mui/icons-material/Category';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ErrorIcon from '@mui/icons-material/Error';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ApprovalIcon from '@mui/icons-material/Approval';

import { Card, FlexRow, BasicMenu, ListItem } from 'components/Layouts';

const OrganizationDetails = () => {
  const lender = useSelector((state) => state?.user?.active_profile?.lender);

  return (
    <Card margin="0" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0px 0px 20px" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Organization Details</Typography>
      </FlexRow>
    </Card>
  );
};

export default OrganizationDetails;
