import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_LOAN_COLLATERAL: ({ loanId }) => `/servicing/loans/${loanId}/collaterals`,
  CREATE_LOAN_COLLATERAL: ({ loanId }) => `/servicing/loans/${loanId}/collaterals`,
  UPDATE_LOAN_COLLATERAL: ({ collateralId, loanId }) => `/servicing/loans/${loanId}/collaterals/${collateralId}`,
  UPLOAD_TITLE_IMG: ({ collateralId, loanId }) => `/servicing/loans/${loanId}/collaterals/${collateralId}/uploadTitleImg`,
  DOWNLOAD_TITLE_IMG: ({ collateralId, loanId }) => `/servicing/loans/${loanId}/collaterals/${collateralId}/titleImg`,
  DELETE_TITLE_IMG: ({ collateralId, loanId }) => `/servicing/loans/${loanId}/collaterals/${collateralId}/titleImg`,
};

export default {
  fetchLoanCollateral: ({ loanId }) =>
    request({
      url: URIS.FETCH_LOAN_COLLATERAL({ loanId }),
      method: METHODS.GET,
    }),

  createLoanCollateral: ({ data, loanId }) =>
    request({
      url: URIS.CREATE_LOAN_COLLATERAL({ loanId }),
      method: METHODS.POST,
      data,
    }),

  updateLoanCollateral: ({ data, collateralId, loanId }) =>
    request({
      url: URIS.UPDATE_LOAN_COLLATERAL({ collateralId, loanId }),
      method: METHODS.PATCH,
      data,
    }),

  uploadTitleImg: ({ formData, collateralId, loanId }) =>
    request({
      url: URIS.UPLOAD_TITLE_IMG({ collateralId, loanId }),
      method: METHODS.PATCH,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  downloadTitleImg: ({ loanId, collateralId }) =>
    request({
      url: URIS.DOWNLOAD_TITLE_IMG({ collateralId, loanId }),
      method: METHODS.GET,
    }),

  deleteTitleImg: ({ loanId, collateralId }) =>
    request({
      url: URIS.DELETE_TITLE_IMG({ collateralId, loanId }),
      method: METHODS.DELETE,
    }),
};
