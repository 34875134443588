import { createAsyncThunk } from '@reduxjs/toolkit';

import { SourceCompanyAPI } from 'store/api';

export const createSourceCompanyThunk = createAsyncThunk('sourceCompany/create', async ({ data }, thunkAPI) => {
  try {
    const response = await SourceCompanyAPI.createSourceCompany({ data });

    /**
     * This will dispatch action creator with type of "sourceCompany/create/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return response;
  } catch (err) {
    /**
     * This will dispatch action creator with type of "sourceCompany/create/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchSourceCompaniesThunk = createAsyncThunk('sourceCompany/fetchSourceCompanies', async (props, thunkAPI) => {
  try {
    const response = await SourceCompanyAPI.fetchSourceCompanies();

    /**
     * This will dispatch action creator with type of "sourceCompany/fetchSourceCompanies/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return response;
  } catch (err) {
    /**
     * This will dispatch action creator with type of "sourceCompany/fetchSourceCompanies/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});
