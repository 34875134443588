import { useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeIcon from '@mui/icons-material/SummarizeOutlined';

import { currencyFormatter } from 'constants/formatters';
import { STATUS_LABELS } from 'constants/status';
import { LINKS } from 'constants/menus';
import { PLAID_AUTH_STATUS } from 'constants/plaid';
import { storeApplication, fetchApplicationById, submitApplication } from 'store/sagas/applications';
import NavBar from 'components/NavBar';
import { UnderNavBarContainer, FlexRow, Card } from 'components/Layouts';
import Sidebar from './sidebar';
import AppInfo from './appInfo';
import Overview from './overview';
import LendingOptions from './LendingOptions';
import Documents from './documents';
import { PaymentProfilesContextProvider } from './PaymentProfiles/context';
import { ReferencesContextProvider } from './References/context';
import ContractPrepOverview from './contractPrepOverview';
import PaymentProfiles from './PaymentProfiles';
import Verify from './verify';
import References from './References';
import Signing from './signing';
import { calculateProgress } from '../utils';

const { SAVED, APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING } = STATUS_LABELS;
const { PRE_PLAID, PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED } = PLAID_AUTH_STATUS;

export const SECTIONS = {
  OVERVIEW: 'overview',
  APP_INFO: 'app_info',
  LENDING_OPTIONS: 'lending_options',
  STIPS_AND_DOCS: 'stips_and_docs',
  CONTRACT_PREP_OVERVIEW: 'contract_prep_overview',
  PAYMENT_PROFILES: 'payment_profiles',
  VERIFY: 'verify',
  REFERENCES: 'references',
  SIGNING: 'signing',
};

const ViewApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const appLoading = useSelector((state) => state.app.loading);
  const currentApplication = useSelector((state) => state.applications.currentApplication);
  const currentStatus = currentApplication && currentApplication.status && currentApplication.status.name;
  const underwriting_status = currentApplication && currentApplication.underwriting && currentApplication.underwriting.underwriting_status;
  const underwriting_status_details = currentApplication && currentApplication.underwriting && currentApplication.underwriting.underwriting_status_details;

  /**
   * Check if there is a contract already prepared.
   */
  const { contract_values, trade_unit, signing, dates, lending_options } = currentApplication;
  const selectedLendingOption = lending_options && lending_options.length > 0 ? lending_options.find((option) => option.selected_by_dealer) : null;
  const hasContractPrepared =
    selectedLendingOption?.apr &&
    selectedLendingOption?.max_amount_loaned &&
    contract_values?.amount_financed &&
    contract_values?.payment_amount &&
    dates?.payment_start_date &&
    contract_values?.required_amount_down &&
    contract_values?.total_down_payment &&
    contract_values?.cash_down;

  /**
   * ! Very Important.
   * The primary payment profile here may not even be verified yet.
   */
  const primaryPaymentProfile = currentApplication?.payment_profiles
    ? currentApplication.payment_profiles.find(
        ({ profile_type, institution_name, account_type, account_name, account_number, routing_number, verification_status }) => {
          if (
            profile_type === 1 &&
            institution_name &&
            account_type &&
            account_name &&
            account_number &&
            routing_number &&
            [PRE_PLAID, PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED].includes(
              verification_status,
            )
          ) {
            return true;
          } else {
            return false;
          }
        },
      )
    : null;

  /**
   * Get application reference requirements.
   */
  const { no_of_ref, no_of_verified_ref } = currentApplication?.lending_rules ?? {};

  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const activeSection = searchParams.get('section');
  const overviewShouldShow = activeSection === SECTIONS.OVERVIEW || !activeSection;
  const readyToSign = currentApplication?.signing?.ready_to_sign;
  const hasCoApplicant = !!currentApplication?.co_applicant?.id;
  const hasTradeIn = !!currentApplication?.trade_unit?.id;
  const { totalProgress } = calculateProgress({ values: currentApplication, hasTradeIn, hasCoApplicant });

  const applicantEmailVerified = currentApplication?.applicant?.email_verified;
  const applicantPhoneVerified = currentApplication?.applicant?.phone_verified;
  const applicantContactDetailsVerified = applicantEmailVerified && applicantPhoneVerified;

  const coApplicantEmailVerified = currentApplication?.co_applicant?.email_verified;
  const coApplicantPhoneVerified = currentApplication?.co_applicant?.phone_verified;
  const coApplicantContactDetailsVerified = coApplicantEmailVerified && coApplicantPhoneVerified;

  const contactDetailsVerified = applicantContactDetailsVerified && (coApplicantContactDetailsVerified || !hasCoApplicant);

  useEffect(() => {
    if (id != 'new') {
      dispatch(fetchApplicationById(id));
    } else {
      dispatch(storeApplication({}));
    }
    return () => {
      dispatch(storeApplication({}));
    };
  }, [id]);

  const breadcrumbs = [
    { text: 'Home', link: LINKS.DEALER.DASHBOARD },
    { text: 'Applications', link: LINKS.DEALER.ALL_APPLICATIONS },
    {
      text:
        id == 'new'
          ? 'New App'
          : currentApplication?.applicant?.first_name && currentApplication?.applicant?.last_name
          ? `${currentApplication.applicant.first_name} ${currentApplication.applicant.last_name}`
          : currentApplication?.applicant?.email
          ? currentApplication.applicant.email
          : 'View Application',
      link: '',
    },
  ];

  const sectionCanShow = {
    lendingOptions: [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    stipsAndDocs: [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    contractPrepOverview: [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    paymentProfiles:
      hasContractPrepared && [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    verify: hasContractPrepared && [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    references:
      (no_of_ref || no_of_verified_ref) &&
      [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    signing:
      primaryPaymentProfile &&
      readyToSign &&
      contactDetailsVerified &&
      [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
  };

  const submitApp = () => {
    const { applicant, co_applicant, unit, trade_unit, dealer, lender, type } = currentApplication;

    const dataObj = {
      applicant,
      co_applicant: hasCoApplicant ? co_applicant : null,
      unit,
      trade_unit: hasTradeIn ? trade_unit : null,
      dealer_id: dealer.id,
      lender_id: lender.id,
      application_type_id: type.id,
    };

    dispatch(submitApplication({ data: dataObj, applicationId: id }));
  };

  let navBarActions = [
    {
      title: 'Edit',
      action: () => navigate(LINKS.DEALER.EDIT_APPLICATION(id)),
      icon: <EditIcon size="small" />,
      forStatus: [SAVED],
    },
    {
      title: 'Submit',
      action: submitApp,
      icon: <PostAddOutlinedIcon size="small" />,
      disabled: totalProgress !== 1,
      forStatus: [SAVED],
    },
    {
      title: 'Prepare Contract',
      action: () => navigate(LINKS.DEALER.PREPARE_CONTRACT(id)),
      icon: <SummarizeIcon size="small" />,
      forStatus: [APPROVED],
    },
  ];

  return (
    <>
      <NavBar
        backButton={LINKS.DEALER.ALL_APPLICATIONS}
        breadcrumbs={breadcrumbs}
        actions={navBarActions.filter((option) => !option.forStatus || option.forStatus.includes(currentStatus))}
      />
      <UnderNavBarContainer padding="0px">
        <FlexRow fullHeight padding="0px">
          <Sidebar appId={id} sectionCanShow={sectionCanShow} activeSection={activeSection} SECTIONS={SECTIONS} />

          <FlexRow alignItems="center" overflowScroll fullHeight flexColumn padding="20px">
            {underwriting_status && (
              <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Underwriting error.">
                <Alert severity="error">
                  <AlertTitle>{underwriting_status}</AlertTitle>
                  {underwriting_status_details}
                </Alert>
              </Card>
            )}

            {contract_values && selectedLendingOption && contract_values.amount_financed > selectedLendingOption.max_amount_loaned && (
              <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Amount financed error.">
                <Alert severity="error">
                  <AlertTitle>Amount Financed</AlertTitle>
                  <Typography>
                    {`The amount financed of ${currencyFormatter.format(
                      contract_values.amount_financed,
                    )} is greater than the max approved amount of ${currencyFormatter.format(selectedLendingOption.max_amount_loaned)}.`}
                  </Typography>
                </Alert>
              </Card>
            )}

            {contract_values && contract_values.total_down_payment < contract_values.required_amount_down && (
              <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Down payment error.">
                <Alert severity="error">
                  <AlertTitle>Down Payment</AlertTitle>
                  <Typography>
                    {`The total down payment of ${currencyFormatter.format(contract_values.total_down_payment)} (${currencyFormatter.format(
                      contract_values.cash_down,
                    )} cash down payment and ${currencyFormatter.format(
                      !!trade_unit?.equity && trade_unit.equity > 0 ? trade_unit.equity : 0,
                    )} trade equity) is less than the required amount of ${currencyFormatter.format(contract_values.required_amount_down)}.`}
                  </Typography>
                </Alert>
              </Card>
            )}

            {signing && !signing.applicant_signed && !signing.co_applicant_signed && !signing.dealer_signed && signing.ready_to_sign && (
              <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Primary payment profile error.">
                <Alert severity="success">
                  <AlertTitle>Ready to Sign</AlertTitle>
                  <Typography>
                    Congratulations! You are all set. You can proceed to the signing screen. Please be aware that, although you can sign the customer right now,
                    until all stipulations have been met the lender will not purchase the retail installment contract from you.
                  </Typography>
                </Alert>
              </Card>
            )}

            {!currentApplication.id && !appLoading
              ? [
                  <Card key={1} margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Application not found.">
                    <Alert severity="error">
                      <AlertTitle>Application not found.</AlertTitle>
                    </Alert>
                  </Card>,
                ]
              : [
                  {
                    section: SECTIONS.OVERVIEW,
                    canShow: true,
                    component: <Overview key="overview" currentApplication={currentApplication} />,
                  },
                  {
                    section: SECTIONS.APP_INFO,
                    canShow: true,
                    component: (
                      <AppInfo
                        key="appInfo"
                        currentApplication={currentApplication}
                        hasCoApplicant={hasCoApplicant}
                        hasTradeIn={hasTradeIn}
                        canEdit={[SAVED].includes(currentStatus)}
                        overviewShouldShow={overviewShouldShow}
                        setActiveSectionToAppInfo={() => navigate(LINKS.DEALER.VIEW_APPLICATION(id, SECTIONS.APP_INFO))}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.LENDING_OPTIONS,
                    canShow: sectionCanShow.lendingOptions,
                    component: (
                      <LendingOptions
                        key="lendingOptions"
                        lendingOptions={currentApplication.lending_options ? currentApplication.lending_options : []}
                        canEdit={currentStatus == APPROVED}
                        applicationStatus={currentStatus}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.STIPS_AND_DOCS,
                    canShow: sectionCanShow.stipsAndDocs,
                    component: (
                      <Documents key="documents" currentApplication={currentApplication} overviewShouldShow={overviewShouldShow} SECTIONS={SECTIONS} />
                    ),
                  },
                  {
                    section: SECTIONS.CONTRACT_PREP_OVERVIEW,
                    canShow: sectionCanShow.contractPrepOverview,
                    component: (
                      <ContractPrepOverview
                        key="contractPrepOverview"
                        showDetailed={!overviewShouldShow}
                        prepareContractAction={() => navigate(LINKS.DEALER.PREPARE_CONTRACT(id))}
                        canPrepare={[APPROVED].includes(currentStatus)}
                        currentApplication={currentApplication}
                        hasContractPrepared={hasContractPrepared}
                        primaryPaymentProfile={primaryPaymentProfile}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.PAYMENT_PROFILES,
                    canShow: sectionCanShow.paymentProfiles,
                    component: (
                      <PaymentProfilesContextProvider key="paymentProfiles">
                        <PaymentProfiles currentApplication={currentApplication} />
                      </PaymentProfilesContextProvider>
                    ),
                  },
                  {
                    section: SECTIONS.VERIFY,
                    canShow: sectionCanShow.verify,
                    component: (
                      <Verify
                        key="verify"
                        hasCoApplicant={hasCoApplicant}
                        applicant={currentApplication?.applicant ? currentApplication.applicant : {}}
                        coApplicant={currentApplication?.co_applicant ? currentApplication.co_applicant : {}}
                        fetchAppData={() => dispatch(fetchApplicationById(id))}
                        appId={id}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.REFERENCES,
                    canShow: sectionCanShow.references,
                    component: (
                      <ReferencesContextProvider key="references">
                        <References currentApplication={currentApplication} />
                      </ReferencesContextProvider>
                    ),
                  },
                  {
                    section: SECTIONS.SIGNING,
                    canShow: sectionCanShow.signing,
                    component: (
                      <Signing
                        key="signing"
                        currentStatus={currentStatus}
                        hasCoApplicant={hasCoApplicant}
                        documents={currentApplication?.documents ? currentApplication.documents : []}
                        signing={currentApplication?.signing ? currentApplication.signing : {}}
                        applicant={currentApplication?.applicant ? currentApplication.applicant : {}}
                        coApplicant={currentApplication?.co_applicant ? currentApplication.co_applicant : {}}
                        dealer={currentApplication?.dealer ? currentApplication.dealer : {}}
                        user={user}
                        appId={id}
                        activeSection={activeSection}
                        SECTIONS={SECTIONS}
                        appLoading={appLoading}
                        overviewShouldShow={overviewShouldShow}
                        application={currentApplication}
                      />
                    ),
                  },
                ]
                  .filter((item) => {
                    if (activeSection == item.section && item.canShow) return true;
                    if (activeSection == SECTIONS.OVERVIEW && item.canShow) return true;
                    if (!activeSection && item.section == SECTIONS.OVERVIEW) return true;

                    return false;
                  })
                  .map(({ component }) => component)}
          </FlexRow>
        </FlexRow>
      </UnderNavBarContainer>
    </>
  );
};

export default ViewApp;
