import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';

import { STATUS_LABELS } from 'constants/status';
import { PLAID_AUTH_STATUS } from 'constants/plaid';
import { fetchApplicationById } from 'store/sagas/applications';
import {
  generateApplicationLinkTokenThunk,
  connectApplicationPaymentProfileThunk,
  setApplicationPrimaryPaymentProfileThunk,
  deleteApplicationPaymentProfileThunk,
} from 'store/thunks/application';
import PaymentProfilesContext from './context';
import { ActionMenu } from 'components/Menu';
import PaymentProfileFormModal from './PaymentProfileFormModal';
import PlaidLink from './PlaidLink';
import Alert from './Alert';

const { FUNDED, SERVICING } = STATUS_LABELS;
const {
  PRE_PLAID,
  PENDING_AUTOMATIC_VERIFICATION,
  PENDING_MANUAL_VERIFICATION,
  AUTOMATICALLY_VERIFIED,
  MANUALLY_VERIFIED,
  VERIFIED,
  VERIFICATION_FAILED,
  VERIFICATION_EXPIRED,
} = PLAID_AUTH_STATUS;

const ConfirmDelete = ({ accountName, accountNumber, routingNumber, deleting, onOk, onCancel }) => {
  return (
    <Dialog open={true}>
      <DialogTitle>
        <Stack direction={'row'} spacing={1}>
          <CreditCardOffOutlinedIcon sx={{ fontSize: '2rem' }} />
          <Typography variant="h6">Confirm Action</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>Are you sure you want to delete this payment profile?</DialogContentText>
        <br />
        <DialogContentText>Account Name: &nbsp;&nbsp;&nbsp;&nbsp;{accountName}</DialogContentText>
        <DialogContentText>Account Number: {accountNumber}</DialogContentText>
        <DialogContentText>Routing Number: &nbsp;&nbsp;{routingNumber}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button disabled={deleting} variant="outlined" color="primary" onClick={() => onCancel()}>
          Cancel
        </Button>

        <LoadingButton loading={deleting} loadingPosition="start" startIcon={<DeleteIcon />} variant="contained" color="error" onClick={async () => onOk()}>
          {deleting ? 'Processing...' : 'Confirm'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const PaymentProfile = ({ mode, width, selectable, data }) => {
  const { xs, sm, md, lg, xl } = width ?? {};
  const { selected, setSelected } = selectable ?? {};
  const {
    applicantId,
    applicationId,
    applicationStatus,
    profileType,
    institutionName,
    institutionLogo,
    institutionPrimaryColor,
    accountType,
    accountName,
    accountNumber,
    routingNumber,
    verificationStatus,
  } = data ?? {};

  const dispatch = useDispatch();
  const { paymentProfilesSectionProcessing, setPaymentProfilesSectionProcessing, setPaymentProfilesSectionErrorMsg, setShowPaymentProfilesSectionError } =
    useContext(PaymentProfilesContext);
  const [showModal, setShowModal] = useState(false);
  const [fullModalForm, setFullModalForm] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deletingPaymentProfile, setDeletingPaymentProfile] = useState(false);

  let actionOptions = [];

  if (verificationStatus === PENDING_AUTOMATIC_VERIFICATION) {
    /**
     * There are 2 potential actions here depending on the scenario.
     *
     * 1.) If account number is not set (which if this is the case we assume that most of the payment profile details is not set),
     * we need to have the end user enter the payment profile details first before allowing to check the verification status of the payment profile.
     *
     * 2.) If payment profile details are already set, then that is the only time we allow end user to check the verification status of the payment profile.
     */

    if (!accountNumber) {
      actionOptions.push({
        value: 'Enter Bank Details',
        icon: <AccountBalanceOutlinedIcon />,
        callback: () => {
          setProcessing(true);
          setFullModalForm(true);
          setShowModal(true);
        },
      });
    } else {
      /**
       * Action to check and attempt to connect payment profile.
       * Note that if the payment profile status is verified, then this also connects the payment profile.
       */
      actionOptions.push({
        value: 'Check Verification Status',
        icon: <PageviewOutlinedIcon />,
        callback: async () => {
          try {
            setPaymentProfilesSectionProcessing(true);
            setProcessing(true);
            const response = await dispatch(connectApplicationPaymentProfileThunk({ applicationId, profileType })).unwrap();

            if (response.success) {
              dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data
            } else if (response.error_code === 'PRODUCT_NOT_READY') {
              setAlertTitle('Payment Profile Unverified');
              setAlertMessage('Payment profile is still not verified. Please try checking again later.');
              setShowAlert(true);
            } else {
              setAlertTitle('Process Failed');
              setAlertMessage('Failed to check verification status of the current payment profile. Please try checking again later.');
              setShowAlert(true);
            }
          } catch (err) {
            console.log('Checking verification status err: ', err);

            setPaymentProfilesSectionErrorMsg('Failed to check verification status.');
            setShowPaymentProfilesSectionError(true);
          } finally {
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }
        },
      });
    }

    actionOptions.push({ divider: true });

    actionOptions.push({
      value: <Typography sx={{ color: 'red' }}>Delete</Typography>,
      icon: <DeleteOutlineOutlinedIcon sx={{ color: 'red' }} />,
      callback: async () => {
        setPaymentProfilesSectionProcessing(true);
        setShowConfirmDeleteModal(true);
      },
    });
  } else if (verificationStatus === PENDING_MANUAL_VERIFICATION) {
    /**
     * There are 2 potential actions here depending on the scenario.
     *
     * 1.) If account number is not set (which if this is the case we assume that most of the payment profile details is not set),
     * we need to have the end user enter the payment profile details first before allowing to check the verification status of the payment profile.
     *
     * 2.) If institution name is not set, then we need to have the end user enter the institution name first before allowing to
     * manually verify the payment profile.
     *
     * 3.) If institution name is already set, then that is the only time we allow end user to manually verify the payment profile.
     */

    if (!accountNumber) {
      actionOptions.push({
        value: 'Enter Bank Details',
        icon: <AccountBalanceOutlinedIcon />,
        callback: () => {
          setProcessing(true);
          setFullModalForm(true);
          setShowModal(true);
        },
      });
    } else if (!institutionName) {
      actionOptions.push({
        value: 'Enter Bank Name',
        icon: <AccountBalanceOutlinedIcon />,
        callback: () => {
          setProcessing(true);
          setFullModalForm(false);
          setShowModal(true);
        },
      });
    } else {
      actionOptions.push({
        value: 'Verify Payment Profile',
        icon: <CreditScoreOutlinedIcon />,
        callback: async () => {
          try {
            setPaymentProfilesSectionProcessing(true);
            setProcessing(true);

            const response = await dispatch(generateApplicationLinkTokenThunk({ applicantId, applicationId, profileType })).unwrap();

            setLinkToken(response.linkToken.link_token);
          } catch (err) {
            console.log('Generating link token err: ', err);

            setPaymentProfilesSectionErrorMsg('Failed to generate link token.');
            setShowPaymentProfilesSectionError(true);

            setLinkToken(null);
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }
        },
      });
    }

    actionOptions.push({ divider: true });

    actionOptions.push({
      value: <Typography sx={{ color: 'red' }}>Delete</Typography>,
      icon: <DeleteOutlineOutlinedIcon sx={{ color: 'red' }} />,
      callback: async () => {
        setPaymentProfilesSectionProcessing(true);
        setShowConfirmDeleteModal(true);
      },
    });
  } else if ([VERIFICATION_FAILED, VERIFICATION_EXPIRED].includes(verificationStatus)) {
    /**
     * This means this payment profile failed verification or can't be verified anymore.
     * This is marked as invalid on plaid's end even.
     * Therefore it has no use anymore but to delete this.
     */

    actionOptions.push({
      value: <Typography sx={{ color: 'red' }}>Delete</Typography>,
      icon: <DeleteOutlineOutlinedIcon sx={{ color: 'red' }} />,
      callback: async () => {
        setPaymentProfilesSectionProcessing(true);
        setShowConfirmDeleteModal(true);
      },
    });
  } else if ([PRE_PLAID, MANUALLY_VERIFIED, AUTOMATICALLY_VERIFIED, VERIFIED].includes(verificationStatus)) {
    if (profileType !== 1) {
      actionOptions.push({
        value: 'Set As Primary Profile',
        icon: <CreditCardOutlinedIcon />,
        callback: async () => {
          try {
            setPaymentProfilesSectionProcessing(true);
            setProcessing(true);

            await dispatch(setApplicationPrimaryPaymentProfileThunk({ applicationId, profileType })).unwrap();

            dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data
          } catch (err) {
            console.log('Setting as primary profile err: ', err);

            setPaymentProfilesSectionErrorMsg('Failed to set primary payment profile.');
            setShowPaymentProfilesSectionError(true);
          } finally {
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }
        },
      });
    }
  }

  return (
    <>
      <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Card>
          <CardHeader
            sx={{ backgroundColor: institutionPrimaryColor ? institutionPrimaryColor : '#5301FF', minHeight: '60px' }}
            avatar={
              institutionLogo ? (
                <Avatar sx={{ width: 52, height: 52 }} src={`data:image/png;base64, ${institutionLogo}`} aria-label="Bank logo" />
              ) : (
                <Avatar sx={{ width: 52, height: 52, backgroundColor: '#fff' }} aria-label="Bank logo">
                  <AccountBalanceIcon sx={{ width: 38, height: 38, color: institutionPrimaryColor ? institutionPrimaryColor : '#5301FF' }} />
                </Avatar>
              )
            }
            action={
              [FUNDED, SERVICING].includes(applicationStatus) ? (
                <></>
              ) : mode === 'selectable' ? (
                <Checkbox disabled={paymentProfilesSectionProcessing} checked={selected} onChange={setSelected} sx={{ color: '#fff' }} color={'secondary'} />
              ) : processing ? (
                <CircularProgress sx={{ color: '#fff' }} />
              ) : paymentProfilesSectionProcessing ? (
                <></>
              ) : actionOptions.length > 0 ? (
                <ActionMenu sx={{ color: '#fff' }} options={actionOptions} />
              ) : (
                <></>
              )
            }
            title={
              <Typography sx={{ color: '#fff' }} variant="subtitle2" gutterBottom>
                {institutionName}
              </Typography>
            }
            subheader={
              profileType === 1 && [PRE_PLAID, MANUALLY_VERIFIED, AUTOMATICALLY_VERIFIED, VERIFIED].includes(verificationStatus) ? (
                <Stack spacing={1} direction={'row'}>
                  <Chip size="small" label="Primary" color="success" />
                  {verificationStatus === PRE_PLAID && <Chip size="small" label="Pre-Plaid" color="info" />}
                </Stack>
              ) : verificationStatus === PRE_PLAID ? (
                <Chip size="small" label="Pre-Plaid" color="info" /> // Reason why we consolidate all pending into one chip is if the chip goes too long, the action menu is move off the screen.
              ) : [PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION].includes(verificationStatus) ? (
                <Chip size="small" label="Pending" color="warning" />
              ) : verificationStatus === VERIFICATION_FAILED ? (
                <Chip size="small" label="Failed" color="error" />
              ) : verificationStatus === VERIFICATION_EXPIRED ? (
                <Chip size="small" label="Expired" color="error" />
              ) : null
            }
          />

          <CardContent>
            <Typography variant="body1">{accountName}</Typography>

            <Typography variant="body2">
              <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" gutterBottom>
                {accountType ? accountType[0].toUpperCase() + accountType.slice(1) : null}
              </Typography>
            </Typography>

            <Typography variant="body2">
              <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" display="inline" gutterBottom>
                Account #:
              </Typography>{' '}
              {accountNumber}
            </Typography>

            <Typography variant="body2">
              <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" display="inline" gutterBottom>
                Routing &nbsp;#:
              </Typography>{' '}
              {routingNumber}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      {showModal && (
        /**
         * The purpose for this is for inputing any payment profile data that is necessary based on payment profile status.
         * Common case is when payment profile is in pending status and no data is saved and therefore we need these data
         * for us to be able to generate documents for signing.
         */
        <PaymentProfileFormModal
          applicationId={applicationId}
          profileType={profileType}
          fullForm={fullModalForm}
          onSuccess={({ data }) => {
            setShowModal(false);
            setProcessing(false);
          }}
          onError={({ error }) => {
            setShowModal(false);
            setProcessing(false);
          }}
          onClose={() => {
            setShowModal(false);
            setProcessing(false);
          }}
        />
      )}

      {linkToken && (
        /**
         * The purpose of this plaid link is to manually verify pending payment profiles that are using the same-day micro-deposit auth.
         * We expect profileType to be supplied.
         *
         * !Important
         * We don't need to send in applicantId, accountOwner and accountName as these will not be used in this case.
         */
        <PlaidLink
          applicationId={applicationId}
          linkToken={linkToken}
          profileType={profileType}
          onError={() => {
            setLinkToken(null);
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);

            setPaymentProfilesSectionErrorMsg('Failed to set primary payment profile.');
            setShowPaymentProfilesSectionError(true);
          }}
          onExit={() => {
            setLinkToken(null);
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }}
          onInputPaymentProfileDataSuccess={() => {
            setLinkToken(null);
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }}
          onInputPaymentProfileDataFail={() => {
            setLinkToken(null);
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }}
          onPaymentProfileConnectSuccess={() => {
            setLinkToken(null);
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }}
          onPaymentProfileConnectFail={() => {
            setLinkToken(null);
            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }}
        />
      )}

      {showAlert && alertTitle && alertMessage && (
        <Alert
          title={alertTitle}
          message={alertMessage}
          open={showAlert}
          handleClose={() => {
            setShowAlert(false);
            setAlertTitle(null);
            setAlertMessage(null);
          }}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmDelete
          accountName={accountName}
          accountNumber={accountNumber}
          routingNumber={routingNumber}
          deleting={deletingPaymentProfile}
          onOk={async () => {
            try {
              setPaymentProfilesSectionProcessing(true);
              setProcessing(true);
              setDeletingPaymentProfile(true);

              await dispatch(deleteApplicationPaymentProfileThunk({ applicationId, profileType })).unwrap();
              dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data
            } catch (err) {
              console.log('Deleting payment profile err: ', err);

              setPaymentProfilesSectionErrorMsg('Failed to delete payment profile.');
              setShowPaymentProfilesSectionError(true);
            } finally {
              setShowConfirmDeleteModal(false);
              setDeletingPaymentProfile(false);

              setProcessing(false);
              setPaymentProfilesSectionProcessing(false);
            }
          }}
          onCancel={async () => {
            setShowConfirmDeleteModal(false);
            setDeletingPaymentProfile(false);

            setProcessing(false);
            setPaymentProfilesSectionProcessing(false);
          }}
        />
      )}
    </>
  );
};

export default PaymentProfile;
