import { Divider, Typography } from '@mui/material';

import ListItem from 'components/ListItem';
import { currencyFormatter, roundAccurately, formatDateOnly } from 'constants/formatters';
import { Card, FlexRow } from 'components/Layouts';

const AmountDue = ({ totalAmountPastDue, nextPaymentDate, nextPaymentAmount, feesAmountDue }) => {
  const totalDue = roundAccurately(totalAmountPastDue + nextPaymentAmount + feesAmountDue, 2);

  return (
    <Card fullHeight variant="outlined" padding="10px 20px" margin="0px">
      <Typography variant="h6" mb="16px">
        Amount due
      </Typography>

      <Typography sx={{ fontWeight: 500 }}>Next Due Date</Typography>
      <ListItem value={formatDateOnly(nextPaymentDate)} label="Date" />
      <ListItem value={nextPaymentAmount ? currencyFormatter.format(nextPaymentAmount) : '-'} label="Amount" />

      <Divider sx={{ m: '16px 0px' }} />

      <Typography sx={{ fontWeight: 500 }}>Other</Typography>
      <ListItem value={totalAmountPastDue ? currencyFormatter.format(totalAmountPastDue) : '-'} label="Past Due" />
      <ListItem value={feesAmountDue ? currencyFormatter.format(feesAmountDue) : '-'} label="Unpaid Fees" />

      <Divider sx={{ m: '16px 0px' }} />

      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: 500 }}>Total Due (incl. next payment)</Typography>
        <Typography sx={{ fontWeight: 500 }}>{totalDue ? currencyFormatter.format(totalDue) : '-'}</Typography>
      </FlexRow>
    </Card>
  );
};

export default AmountDue;
