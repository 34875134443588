import { Alert, Typography, LinearProgress, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FlexRow } from 'components/Layouts';
import { currencyFormatter, roundAccurately, formatDateOnly } from 'constants/formatters';
import { parsePaymentType } from '../../Payments/helpers';
import ListItem from 'components/ListItem';
import { COLORS } from 'constants/styles';

const Payments = ({ period }) => {
  const percentagePaid =
    period?.amount_paid && period?.payment_amount ? roundAccurately((Number(period.amount_paid) / Number(period.payment_amount)) * 100, 4) : 0;
  return (
    <>
      <Typography sx={{ m: '32px 0px 12px' }} variant="h6">
        Payments
      </Typography>

      {period?.loan_payments && period?.loan_payments?.length && period?.loan_payments?.length > 0 ? (
        <>
          <FlexRow padding="0px" margin="0px 0px 12px" flexColumn justifyContent="center" alignItems="center">
            <Typography variant="h5">{period?.amount_paid ? currencyFormatter.format(Number(period.amount_paid)) : '-'}</Typography>
            <Typography variant="caption">Paid So Far</Typography>
          </FlexRow>

          <div style={{ padding: '0px 80px', marginBottom: '20px' }}>
            <LinearProgress sx={{ height: '12px', borderRadius: '8px', mt: '12px' }} variant="determinate" value={percentagePaid} />

            <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
              <Typography variant="body2">{currencyFormatter.format(0)}</Typography>
              <Typography variant="body2">
                {period?.payment_amount ? currencyFormatter.format(Number(period?.payment_amount)) : currencyFormatter.format(0)}
              </Typography>
            </FlexRow>
          </div>
          {period.loan_payments.map((payment) => (
            <Accordion
              key={payment?.id}
              disableGutters
              elevation={0}
              TransitionProps={{ unmountOnExit: true }}
              sx={{
                borderRadius: '8px',
                border: `1px solid ${COLORS.borderColor}`,
                '&:before': {
                  display: 'none',
                },
                background: COLORS.backgroundColor,
                marginBottom: '10px',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={0} sx={{ width: '100%' }}>
                  <Grid xs={5}>
                    <Typography sx={{ fontWeight: 700 }}>
                      {payment?.payment_period_payment?.payment_amount
                        ? currencyFormatter.format(Number(payment?.payment_period_payment?.payment_amount))
                        : '-'}
                    </Typography>
                  </Grid>

                  <Grid xs={7}>
                    <Typography sx={{ color: 'text.secondary' }}>
                      {payment?.payment_application_date ? `Applied: ${formatDateOnly(payment?.payment_application_date)}` : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={4}>
                  <Grid xs={6}>
                    <ListItem
                      label={period?.period_number ? `Applied to period ${period?.period_number}` : 'Applied to this period:'}
                      value={
                        payment?.payment_period_payment?.payment_amount
                          ? currencyFormatter.format(Number(payment?.payment_period_payment?.payment_amount))
                          : '-'
                      }
                    />
                    <ListItem label="Date" value={payment?.payment_application_date ? formatDateOnly(payment?.payment_application_date) : '-'} />
                    <ListItem label="Type" value={payment?.payment_application_schedule ? parsePaymentType(payment?.payment_application_schedule) : '-'} />
                    <ListItem label="Id" value={payment?.id} />
                  </Grid>

                  <Grid xs={6}>
                    <ListItem label="Total Payment Amount" value={payment?.payment_amount ? currencyFormatter.format(Number(payment?.payment_amount)) : '-'} />
                    <ListItem
                      label="Principal"
                      value={payment?.principal_payment_amount ? currencyFormatter.format(Number(payment?.principal_payment_amount)) : '-'}
                    />
                    <ListItem
                      label="Interest"
                      value={payment?.interest_payment_amount ? currencyFormatter.format(Number(payment?.interest_payment_amount)) : '-'}
                    />
                    <ListItem label="Fees" value={payment?.fees_payment_amount ? currencyFormatter.format(Number(payment?.fees_payment_amount)) : '-'} />
                  </Grid>
                </Grid>

                <Alert
                  sx={{ m: '12px 0px 0px' }}
                  severity="error"
                  color="primary"
                >{`This payment applies to period ${period?.period_number} but may also apply to other periods.`}</Alert>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ) : (
        <FlexRow padding="0px" margin="0px" justifyContent="center" alignItems="center">
          <Alert sx={{ width: '275px' }} severity="warning">{`Period ${period?.period_number} has no payments yet`}</Alert>
        </FlexRow>
      )}
    </>
  );
};

export default Payments;
