import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Button, CircularProgress } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

import { useFormie } from 'components/Formie';
import { Card, FlexRow } from 'components/Layouts';
import { setAlert } from 'store/sagas/app';
import SettingsDrawer from './SettingsDrawer';
import { currencyFormatter, percentageFormatterV2 } from 'constants/formatters';
import { updateLoanThunk } from 'store/thunks/loan';
import { configureAdvancedSettings } from '../../Onboarding/formInfo';
import { fetchLoanThunk } from 'store/thunks/loan';
import { LOAN_STATUS_MAP } from 'views/Lender/Loans/AllLoans/helpers';
import { SettingValueWithDescription } from '../../Terms/SetupTerms/helpers';

const parseLateFeeType = (type) => {
  let prettyType = '-';

  if (type === 'percent-of-payment') prettyType = 'Percent of Late Payment';
  if (type === 'dollars') prettyType = 'Flat Fee';

  return prettyType;
};

const parseLateFeeAmount = (type, amount) => {
  let prettyAmount = '-';

  if (type === 'percent-of-payment' && amount) prettyAmount = percentageFormatterV2(amount);
  if (type === 'dollars' && amount) prettyAmount = currencyFormatter.format(amount);

  return prettyAmount;
};

const AdvancedSettings = () => {
  const dispatch = useDispatch();

  const { loanId } = useParams();
  const [open, setOpen] = useState(false);
  const [loan, setLoan] = useState({});
  const [loading, setLoading] = useState(true);

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { loan } = await dispatch(
        fetchLoanThunk({
          loanId,
          limit: 1000,
        }),
      ).unwrap();
      setLoan(loan);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch loan. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      resetForm();
    }
  };

  const updateLoan = async () => {
    setLoading(true);
    try {
      await dispatch(
        updateLoanThunk({
          loanId,
          data: {
            grace_period: formie?.values?.advancedSettings?.grace_period,
            late_fee_amount: formie?.values?.advancedSettings?.late_fee_amount,
            late_fee_type: formie?.values?.advancedSettings?.late_fee_type,
            nsf_fee: formie?.values?.advancedSettings?.nsf_fee,
            timezone: formie?.values?.advancedSettings?.timezone,
          },
        }),
      ).unwrap();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to update settings. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleStartEdit = () => {
    setOpen(true);
    formie.setAllValues({
      advancedSettings: {
        late_fee_type: loan?.late_fee_type,
        late_fee_amount: loan?.late_fee_amount && Number(loan?.late_fee_amount),
        grace_period: loan?.grace_period && Number(loan?.grace_period),
        nsf_fee: loan?.nsf_fee && Number(loan?.nsf_fee),
        type: loan?.type,
        reporting_type: loan?.reporting_type,
        category: loan?.category,
        timezone: loan?.timezone,
      },
    });
  };

  const formie = useFormie({
    configuration: configureAdvancedSettings(loan?.late_fee_type || 'percent-of-payment'),
    initialValues: {
      advancedSettings: {
        late_fee_type: loan?.late_fee_type,
        late_fee_amount: loan?.late_fee_amount && Number(loan?.late_fee_amount),
        grace_period: loan?.grace_period && Number(loan?.grace_period),
        nsf_fee: loan?.nsf_fee && Number(loan?.nsf_fee),
        type: loan?.type,
        reporting_type: loan?.reporting_type,
        category: loan?.category,
        timezone: loan?.timezone,
      },
    },
    setLoading,
    onSubmit: updateLoan,
  });

  useEffect(() => {
    replaceConfiguration(configureAdvancedSettings(formie?.values?.advancedSettings?.late_fee_type || 'percent-of-payment'));
  }, [formie?.values?.advancedSettings?.late_fee_type]);

  const { handleSubmit, resetForm, replaceConfiguration } = formie;

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <div style={{ margin: 0, padding: '0px' }}>
      <FlexRow padding="0px" margin="0px 0px 10px" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">General Settings</Typography>
        <Button onClick={handleStartEdit} startIcon={<TuneIcon />}>
          Edit
        </Button>
      </FlexRow>

      <Card variant="outlined">
        <Typography variant="h5">General</Typography>
        {[
          { label: 'Id', value: loan?.id, description: 'The system UUID of the loan.', widerValue: true },
          { label: 'Loan Type', value: loan?.type === 'installment' ? 'Installment' : '-', description: 'The only loan type supported is Installment.' },
          {
            label: 'Status',
            value: loan?.status ? LOAN_STATUS_MAP.find((status) => status.name === loan.status)?.label : '-',
            description: 'The current status of the loan. Can be Draft, Open, Closed, Paid Off, or Charged Off.',
          },
          {
            label: 'Active',
            value: loan?.active ? 'Active' : 'Inactive',
            description:
              'Is the loan currently active or inactive? Inactive loans will not accrue interest, create past dues, or expect any payments to be made.',
          },
          { label: 'Timezone', value: loan?.timezone, noDivider: true, description: 'What timezone is this loan attached to?' },
        ].map((setting) => (
          <SettingValueWithDescription
            key={setting.label}
            label={setting.label}
            noDivider={setting.noDivider}
            widerValue={setting.widerValue}
            description={setting.description}
            value={setting.value}
          />
        ))}
      </Card>

      <Card variant="outlined" margin="20px 0px 0px">
        <Typography variant="h5">Fees</Typography>
        {[
          {
            label: 'Late Fee Type',
            value: parseLateFeeType(loan?.late_fee_type),
            description: 'The late fee can be caluclated as a flat fee or as a percentage of the missed payment amount.',
          },
          {
            label: 'Late Fee Amount',
            value: parseLateFeeAmount(loan?.late_fee_type, loan?.late_fee_amount),
            description: 'Either a flat amount or a percentage, depending on the Late Fee Type.',
          },
          {
            label: 'Grace Period',
            value: loan?.grace_period ? `${loan?.grace_period} days` : '-',
            description:
              'How many days after the payment is missed until the late fee can be charged? For example, if the payment is due on the 15th, and the grace period is 10 days, the fee can be charged after 10 days - on the 26th.',
          },
          {
            label: 'NSF Fee',
            value: loan?.nsf_fee ? currencyFormatter.format(loan?.nsf_fee) : '-',
            description: 'If the payment is returned due to insufficient funds, how much is the fee?',
            noDivider: true,
          },
        ].map((setting) => (
          <SettingValueWithDescription
            key={setting.label}
            label={setting.label}
            noDivider={setting.noDivider}
            widerValue={setting.widerValue}
            description={setting.description}
            value={setting.value}
          />
        ))}
      </Card>

      <Card variant="outlined" margin="20px 0px 0px">
        <Typography variant="h5">Data Furnishing</Typography>
        {[
          {
            label: 'Reporting Type',
            value: loan?.reporting_type === 'installment' ? 'Installment' : '-',
            description: 'When reporting to the credit bureaus, what is the loan type.',
          },
          {
            label: 'Category',
            value: loan?.category === 'automobile' ? '00 - Automobile' : '-',
            description: 'When reporting to the credit bureaus, what is the loan category.',
          },
          {
            label: 'ECOA Code',
            value: loan?.ecoa_code ? loan?.ecoa_code : '-',
            description: "When reporting to the credit bureaus, this code indicates the primary customer's position on the loan.",
          },
          {
            label: 'Credit Status',
            value: loan?.credit_status ? loan.credit_status : '-',
            description: 'When reporting to the credit bureaus, this indicates the current credit status of the loan.',
            noDivider: true,
          },
        ].map((setting) => (
          <SettingValueWithDescription
            key={setting.label}
            label={setting.label}
            noDivider={setting.noDivider}
            widerValue={setting.widerValue}
            description={setting.description}
            value={setting.value}
          />
        ))}
      </Card>
      <SettingsDrawer open={open} handleClose={handleClose} handleSubmit={handleSubmit} formie={formie} />
    </div>
  );
};

export default AdvancedSettings;
