import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Typography } from '@mui/material';
import { parse } from 'date-fns';

import { setAlert } from 'store/sagas/app';
import { FlexRow } from 'components/Layouts';
import { useFormie } from 'components/Formie';
import { fetchLoanPaymentProfilesThunk } from 'store/thunks/paymentProfile';
import { fetchCustomerThunk, fetchLoanCustomersThunk } from 'store/thunks/customer';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PaymentProfileCard from './PaymentProfileCard';
import PaymentProfileDrawer from './PaymentProfileDrawer';
import {
  paymentProfileConfiguration,
  paymentProfileInitialValues,
  creditCardPaymentProfileConfiguration,
  creditCardPaymentProfileInitialValues,
} from '../../Onboarding/formInfo';
import {
  addBankPaymentProfileThunk,
  unlinkPaymentProfileThunk,
  editPaymentProfileThunk,
  linkExistingPaymentProfileThunk,
  addCreditCardPaymentProfileThunk,
  swapPaymentProfileThunk,
} from 'store/thunks/paymentProfile';

const PaymentProfiles = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [editing, setEditing] = useState(false);
  const [paymentProfiles, setPaymentProfiles] = useState([]);
  const [unusedCustomerPaymentProfiles, setUnusedCustomerPaymentProfiles] = useState([]);
  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { paymentProfiles } = await dispatch(
        fetchLoanPaymentProfilesThunk({
          loanId,
        }),
      ).unwrap();
      setPaymentProfiles(paymentProfiles);

      const { customers } = await dispatch(
        fetchLoanCustomersThunk({
          subdata: ['payment-profiles'],
          loanId,
        }),
      ).unwrap();
      setCustomers(customers);

      if (customers && customers?.length && customers?.length > 0) {
        const cust1 = customers[0];
        const cust2 = customers[1];

        const { customer: cust1Detail } = cust1
          ? await dispatch(
              fetchCustomerThunk({
                subdata: ['payment-profiles'],
                customerId: cust1?.id,
              }),
            ).unwrap()
          : { customer: { customer_payment_profiles: [] } };

        const { customer: cust2Detail } = cust2
          ? await dispatch(
              fetchCustomerThunk({
                customerId: cust2?.id,
              }),
            ).unwrap()
          : { customer: { customer_payment_profiles: [] } };

        const loanPaymentProfiles = paymentProfiles.map((profile) => profile.id);
        const profiles = [...cust1Detail?.customer_payment_profiles, ...cust2Detail?.customer_payment_profiles].filter(
          (profile) => !loanPaymentProfiles.includes(profile?.id),
        );

        setUnusedCustomerPaymentProfiles(profiles);
      }
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch payment profiles. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      setEditing(false);
      resetForm();
      resetFormCC();
    }
  };

  const addCreditCardPaymentProfile = async () => {
    setLoading(true);
    setOpen(false);
    try {
      await dispatch(
        addCreditCardPaymentProfileThunk({
          data: {
            customer_id: creditCardFormie?.values?.paymentProfile?.customer_id,
            profile_name: creditCardFormie?.values?.paymentProfile?.profile_name,
            account_number: creditCardFormie?.values?.paymentProfile?.account_number,
            account_first_name: creditCardFormie?.values?.paymentProfile?.account_first_name,
            account_last_name: creditCardFormie?.values?.paymentProfile?.account_last_name,
            cvv_code: creditCardFormie?.values?.paymentProfile?.cvv_code,
            expiry_date: parse(creditCardFormie?.values?.paymentProfile?.expiry_date, 'MMyy', new Date()),
            card_zip: creditCardFormie?.values?.paymentProfile?.card_zip,
          },
          loanId,
        }),
      ).unwrap();
      resetForm();
      resetFormCC();
      setEditing(false);
      hydrateData();
    } catch (err) {
      setOpen(true);
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to add credit card. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const addOrEditBankPaymentProfile = async () => {
    setLoading(true);
    setOpen(false);
    try {
      if (editing) {
        await dispatch(
          editPaymentProfileThunk({
            data: {
              account_type: formie?.values?.paymentProfile?.account_type,
              account_first_name: formie?.values?.paymentProfile?.account_first_name,
              account_last_name: formie?.values?.paymentProfile?.account_last_name,
              account_number: formie?.values?.paymentProfile?.account_number,
              routing_number: formie?.values?.paymentProfile?.routing_number,
              institution_name: formie?.values?.paymentProfile?.institution_name,
              profile_name: formie?.values?.paymentProfile?.profile_name,
            },
            customerId: formie?.values?.paymentProfile?.customer_id,
            paymentProfileId: formie?.values?.paymentProfile?.id,
          }),
        ).unwrap();
      } else {
        await dispatch(
          addBankPaymentProfileThunk({
            data: {
              customer_id: formie?.values?.paymentProfile?.customer_id,
              account_type: formie?.values?.paymentProfile?.account_type,
              account_first_name: formie?.values?.paymentProfile?.account_first_name,
              account_last_name: formie?.values?.paymentProfile?.account_last_name,
              account_number: formie?.values?.paymentProfile?.account_number,
              routing_number: formie?.values?.paymentProfile?.routing_number,
              institution_name: formie?.values?.paymentProfile?.institution_name,
              profile_name: formie?.values?.paymentProfile?.profile_name,
            },
            loanId,
          }),
        ).unwrap();
      }
      resetForm();
      resetFormCC();
      hydrateData();
      setEditing(false);
    } catch (err) {
      setOpen(true);
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to ${!!editing ? 'edit' : 'add'} payment profile. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const unlinkPaymentProfile = async (paymentProfileId) => {
    setLoading(true);
    setOpen(false);
    try {
      await dispatch(
        unlinkPaymentProfileThunk({
          paymentProfileId,
          loanId,
        }),
      ).unwrap();
      resetForm();
      resetFormCC();
      hydrateData();
      setEditing(false);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to remove payment profile. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const linkPaymentProfile = async (customerId, paymentProfileId) => {
    setLoading(true);
    setOpen(false);
    try {
      await dispatch(
        linkExistingPaymentProfileThunk({
          paymentProfileId,
          customerId,
          loanId,
        }),
      ).unwrap();
      resetForm();
      resetFormCC();
      hydrateData();
      setEditing(false);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to link payment profile. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const makePrimaryProfile = async (backupId) => {
    setLoading(true);
    setOpen(false);
    try {
      await dispatch(
        swapPaymentProfileThunk({
          paymentProfileId: backupId,
          loanId,
          data: { profile_type: 1 },
        }),
      ).unwrap();
      resetForm();
      resetFormCC();
      hydrateData();
      setEditing(false);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to make the primary payment profile. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleStartEdit = (profile) => {
    setOpen(true);
    setEditing(true);
    formie.setAllValues({
      paymentProfile: {
        id: profile.id,
        customer_id: profile.belongsTo?.id,
        account_type: profile.accountType,
        account_first_name: profile.accountFirstName,
        account_last_name: profile.accountLastName,
        account_number: profile.accountNumber,
        routing_number: profile.routingNumber,
        institution_name: profile.institutionName,
        profile_name: profile.profileName,
      },
    });
  };

  const formie = useFormie({
    configuration: paymentProfileConfiguration(
      customers
        ? customers
            .map((customer) => ({ value: customer?.id, label: `${customer?.first_name} ${customer?.last_name}` }))
            .filter((id) => !!id)
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    ),
    initialValues: paymentProfileInitialValues,
    setLoading,
    onSubmit: addOrEditBankPaymentProfile,
  });

  const creditCardFormie = useFormie({
    configuration: creditCardPaymentProfileConfiguration(
      customers
        ? customers
            .map((customer) => ({ value: customer?.id, label: `${customer?.first_name} ${customer?.last_name}` }))
            .filter((id) => !!id)
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    ),
    initialValues: creditCardPaymentProfileInitialValues,
    onSubmit: addCreditCardPaymentProfile,
  });

  const { handleSubmit, resetForm } = formie;
  const { handleSubmit: handleSubmitCC, resetForm: resetFormCC } = creditCardFormie;

  const profilesForDisplay = paymentProfiles
    .map((profile) => ({
      accountFirstName: profile.account_first_name,
      accountLastName: profile.account_last_name,
      accountNumber: profile.account_number,
      accountType: profile.account_type,
      belongsTo: customers.find((cust) => cust.id === profile.loans_payment_profiles?.customer_id),
      cardZip: profile.card_zip,
      cvcCvv: profile.cvc_cvv,
      expirationDate: profile.expiry_date,
      id: profile.id,
      institutionId: profile.institution_id,
      institutionLogo: profile.institution_logo,
      institutionName: profile.institution_name,
      institutionPrimaryColor: profile.institutionPrimaryColor,
      institutionUrl: profile.institution_url,
      profileName: profile.profile_name,
      routingNumber: profile.routing_number,
      verificationStatus: profile.verification_status,
      profileType: profile.loans_payment_profiles?.profile_type,
    }))
    .sort((a, b) => a.profileType - b.profileType);

  const primaryCustomer = customers && customers.length ? customers.find((customer) => customer?.loans_customers?.customer_role === 'primary-debtor') : null;
  const secondaryCustomer =
    customers && customers.length ? customers.find((customer) => customer?.loans_customers?.customer_role === 'secondary-debtor') : null;

  const allPrimaryCustomerPaymentProfiles = primaryCustomer
    ? unusedCustomerPaymentProfiles.filter((profile) => profile.customer_id === primaryCustomer?.id)
    : [];
  const allSecondaryCustomerPaymentProfiles = secondaryCustomer
    ? unusedCustomerPaymentProfiles.filter((profile) => profile.customer_id === secondaryCustomer?.id)
    : [];

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <>
      {/* Header row with action button */}
      <FlexRow padding="0px" margin="0px 0px 10px" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Payment Profiles</Typography>

        <Button onClick={handleOpen} size="small" variant="contained">
          Add Payment Profile
        </Button>
      </FlexRow>

      <Grid container spacing={2}>
        {profilesForDisplay.map((profile) => (
          <Grid key={profile.id} xs={6}>
            <PaymentProfileCard
              unlinkPaymentProfile={unlinkPaymentProfile}
              makePrimaryProfile={makePrimaryProfile}
              handleStartEdit={handleStartEdit}
              profile={profile}
            />
          </Grid>
        ))}
      </Grid>

      <PaymentProfileDrawer
        handleSubmit={handleSubmit}
        formie={formie}
        handleClose={handleClose}
        open={open}
        handleOpen={handleOpen}
        editing={editing}
        linkPaymentProfile={linkPaymentProfile}
        creditCardFormie={creditCardFormie}
        handleSubmitCC={handleSubmitCC}
        primaryCustomer={primaryCustomer}
        secondaryCustomer={secondaryCustomer}
        allPrimaryCustomerPaymentProfiles={allPrimaryCustomerPaymentProfiles}
        allSecondaryCustomerPaymentProfiles={allSecondaryCustomerPaymentProfiles}
      />
    </>
  );
};

export default PaymentProfiles;
