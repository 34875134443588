import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Button, Divider, CircularProgress, Backdrop } from '@mui/material';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';

import { useFormie } from 'components/Formie';
import { Card, FlexRow } from 'components/Layouts';
import { setAlert } from 'store/sagas/app';
import Accordion from 'components/Accordion';
import ConfigurationDrawer from './ConfigurationDrawer';
import { configureLoanConfiguration } from '../formInfo';
import { generateTermsThunk } from 'store/thunks/loan';
import { currencyFormatter, percentageFormatterV2, formatDateOnly, parseDateOnlyForInputs } from 'constants/formatters';
import { columns, parsePaymentFrequency, TilaCard, DetailsList } from './helpers';
import { COLORS } from 'constants/styles';

const LoanTerms = ({ paymentPeriods, loan, rehydrateOnboarding }) => {
  const dispatch = useDispatch();

  const { loanId } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const hasPaymentPeriods = paymentPeriods && paymentPeriods?.length > 0;

  const handleClose = () => {
    setOpen(false);
  };

  const generateTerms = async () => {
    setLoading(true);
    const contractDate = formie?.values?.configuration?.contract_date && format(parseISO(formie?.values?.configuration?.contract_date), 'yyyy-MM-dd');
    const firstPaymentDate =
      formie?.values?.configuration?.first_payment_date && format(parseISO(formie?.values?.configuration?.first_payment_date), 'yyyy-MM-dd');
    try {
      await dispatch(
        generateTermsThunk({
          data: {
            loan_id: loanId,
            principal_amount: formie?.values?.configuration?.principal_amount,
            interest_rate: formie?.values?.configuration?.interest_rate,
            terms: formie?.values?.configuration?.terms,
            contract_date: contractDate,
            first_payment_date: firstPaymentDate,
            schedule_round: formie?.values?.configuration?.schedule_round,
            down_payment_amount: formie?.values?.configuration?.down_payment_amount,
            payment_amount: formie?.values?.configuration?.payment_amount,
            interest_calculation_type: formie?.values?.configuration?.interest_calculation_type,
            payment_frequency: formie?.values?.configuration?.payment_frequency,

            type: 'installment',
            calculation_type: 'simple-interest',
          },
        }),
      ).unwrap();
      await rehydrateOnboarding();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to generate terms. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const formie = useFormie({
    configuration: configureLoanConfiguration,
    initialValues: {
      configuration: {
        principal_amount: loan?.principal_amount && Number(loan?.principal_amount),
        interest_rate: loan?.interest_rate && Number(loan?.interest_rate),
        terms: loan?.terms,
        contract_date: parseDateOnlyForInputs(loan?.contract_date),
        first_payment_date: parseDateOnlyForInputs(loan?.first_payment_date),
        schedule_round: loan?.schedule_round && Number(loan?.schedule_round),
        payment_frequency: loan?.payment_frequency,
        interest_calculation_type: loan?.interest_calculation_type,
        down_payment_amount: loan?.down_payment_amount && Number(loan?.down_payment_amount),
      },
    },
    setLoading,
    onSubmit: generateTerms,
  });

  const { handleSubmit } = formie;

  const rows = loan?.payment_periods
    ? loan.payment_periods
        .map((period) => ({
          id: period.period_number,
          date: period.due_date,
          startingPrincipal: period.starting_principal_amount,
          accruedInterest: period.accrued_interest,
          payment: period.payment_amount,
          interestPayment: period.interest_payment_amount,
          principalPayment: period.principal_payment_amount,
        }))
        .sort((a, b) => a.id - b.id)
    : [];

  const finalPaymentPeriod = loan?.payment_periods ? loan.payment_periods[loan.payment_periods.length - 1] : {};

  return (
    <>
      <FlexRow padding="0px" margin="32px 0px 0px" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">What are the terms?</Typography>
        {hasPaymentPeriods && (
          <Button onClick={() => setOpen(true)} startIcon={<ConstructionOutlinedIcon />}>
            Configure
          </Button>
        )}
      </FlexRow>

      {hasPaymentPeriods ? (
        <div style={{ marginTop: '10px' }}>
          {/* TILA Terms */}
          <Grid container spacing={2} columns={20}>
            {[
              { label: 'APR', value: loan?.apr ? percentageFormatterV2(loan?.apr, 4) : '-' },
              { label: 'Finance Charge', value: loan?.finance_charge ? currencyFormatter.format(loan?.finance_charge) : '-' },
              { label: 'Amount Financed', value: loan?.principal_amount ? currencyFormatter.format(loan?.principal_amount) : '-' },
              { label: 'Total of Payments', value: loan?.total_payments ? currencyFormatter.format(loan?.total_payments) : '-' },
              { label: 'Total Sales Price', value: loan?.total_sales_price ? currencyFormatter.format(loan?.total_sales_price) : '-' },
            ].map((tila) => (
              <Grid key={`tilaCard-${tila.label}`} xs={4}>
                <TilaCard label={tila.label} value={tila.value} />
              </Grid>
            ))}

            {/* Card with amortization schedule.  */}
            <Grid xs={20}>
              <Accordion defaultExpanded summaryTitle="Amortization Schedule">
                <div style={{ height: '345px' }}>
                  <DataGrid
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                    disableColumnMenu
                    density="compact"
                    columns={columns}
                    rows={rows}
                  />
                </div>
              </Accordion>
            </Grid>

            {/* Details Accordion */}
            <Grid xs={20}>
              <Accordion summaryTitle="Details">
                {/* Financial Details */}
                <Grid container spacing={2}>
                  <DetailsList
                    list={[
                      { label: 'Contract Date', value: loan?.contract_date ? formatDateOnly(loan.contract_date) : '-' },
                      { label: 'Payments Start', value: loan?.first_payment_date ? formatDateOnly(loan.first_payment_date) : '-' },
                      { label: 'Payments End', value: loan?.last_payment_date ? formatDateOnly(loan.last_payment_date) : '-' },
                      { label: 'Payment Frequency', value: parsePaymentFrequency(loan.payment_frequency) },
                      { label: 'Number of Payments', value: loan?.terms || '-' },
                      { label: 'Interest Rate', value: loan?.interest_rate ? percentageFormatterV2(loan.interest_rate) : '-', noDivider: true },
                    ]}
                  />

                  <DetailsList
                    list={[
                      { label: 'Principal Amount', value: loan?.principal_amount ? currencyFormatter.format(loan.principal_amount) : '-' },
                      { label: 'Down Payment Amount', value: loan?.down_payment_amount ? currencyFormatter.format(loan.down_payment_amount) : '-' },
                      { label: 'Payment Amount', value: loan?.payment_amount ? currencyFormatter.format(loan.payment_amount) : '-' },
                      { label: 'Last Payment Amount', value: loan?.last_payment_amount ? currencyFormatter.format(loan.last_payment_amount) : '-' },
                      { label: 'Rounding', value: loan?.schedule_round ? currencyFormatter.format(loan.schedule_round) : '-' },
                      {
                        label: 'Final Principal Amount',
                        value: finalPaymentPeriod?.ending_principal_amount ? currencyFormatter.format(finalPaymentPeriod.ending_principal_amount) : '-',
                        noDivider: true,
                      },
                    ]}
                  />
                </Grid>

                {/* Advanced Details */}
                <Divider sx={{ mt: '30px' }}>
                  <Typography sx={{ fontWeight: 700 }}>Advanced Details</Typography>
                </Divider>
                <Grid container spacing={2}>
                  <DetailsList
                    list={[
                      { label: 'Interest Calculation Type', value: loan?.interest_calculation_type },
                      { label: 'Calculation Type', value: loan?.calculation_type === 'simple-interest' ? 'Simple Interest' : '-' },
                      { label: 'First Day Interest', value: loan?.first_day_interest ? 'Yes' : 'No' },
                      { label: 'Last as Final', value: loan?.last_as_final ? 'Yes' : 'No', noDivider: true },
                    ]}
                  />

                  <DetailsList
                    list={[
                      { label: 'Beginning/End', value: loan?.beginning_end === 'end' ? 'End' : '-' },
                      { label: 'Schedule Smoothing', value: loan?.schedule_smoothing === 'basic' ? 'Basic' : '-' },
                      { label: 'Odd Days', value: loan?.odd_days },
                      { label: 'Unit Periods', value: loan?.unit_periods, noDivider: true },
                    ]}
                  />
                </Grid>
              </Accordion>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Card margin="12px 0px 0px" padding="20px" variant="outlined">
          <FlexRow padding="0px" margin="0px" alignItems="center" justifyContent="center">
            <Button onClick={() => setOpen(true)} startIcon={<ConstructionOutlinedIcon />}>
              Configure
            </Button>
          </FlexRow>
        </Card>
      )}

      <ConfigurationDrawer open={open} handleClose={handleClose} handleSubmit={handleSubmit} formie={formie} />
      <Backdrop sx={{ color: COLORS.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LoanTerms;
