import SearchIcon from '@mui/icons-material/SearchOutlined';
import { isValidVin } from '@shaggytools/nhtsa-api-wrapper';

import { TYPES } from 'components/Formie/inputs';
import { validateNotNull, stringNotEmpty, validatePositiveInteger } from 'components/Formie/validators';
import { FlexRow } from 'components/Layouts';

export const TIMEZONES = [
  { label: 'America/Chicago', value: 'America/Chicago' },
  { label: 'America/Denver', value: 'America/Denver' },
  { label: 'America/Los_Angeles', value: 'America/Los_Angeles' },
  { label: 'America/New_York', value: 'America/New_York' },
  { label: 'America/Phoenix', value: 'America/Phoenix' },
];

export const configuration = (sourceCompanies) => ({
  sourceCompanyId: {
    type: TYPES.basicSelect,
    dataPath: 'sourceCompanyId',
    required: false,
    label: 'Source Company',
    options: sourceCompanies ? sourceCompanies : [],
  },
  collateral: {
    vin: {
      type: TYPES.vinLookup,
      dataPath: 'collateral.vin',
      required: true,
      label: (
        <FlexRow inline padding="0px" margin="0px">
          <SearchIcon sx={{ margin: '0px 5px 0px 0px', padding: '0px' }} />
          VIN *
        </FlexRow>
      ),
      validator: isValidVin,
    },
    year: {
      type: TYPES.text,
      dataPath: 'collateral.year',
      required: true,
      label: 'Year',
      validator: stringNotEmpty,
    },
    make: {
      type: TYPES.text,
      dataPath: 'collateral.make',
      required: true,
      label: 'Make',
      validator: stringNotEmpty,
    },
    model: {
      type: TYPES.text,
      dataPath: 'collateral.model',
      required: true,
      label: 'Model',
      validator: stringNotEmpty,
    },
    condition: {
      type: TYPES.basicSelect,
      dataPath: 'collateral.condition',
      required: true,
      validator: validateNotNull,
      label: 'Condition',
      options: [
        { value: 'New', label: 'New' },
        { value: 'Used', label: 'Used' },
      ],
    },
    mileage: {
      type: TYPES.integer,
      dataPath: 'collateral.mileage',
      required: true,
      validator: validatePositiveInteger,
      label: 'Mileage',
    },
  },
  timezone: {
    timezone: {
      type: TYPES.basicSelect,
      dataPath: 'timezone.timezone',
      required: true,
      validator: validateNotNull,
      label: 'Timezone',
      options: TIMEZONES,
    },
  },
});

export const initialValues = {
  sourceCompanyId: null,
  collateral: {
    vin: null,
    year: null,
    make: null,
    model: null,
    condition: null,
    mileage: null,
  },
  timezone: {
    timezone: null,
  },
};

export const sourceCompanyConfiguration = {
  name: {
    type: TYPES.text,
    dataPath: 'name',
    required: true,
    validator: stringNotEmpty,
    label: 'Name',
  },
};

export const sourceCompanyInitialValues = {
  name: null,
};
