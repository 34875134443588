import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { format, parseISO } from 'date-fns';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { Card, FlexRow } from 'components/Layouts';
import { fetchCustomerThunk } from 'store/thunks/customer';
import { setAlert } from 'store/sagas/app';
import { formatPhoneNumber, formatSSN } from 'constants/formatters';
import { COLORS } from 'constants/styles';
import { Divider } from '@mui/material';

const LabeledIcon = ({ icon, label }) => {
  return (
    <FlexRow padding="0px 0px 5px" margin="0px" alignItems="center" justifyContent="flex-start">
      {icon}
      <Typography noWrap sx={{ ml: '10px' }} variant="caption">
        {label}
      </Typography>
    </FlexRow>
  );
};

const CustomerCard = ({ customerId, role, handleOpen, handleStartEdit, unlinkCustomerFromLoan }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const buttonText = role === 'primary' ? 'Add Customer' : 'Add Secondary';

  const fetchCustomer = async () => {
    setLoading(true);
    try {
      const { customer } = await dispatch(fetchCustomerThunk({ customerId })).unwrap();
      setCustomer(customer);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to find customer details. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchCustomer();
    }
  }, [customerId]);

  if (loading) {
    return (
      <Card margin="0px" padding="0px" variant="outlined" style={{ height: '100%' }}>
        <FlexRow padding="20px 0px" margin="0px" justifyContent="center" alignItems="center" fullHeight>
          <CircularProgress />
        </FlexRow>
      </Card>
    );
  }

  if (!customerId || !customer?.id) {
    return (
      <Card margin="0px" padding="0px" variant="outlined" style={{ height: '100%' }}>
        <FlexRow padding="20px 0px" margin="0px" justifyContent="center" alignItems="center" fullHeight>
          <Button onClick={() => handleOpen(role)} startIcon={<PersonAddOutlinedIcon />}>
            {buttonText}
          </Button>
        </FlexRow>
      </Card>
    );
  }

  const residenceAddress = customer?.addresses?.find((address) => address?.address_type === 'residence');
  const mailingAddress = customer?.addresses?.find((address) => address?.address_type === 'mailing');

  const residenceAddressComponents = [
    residenceAddress?.address_1,
    residenceAddress?.address_2,
    `${residenceAddress?.city}, ${residenceAddress?.state} ${residenceAddress?.zip}`,
  ].filter((component) => !!component);

  const mailingAddressComponents = [
    mailingAddress?.address_1,
    mailingAddress?.address_2,
    `${mailingAddress?.city}, ${mailingAddress?.state} ${mailingAddress?.zip}`,
  ].filter((component) => !!component);

  const addressesAreEqual =
    residenceAddressComponents[0] == mailingAddressComponents[0] &&
    residenceAddressComponents[1] == mailingAddressComponents[1] &&
    residenceAddressComponents[2] == mailingAddressComponents[2];

  return (
    <Card margin="0px" padding="0px" variant="outlined" style={{ height: '100%' }}>
      {/* Title */}
      <FlexRow padding="10px 10px 5px" margin="0px" borderBottom justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{`${customer?.first_name} ${customer?.last_name}`}</Typography>
        <Chip color={role === 'primary' ? 'primary' : 'default'} size="small" label={role === 'primary' ? 'Primary' : 'Secondary'} />
      </FlexRow>

      {/* Content Container */}
      <div style={{ padding: '0px 5px 10px', height: 'calc(100% - 48px - 53.5px)' }}>
        {/* Details */}
        <Grid container spacing={1}>
          <Grid xs={6}>
            <LabeledIcon icon={<CalendarMonthOutlinedIcon />} label={customer?.dob ? format(parseISO(customer.dob), 'PP') : '-'} />
            <LabeledIcon icon={<LocalPhoneOutlinedIcon />} label={formatPhoneNumber(customer?.phone)} />
          </Grid>

          <Grid xs={6}>
            <LabeledIcon icon={<FingerprintOutlinedIcon />} label={formatSSN(customer?.ssn)} />
            <LabeledIcon icon={<EmailOutlinedIcon />} label={customer?.email} />
          </Grid>
        </Grid>

        {/* Addresses */}
        <div style={{ background: COLORS.backgroundColor, borderRadius: '8px', padding: '5px', margin: '0px' }}>
          <Typography textAlign="center" variant="body2">
            {addressesAreEqual ? 'Address' : 'Primary Address'}
          </Typography>
          <Divider sx={{ m: '5px 0px' }} />
          <FlexRow padding="0px" margin="0px" alignItems="flex-start" justifyContent="flex-start">
            <LocationOnOutlinedIcon />

            <div>
              {residenceAddressComponents.map((component) => (
                <Typography noWrap key={component} display="block" sx={{ ml: '10px' }} variant="caption">
                  {component}
                </Typography>
              ))}
            </div>
          </FlexRow>
        </div>
      </div>

      {/* Actions */}
      <FlexRow borderedTop padding="5px 10px" margin="0px" justifyContent={unlinkCustomerFromLoan ? 'space-between' : 'flex-end'} alignItems="center">
        {unlinkCustomerFromLoan && <Button onClick={unlinkCustomerFromLoan}>Remove</Button>}
        <Button onClick={() => handleStartEdit(customer, role)}>Edit</Button>
      </FlexRow>
    </Card>
  );
};

export const CustomerCardForDrawer = ({ customer }) => {
  return (
    <Card margin="0px" padding="0px" variant="outlined">
      {/* Title */}
      <FlexRow padding="10px 10px 5px" margin="0px" borderBottom alignItems="center">
        <PersonIcon color="primary" />
        <Typography color="primary" sx={{ ml: '10px' }} variant="h6">{`${customer?.first_name} ${customer?.last_name}`}</Typography>
      </FlexRow>

      {/* Details */}
      <Grid sx={{ padding: '10px' }} container spacing={1}>
        <Grid xs={6}>
          <LabeledIcon icon={<CalendarMonthOutlinedIcon />} label={customer?.dob ? format(parseISO(customer.dob), 'PP') : '-'} />
          <LabeledIcon icon={<LocalPhoneOutlinedIcon />} label={formatPhoneNumber(customer?.phone)} />
        </Grid>

        <Grid xs={6}>
          <LabeledIcon icon={<FingerprintOutlinedIcon />} label={formatSSN(customer?.ssn)} />
          <LabeledIcon icon={<EmailOutlinedIcon />} label={customer?.email} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerCard;
