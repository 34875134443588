import { METHODS, request } from 'services/axios';

const URIS = {
  UPLOAD_DOCUMENT_TO_STIPULATION: ({ stipulationId }) => `/stipulations/${stipulationId}/upload-document`,
  DELETE_DOCUMENT_FROM_STIPULATION: ({ stipulationId }) => `/stipulations/${stipulationId}/document`,
};

export default {
  uploadDocumentToStipulation: ({ stipulationId, customConfig, formData }) =>
    request({
      url: URIS.UPLOAD_DOCUMENT_TO_STIPULATION({ stipulationId }),
      method: METHODS.POST,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...customConfig,
    }),

  deleteDocumentFromStipulation: ({ stipulationId }) =>
    request({
      url: URIS.DELETE_DOCUMENT_FROM_STIPULATION({ stipulationId }),
      method: METHODS.DELETE,
    }),
};
