import { Typography, Divider, TextField, MenuItem } from '@mui/material';
import { PieChart } from '@mui/x-charts';

import { useState } from 'react';

import { Card, FlexRow } from 'components/Layouts';
import { currencyFormatter, roundAccurately, formatDateOnly } from 'constants/formatters';
import { COLORS } from 'constants/styles';
import ListItem from 'components/ListItem';
import BasicSelect from 'components/BasicSelect';

const calculatePaymentMetrics = (payments) => {
  if (!payments || payments?.length === 0) return [{ id: 'all', title: 'All Payments', fees: 0, principal: 0, interest: 0 }];

  const structuredPaymentData = payments
    .map((payment) => ({
      id: payment?.id,
      title: formatDateOnly(payment?.payment_application_date),
      date: payment?.payment_application_date,
      year: payment?.payment_application_date.split('-')[0],
      fees: Number(payment?.fees_payment_amount),
      principal: Number(payment?.principal_payment_amount),
      interest: Number(payment?.interest_payment_amount),
    }))
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const uniqueYearsRawData = [...new Set(structuredPaymentData.map((payment) => payment.year))].map((year) =>
    structuredPaymentData.filter((payment) => payment.year === year),
  );

  const uniqueYearsData = uniqueYearsRawData.map((yearlyData) => {
    return yearlyData.reduce(
      (acc, curr) => ({
        title: curr.year,
        id: curr.year,
        fees: roundAccurately(acc.fees + curr.fees, 2),
        principal: roundAccurately(acc.principal + curr.principal, 2),
        interest: roundAccurately(acc.interest + curr.interest, 2),
      }),
      { fees: 0, principal: 0, interest: 0 },
    );
  });

  const totalData = structuredPaymentData.reduce(
    (acc, curr) => ({
      title: 'All Payments',
      id: 'all',
      fees: roundAccurately(acc.fees + curr.fees, 2),
      principal: roundAccurately(acc.principal + curr.principal, 2),
      interest: roundAccurately(acc.interest + curr.interest, 2),
    }),
    { title: 'All Payments', fees: 0, principal: 0, interest: 0 },
  );

  return [totalData, ...uniqueYearsData, ...structuredPaymentData.slice(0, 3)];
};

export const PaymentBreakdownCard = ({ options, value, setValue, data }) => {
  const totalPaid = currencyFormatter.format(roundAccurately(data?.principal + data?.interest + data?.fees, 2));
  const countOfDataPoints = !!data?.principal + !!data?.interest + !!data?.fees;

  return (
    <Card variant="outlined" padding="10px 20px" margin="0px">
      <FlexRow padding="0px" margin="0px 0px 16px" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Payment Breakdown</Typography>

        {options && setValue && <BasicSelect options={options} handleChange={(e) => setValue(e.target.value)} value={value} />}
      </FlexRow>

      {data?.principal === 0 && data?.interest === 0 && data?.fees === 0 ? (
        <FlexRow padding="0px" margin="0px" height="200px" justifyContent="center" alignItems="center">
          <Typography>No Payments Made Yet</Typography>
        </FlexRow>
      ) : (
        <div style={{ width: '100%' }}>
          <PieChart
            series={[
              {
                data: [
                  { id: 0, value: data?.principal, label: <Typography color="text.secondary">Principal</Typography>, color: COLORS.primary },
                  { id: 2, value: data?.interest, label: <Typography color="text.secondary">Interest</Typography>, color: COLORS.secondary },
                  { id: 1, value: data?.fees, label: <Typography color="text.secondary">Fees</Typography>, color: COLORS.warning },
                ],
                innerRadius: 30,
                paddingAngle: countOfDataPoints <= 1 ? 1 : 3,
                cornerRadius: 5,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30 },
                valueFormatter: (e) => <Typography>{currencyFormatter.format(e.value)}</Typography>,
              },
            ]}
            height={200}
            legend={{ hidden: true }}
            margin={{ right: 5 }}
          />
        </div>
      )}

      <Divider sx={{ m: '24px 0px 8px' }} />

      <ListItem value={data?.principal ? currencyFormatter.format(data.principal) : '-'} label="Principal" />
      <ListItem value={data?.interest ? currencyFormatter.format(data.interest) : '-'} label="Interest" />
      <ListItem value={data?.fees ? currencyFormatter.format(data.fees) : '-'} label="Fees" />
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: 500 }}>Total</Typography>
        <Typography sx={{ fontWeight: 500 }}>{totalPaid}</Typography>
      </FlexRow>
    </Card>
  );
};

const PaymentBreakdown = ({ processedPayments }) => {
  const [value, setValue] = useState('all');
  const paymentData = calculatePaymentMetrics(processedPayments);
  const options = paymentData.map((option) => ({ value: option.id, label: option.title }));
  const data = paymentData.find((data) => data.id === value);

  return <PaymentBreakdownCard options={options} data={data} value={value} setValue={setValue} />;
};

export default PaymentBreakdown;
