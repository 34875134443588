import { createAsyncThunk } from '@reduxjs/toolkit';

import { LoanMetricAPI } from 'store/api';

export const fetchLoanMetricsThunk = createAsyncThunk(
  'loanMetrics/fetchLoanMetrics',
  async ({ loanId, from_date, to_date, limit = 30, sort = 'created_at|ASC', offset = 1 }, thunkAPI) => {
    try {
      let queryString = `?limit=${limit}&sort=${sort}&offset=${offset}`;

      if (from_date) {
        queryString = `${queryString}&from_date=${from_date}`;
      }

      if (to_date) {
        queryString = `${queryString}&to_date=${to_date}`;
      }

      const { metrics, metadata } = await LoanMetricAPI.fetchLoanMetrics({ queryString, loanId });

      /**
       * This will dispatch action creator with type of "loanMetrics/fetchLoanMetrics/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return { metrics, metadata };
    } catch (err) {
      /**
       * This will dispatch action creator with type of "loanMetrics/fetchLoanMetrics/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);
