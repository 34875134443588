import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import userReducer from './sagas/user';
import appReducer from './sagas/app';
import applicationsReducer, { uploadDocumentToStipulation } from './sagas/applications';
import adminReducer from './sagas/admin';
import rootSaga from './sagas';

export function initializeStore(preloadedState = undefined) {
  const SagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: {
      admin: adminReducer,
      app: appReducer,
      applications: applicationsReducer,
      user: userReducer,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions: [
            uploadDocumentToStipulation.type,

            // Application Payment Profiles
            'application/generateApplicationLinkToken/fulfilled',
            'application/generateApplicationLinkToken/rejected',
            'application/generateApplicationAccessToken/fulfilled',
            'application/generateApplicationAccessToken/rejected',
            'application/updateApplicationAccessToken/fulfilled',
            'application/updateApplicationAccessToken/rejected',
            'application/connectApplicationPaymentProfile/fulfilled',
            'application/connectApplicationPaymentProfile/rejected',
            'application/connectExistingApplicationPaymentProfile/fulfilled',
            'application/connectExistingApplicationPaymentProfile/rejected',
            'application/updateApplicationPaymentProfile/fulfilled',
            'application/updateApplicationPaymentProfile/rejected',
            'application/setApplicationPrimaryPaymentProfile/fulfilled',
            'application/setApplicationPrimaryPaymentProfile/fulfilled',
            'application/deleteApplicationPaymentProfile/fulfilled',
            'application/deleteApplicationPaymentProfile/rejected',
            'application/getApplicationPaymentProfiles/fulfilled',
            'application/getApplicationPaymentProfiles/rejected',
            'application/createApplicationCreditCardPaymentProfile/fulfilled',
            'application/createApplicationCreditCardPaymentProfile/rejected',

            // Application References
            'application/createApplicationReference/fulfilled',
            'application/createApplicationReference/rejected',
            'application/updateApplicationReference/fulfilled',
            'application/updateApplicationReference/rejected',
            'application/deleteApplicationReference/fulfilled',
            'application/deleteApplicationReference/rejected',
            'application/applicationReferenceSendOTP/fulfilled',
            'application/applicationReferenceSendOTP/rejected',
            'application/applicationReferenceCheckOTP/fulfilled',
            'application/applicationReferenceCheckOTP/rejected',

            // Applicant Credit Report
            'applicant/fetchApplicantCreditReport/fulfilled',
            'applicant/fetchApplicantCreditReport/rejected',

            // Fees
            'fees/fetchLoanFees/fulfilled',
            'fees/fetchLoanFees/rejected',
            'fees/reverseLoanFee/fulfilled',
            'fees/reverseLoanFee/rejected',
            'fees/editLoanFee/fulfilled',
            'fees/editLoanFee/rejected',

            // Lender
            'lender/fetchLoans/fulfilled',
            'lender/fetchLoans/rejected',

            // Loans
            'loan/create/fulfilled',
            'loan/create/rejected',
            'loan/fetch/fulfilled',
            'loan/fetch/rejected',
            'loan/editCustomerRoles/fulfilled',
            'loan/editCustomerRoles/rejected',
            'loan/createCustomer/fulfilled',
            'loan/createCustomer/rejected',
            'loan/generateTerms/fulfilled',
            'loan/generateTerms/rejected',
            'loan/update/fulfilled',
            'loan/update/rejected',
            'loan/openAndActivate/fulfilled',
            'loan/openAndActivate/rejected',

            // Loan Collateral
            'loanCollateral/uploadTitleImg/fulfilled',
            'loanCollateral/uploadTitleImg/rejected',
            'loanCollateral/deleteTitleImg/fulfilled',
            'loanCollateral/deleteTitleImg/rejected',
            'loanCollateral/fetchLoanCollateral/fulfilled',
            'loanCollateral/fetchLoanCollateral/rejected',
            'loanCollateral/update/fulfilled',
            'loanCollateral/update/rejected',

            // Loan Metrics
            'loanMetrics/fetchLoanMetrics/fulfilled',
            'loanMetrics/fetchLoanMetrics/rejected',

            // Payments
            'payments/fetchLoanPayments/fulfilled',
            'payments/fetchLoanPayments/rejected',
            'payments/editLoanPayment/fulfilled',
            'payments/editLoanPayment/rejected',

            // Past Dues
            'pastDues/fetchLoanPastDues/fulfilled',
            'pastDues/fetchLoanPastDues/rejected',

            // Payment Periods
            'paymentPeriods/fetchLoanPaymentPeriods/fulfilled',
            'paymentPeriods/fetchLoanPaymentPeriods/rejected',

            // Payment Profiles
            'payment-profiles/addBankPaymentProfile/rejected',
            'payment-profiles/addBankPaymentProfile/fulfilled',
            'payment-profiles/unlinkPaymentProfile/fulfilled',
            'payment-profiles/unlinkPaymentProfile/rejected',
            'payment-profiles/editBankPaymentProfile/rejected',
            'payment-profiles/editBankPaymentProfile/fulfilled',
            'payment-profiles/linkExistingPaymentProfile/rejected',
            'payment-profiles/linkExistingPaymentProfile/fulfilled',
            'payment-profiles/addCreditCardPaymentProfile/rejected',
            'payment-profiles/addCreditCardPaymentProfile/fulfilled',
            'payment-profiles/swapPaymentProfile/rejected',
            'payment-profiles/swapPaymentProfile/fulfilled',
            'payment-profiles/getLoanPaymentProfiles/rejected',
            'payment-profiles/getLoanPaymentProfiles/fulfilled',

            // Source Company
            'sourceCompany/create/fulfilled',
            'sourceCompany/create/rejected',
            'sourceCompany/fetchSourceCompanies/fulfilled',
            'sourceCompany/fetchSourceCompanies/rejected',

            // Customer
            'customer/edit/fulfilled',
            'customer/edit/rejected',
            'customer/fetch/fulfilled',
            'customer/fetch/rejected',
            'customer/query/fulfilled',
            'customer/query/rejected',
          ],
        },
      }).prepend(SagaMiddleware);
    },
  });

  SagaMiddleware.run(rootSaga);

  return store;
}
