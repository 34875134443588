import { takeLatest, call, put } from 'redux-saga/effects';

import { AuthAPI, UserAPI } from 'store/api';
import { setAlertSaga } from '../app/sagas';
import { fetchUserInfo, storeUserInfo, logUserIn, logUserOut, initialState, setActiveProfile } from './index';
import { startAppLoading, stopAppLoading } from '../app';

export function* fetchUserSaga() {
  try {
    yield put(startAppLoading());
    const { user } = yield call(UserAPI.fetchUserInfo);
    const active_profile = user.profiles?.find((profile) => profile.is_active_profile);
    yield put(storeUserInfo({ ...user, active_profile }));
    yield put(stopAppLoading());
  } catch (err) {
    yield put(stopAppLoading());
    err.tip = 'Error at fetchUserSaga';
    console.log(err);
  }
}

export function* logUserInSaga({ payload }) {
  try {
    yield put(startAppLoading());
    const { user } = yield call(AuthAPI.login, { data: payload });
    const active_profile = user.profiles?.find((profile) => profile.is_active_profile);
    yield put(storeUserInfo({ ...user, active_profile }));
    yield put(stopAppLoading());
  } catch (err) {
    yield put(stopAppLoading());
    yield call(setAlertSaga, {
      payload: {
        type: 'error',
        message: 'That email and password combination did not work. Try again.',
        showing: true,
      },
    });
    err.tip = 'Error at logUserInSaga';
    console.log(err);
  }
}

export function* logUserOutSaga() {
  try {
    yield put(startAppLoading());
    yield call(AuthAPI.logout);
    yield put(storeUserInfo(initialState));
    yield put(stopAppLoading());
  } catch (err) {
    yield put(stopAppLoading());
    err.tip = 'Error at logUserOutSaga';
    console.log(err);
  }
}

export function* setActiveProfileSaga({ payload: { newProfile, oldProfile, user } }) {
  try {
    yield put(startAppLoading());
    const { user: updatedUser } = yield call(UserAPI.updateActiveProfile, { id: user.id, data: { newProfile, oldProfile } });
    const active_profile = updatedUser.profiles?.find((profile) => profile.is_active_profile);
    yield put(storeUserInfo({ ...updatedUser, active_profile }));
    yield put(stopAppLoading());
  } catch (err) {
    yield put(stopAppLoading());
    err.tip = 'Error at setActiveProfileSaga';
    console.log(err);
  }
}

export const userSagas = [
  takeLatest(fetchUserInfo.type, fetchUserSaga),
  takeLatest(logUserIn.type, logUserInSaga),
  takeLatest(logUserOut.type, logUserOutSaga),
  takeLatest(setActiveProfile.type, setActiveProfileSaga),
];

export default userSagas;
