import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SuperAdmin from 'views/SuperAdmin';

export const AppProtection = () => {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  if (!user || !user.id) {
    if (!app.loading) {
      return <Navigate to="/login" />;
    }
  }

  return <Outlet />;
};

export const TypeProtection = ({ type }) => {
  const user = useSelector((state) => state.user);

  if (!!user?.active_profile?.id) {
    const entityType = user.active_profile.dealer ? 'dealer' : 'lender';

    if (entityType === type) {
      return <Outlet />;
    } else {
      return <Navigate to={`/app/${entityType}/dashboard`} />;
    }
  }
};

export const AdminProtection = () => {
  const user = useSelector((state) => state.user);

  if (user.active_profile.is_admin) {
    return <Outlet />;
  } else {
    const entityType = user.active_profile.dealer ? 'dealer' : 'lender';
    return <Navigate to={`/app/${entityType}/dashboard`} />;
  }
};

export const SuperAdminProtection = () => {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  if (!user || !user.id) {
    if (!app.loading) {
      return <Navigate to="/login" />;
    }
  } else if (user.is_super_admin) {
    return <SuperAdmin />;
  } else {
    return <Navigate to="/login" />;
  }
};
