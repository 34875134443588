import { Typography, Accordion as MaterialAccordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { COLORS } from 'constants/styles';

const Accordion = ({ summaryTitle, defaultExpanded, children, sx, titleVariant }) => {
  return (
    <MaterialAccordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={0}
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        borderRadius: '8px',
        border: `1px solid ${COLORS.borderColor}`,
        '&:before': {
          display: 'none',
        },
        ...sx,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant={titleVariant || 'h6'}>{summaryTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MaterialAccordion>
  );
};

export default Accordion;
