import { useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { STATUS_LABELS } from 'constants/status';
import { PLAID_AUTH_STATUS } from 'constants/plaid';
import { Card, FlexRow } from 'components/Layouts';
import PaymentProfilesContext from './context';
import PaymentProfile from './PaymentProfile';
import CCPaymentProfile from './CCPaymentProfile';
import AddNewPaymentProfile from './AddNewPaymentProfile';

const { FUNDED, SERVICING } = STATUS_LABELS;
const { PRE_PLAID, PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED } = PLAID_AUTH_STATUS;

const PaymentProfiles = ({ currentApplication }) => {
  const { no_of_payment_profiles } = currentApplication?.lending_rules ?? {};
  const applicationStatus = currentApplication?.status?.name;
  const applicantId = currentApplication?.applicant?.id;
  const applicationId = currentApplication?.id;
  const paymentProfiles = currentApplication?.payment_profiles ?? [];

  const { paymentProfilesSectionErrorMsg, showPaymentProfilesSectionError, setPaymentProfilesSectionErrorMsg, setShowPaymentProfilesSectionError } =
    useContext(PaymentProfilesContext);

  /**
   * Payment profiles that are considered as valid.
   * Valid means that it contains all required data and at least be in pending verification status.
   */
  const validPaymentProfiles = paymentProfiles.filter(
    ({ institution_name, account_type, account_name, account_number, routing_number, verification_status }) =>
      account_type === 'credit' ||
      (institution_name &&
        account_type &&
        account_name &&
        account_number &&
        routing_number &&
        [PRE_PLAID, PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED].includes(
          verification_status,
        )),
  );

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Payment Profiles
        </Typography>
      </FlexRow>

      <FlexRow margin="12px 0px 20px 0px" padding="0">
        <Alert sx={{ width: '100%' }} severity="info">
          <AlertTitle>Lender Requires ACH</AlertTitle>
          <Typography>
            The lender requires that the applicant makes their loan payments via ACH. This information will populate an Electronic Funds Transfer agreement that
            will be signed during the signing ceremony.
          </Typography>
        </Alert>
      </FlexRow>

      <FlexRow margin="12px 0px 20px 0px" padding="0">
        <Alert sx={{ width: '100%' }} severity="info">
          <AlertTitle>Primary Payment Profile</AlertTitle>
          <Typography>A primary payment profile must be set before documents can be generated and signed.</Typography>
          <Typography>Note that you cannot use a credit card as a primary payment profile.</Typography>
        </Alert>
      </FlexRow>

      <Grid sx={{ marginBottom: '0.4rem' }} className={'references-requirements'} container spacing={2}>
        <Grid xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Required valid payment profiles:{' '}
            <span style={{ color: validPaymentProfiles.length >= (no_of_payment_profiles ? no_of_payment_profiles : 0) ? 'green' : 'red' }}>
              {validPaymentProfiles.length}
            </span>
            /{!no_of_payment_profiles ? 0 : no_of_payment_profiles}
          </Typography>

          <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'normal' }}>
            Valid payment profiles are payment profiles that contains all required data and is at least in the pending verification status.
          </Typography>
        </Grid>
      </Grid>

      <Grid className={'payment-profiles-list'} container spacing={2}>
        {paymentProfiles
          .filter(({ account_type, account_name, account_number, routing_number, verification_status }) => {
            if ([AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED].includes(verification_status)) {
              if (account_type === 'credit' || (account_name && account_number && routing_number)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })
          /**
           * Reference:
           * https://stackoverflow.com/a/9592774
           *
           * We need to slice the array of payment profiles first
           * so we can sort it without mutating the original array.
           */
          .slice()
          .sort((p1, p2) => p1.profile_type - p2.profile_type)
          .map(
            ({
              id,
              profile_type,
              institution_name,
              institution_logo,
              institution_primary_color,
              account_owner,
              account_type,
              account_name,
              account_number,
              routing_number,
              expiry_date,
              cvc_cvv,
              card_zip,
              verification_status,
            }) =>
              account_type === 'credit' ? (
                <CCPaymentProfile
                  key={id}
                  width={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}
                  data={{
                    applicationId,
                    applicationStatus,
                    profileType: profile_type,
                    institutionName: institution_name,
                    institutionPrimaryColor: institution_primary_color,
                    accountName: account_name,
                    accountNumber: account_number,
                    expiryDate: expiry_date,
                    cvvCode: cvc_cvv,
                    cardZip: card_zip,
                    verificationStatus: verification_status,
                  }}
                />
              ) : (
                <PaymentProfile
                  key={id}
                  width={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}
                  mode="advance"
                  data={{
                    applicantId,
                    applicationId,
                    applicationStatus,
                    profileType: profile_type,
                    institutionName: institution_name,
                    institutionLogo: institution_logo,
                    institutionPrimaryColor: institution_primary_color,
                    accountOwner: account_owner,
                    accountType: account_type,
                    accountName: account_name,
                    accountNumber: account_number,
                    routingNumber: routing_number,
                    verificationStatus: verification_status,
                  }}
                />
              ),
          )}

        {![FUNDED, SERVICING].includes(applicationStatus) && <AddNewPaymentProfile currentApplication={currentApplication} />}
      </Grid>

      <Snackbar
        open={showPaymentProfilesSectionError}
        autoHideDuration={6000}
        onClose={() => {
          setShowPaymentProfilesSectionError(false);
          setPaymentProfilesSectionErrorMsg('Process failed.');
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: '100%' }}
          onClose={() => {
            setShowPaymentProfilesSectionError(false);
            setPaymentProfilesSectionErrorMsg('Process failed.');
          }}
        >
          {paymentProfilesSectionErrorMsg}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default PaymentProfiles;
