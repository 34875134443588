import { Divider, Typography } from '@mui/material';

import { Card, FlexRow } from 'components/Layouts';
import { currencyFormatter, roundAccurately } from 'constants/formatters';
import ListItem from 'components/ListItem';

const Position = ({ interestPaid, feesPaid, originalPrincipal, principalPaid, position }) => {
  const calculatedPosition = position || roundAccurately(originalPrincipal - interestPaid - feesPaid - principalPaid, 2);
  return (
    <Card variant="outlined" padding="10px 20px">
      <Typography variant="h6" sx={{ mb: '8px' }}>
        Position
      </Typography>
      <ListItem labelPrepend=" " label="Original Principal" value={originalPrincipal ? currencyFormatter.format(originalPrincipal) : '-'} />
      <ListItem labelPrepend="-" label="Interest Paid" value={interestPaid ? currencyFormatter.format(interestPaid) : '-'} />
      <ListItem labelPrepend="-" label="Fees Paid" value={feesPaid ? currencyFormatter.format(feesPaid) : '-'} />
      <ListItem labelPrepend="-" label="Principal Paid" value={principalPaid ? currencyFormatter.format(principalPaid) : '-'} />

      <Divider sx={{ m: '8px 0px' }} />

      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: 500 }}>Current Position</Typography>
        <Typography sx={{ fontWeight: 500 }}>{calculatedPosition ? currencyFormatter.format(calculatedPosition) : '-'}</Typography>
      </FlexRow>
    </Card>
  );
};

export default Position;
