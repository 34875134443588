import { useNavigate } from 'react-router';
import { DataGrid } from '@mui/x-data-grid';

import { LINKS } from 'constants/menus';
import { COLORS } from 'constants/styles';
import { roundAccurately } from 'constants/formatters';
import { columns } from './helpers.js';

const LoansGrid = ({ loans, updatePagination, metadata, limit, offset }) => {
  const navigate = useNavigate();

  const rows = loans.map((loan) => {
    const { metrics: metricsArray, customers } = loan;
    const primaryCustomer = customers && customers.length ? customers.find((customer) => customer?.loans_customers?.customer_role === 'primary-debtor') : null;
    const metrics = metricsArray && metricsArray?.length && metricsArray[0];
    const originalPrincipal = loan?.principal_amount ? Number(loan.principal_amount) : 0;
    const currentPrincipal = metrics?.total_running_principal_amount ? Number(metrics.total_running_principal_amount) : 0;
    const unpaidFees = metrics?.total_running_fees ? Number(metrics?.total_running_fees) : 0;
    const interestPaid = metrics?.total_interests_paid ? Number(metrics.total_interests_paid) : 0;
    const principalPaid = metrics?.total_principal_paid ? Number(metrics.total_principal_paid) : 0;
    const feesPaid = metrics?.total_fees_paid ? Number(metrics.total_fees_paid) : 0;
    const totalPaid = roundAccurately(interestPaid + principalPaid + feesPaid, 2);
    const roi = roundAccurately((interestPaid + feesPaid) / originalPrincipal, 4);
    const percentReturned = roundAccurately((interestPaid + feesPaid + principalPaid) / originalPrincipal, 4);
    const pastDue = metrics?.total_running_past_due_amount ? Number(metrics.total_running_past_due_amount) : 0;
    const payoff = metrics?.payoff_amount ? Number(metrics.payoff_amount) : 0;

    return {
      id: loan.id,
      date: loan.contract_date,
      firstPaymentDate: loan.first_payment_date,
      interestRate: loan.interest_rate,
      lastPaymentDate: loan.last_payment_date,
      payment: loan?.payment_amount ? Number(loan.payment_amount) : 0,
      paymentFrequency: loan.payment_frequency,
      originalPrincipal: loan.principal_amount,
      terms: loan.terms,
      createdAt: loan.created_at,
      currentPrincipal,
      unpaidFees,
      principalPaid,
      interestPaid,
      totalPaid,
      feesPaid,
      name: primaryCustomer ? `${primaryCustomer.first_name} ${primaryCustomer.last_name}` : '',
      roi,
      percentReturned,
      pastDue,
      payoff,
    };
  });
  return (
    <div style={{ margin: '16px 0px 16px', height: 'calc(100vh - 140px)', background: COLORS.surfaceColor }}>
      <DataGrid
        onRowClick={(loan) => navigate(LINKS.LENDER.LOANS.VIEW_ONE(loan?.id, 'summary'))}
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        disableColumnMenu
        density="compact"
        columns={columns}
        rows={rows}
        paginationModel={{
          pageSize: Number(limit),
          page: Number(offset) <= 1 ? 0 : Number(offset) - 1,
        }}
        onPaginationModelChange={(params) => updatePagination({ offset: params.page + 1, limit: params.pageSize })}
        paginationMode="server"
        rowCount={metadata?.count ? Number(metadata.count) : 0}
        sx={{
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
          '& .cell-has-past-due': {
            color: COLORS.error,
            fontWeight: 'bold',
          },
          '& .cell-has-no-past-due': {
            color: COLORS.success,
          },
        }}
      />
    </div>
  );
};

export default LoansGrid;
