import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, IconButton, Tooltip } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

import { setAlert } from 'store/sagas/app';
import { FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import { fetchLoanMetricsThunk } from 'store/thunks/loanMetric';
import BasicSelect from 'components/BasicSelect';
import { options, createFetchConfiguration, columns } from './helpers';
import { Download } from '@mui/icons-material';

const DailyArchive = ({ contractDate, timezone }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [rangeOption, setRangeOption] = useState('30');
  const [metrics, setMetrics] = useState([]);
  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const fetchConfiguration = createFetchConfiguration(rangeOption, contractDate, timezone);
      const { metrics } = await dispatch(
        fetchLoanMetricsThunk({
          loanId,
          ...fetchConfiguration,
        }),
      ).unwrap();
      setMetrics(metrics);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch daily archive data. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId, rangeOption]);

  const rows = metrics && metrics?.length && metrics?.length ? [...metrics].sort((a, b) => new Date(b.date) - new Date(a.date)) : [];
  const apiRef = useGridApiRef();

  return (
    <>
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Daily Archive</Typography>

        <div>
          <BasicSelect options={options} value={rangeOption} handleChange={(e) => setRangeOption(e.target.value)} />
          <Tooltip title="Download as CSV">
            <IconButton sx={{ ml: '10px' }} onClick={() => apiRef.current.exportDataAsCsv()}>
              <Download />
            </IconButton>
          </Tooltip>
        </div>
      </FlexRow>

      <div style={{ margin: '16px 0px 0px', height: 'calc(100vh - 215px)', background: COLORS.surfaceColor }}>
        <DataGrid
          // onRowClick={(params) => console.log(params.id)}
          apiRef={apiRef}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          disableColumnMenu
          density="compact"
          loading={loading}
          columns={columns}
          rows={rows}
          sx={{
            '.MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            // '& .MuiDataGrid-row:hover': {
            //   cursor: 'pointer',
            // },
          }}
        />
      </div>
    </>
  );
};

export default DailyArchive;
