import SaveIcon from '@mui/icons-material/Save';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CreateIcon from '@mui/icons-material/Create';
import ArticleIcon from '@mui/icons-material/Article';
import PaidIcon from '@mui/icons-material/Paid';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorIcon from '@mui/icons-material/Error';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const STATUS_LABELS = {
  SAVED: 'Saved',
  PROCESSING: 'Processing',
  REFERRED: 'Referred',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  PENDING_SIGNATURES: 'Pending Signatures',
  PENDING_DOCUMENTS: 'Pending Documents',
  FUNDING_REVIEW: 'Funding Review',
  FUNDING_DELAY: 'Funding Delay',
  FUNDED: 'Funded',
  SERVICING: 'Servicing',
  CLOSED: 'Closed',
};

const { SAVED, PROCESSING, REFERRED, APPROVED, DENIED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING, CLOSED } =
  STATUS_LABELS;

export const STATUS = [
  {
    label: SAVED,
    icon: <SaveIcon />,
    outlined: true,
    color: 'info',
  },
  {
    label: PROCESSING,
    icon: <AutorenewIcon />,
    outlined: true,
    color: 'warning',
  },
  {
    label: REFERRED,
    icon: <HourglassBottomIcon />,
    outlined: true,
    color: 'warning',
  },
  {
    label: APPROVED,
    icon: <CheckBoxIcon />,
    outlined: true,
    color: 'success',
  },
  {
    label: DENIED,
    icon: <DoDisturbIcon />,
    outlined: true,
    color: 'error',
  },
  {
    label: PENDING_SIGNATURES,
    icon: <CreateIcon />,
    outlined: true,
    color: 'primary',
  },
  {
    label: PENDING_DOCUMENTS,
    icon: <ArticleIcon />,
    outlined: false,
    color: 'info',
  },
  {
    label: FUNDING_REVIEW,
    icon: <AutorenewIcon />,
    outlined: false,
    color: 'secondary',
  },
  {
    label: FUNDING_DELAY,
    icon: <ErrorIcon />,
    outlined: false,
    color: 'error',
  },
  {
    label: FUNDED,
    icon: <PaidIcon />,
    outlined: false,
    color: 'primary',
  },
  {
    label: SERVICING,
    icon: <CloudDoneIcon />,
    outlined: false,
    color: 'success',
  },
  {
    label: CLOSED,
    icon: <HighlightOffIcon />,
    outlined: false,
    color: 'error',
  },
];

/* 
Saved - Neutral
Processing - Neutral
Referred - Needs Attention
Approved - Good
Approved w/ Override - Good
Denied - Bad
Override Requested - Needs Attention
Needs Signatures - Needs Attention
Needs Documents - Needs Attention
Processing Funding - Good
Funded - Really Good
Servicing - Really Good
Closed - Never Signed After 30 Days or Was Not Funded
*/
