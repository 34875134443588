import { basePasswordField } from 'components/Formiks - Deprecated/constants';

export const userFormInfo = {
  password: {
    ...basePasswordField,
    label: 'Password',
    parameterName: 'password',
  },
  confirm_password: {
    ...basePasswordField,
    label: 'Confirm Password',
    parameterName: 'confirm_password',
  },
};
