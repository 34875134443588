import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppSidebar from './components/AppSidebar';
import { closeAlert, setWindowSize, fetchHydrationData } from 'store/sagas/app';
import { FlexRow, MainContentContainer } from './components/Layouts';
import { Typography } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const { alert } = useSelector((state) => state.app);

  useEffect(() => {
    function handleWindowResize() {
      dispatch(setWindowSize(window.innerWidth));
    }

    window.addEventListener('resize', handleWindowResize);

    if (!user.id) {
      dispatch(fetchHydrationData());
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleAlertClose = () => {
    dispatch(closeAlert());
  };

  const mobileView = app?.windowSize < 900;

  return (
    <div>
      <AppSidebar style={{ zIndex: 1 }} mobileView={mobileView} />

      <MainContentContainer style={{ zIndex: 2 }} mobileView={mobileView}>
        <Outlet />
      </MainContentContainer>

      <Backdrop sx={{ color: '#FFF', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={app.loading}>
        <FlexRow padding="0" margin="0" justifyContent="center" alignItems="center" flexColumn>
          <CircularProgress color="primary" />
          <Typography sx={{ marginTop: '10px' }} variant="overline">
            {app.loadingMessage}
          </Typography>
        </FlexRow>
      </Backdrop>

      <Snackbar open={alert.showing} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={alert.type} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
