import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ButtonGroup from '@mui/material/ButtonGroup';
import isEqual from 'lodash/isEqual';

import { buildFormikFields, useBuildFormik } from 'components/Formiks - Deprecated';
import { FlexRow } from 'components/Layouts';
import { stipulationTemplateFormInfo } from './formInfo';
import Trigger from './trigger';
import { createStipulationTemplate, updateStipulationTemplate } from 'store/sagas/admin';

export const blankTrigger = {
  attribute: null,
  operation: null,
  compare_value: '',
};

const StipulationTemplateModal = ({ stipulationTemplate, open, onClose, documentTemplates, applicationShape }) => {
  const dispatch = useDispatch();

  const activeProfile = useSelector((state) => state.user?.active_profile);

  const editing = !!stipulationTemplate.id;
  const stipulationTemplateFormInfoObj = stipulationTemplateFormInfo(
    documentTemplates.map((template) => ({ label: template.name, key: template.id, ...template })),
  );

  const formik = useBuildFormik({
    formInfo: Object.values(stipulationTemplateFormInfoObj),
    onSubmit: (values) => handleSubmit(values),
    values: stipulationTemplate,
    validate: (incomingVals) => {
      let errors = {};

      if (!incomingVals?.show_for_all_applications) {
        const triggerErrors = incomingVals?.stipulation_template_triggers?.map((trigger) => {
          let errorObj = {};

          if (!trigger.attribute) errorObj.attribute = true;
          if (!trigger.operation) errorObj.operation = true;
          if (!(trigger.operation === 'exists' || trigger.operation === 'does not exist') && !trigger.compare_value) errorObj.compare_value = true;

          return errorObj;
        });

        if (triggerErrors?.filter((obj) => Object.keys(obj).length > 0).length > 0) errors.stipulation_template_triggers = triggerErrors;
      }

      return errors;
    },
  });

  const { values } = formik;
  const { document_template_id, description, name } = stipulationTemplateFormInfoObj;
  const selectedDocumentTemplate = documentTemplates.find((template) => template.id === values?.document_template_id);
  const selectedDocumentTemplateIsSignable = selectedDocumentTemplate ? selectedDocumentTemplate.is_signable : false;

  const handleSubmit = (values) => {
    if (editing) {
      const data = values;
      dispatch(updateStipulationTemplate({ data: { ...data, lender_id: activeProfile.lender_id }, templateId: stipulationTemplate.id }));
    } else {
      const data = values;
      dispatch(createStipulationTemplate({ data: { ...data, lender_id: activeProfile.lender_id } }));
    }
    onClose();
  };

  const addTrigger = () => {
    formik.setFieldValue('stipulation_template_triggers', [...values?.stipulation_template_triggers, blankTrigger]);
  };

  const removeTrigger = () => {
    let copyOfTriggers = structuredClone(values?.stipulation_template_triggers);
    copyOfTriggers.splice(-1);

    formik.setFieldValue('stipulation_template_triggers', copyOfTriggers);
  };

  const handleTriggerForAllApplications = (checked) => {
    if (!checked) {
      const initialTriggers = formik?.initialValues?.stipulation_template_triggers;
      formik.setFieldValue('stipulation_template_triggers', initialTriggers?.length > 0 ? initialTriggers : [blankTrigger]);
    } else {
      formik.setFieldValue('stipulation_template_triggers', []);
    }

    formik.setFieldValue('show_for_all_applications', checked);
  };

  useEffect(() => {
    if (!selectedDocumentTemplate?.is_signable) {
      formik.setFieldValue('fulfill_if_document_is_signed', false);
    }
  }, [selectedDocumentTemplate]);

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth maxWidth="xl" open={open}>
      <DialogTitle>{`${editing ? 'Edit' : 'Add'}`} Stipulation Template</DialogTitle>
      <DialogContent>
        {buildFormikFields({
          formik,
          fields: [document_template_id, name],
          fieldsPerRow: 2,
        })}
        {buildFormikFields({
          formik,
          fields: [description],
        })}
        <Divider sx={{ margin: '20px 0px' }} />

        <Typography variant="h6">Settings</Typography>
        {[
          {
            customOnChange: handleTriggerForAllApplications,
            checked: values.show_for_all_applications,
            fieldName: 'show_for_all_applications',
            label: 'Trigger for all applications',
            explanation:
              'If this is turned on, every approved application will trigger this stipulation template and be required to fulfill it before they can be funded.',
          },
          {
            checked: values.fulfill_if_document_is_signed,
            fieldName: 'fulfill_if_document_is_signed',
            label: 'Fullfill with signed document',
            explanation:
              'This setting can be turned on if the associated document template is a signing document. If this is on, the document template will be included in the signing package and the stipulation will be fulfilled once signing is complete.',
          },
        ].map(({ checked, fieldName, label, explanation, customOnChange }) => (
          <Fragment key={`switch-${fieldName}`}>
            <FormControlLabel
              control={
                <Switch
                  checked={checked || false}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    if (fieldName === 'fulfill_if_document_is_signed' && !selectedDocumentTemplateIsSignable) {
                      checked = false;
                    }

                    customOnChange ? customOnChange(checked) : formik.setFieldValue(fieldName, checked);
                  }}
                  name={fieldName}
                />
              }
              label={
                <Typography sx={{ fontWeight: 700 }} variant="body1">
                  {label}
                </Typography>
              }
            />
            <Typography sx={{ marginLeft: '47px' }} variant="body2">
              {explanation}
            </Typography>
          </Fragment>
        ))}
        <Divider sx={{ margin: '20px 0px' }} />

        {!values?.show_for_all_applications && (
          <Fragment>
            <Typography variant="h6">Trigger Rules</Typography>
            {values?.stipulation_template_triggers?.map((trigger, i) => (
              <Trigger key={`trigger-${i}`} position={i} applicationShape={applicationShape} formik={formik} values={trigger} />
            ))}
            <FlexRow padding="0" margin="10px 0px" justifyContent="center" alignItems="center">
              <ButtonGroup variant="contained">
                {
                  <Button disabled={values?.stipulation_template_triggers?.length <= 1} onClick={removeTrigger} size="small">
                    <RemoveIcon />
                  </Button>
                }
                {
                  <Button disabled={values?.stipulation_template_triggers?.length > 5} onClick={addTrigger} size="small">
                    <AddIcon />
                  </Button>
                }
              </ButtonGroup>
            </FlexRow>
            <Divider sx={{ margin: '20px 0px' }} />
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={isEqual(values, stipulationTemplate)} onClick={formik.handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StipulationTemplateModal;
