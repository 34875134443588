import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import GavelIcon from '@mui/icons-material/Gavel';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import { LINKS } from 'constants/menus';
import { UtilitySidebar, UtilitySideBarItem } from 'components/Layouts';

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <UtilitySidebar>
      {[{ icon: <PeopleOutlineIcon />, label: 'User Management', link: LINKS.DEALER.SETTINGS }].map(({ icon, label, link }, i) => (
        <NavLink style={{ width: '100%' }} key={`dealer-settings-link-${i}`} to={link}>
          {({ isActive }) => <UtilitySideBarItem onClick={() => navigate(link)} active={isActive} icon={icon} label={label} />}
        </NavLink>
      ))}
    </UtilitySidebar>
  );
};

export default Sidebar;
