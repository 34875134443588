import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, CircularProgress, Backdrop, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { format, parseISO } from 'date-fns';

import { useFormie } from 'components/Formie';
import { editCustomerThunk } from 'store/thunks/customer';
import { createCustomerThunk, editCustomerRolesThunk } from 'store/thunks/loan';
import { setAlert } from 'store/sagas/app';
import CustomerCard from './CustomerCard';
import CustomerDrawer from './CustomerDrawer';
import { customerConfiguration, customerInitialValues } from '../formInfo';
import { COLORS } from 'constants/styles';
import { FlexRow } from 'components/Layouts';
import { parseDateOnlyForInputs } from 'constants/formatters';

const Customers = ({ customers, rehydrateOnboarding, rehydrateTopLevel }) => {
  const dispatch = useDispatch();

  const { loanId } = useParams();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState('primary');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = (role) => {
    setOpen(true);
    setRole(role);
  };

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      setEditing(false);
      resetForm();
    }
  };

  const handleStartEdit = (customer, role) => {
    const residenceAddress = customer?.addresses?.find((address) => address?.address_type === 'residence');
    const mailingAddress = customer?.addresses?.find((address) => address?.address_type === 'mailing');
    setOpen(true);
    setRole(role);
    setEditing(true);
    const correctDOB = customer?.dob && parseDateOnlyForInputs(customer?.dob);
    const addressesMatch =
      residenceAddress?.address_1 == mailingAddress?.address_1 &&
      residenceAddress?.address_2 == mailingAddress?.address_2 &&
      residenceAddress?.city == mailingAddress?.city &&
      residenceAddress?.state == mailingAddress?.state &&
      residenceAddress?.zip == mailingAddress?.zip;

    formie.setAllValues({
      customer: {
        id: customer?.id,
        first_name: customer?.first_name,
        last_name: customer?.last_name,
        dob: correctDOB,
        ssn: customer?.ssn,
        email: customer?.email,
        phone: customer?.phone,
      },
      address_residence: {
        id: residenceAddress?.id,
        address_1: residenceAddress?.address_1,
        address_2: residenceAddress?.address_2,
        city: residenceAddress?.city,
        state: residenceAddress?.state,
        zip: residenceAddress?.zip,
      },
      address_mailing: {
        id: mailingAddress?.id,
        address_1: mailingAddress?.address_1,
        address_2: mailingAddress?.address_2,
        city: mailingAddress?.city,
        state: mailingAddress?.state,
        zip: mailingAddress?.zip,
      },
      address_helper: {
        use_primary_address: addressesMatch,
      },
    });
  };

  const addOrEditCustomer = async () => {
    setLoading(true);
    setOpen(false);
    const residenceAddress = formie?.values?.address_residence;
    const mailingAddress = formie?.values?.address_helper?.use_primary_address ? formie?.values?.address_residence : formie?.values?.address_mailing;

    try {
      if (!!editing) {
        await dispatch(
          editCustomerThunk({
            customer: {
              ...formie?.values?.customer,
              dob: formie?.values?.customer?.dob && format(parseISO(formie.values.customer.dob), 'yyyy-MM-dd'),
            },
            residenceAddress,
            mailingAddress: { ...mailingAddress, id: formie?.values?.address_mailing?.id },
          }),
        ).unwrap();
      } else {
        await dispatch(
          createCustomerThunk({
            customer: {
              ...formie?.values?.customer,
              dob: formie?.values?.customer?.dob && format(parseISO(formie.values.customer.dob), 'yyyy-MM-dd'),
            },
            residenceAddress,
            mailingAddress,
            loanId,
            role: role === 'primary' ? 'primary-debtor' : 'secondary-debtor',
          }),
        ).unwrap();
      }
      await Promise.all([rehydrateOnboarding(), rehydrateTopLevel()]);
      resetForm();
      setEditing(false);
    } catch (err) {
      setOpen(true);
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to ${!!editing ? 'edit' : 'add'} customer. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const linkCustomerToLoan = async (primaryCustomerId, secondaryCustomerId) => {
    setLoading(true);
    setOpen(false);
    try {
      await dispatch(editCustomerRolesThunk({ primaryCustomerId, secondaryCustomerId, loanId })).unwrap();
      await Promise.all([rehydrateOnboarding(), rehydrateTopLevel()]);
      resetForm();
      setEditing(false);
    } catch (err) {
      setOpen(true);
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to link customer. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const swapCustomerRoles = async (currentPrimaryCustomerId, currentSecondaryCustomerId) => {
    setLoading(true);
    try {
      await dispatch(editCustomerRolesThunk({ primaryCustomerId: currentSecondaryCustomerId, secondaryCustomerId: currentPrimaryCustomerId, loanId })).unwrap();
      await Promise.all([rehydrateOnboarding(), rehydrateTopLevel()]);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to link customer. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const formie = useFormie({
    configuration: customerConfiguration,
    initialValues: customerInitialValues,
    setLoading,
  });

  const handleAddress = (address, path) => {
    formie.handleAddressValues(address, path);
  };

  const { resetForm } = formie;

  const primaryCustomer = customers && customers.length ? customers.find((customer) => customer?.loans_customers?.customer_role === 'primary-debtor') : null;
  const secondaryCustomer =
    customers && customers.length ? customers.find((customer) => customer?.loans_customers?.customer_role === 'secondary-debtor') : null;

  return (
    <>
      <FlexRow padding="0px" margin="32px 0px 0px" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Who are the customers?</Typography>

        {customers && customers.length > 1 && (
          <Button onClick={() => swapCustomerRoles(primaryCustomer?.id, secondaryCustomer?.id)} startIcon={<SwapHorizOutlinedIcon />}>
            Swap Roles
          </Button>
        )}
      </FlexRow>

      <Grid sx={{ mt: 0 }} container spacing={2}>
        {/* Primary Debtor */}
        <Grid xs={6}>
          <CustomerCard handleStartEdit={handleStartEdit} customerId={primaryCustomer?.id} role="primary" handleOpen={handleOpen} />
        </Grid>

        {/* Secondary Debtor - only show this if there is already a primary customer. */}
        {primaryCustomer && (
          <Grid xs={6}>
            <CustomerCard
              unlinkCustomerFromLoan={() => linkCustomerToLoan(primaryCustomer?.id, null)}
              handleStartEdit={handleStartEdit}
              customerId={secondaryCustomer?.id}
              role="secondary"
              handleOpen={handleOpen}
            />
          </Grid>
        )}
      </Grid>

      <CustomerDrawer
        handleSubmit={addOrEditCustomer}
        handleAddress={handleAddress}
        formie={formie}
        handleClose={handleClose}
        role={role}
        open={open}
        handleOpen={handleOpen}
        editing={!!editing}
        linkCustomerToLoan={linkCustomerToLoan}
        primaryCustomerId={primaryCustomer?.id}
      />

      <Backdrop sx={{ color: COLORS.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Customers;
