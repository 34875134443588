import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import DoNotDisturbOffOutlinedIcon from '@mui/icons-material/DoNotDisturbOffOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { DocumentAPI } from 'store/api';
import { LINKS } from 'constants/menus';
import { STATUS, STATUS_LABELS } from 'constants/status';
import { startAppLoading, stopAppLoading } from 'store/sagas/app';
import { updateStipulation, updateApplicationStatus } from 'store/sagas/applications';
import { Card, FlexRow, ListItem, FileContainer } from 'components/Layouts';
import { getApplicationStatusIcon } from 'components/Icon';
import { ActionMenu } from 'components/Menu';
import { DoubleConfirmNoButton } from 'components/DoubleConfirm';

const { FUNDING_REVIEW, FUNDED, SERVICING } = STATUS_LABELS;

const Documents = ({ SECTIONS, currentApplication: { status, lender, stipulations, id: applicationId }, overviewShouldShow, primaryPaymentProfile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeProfile = useSelector((state) => state.user?.active_profile);

  const [modalOpen, setModalOpen] = useState(false);

  const downloadDocument = async ({ id }) => {
    try {
      dispatch(startAppLoading());
      const { link } = await DocumentAPI.downloadDocumentFromS3({ documentId: id });
      dispatch(stopAppLoading());
      window.open(link);
    } catch (err) {
      console.log(err);
      dispatch(stopAppLoading());
    }
  };

  const allStipsFulfilled = stipulations ? stipulations.every((s) => s.fulfilled) : false;
  const sortedStipulations = structuredClone(stipulations).sort((a, b) => a.stipulation_template.name.localeCompare(b.stipulation_template.name));

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="Stips and documents.">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography style={{ margin: '0px' }} variant="h5">
            Stips & Documents
          </Typography>
          <Typography
            style={{ margin: '0px 0px 10px' }}
            variant="body1"
          >{`All of these stipulations must be met before ${lender.name} will fund this loan.`}</Typography>
        </Grid>

        {!overviewShouldShow && (
          <Grid item xs={4} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Stack spacing={1} direction="row" alignContent="center" alignItems="center">
              {getApplicationStatusIcon({ statusObj: STATUS.find((stat) => stat.label === status.name), size: 'medium' })}

              {allStipsFulfilled && primaryPaymentProfile && [FUNDING_REVIEW, FUNDED].includes(status.name) ? (
                <ActionMenu
                  icon={<EditOutlinedIcon fontSize="16px" />}
                  options={[
                    status.name === FUNDING_REVIEW
                      ? {
                          value: getApplicationStatusIcon({ statusObj: STATUS.find((s) => s.label === FUNDED) }),
                          callback: () => setModalOpen(true),
                        }
                      : status.name === FUNDED
                      ? {
                          value: getApplicationStatusIcon({ statusObj: STATUS.find((s) => s.label === SERVICING) }),
                          callback: () => setModalOpen(true),
                        }
                      : null,
                  ]}
                />
              ) : null}
            </Stack>
          </Grid>
        )}
      </Grid>

      <List>
        <Divider />
        {sortedStipulations.map((stipulation) => {
          const { fulfilled, stipulation_template, linked_document, id: stipulationId } = stipulation;
          const { document_template } = stipulation_template;
          const stipDocSigned = linked_document && linked_document.signed;

          const action =
            [STATUS_LABELS.PENDING_DOCUMENTS, STATUS_LABELS.FUNDING_DELAY, STATUS_LABELS.FUNDING_REVIEW].includes(status.name) && linked_document ? (
              <ActionMenu
                options={[
                  fulfilled === true
                    ? {
                        icon: <UnpublishedOutlinedIcon />,
                        value: 'Un-Approve',
                        callback: () =>
                          dispatch(updateStipulation({ data: { lender_id: activeProfile.lender_id, fulfilled: null }, stipulationId, applicationId })),
                      }
                    : {
                        icon: <CheckCircleOutlinedIcon color={'success'} />,
                        value: 'Approve',
                        callback: () =>
                          dispatch(updateStipulation({ data: { lender_id: activeProfile.lender_id, fulfilled: true }, stipulationId, applicationId })),
                      },
                  fulfilled === false
                    ? {
                        icon: <DoNotDisturbOffOutlinedIcon />,
                        value: 'Un-Reject',
                        callback: () =>
                          dispatch(updateStipulation({ data: { lender_id: activeProfile.lender_id, fulfilled: null }, stipulationId, applicationId })),
                      }
                    : {
                        icon: <DoDisturbOnOutlinedIcon color={'error'} />,
                        value: 'Reject',
                        callback: () =>
                          dispatch(updateStipulation({ data: { lender_id: activeProfile.lender_id, fulfilled: false }, stipulationId, applicationId })),
                      },
                ]}
              />
            ) : null;

          return (
            <ListItem
              onClick={overviewShouldShow ? () => navigate(LINKS.LENDER.APPLICATIONS.VIEW_ONE(applicationId, SECTIONS.STIPS_AND_DOCS)) : null}
              key={stipulationId}
              primaryText={stipulation_template?.name}
              secondaryText={stipulation_template?.description}
              stackArray={
                overviewShouldShow
                  ? []
                  : [
                      {
                        display: fulfilled === true,
                        label: 'Completed',
                        icon: <CheckCircleOutlinedIcon />,
                        color: 'success',
                        tip: 'Success! This stipulation has been fulfilled.',
                      },
                      {
                        display: fulfilled === false,
                        label: 'Document Rejected',
                        icon: <DoDisturbOnOutlinedIcon />,
                        color: 'error',
                        tip: 'This stipulation is rejected.',
                      },
                      {
                        display: fulfilled === null || fulfilled === undefined,
                        label: 'Incomplete',
                        icon: <DoDisturbIcon />,
                        color: 'default',
                        tip: 'This stipulation is not yet fulfilled. You cannot get funded until all stips have cleared.',
                      },
                      {
                        display: !linked_document?.s3_id,
                        label: 'Not Uploaded',
                        icon: <DoDisturbIcon />,
                        color: 'default',
                        tip: 'No document has been uploaded to fulfill this stipulation yet.',
                      },
                      {
                        display: linked_document?.s3_id,
                        label: 'Uploaded',
                        icon: <CheckBoxIcon />,
                        color: 'primary',
                        tip: 'This stipulation has an uploaded document.',
                      },
                      {
                        display: document_template.is_signable,
                        label: stipDocSigned ? 'Signed' : 'Signable',
                        icon: <HistoryEduIcon />,
                        color: stipDocSigned ? 'primary' : 'default',
                        tip: stipDocSigned ? 'This document has already been signed' : 'This document will be generated and signed on the signing page.',
                      },
                    ]
              }
              icon={<InsertDriveFileIcon color="primary" sx={{ fontSize: '40px' }} />}
              action={overviewShouldShow ? null : action}
            >
              {linked_document?.s3_id && !overviewShouldShow && (
                <FileContainer variant="permanent">
                  <FlexRow padding="0" margin="0" alignItems="center">
                    <InsertDriveFileIcon color="primary" />
                    <Typography noWrap variant="body1" style={{ marginLeft: '10px' }}>
                      {linked_document?.name}
                    </Typography>
                  </FlexRow>

                  <IconButton disabled={!linked_document?.id} onClick={() => downloadDocument(linked_document)}>
                    <VisibilityIcon color={'primary'} />
                  </IconButton>
                </FileContainer>
              )}
            </ListItem>
          );
        })}
      </List>

      {status && stipulations && stipulations.every((s) => s.fulfilled) ? (
        <DoubleConfirmNoButton
          title="Are you sure?"
          description={`Marking this application as "${status.name === FUNDING_REVIEW ? 'Funded' : 'Servicing'}" is irreversible.`}
          action={() => {
            /**
             * ! Warning
             * ! We are hardcoding and assuming that the id of application status of "Funded" is 10 and "Servicing" is 11.
             *
             * TODO: Properly fetch application status from DB so we can use the actual id of the application status on the DB.
             */
            const application_status_id =
              status.name === FUNDING_REVIEW
                ? // Funding Review -> Funded
                  10
                : status.name === FUNDED
                ? // Funded -> Servicing
                  11
                : null;

            if (application_status_id) {
              dispatch(updateApplicationStatus({ data: { application_status_id }, applicationId }));
            }
          }}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </Card>
  );
};

export default Documents;
