import CloseIcon from '@mui/icons-material/Close';
import { Alert, Dialog, DialogContent, DialogTitle, Typography, IconButton, Divider } from '@mui/material';

import { FlexRow } from 'components/Layouts';
import { formatDateOnly } from 'constants/formatters';
import { parsePaymentStatus, parsePaymentType, parsePaymentMethod, parseExtraToward } from './helpers';
import { currencyFormatter } from 'constants/formatters';
import ListItem from 'components/ListItem';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { COLORS } from 'constants/styles';

const DetailsDialog = ({ onClose, open, payment }) => {
  const pastDues = payment ? payment?.loan_payment_past_dues.sort((a, b) => a?.payment_period_number - b?.payment_period_number) : [];
  const appliedToPastDues = !!pastDues && !!pastDues.length && !!pastDues.length > 0;
  const paymentPeriods = payment ? payment?.loan_payment_payment_periods.sort((a, b) => a?.period_number - b?.period_number) : [];
  const appliedToPaymentPeriods = !!paymentPeriods && !!paymentPeriods.length && !!paymentPeriods.length > 0;
  const fees = payment ? payment?.loan_payment_fees.sort((a, b) => new Date(a.fee_date) - new Date(b.fee_date)) : [];
  const appliedToFees = !!fees && !!fees.length && !!fees.length > 0;

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle>{`${formatDateOnly(payment?.payment_application_date)} Payment Details`}</DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: '0px 24px 20px' }}>
        <FlexRow padding="0px 0px 8px" margin="0px 0px 12px" flexColumn justifyContent="center" alignItems="center" borderBottom>
          <Typography variant="h4">{payment?.payment_amount ? currencyFormatter.format(Number(payment.payment_amount)) : '$0.00'}</Typography>
          <Typography>Amount Paid</Typography>
        </FlexRow>

        <Grid container columnSpacing={4}>
          <Grid xs={6}>
            <div style={{ background: COLORS.backgroundColor, padding: '12px', borderRadius: '8px' }}>
              <Typography sx={{ fontWeight: 700 }}>Loan Before</Typography>
              <ListItem
                label="Principal"
                value={
                  payment?.before_total_running_principal_amount ? currencyFormatter.format(Number(payment.before_total_running_principal_amount)) : '$0.00'
                }
              />
              <ListItem
                label="Interest"
                value={
                  payment?.before_total_running_accrued_interest ? currencyFormatter.format(Number(payment.before_total_running_accrued_interest)) : '$0.00'
                }
              />
              <ListItem
                label="Fees"
                value={payment?.before_total_running_fees ? currencyFormatter.format(Number(payment.before_total_running_fees)) : '$0.00'}
              />

              <Typography sx={{ fontWeight: 700, mt: '16px' }}>Payment Breakdown</Typography>
              <ListItem
                label="Principal"
                value={payment?.principal_payment_amount ? currencyFormatter.format(Number(payment.principal_payment_amount)) : '$0.00'}
              />
              <ListItem
                label="Interest"
                value={payment?.interest_payment_amount ? currencyFormatter.format(Number(payment.interest_payment_amount)) : '$0.00'}
              />
              <ListItem label="Fees" value={payment?.fees_payment_amount ? currencyFormatter.format(Number(payment.fees_payment_amount)) : '$0.00'} />

              <Typography sx={{ fontWeight: 700, mt: '16px' }}>Loan After</Typography>
              <ListItem
                label="Principal"
                value={payment?.after_total_running_principal_amount ? currencyFormatter.format(Number(payment.after_total_running_principal_amount)) : '$0.00'}
              />
              <ListItem
                label="Interest"
                value={payment?.after_total_running_accrued_interest ? currencyFormatter.format(Number(payment.after_total_running_accrued_interest)) : '$0.00'}
              />
              <ListItem label="Fees" value={payment?.after_total_running_fees ? currencyFormatter.format(Number(payment.after_total_running_fees)) : '$0.00'} />
            </div>
          </Grid>

          <Grid xs={6}>
            {appliedToPaymentPeriods && (
              <>
                <Typography sx={{ fontWeight: 700, mt: '8px' }}>Applied to Payment Periods</Typography>
                {paymentPeriods.map((period) => (
                  <ListItem
                    key={period?.id}
                    label={`Period ${period?.period_number}:`}
                    value={
                      period?.period_payments?.payment_amount
                        ? currencyFormatter.format(Number(period.period_payments.payment_amount))
                        : currencyFormatter.format(0)
                    }
                  />
                ))}
              </>
            )}

            {appliedToPastDues && (
              <>
                <Typography sx={{ fontWeight: 700, mt: '8px' }}>Applied to Past Dues</Typography>
                {pastDues.map((pastDue) => (
                  <ListItem
                    key={pastDue?.id}
                    label={`Period ${pastDue?.payment_period_number} Past Due:`}
                    value={
                      pastDue?.due_payments?.amount_settled
                        ? currencyFormatter.format(Number(pastDue.due_payments.amount_settled))
                        : currencyFormatter.format(0)
                    }
                  />
                ))}
              </>
            )}

            {appliedToFees && (
              <>
                <Typography sx={{ fontWeight: 700, mt: '8px' }}>Applied to Fees</Typography>
                {fees.map((fee) => (
                  <ListItem
                    key={fee?.id}
                    label={`${formatDateOnly(fee?.fee_date)} - ${fee?.title}`}
                    value={fee?.fee_payments?.amount_paid ? currencyFormatter.format(Number(fee.fee_payments.amount_paid)) : currencyFormatter.format(0)}
                  />
                ))}
              </>
            )}

            <Typography sx={{ fontWeight: 700, mt: '16px' }}>Details</Typography>
            <ListItem label="Date" value={payment?.payment_application_date ? formatDateOnly(payment.payment_application_date) : '-'} />
            <ListItem label="Status" value={payment?.status ? parsePaymentStatus(payment.status) : '-'} />
            <ListItem label="Method" value={payment?.payment_method ? parsePaymentMethod(payment.payment_method) : '-'} />
            <ListItem label="Type" value={payment?.payment_application_schedule ? parsePaymentType(payment.payment_application_schedule) : '-'} />
            <ListItem label="Extra To" value={payment?.handling_extra_payments ? parseExtraToward(payment.handling_extra_payments) : '-'} />
            <ListItem label="Id" value={payment?.id ? payment.id : '-'} />

            {!!payment?.source_loan_payment_id && (
              <>
                <Typography sx={{ fontWeight: 700, mt: '16px' }}>Original Payment</Typography>
                <ListItem label="Id" value={payment?.source_loan_payment_id ? payment.source_loan_payment_id : '-'} />
              </>
            )}
          </Grid>
        </Grid>

        {!!payment?.status === 'pending' && (
          <Alert sx={{ mt: '16px' }} severity="info">
            This payment is pending. It will apply when the application date is reached. Until then, the breakdown values will all be $0 and it will not apply
            to the loan.
          </Alert>
        )}

        {!!payment?.status === 'edited' && (
          <Alert sx={{ mt: '16px' }} severity="error">
            This payment has been edited and no longer applies. It is only here for record keeping.
          </Alert>
        )}

        {!!payment?.status === 'reversed' && (
          <Alert sx={{ mt: '16px' }} severity="error">
            This payment has been reversed and no longer applies. It is only here for record keeping.
          </Alert>
        )}

        {!!payment?.status === 'failed' && (
          <Alert sx={{ mt: '16px' }} severity="error">
            This payment failed and no longer applies. It is only here for record keeping.
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DetailsDialog;
