import { METHODS, request } from 'services/axios';

const URIS = {
  CREATE_SOURCE_COMPANY: () => `/servicing/source-companies`,
  FETCH_SOURCE_COMPANIES: () => `/servicing/source-companies`,
};

export default {
  createSourceCompany: ({ data }) =>
    request({
      url: URIS.CREATE_SOURCE_COMPANY(),
      method: METHODS.POST,
      data,
    }),

  fetchSourceCompanies: () =>
    request({
      url: URIS.FETCH_SOURCE_COMPANIES(),
      method: METHODS.GET,
    }),
};
