import { useSearchParams } from 'react-router-dom';

import { SectionContainer, SectionContentContainer, ViewLoanPageLayout } from '../styled-components';
import Payments from './Payments';
import PastDues from './PastDues';
import PaymentPeriods from './PaymentPeriods';
import Fees from './Fees';
import DailyArchive from './DailyArchive';

const Servicing = ({ contractDate, timezone }) => {
  const [searchParams] = useSearchParams();

  const SECTIONS = {
    PAYMENTS: 'payments',
    AUTO_PAY: 'auto-pay',
    FEES: 'fees',
    PAST_DUE: 'past_due',
    FORECAST: 'forecast',
    DAILY_ARCHIVE: 'daily_archive',
    PAYMENT_PERIODS: 'payment_periods',
  };

  const sectionObjs = [
    { label: 'Payments', value: SECTIONS.PAYMENTS, component: <Payments /> },
    { label: 'Auto-Pay', value: SECTIONS.AUTO_PAY },
    { label: 'Past Due', value: SECTIONS.PAST_DUE, component: <PastDues timezone={timezone} /> },
    { label: 'Fees', value: SECTIONS.FEES, component: <Fees /> },
    { label: 'Payment Periods', value: SECTIONS.PAYMENT_PERIODS, component: <PaymentPeriods timezone={timezone} /> },
    { label: 'Daily Archive', fullWidth: true, value: SECTIONS.DAILY_ARCHIVE, component: <DailyArchive contractDate={contractDate} timezone={timezone} /> },
    { label: 'Forecast', value: SECTIONS.FORECAST },
  ];

  const defaultSection = SECTIONS.PAYMENTS;
  const activeSection = searchParams.get('section') || defaultSection;
  const activeComponent = sectionObjs.find((section) => section.value === activeSection)?.component;

  return (
    <ViewLoanPageLayout defaultItem={defaultSection} menuItems={sectionObjs}>
      <SectionContainer>
        <SectionContentContainer fullWidth={sectionObjs.find((section) => section.value === activeSection)?.fullWidth}>
          {activeComponent ? activeComponent : <div>Error</div>}
        </SectionContentContainer>
      </SectionContainer>
    </ViewLoanPageLayout>
  );
};

export default Servicing;
