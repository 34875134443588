import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TrendLogo from './TrakTech-Logo-FullStack-Color.png';
import TrendMobileLogo from './TrakTech-Logo-Favicon-Wht.png';
import { MobileMenu, SideBarMenu } from '../Menu';
import { Sidebar, LogoContainer, NavContainer, MobileHeader } from './styled-components';
import { FlexRow } from 'components/Layouts';
import AvatarMenu from './AvatarMenu';
import FullAccountMenu from './FullAccountMenu';

const AppSidebar = ({ mobileView }) => {
  const user = useSelector((state) => state.user);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const entityType = user?.active_profile?.dealer ? 'dealer' : 'lender';
  const logoLink = !!entityType ? `/app/${entityType}/dashboard` : '/';
  const loggedOut = !user.id;

  if (mobileView) {
    return (
      <MobileHeader>
        <div>
          <IconButton
            size="small"
            color="white"
            onClick={() => setDrawerOpen(!drawerOpen)}
            style={{
              padding: 0,
              marginLeft: '15px',
            }}
          >
            <MenuIcon sx={{ width: 30, height: 30 }} />
          </IconButton>

          <MobileMenu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </div>

        <Link to={logoLink}>
          <FlexRow padding="0" margin="0" justifyContent="center" alignItems="center">
            <img style={{ height: '40px', marginRight: '5px' }} src={TrendMobileLogo} alt="logo" aria-label="Logo" />
          </FlexRow>
        </Link>

        <AvatarMenu />
      </MobileHeader>
    );
  }

  return (
    <Sidebar>
      <LogoContainer>
        <Link to={logoLink}>
          <img style={{ width: '100%' }} src={TrendLogo} alt="logo" aria-label="Logo" />
        </Link>
      </LogoContainer>

      <NavContainer>{!loggedOut && <SideBarMenu />}</NavContainer>

      <FullAccountMenu loggedOut={loggedOut} user={user} />
    </Sidebar>
  );
};

export default AppSidebar;
