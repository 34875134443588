import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';

import { COLORS } from '../../constants/styles';
import { FlexRow, FileContainer } from '../Layouts';

const DropArea = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 2px dashed ${COLORS.borderColor};
  margin: 10px 0px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  background: ${COLORS.backgroundColor};
  ${({ hide }) => hide && `display: none;`}

  ${({ dragging }) =>
    dragging &&
    `
        background: ${COLORS.primaryFaded};
        border-color: ${COLORS.primary}; 
        color: ${COLORS.primary}; 
    `}
`;

const DragAndDropFileUpload = ({ uploadFn, uploading }) => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);

  const onDrop = (event) => {
    event.preventDefault();
    const acceptedFile = event.dataTransfer.files[0];
    const fileType = acceptedFile.type;

    if (fileType.startsWith('image/') || fileType === 'application/pdf') {
      setFile(acceptedFile);
    }

    setDragging(false);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const onDragLeave = () => {
    setDragging(false);
  };

  const onFileSelect = (event) => {
    const acceptedFile = event.target.files[0];
    const fileType = acceptedFile.type;
    if (fileType.startsWith('image/') || fileType === 'application/pdf') {
      setFile(acceptedFile);
    }
  };

  return (
    <Fragment>
      <DropArea onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave} dragging={dragging} hide={!!file}>
        <CloudUploadIcon color="primary" sx={{ fontSize: '50px', marginBottom: '10px' }} />

        <Typography variant="body1" sx={{ color: dragging ? COLORS.primary : 'inherit' }}>
          Drag & Drop, or
        </Typography>

        <Button variant="text" component="label">
          Browse Files
          <input onChange={onFileSelect} hidden accept="image/*,application/pdf" type="file" />
        </Button>
      </DropArea>

      {file && (
        <FileContainer>
          <FlexRow padding="0" margin="0" alignItems="center">
            <InsertDriveFileIcon color="primary" />
            <Typography noWrap variant="body1" style={{ marginLeft: '10px' }}>
              {file.name}
            </Typography>
          </FlexRow>

          {!uploading ? (
            <FlexRow padding="0" margin="0" alignItems="center" justifyContent="flex-end">
              <Button variant="contained" onClick={() => file && uploadFn({ file })}>
                Upload
              </Button>
              <IconButton onClick={() => setFile(null)}>
                <ClearIcon />
              </IconButton>
            </FlexRow>
          ) : (
            <CircularProgress />
          )}
        </FileContainer>
      )}
    </Fragment>
  );
};

export default DragAndDropFileUpload;
