import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { PLAID_AUTH_STATUS } from 'constants/plaid';

const {
  PRE_PLAID,
  PENDING_AUTOMATIC_VERIFICATION,
  PENDING_MANUAL_VERIFICATION,
  AUTOMATICALLY_VERIFIED,
  MANUALLY_VERIFIED,
  VERIFIED,
  VERIFICATION_FAILED,
  VERIFICATION_EXPIRED,
} = PLAID_AUTH_STATUS;

const PaymentProfile = ({ width, data }) => {
  const { xs, sm, md, lg, xl } = width ?? {};
  const { profileType, institutionName, institutionLogo, institutionPrimaryColor, accountType, accountName, accountNumber, routingNumber, verificationStatus } =
    data ?? {};

  return (
    <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardHeader
          sx={{ backgroundColor: institutionPrimaryColor ? institutionPrimaryColor : '#5301FF', minHeight: '60px' }}
          avatar={
            institutionLogo ? (
              <Avatar sx={{ width: 52, height: 52 }} src={`data:image/png;base64, ${institutionLogo}`} aria-label="Bank logo" />
            ) : (
              <Avatar sx={{ width: 52, height: 52, backgroundColor: '#fff' }} aria-label="Bank logo">
                <AccountBalanceIcon sx={{ width: 38, height: 38, color: institutionPrimaryColor ? institutionPrimaryColor : '#5301FF' }} />
              </Avatar>
            )
          }
          title={
            <Typography sx={{ color: '#fff' }} variant="subtitle2" gutterBottom>
              {institutionName}
            </Typography>
          }
          subheader={
            profileType === 1 && [PRE_PLAID, MANUALLY_VERIFIED, AUTOMATICALLY_VERIFIED, VERIFIED].includes(verificationStatus) ? (
              <Stack spacing={1} direction={'row'}>
                <Chip size="small" label="Primary" color="success" />
                {verificationStatus === PRE_PLAID && <Chip size="small" label="Pre-Plaid" color="info" />}
              </Stack>
            ) : verificationStatus === PRE_PLAID ? (
              <Chip size="small" label="Pre-Plaid" color="info" /> // Reason why we consolidate all pending into one chip is if the chip goes too long, the action menu is move off the screen.
            ) : [PENDING_AUTOMATIC_VERIFICATION, PENDING_MANUAL_VERIFICATION].includes(verificationStatus) ? (
              <Chip size="small" label="Pending" color="warning" />
            ) : verificationStatus === VERIFICATION_FAILED ? (
              <Chip size="small" label="Failed" color="error" />
            ) : verificationStatus === VERIFICATION_EXPIRED ? (
              <Chip size="small" label="Expired" color="error" />
            ) : null
          }
        />
        <CardContent>
          <Typography variant="body1" color="text.primary">
            {accountName}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" gutterBottom>
              {accountType ? accountType[0].toUpperCase() + accountType.slice(1) : null}
            </Typography>
          </Typography>

          <Typography variant="body2" color="text.secondary">
            <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" display="inline" gutterBottom>
              Account #:
            </Typography>{' '}
            {accountNumber}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            <Typography sx={{ fontWeight: 600, fontSize: 'inherit' }} variant="caption" display="inline" gutterBottom>
              Routing &nbsp;#:
            </Typography>{' '}
            {routingNumber}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PaymentProfile;
