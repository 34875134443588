import { METHODS, request } from 'services/axios';

const URIS = {
  // Loans Endpoints
  ADD_BANK_PAYMENT_PROFILE: ({ loanId }) => `/servicing/loans/${loanId}/payment-profiles/banks`,
  ADD_CREDIT_CARD_PAYMENT_PROFILE: ({ loanId }) => `/servicing/loans/${loanId}/payment-profiles/credit-cards`,
  LINK_EXISTING_PAYMENT_PROFILE: ({ loanId }) => `/servicing/loans/${loanId}/payment-profiles/connect-existing`,
  UPDATE_PAYMENT_PROFILE_POSITION: ({ loanId, paymentProfileId }) => `servicing/loans/${loanId}/payment-profiles/${paymentProfileId}/profile-type`,
  UNLINK_PAYMENT_PROFILE: ({ loanId, paymentProfileId }) => `/servicing/loans/${loanId}/payment-profiles/${paymentProfileId}`,
  FETCH_LOAN_PAYMENT_PROFILES: ({ loanId }) => `/servicing/loans/${loanId}/payment-profiles`,

  // Customer Endpoints
  UPDATE_PAYMENT_PROFILE: ({ customerId, paymentProfileId }) => `servicing/customers/${customerId}/payment-profiles/banks/${paymentProfileId}`,

  // Utility
  LOOKUP_ROUTING_NUMBER: ({ routingNumber }) => `/servicing/lenders/utility/bank-routing-number-lookup?routingNumber=${routingNumber}`,
};

export default {
  fetchLoanPaymentProfiles: ({ loanId }) =>
    request({
      url: URIS.FETCH_LOAN_PAYMENT_PROFILES({ loanId }),
      method: METHODS.GET,
    }),

  addBankPaymentProfile: ({ loanId, data }) =>
    request({
      url: URIS.ADD_BANK_PAYMENT_PROFILE({ loanId }),
      method: METHODS.POST,
      data,
    }),

  addCreditCardPaymentProfile: ({ loanId, data }) =>
    request({
      url: URIS.ADD_CREDIT_CARD_PAYMENT_PROFILE({ loanId }),
      method: METHODS.POST,
      data,
    }),

  linkExistingPaymentProfile: ({ loanId, data }) =>
    request({
      url: URIS.LINK_EXISTING_PAYMENT_PROFILE({ loanId }),
      method: METHODS.POST,
      data,
    }),

  updatePaymentProfilePosition: ({ loanId, paymentProfileId, data }) =>
    request({
      url: URIS.UPDATE_PAYMENT_PROFILE_POSITION({ loanId, paymentProfileId }),
      method: METHODS.PATCH,
      data,
    }),

  editPaymentProfile: ({ customerId, paymentProfileId, data }) =>
    request({
      url: URIS.UPDATE_PAYMENT_PROFILE({ customerId, paymentProfileId }),
      method: METHODS.PATCH,
      data,
    }),

  unlinkPaymentProfile: ({ loanId, paymentProfileId }) =>
    request({
      url: URIS.UNLINK_PAYMENT_PROFILE({ loanId, paymentProfileId }),
      method: METHODS.DELETE,
    }),

  lookupRoutingNumber: ({ routingNumber }) =>
    request({
      url: URIS.LOOKUP_ROUTING_NUMBER({ routingNumber }),
      method: METHODS.GET,
    }),
};
