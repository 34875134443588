import styled from 'styled-components';

import { COLORS } from './../../constants/styles';

export const StyledNavBar = styled.div`
  padding: 0px 20px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ noBorder }) => !noBorder && `border-bottom: 1px solid ${COLORS.borderColor}`};
  position: relative;
  background: ${COLORS.surfaceColor};
`;

export const StyledBackButton = styled.div`
  margin: 0;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${COLORS.primary};
  }
`;

export const StyledBreadcrumbContainer = styled.div`
  padding: 10px;
  color: ${COLORS.textColor};
  border-left: 1px solid ${COLORS.borderColor};
`;
