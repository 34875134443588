import { Fragment } from 'react';
import InputMask from 'react-input-mask';
import { isValid, parseISO, getYear, formatISO } from 'date-fns';
import get from 'lodash/get';
import creditCardType from 'credit-card-type';
import { NumericFormat } from 'react-number-format';
import { Client, isValidVin } from '@shaggytools/nhtsa-api-wrapper';
import {
  TextField,
  Autocomplete,
  InputLabel,
  MenuItem,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  FormHelperText,
  FormControlLabel,
  Switch,
  Typography,
  InputAdornment,
  Avatar,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import { ApplicantAPI } from 'store/api';
import { formatStringNumber, roundAccurately } from 'constants/formatters';
import { validateSSN } from './validators';

const inputVariant = 'filled';
const baseWidth = '100%';
const baseMargin = '0px';
const size = 'small';

export const TYPES = {
  text: 'text',
  ssnLookup: 'ssnLookup',
  email: 'email',
  vinLookup: 'vinLookup',
  phone: 'tel',
  date: 'date',
  select: 'select',
  basicSelect: 'basicSelect',
  integer: 'integer',
  percentage: 'percentage',
  percentageV2: 'percentageV2',
  currency: 'currency',
  currencyV2: 'currencyV2',
  password: 'password',
  masked: 'masked',
  toggle: 'toggle',
  switch: 'switch',
  OTP: 'OTP',
  creditCard: 'creditCard',
};

const CurrencyV2CustomInput = (props) => (
  <TextField
    {...props}
    InputProps={{
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
  />
);

const PercentageV2CustomInput = (props) => (
  <TextField
    {...props}
    InputProps={{
      startAdornment: <InputAdornment position="start">%</InputAdornment>,
    }}
  />
);

export const INPUTS = {
  // TOGGLE
  [TYPES.toggle]: ({ configuration, values }) => {
    const { dataPath, handleChange, options, inputWidth } = configuration;
    const value = get(values, dataPath);

    return (
      <div style={{ width: inputWidth, padding: 0, margin: 0 }} key={`toggle-${dataPath}`}>
        <ToggleButtonGroup
          fullWidth
          onChange={(event, newVal) => {
            if (newVal !== null) {
              handleChange(dataPath, newVal);
            }
          }}
          color="primary"
          exclusive
          value={value}
        >
          {options &&
            options.map((option) => (
              <ToggleButton key={option.value} value={option.value}>
                {option.label}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </div>
    );
  },

  // SWITCH
  [TYPES.switch]: ({ configuration, values }) => {
    const { dataPath, label, handleChange, explanation } = configuration;
    const value = get(values, dataPath);

    return (
      <Fragment key={`switch-${label}`}>
        <FormControlLabel
          control={
            <Switch
              checked={value || false}
              onChange={(e) => {
                const checked = e.target.checked;
                handleChange(dataPath, checked);
              }}
              name={label}
            />
          }
          label={
            <Typography sx={{ fontWeight: 700 }} variant="body1">
              {label}
            </Typography>
          }
        />
        <Typography sx={{ marginLeft: '47px' }} variant="body2">
          {explanation}
        </Typography>
      </Fragment>
    );
  },

  // VIN LOOKUP
  [TYPES.vinLookup]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleChange, setLoading, handleVinBlur, blurCallback } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <TextField
        id={`${Date.now().toString()}-${Math.random() * 100000}-vin`}
        key={dataPath}
        error={error}
        value={value !== null && value !== undefined ? value : ''}
        label={label}
        required={required}
        helperText={error ? 'Please provide a valid VIN.' : ''}
        onBlur={async () => {
          const valid = isValidVin(value);
          if (!valid) {
            handleVinBlur(dataPath, { year: null, make: null, model: null, vin: value });

            if (blurCallback && typeof blurCallback === 'function') {
              blurCallback(false, null, null, null);
            }
          } else {
            setLoading(true);
            const { Results } = await Client.DecodeVin(value);
            const year = Results && Results.find((res) => res.VariableId === 29);
            const make = Results && Results.find((res) => res.VariableId === 26);
            const model = Results && Results.find((res) => res.VariableId === 28);
            handleVinBlur(dataPath, { year: year?.Value, make: make?.Value, model: model?.Value, vin: value });

            if (blurCallback && typeof blurCallback === 'function') {
              blurCallback(true, year?.Value, make?.Value, model?.Value);
            }

            setLoading(false);
          }
        }}
        onChange={(e) => handleChange(dataPath, e.target.value)}
        variant={inputVariant}
        autoComplete={`${Date.now().toString()}-${Math.random() * 100000}-vin`}
        inputProps={{
          autoComplete: `${Date.now().toString()}-${Math.random() * 100000}-vin`,
        }}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
      />
    );
  },

  // SELECT
  [TYPES.select]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange, options } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <Autocomplete
        options={options}
        value={value}
        key={dataPath}
        autoHighlight
        disablePortal
        onChange={(e, newVal) => handleChange(dataPath, newVal)}
        onBlur={() => handleBlur(dataPath)}
        getOptionLabel={(option) => option?.label ?? option}
        fullWidth
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            error={error}
            helperText={error ? 'Please make a valid selection.' : ''}
            label={label}
            variant={inputVariant}
            required={required}
            key={label}
            id={`${Date.now().toString()}-${Math.random() * 100000}-select`}
            inputProps={{
              ...params.inputProps,
              autoComplete: Date.now().toString(),
            }}
          />
        )}
      />
    );
  },

  // OTP
  [TYPES.OTP]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <InputMask
        key={dataPath}
        maskChar=""
        mask="999999"
        value={value !== null && value !== undefined ? value : ''}
        onBlur={() => handleBlur(dataPath)}
        onChange={(e) => handleChange(dataPath, formatStringNumber(e.target.value))}
      >
        {(inputProps) => (
          <TextField
            id={`${Date.now().toString()}-${Math.random() * 100000}-OTP`}
            type="text"
            error={error}
            label={label}
            required={required}
            helperText={error ? 'Please provide a valid code.' : ''}
            variant={inputVariant}
            style={{
              width: inputWidth || baseWidth,
              margin: margin || baseMargin,
            }}
            inputProps={{
              ...inputProps,
              autoComplete: Date.now().toString(),
            }}
            size={size}
          />
        )}
      </InputMask>
    );
  },

  // INTEGER
  [TYPES.integer]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <NumericFormat
        label={label}
        required={required}
        customInput={TextField}
        value={value !== null && value !== undefined ? value : ''}
        error={error}
        helperText={error ? 'Please provide a valid integer.' : ''}
        onBlur={() => handleBlur(dataPath)}
        key={dataPath}
        variant={inputVariant}
        onValueChange={({ formattedValue, value, floatValue }) => {
          handleChange(dataPath, floatValue);
        }}
        allowNegative={false}
        decimalScale={0}
        thousandSeparator=","
        id={`${Date.now().toString()}-${Math.random() * 100000}-integer`}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
      />
    );
  },

  // PERCENTAGE
  [TYPES.percentage]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <NumericFormat
        label={label}
        required={required}
        customInput={TextField}
        value={value !== null && value !== undefined ? roundAccurately(value * 100, 4) : ''}
        error={error}
        helperText={error ? 'Please provide a valid percentage.' : ''}
        onBlur={() => handleBlur(dataPath)}
        key={dataPath}
        variant={inputVariant}
        onValueChange={({ formattedValue, value, floatValue }) => {
          handleChange(dataPath, roundAccurately(floatValue / 100, 4));
        }}
        allowNegative={false}
        decimalScale={2}
        suffix={'%'}
        id={`${Date.now().toString()}-${Math.random() * 100000}-percentage`}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
      />
    );
  },

  // PERCENTAGE V2
  [TYPES.percentageV2]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <NumericFormat
        label={label}
        required={required}
        customInput={PercentageV2CustomInput}
        value={value !== null && value !== undefined ? roundAccurately(value * 100, 4) : ''}
        error={error}
        helperText={error ? 'Please provide a valid percentage.' : ''}
        onBlur={() => handleBlur(dataPath)}
        key={dataPath}
        variant={inputVariant}
        onValueChange={({ formattedValue, value, floatValue }) => {
          handleChange(dataPath, roundAccurately(floatValue / 100, 4));
        }}
        allowNegative={false}
        decimalScale={2}
        id={`${Date.now().toString()}-${Math.random() * 100000}-percentage`}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
      />
    );
  },

  // CURRENCY
  [TYPES.currency]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <NumericFormat
        label={label}
        required={required}
        customInput={TextField}
        value={value !== null && value !== undefined ? value : ''}
        error={error}
        helperText={error ? 'Please provide a valid number.' : ''}
        onBlur={() => handleBlur(dataPath)}
        key={dataPath}
        variant={inputVariant}
        onValueChange={({ formattedValue, value, floatValue }) => {
          handleChange(dataPath, floatValue);
        }}
        allowNegative={false}
        decimalScale={2}
        prefix={'$'}
        thousandSeparator=","
        id={`${Date.now().toString()}-${Math.random() * 100000}-currency`}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
      />
    );
  },

  // CURRENCY V2
  [TYPES.currencyV2]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange, allowNegative } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <NumericFormat
        label={label}
        required={required}
        customInput={CurrencyV2CustomInput}
        value={value !== null && value !== undefined ? value : ''}
        error={error}
        helperText={error ? 'Please provide a valid number.' : ''}
        onBlur={() => handleBlur(dataPath)}
        key={dataPath}
        variant={inputVariant}
        onValueChange={({ formattedValue, value, floatValue }) => {
          handleChange(dataPath, floatValue);
        }}
        allowNegative={allowNegative ? true : false}
        decimalScale={2}
        thousandSeparator=","
        id={`${Date.now().toString()}-${Math.random() * 100000}-currency`}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
      />
    );
  },

  // MASKED
  [TYPES.masked]: ({ configuration, errors, values }) => {
    const {
      dataPath,
      inputWidth,
      margin,
      label,
      required,
      handleBlur,
      handleChange,
      mask,
      placeholder,
      errorMessage = 'Please provide a valid input.',
    } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <InputMask
        key={dataPath}
        maskChar=""
        mask={mask}
        value={value !== null && value !== undefined ? value : ''}
        onBlur={() => handleBlur(dataPath)}
        onChange={(e) => handleChange(dataPath, formatStringNumber(e.target.value))}
      >
        {(inputProps) => (
          <TextField
            label={label}
            value={value !== null && value !== undefined ? value : ''}
            required={required}
            id={`${Date.now().toString()}-${Math.random() * 100000}-masked`}
            error={error}
            helperText={error ? errorMessage : ''}
            variant={inputVariant}
            style={{
              width: inputWidth || baseWidth,
              margin: margin || baseMargin,
            }}
            inputProps={{
              ...inputProps,
              autoComplete: Date.now().toString(),
              placeholder,
            }}
            size={size}
          />
        )}
      </InputMask>
    );
  },

  // PHONE
  [TYPES.phone]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, readOnly, required, handleBlur, handleChange } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <InputMask
        key={dataPath}
        maskChar=""
        mask="999-999-9999"
        value={value !== null && value !== undefined ? value : ''}
        onBlur={() => handleBlur(dataPath)}
        onChange={(e) => handleChange(dataPath, formatStringNumber(e.target.value))}
      >
        {(inputProps) => (
          <TextField
            id={`${Date.now().toString()}-${Math.random() * 100000}-phone`}
            type="tel"
            error={error}
            label={label}
            required={required}
            helperText={error ? 'Please provide a valid phone number.' : ''}
            variant={inputVariant}
            style={{
              width: inputWidth || baseWidth,
              margin: margin || baseMargin,
            }}
            inputProps={{
              ...inputProps,
              autoComplete: Date.now().toString(),
            }}
            size={size}
            InputProps={{ readOnly }}
          />
        )}
      </InputMask>
    );
  },

  // DATE
  [TYPES.date]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange, openTo, placeholder, views } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    const formattedForPicker = isValid(parseISO(value)) ? parseISO(value) : null;
    return (
      <LocalizationProvider key={dataPath} dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={formattedForPicker !== null ? formattedForPicker : ''}
          onChange={(newValue) => {
            const formattedForDb = isValid(newValue) && getYear(newValue) > new Date().getFullYear() - 110 ? formatISO(newValue) : null;
            handleChange(dataPath, formattedForDb);
          }}
          PopperProps={{ onBlur: () => handleBlur(dataPath) }}
          views={views}
          openTo={openTo ? openTo : 'day'}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                width: inputWidth || baseWidth,
                margin: margin || baseMargin,
              }}
              required={required}
              onBlur={() => {
                handleBlur(dataPath);
              }}
              id={`${Date.now().toString()}-${Math.random() * 100000}-date`}
              size={size}
              variant={inputVariant}
              helperText={error ? 'Please provide a valid date.' : ''}
              error={error}
              inputProps={{
                ...params.inputProps,
                placeholder: placeholder ? placeholder : 'mm/dd/yyyy',
              }}
            />
          )}
        />
      </LocalizationProvider>
    );
  },

  // SSN LOOKUP
  [TYPES.ssnLookup]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange, setLoading, checkedSSNs, handleSSNLookupBlur } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <InputMask
        key={dataPath}
        maskChar=""
        mask="999-99-9999"
        value={value !== null && value !== undefined ? value : ''}
        onChange={(e) => handleChange(dataPath, formatStringNumber(e.target.value))}
        onBlur={async () => {
          const valid = validateSSN(value);

          if (!valid || checkedSSNs[dataPath.split('.')[0]]) {
            handleBlur(dataPath);
          } else {
            setLoading(true);

            const { applicant } = await ApplicantAPI.fetchApplicantBySSN({ data: { ssn: value } });
            const {
              first_name,
              last_name,
              email,
              phone,
              dob,
              ssn,
              address_1,
              address_2,
              city,
              state,
              zip,
              address_months_at,
              employment_status,
              employer,
              employer_phone,
              title,
              employer_months_at,
              annual_income,
              home_ownership,
              home_monthly_payment,
            } = applicant ? applicant : {};

            handleSSNLookupBlur(
              dataPath,
              applicant
                ? {
                    first_name,
                    last_name,
                    email,
                    phone,
                    dob,
                    ssn,
                    address_1,
                    address_2,
                    city,
                    state,
                    zip,
                    address_months_at,
                    employment_status,
                    employer,
                    employer_phone,
                    title,
                    employer_months_at,
                    annual_income,
                    home_ownership,
                    home_monthly_payment,
                  }
                : null,
            );
            setLoading(false);
          }
        }}
      >
        {(inputProps) => (
          <TextField
            id={`${Date.now().toString()}-${Math.random() * 100000}-ssn-lookup`}
            key={dataPath}
            error={error}
            value={value !== null && value !== undefined ? value : ''}
            label={label}
            required={required}
            helperText={error ? 'Please provide a valid ssn.' : ''}
            variant={inputVariant}
            autoComplete={`${Date.now().toString()}-${Math.random() * 100000}-ssn-lookup`}
            inputProps={{
              ...inputProps,
              autoComplete: `${Date.now().toString()}-${Math.random() * 100000}-ssn-lookup`,
            }}
            style={{
              width: inputWidth || baseWidth,
              margin: margin || baseMargin,
            }}
            size={size}
          />
        )}
      </InputMask>
    );
  },

  // EMAIL
  [TYPES.email]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, readOnly, required, handleBlur, handleChange } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <TextField
        id={`${Date.now().toString()}-${Math.random() * 100000}-email`}
        key={dataPath}
        error={error}
        value={value !== null && value !== undefined ? value : ''}
        label={label}
        required={required}
        helperText={error ? 'Please provide a valid email.' : ''}
        onBlur={async () => handleBlur(dataPath)}
        onChange={(e) => handleChange(dataPath, e.target.value)}
        variant={inputVariant}
        autoComplete={`${Date.now().toString()}-${Math.random() * 100000}-email`}
        inputProps={{
          autoComplete: `${Date.now().toString()}-${Math.random() * 100000}-email`,
        }}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
        InputProps={{ readOnly }}
      />
    );
  },

  // TEXTFIELD
  [TYPES.text]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, readOnly, required, handleBlur, handleChange, additionalBlurFunction } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    const onBlur = () => {
      if (additionalBlurFunction && typeof additionalBlurFunction === 'function') additionalBlurFunction(value);
      handleBlur(dataPath);
    };

    return (
      <TextField
        id={`${Date.now().toString()}-${Math.random() * 100000}-textfield`}
        key={dataPath}
        error={error}
        value={value !== null && value !== undefined ? value : ''}
        label={label}
        required={required}
        helperText={error ? 'Please provide a valid value.' : ''}
        onBlur={onBlur}
        onChange={(e) => handleChange(dataPath, e.target.value)}
        variant={inputVariant}
        autoComplete={`${Date.now().toString()}-${Math.random() * 100000}-textfield`}
        inputProps={{
          autoComplete: `${Date.now().toString()}-${Math.random() * 100000}-textfield`,
        }}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
        InputProps={{ readOnly }}
      />
    );
  },

  // BASIC SELECT
  [TYPES.basicSelect]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, required, handleBlur, handleChange, options, disabled } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);

    return (
      <FormControl
        key={dataPath}
        required={required}
        error={error}
        variant={inputVariant}
        disabled={!!disabled}
        style={{
          width: inputWidth || baseWidth,
          margin: margin || baseMargin,
        }}
        size={size}
        fullWidth
      >
        <InputLabel>{label}</InputLabel>

        <Select
          value={value !== null && value !== undefined ? value : ''}
          onChange={(e) => handleChange(dataPath, e.target.value)}
          onBlur={() => handleBlur(dataPath)}
          label={label}
          error={error}
          key={dataPath}
          id={`${Date.now().toString()}-${Math.random() * 100000}-selectBasic`}
          size={size}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText>{error ? 'Please make a valid selection.' : ''}</FormHelperText>
      </FormControl>
    );
  },

  // Credit Card
  [TYPES.creditCard]: ({ configuration, errors, values }) => {
    const { dataPath, inputWidth, margin, label, readOnly, required, handleBlur, handleChange, mask, placeholder } = configuration;
    const value = get(values, dataPath);
    const error = get(errors, dataPath);
    const cardType = value ? creditCardType(value) : [];

    return (
      <InputMask
        key={dataPath}
        maskChar=""
        mask={mask}
        value={value !== null && value !== undefined ? value : ''}
        onBlur={async () => handleBlur(dataPath)}
        onChange={(e) => handleChange(dataPath, e.target.value.replace(/\s/g, ''))} // Trim off all white spaces
      >
        {(inputProps) => (
          <TextField
            id={`${Date.now().toString()}-${Math.random() * 100000}-credit-card`}
            key={dataPath}
            error={error}
            value={value !== null && value !== undefined ? value : ''}
            label={label}
            required={required}
            helperText={error ? 'Please provide a valid credit card number.' : ''}
            variant={inputVariant}
            autoComplete={`${Date.now().toString()}-${Math.random() * 100000}-credit-card`}
            inputProps={{
              ...inputProps,
              autoComplete: `${Date.now().toString()}-${Math.random() * 100000}-credit-card`,
              placeholder,
            }}
            style={{
              width: inputWidth || baseWidth,
              margin: margin || baseMargin,
            }}
            size={size}
            InputProps={{
              readOnly,
              endAdornment: (
                <InputAdornment position="end">
                  {cardType.length <= 0 ? (
                    <CreditCardOutlinedIcon />
                  ) : cardType[0].niceType === 'American Express' ? (
                    <Avatar src="/images/american_express.svg" aria-label="Bank logo" />
                  ) : cardType[0].niceType === 'Visa' ? (
                    <Avatar src="/images/visa.svg" aria-label="Bank logo" />
                  ) : cardType[0].niceType === 'Mastercard' ? (
                    <Avatar src="/images/master_card.svg" aria-label="Bank logo" sx={{ width: '46px' }} />
                  ) : cardType[0].niceType === 'Discover' ? (
                    <Avatar src="/images/discover.svg" variant="square" aria-label="Bank logo" />
                  ) : cardType[0].niceType === 'JCB' ? (
                    <Avatar src="/images/jcb.svg" variant="square" aria-label="Bank logo" sx={{ width: '46px' }} />
                  ) : cardType[0].niceType === 'Diners Club' ? (
                    <Avatar src="/images/diners-club.svg" aria-label="Bank logo" />
                  ) : (
                    <CreditCardOutlinedIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      </InputMask>
    );
  },
};
