import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_LOAN_METRICS: ({ loanId }) => `/servicing/loans/${loanId}/metrics`,
};

export default {
  fetchLoanMetrics: ({ queryString, loanId }) =>
    request({
      url: `${URIS.FETCH_LOAN_METRICS({ loanId })}${queryString || ''}`,
      method: METHODS.GET,
    }),
};
