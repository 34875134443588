import { IconButton, Tabs, Tab, Button, Tooltip } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import { useState } from 'react';

export const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: ${({ padding }) => padding || '10px 20px 20px'};
  margin: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabsContainer = styled.div`
  width: 100%;
  margin: 8px 0px 0px;
  padding: 0px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.borderColor};
`;

export const SectionContentContainer = styled.div`
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '1080px')};
`;

export const SidebarContainer = styled.div`
  width: ${({ visible }) => (visible ? '180px' : '0px')};
  box-sizing: border-box;
  height: 100%;
  margin: ${({ visible }) => (visible ? '0px' : '0px 20px 0px 0px')};
  position: relative;
  transition: all 0.3s ease-in-out;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: ${({ sidebarVisible }) => (sidebarVisible ? 'calc(100% - 180px)' : 'calc(100% - 40px)')};
  margin: 0px;
  box-sizing: border-box;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
`;

export const ViewLoanPageLayout = ({ menuItems, defaultItem, children }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const section = searchParams.get('section');

  const numberOfTabs = menuItems.length;
  const heightOfTabs = numberOfTabs * 32;

  const handleTabChange = (e, newValue) => {
    setSearchParams({ section: newValue });
  };

  return (
    <FlexRow height="calc(100vh - 126px)" padding="0px" margin="0px">
      <SidebarContainer visible={showSidebar}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={section || defaultItem}
          onChange={handleTabChange}
          sx={{
            '& .MuiTabs-indicator': { display: 'none' },
            '& .Mui-selected': { backgroundColor: COLORS.primaryFaded },
            borderRight: `1px solid ${COLORS.borderColor}`,
            p: showSidebar ? '10px 5px' : '10px 10px',
            transition: 'padding 0.3s ease-in-out',
          }}
        >
          {menuItems.map((item) => (
            <Tab
              sx={{
                textTransform: 'none',
                borderRadius: '8px',
                minHeight: '32px',
                p: '0px 5px',
                '&:hover': { backgroundColor: COLORS.primaryFaded, color: COLORS.primary },
                alignItems: 'start',
                opacity: showSidebar ? 100 : 0,
                transition: 'opacity 0.3s ease-in-out',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              key={`${item.label}-tab`}
              label={item.label}
              value={item.value}
            />
          ))}
        </Tabs>
        <Tooltip title={showSidebar ? 'Collapse' : 'Expand'} placement="right">
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              position: 'absolute',
              top: `${heightOfTabs}px`,
              right: showSidebar ? '-16px' : '-36px',
              background: COLORS.surfaceColor,
              '&:hover, &.Mui-focusVisible': { boxShadow: COLORS.boxShadow, background: COLORS.surfaceColor },
              transition: 'all 0.3s ease-in-out',
            }}
            variant="contained"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <ChevronLeftIcon sx={{ transform: !showSidebar ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.3s ease-in-out' }} />
          </IconButton>
        </Tooltip>
      </SidebarContainer>
      <ContentContainer sidebarVisible={showSidebar}>{children}</ContentContainer>
    </FlexRow>
  );
};
