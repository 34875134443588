import { createAsyncThunk } from '@reduxjs/toolkit';

import { LenderAPI } from 'store/api';

export const fetchLoansThunk = createAsyncThunk(
  'lender/fetchLoans',
  async ({ subdata = [], status = [], frequency, active = true, limit = 30, sort = 'created_at|ASC', offset = 1 }, thunkAPI) => {
    try {
      let queryString = `?limit=${limit}&sort=${sort}&offset=${offset}&active=${active}`;

      if (subdata && subdata?.length && subdata.length > 0) {
        queryString = `${queryString}&subdata=${subdata.join(',')}`;
      }

      if (status && status?.length && status.length > 0) {
        queryString = `${queryString}&status=${status.join(',')}`;
      }

      if (frequency && frequency?.length && frequency.length > 0) {
        queryString = `${queryString}&frequency=${frequency.join(',')}`;
      }

      const { loans, metadata } = await LenderAPI.fetchLoans({ queryString });

      /**
       * This will dispatch action creator with type of "lender/fetchLoans/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return { loans, metadata };
    } catch (err) {
      /**
       * This will dispatch action creator with type of "lender/fetchLoans/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);
