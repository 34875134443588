import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import SyncIcon from '@mui/icons-material/Sync';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AddIcon from '@mui/icons-material/Add';

import { currencyFormatter, percentageFormatter } from 'constants/formatters';
import { STATUS_LABELS } from 'constants/status';
import { updateLendingOption } from 'store/sagas/applications';
import { Card, FlexRow } from 'components/Layouts';
import ProposeLendingOptionModal from './ProposeLendingOptionModal';

const { SAVED, PROCESSING, REFERRED, APPROVED } = STATUS_LABELS;

const rowStyle = {
  width: '20%',
  margin: '0',
  padding: '0',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const headerStyle = {
  ...rowStyle,
  fontWeight: 700,
};

const LendingOptions = ({ lendingOptions, canEdit, applicationStatus }) => {
  const user = useSelector((state) => state.user);
  const active_profile = useSelector((state) => state.user?.active_profile);

  const dispatch = useDispatch();
  const { id: applicationId } = useParams();

  const [modalOpen, setModalOpen] = useState(false);

  const rows =
    lendingOptions && lendingOptions.length
      ? lendingOptions
          .map(({ id, selected_by_dealer, max_amount_loaned, min_percent_down, term, apr, approved_by_lender }) => ({
            id,
            selected_by_dealer,
            approved_by_lender,
            max_amount_loaned,
            min_percent_down,
            term,
            apr,
          }))
          .sort((a, b) => a.term - b.term)
      : [];

  const selectLendingOption = ({ id, selected }) => {
    if (canEdit) {
      dispatch(updateLendingOption({ applicationId, lendingOptionId: id.toString(), selected_by_dealer: selected, dealer_id: user.active_profile.dealer_id }));
    }
  };

  const handleCheck = ({ e, id, selected }) => {
    e.stopPropagation();
    e.preventDefault();
    selectLendingOption({ id, selected });
  };

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="Lending options.">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Lending Options
        </Typography>

        {[SAVED, PROCESSING, REFERRED, APPROVED].includes(applicationStatus) ? (
          <Button onClick={() => setModalOpen(true)} startIcon={<AddIcon />}>
            Request
          </Button>
        ) : null}
      </FlexRow>

      <List>
        <ListItem sx={{ padding: '5px 10px 5px !important' }}>
          <FlexRow padding="0" margin="0" justifyContent="space-between">
            {[
              { title: 'Selected', style: { ...headerStyle, width: '85px' } },
              { title: 'Max $ Financed' },
              { title: 'Down Payment %' },
              { title: 'Term' },
              { title: 'APR' },
            ].map(({ title, style }, i) => (
              <Typography key={`${title}-${i}`} variant="body2" component="div" style={style || headerStyle}>
                {title}
              </Typography>
            ))}
          </FlexRow>
        </ListItem>

        <Divider />

        {rows.map((row) => (
          <Fragment key={`lending-option-${row.id}`}>
            <Tooltip
              placement="left"
              title={row.approved_by_lender === null ? 'Pending lender review' : row.approved_by_lender === false ? 'Rejected by lender' : ''}
            >
              <ListItem disablePadding>
                <ListItemButton
                  selected={row.selected_by_dealer}
                  disabled={!row.approved_by_lender || !canEdit}
                  onClick={() => !row.selected_by_dealer && selectLendingOption({ id: row.id, selected: true })}
                  sx={{ padding: '10px !important' }}
                >
                  <FlexRow padding="0" margin="0" justifyContent="space-between">
                    <Typography variant="body1" component="div" sx={{ ...rowStyle, width: '85px' }}>
                      {row.approved_by_lender === null ? (
                        <SyncIcon />
                      ) : row.approved_by_lender === false ? (
                        <DoDisturbIcon color={'error'} />
                      ) : (
                        <Checkbox
                          sx={{ padding: 0 }}
                          checked={!!row.selected_by_dealer}
                          onClick={(e) => !row.selected_by_dealer && handleCheck({ e, id: row.id, selected: true })}
                        />
                      )}
                    </Typography>

                    <Typography variant="body1" component="div" sx={rowStyle}>
                      {currencyFormatter.format(row.max_amount_loaned)}
                    </Typography>

                    <Typography variant="body1" component="div" sx={rowStyle}>
                      {percentageFormatter.format(row.min_percent_down)}
                    </Typography>

                    <Typography variant="body1" component="div" sx={rowStyle}>
                      {row.term}
                    </Typography>

                    <Typography variant="body1" component="div" sx={rowStyle}>
                      {percentageFormatter.format(row.apr)}
                    </Typography>
                  </FlexRow>
                </ListItemButton>
              </ListItem>
            </Tooltip>
            <Divider />
          </Fragment>
        ))}
      </List>

      {modalOpen && (
        <ProposeLendingOptionModal applicationId={applicationId} dealerId={active_profile?.dealer_id} open={modalOpen} onClose={() => setModalOpen(false)} />
      )}
    </Card>
  );
};

export default LendingOptions;
