import { METHODS, request } from 'services/axios';

const URIS = {
  QUERY_CUSTOMERS: ({ ssn }) => `/servicing/customers?ssn=${ssn}`,
  FETCH_CUSTOMER: ({ customerId }) => `/servicing/customers/${customerId}?subdata=addresses,payment-profiles`,
  FETCH_LOAN_CUSTOMERS: ({ loanId }) => `/servicing/loans/${loanId}/customers`,
  CREATE_CUSTOMER_ADDRESS: ({ customerId }) => `/servicing/customers/${customerId}/addresses`,
  UPDATE_CUSTOMER_ADDRESS: ({ customerId, addressId }) => `/servicing/customers/${customerId}/addresses/${addressId}`,
  CREATE_CUSTOMER_EMPLOYMENT: ({ customerId }) => `/servicing/customers/${customerId}/employments`,
};

export default {
  createCustomerAddress: ({ customerId, data }) =>
    request({
      url: URIS.CREATE_CUSTOMER_ADDRESS({ customerId }),
      method: METHODS.POST,
      data,
    }),

  createCustomerEmployment: ({ customerId, data }) =>
    request({
      url: URIS.CREATE_CUSTOMER_EMPLOYMENT({ customerId }),
      method: METHODS.POST,
      data,
    }),

  fetchCustomer: ({ customerId }) =>
    request({
      url: URIS.FETCH_CUSTOMER({ customerId }),
      method: METHODS.GET,
    }),

  fetchLoanCustomers: ({ loanId, queryString }) =>
    request({
      url: `${URIS.FETCH_LOAN_CUSTOMERS({ loanId })}${queryString || ''}`,
      method: METHODS.GET,
    }),

  editCustomer: ({ customerId, data }) =>
    request({
      url: URIS.FETCH_CUSTOMER({ customerId }),
      method: METHODS.PATCH,
      data,
    }),

  editCustomerAddress: ({ customerId, addressId, data }) =>
    request({
      url: URIS.UPDATE_CUSTOMER_ADDRESS({ customerId, addressId }),
      method: METHODS.PATCH,
      data,
    }),

  fetchCustomerAddresses: ({ customerId }) =>
    request({
      url: URIS.CREATE_CUSTOMER_ADDRESS({ customerId }),
      method: METHODS.GET,
    }),

  queryCustomers: ({ ssn }) =>
    request({
      url: URIS.QUERY_CUSTOMERS({ ssn }),
      method: METHODS.GET,
    }),
};
