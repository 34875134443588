import { ActionMenu } from 'components/Menu';
import { COLORS } from 'constants/styles';
import { formatDateOnly, formatCurrencyForDataGrid } from 'constants/formatters';

export const PAYMENT_APPLICATION_SCHEDULES = {
  IPF: 'ipf',
  IFP: 'ifp',
  FIP: 'fip',
  INTEREST_ONLY: 'interest-only',
  FEES_ONLY: 'fees-only',
  PRINCIPAL_ONLY: 'principal-only',
};

export const PAYMENT_METHODS = {
  CASH: 'cash',
  CHECK: 'check',
  CREDIT_DEBIT: 'credit/debit',
  EFT: 'eft',
  PAYROLL: 'payroll',
  OTHER: 'other',
};

export const EXTRA_TOWARD = {
  PRINCIPAL_ONLY: 'principal-only',
};

export const paymentMethodMap = [
  { value: PAYMENT_METHODS.CASH, label: 'Cash' },
  { value: PAYMENT_METHODS.CHECK, label: 'Check' },
  { value: PAYMENT_METHODS.CREDIT_DEBIT, label: 'Credit/Debit Card' },
  { value: PAYMENT_METHODS.EFT, label: 'EFT' },
  { value: PAYMENT_METHODS.PAYROLL, label: 'Payroll' },
  { value: PAYMENT_METHODS.OTHER, label: 'Other' },
];

export const parseExtraToward = (val) => {
  if (val === EXTRA_TOWARD.PRINCIPAL_ONLY) return 'Principal Only';
  else return '';
};

export const parsePaymentType = (type) => {
  if (type === PAYMENT_APPLICATION_SCHEDULES.IPF) return 'IPF';
  else if (type === PAYMENT_APPLICATION_SCHEDULES.IFP) return 'IFP';
  else if (type === PAYMENT_APPLICATION_SCHEDULES.FIP) return 'FIP';
  else if (type === PAYMENT_APPLICATION_SCHEDULES.INTEREST_ONLY) return 'Interest Only';
  else if (type === PAYMENT_APPLICATION_SCHEDULES.FEES_ONLY) return 'Fees Only';
  else if (type === PAYMENT_APPLICATION_SCHEDULES.PRINCIPAL_ONLY) return 'Principal Only';
  else return '';
};

export const parsePaymentStatus = (status) => {
  if (status === 'processed') return 'Processed';
  else if (status === 'pending') return 'Pending';
  else if (status === 'failed') return 'Failed';
  else if (status === 'edited') return 'Edited';
  else if (status === 'reversed') return 'Reversed';
  else return '-';
};

export const parsePaymentMethod = (method) => {
  if (!method) return '';
  return paymentMethodMap.find((obj) => obj.value === method)?.label;
};

export const createColumns = ({ showDetails, editPayment, reversePayment }) => [
  {
    field: 'date',
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    headerName: 'Date',
    valueFormatter: (params) => formatDateOnly(params.value),
  },
  {
    field: 'amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Amount',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'interestAmount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Interest',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'principalAmount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Principal',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'feesAmount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Fees',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'method',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Method',
    flex: 1,
    valueFormatter: (props) => parsePaymentMethod(props.value),
  },
  {
    field: 'type',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Type',
    flex: 1,
    valueFormatter: (props) => parsePaymentType(props.value),
  },
  {
    field: 'status',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Status',
    flex: 1,
    valueFormatter: (props) => parsePaymentStatus(props.value),
  },
  {
    field: 'action',
    headerName: '',
    editable: false,
    width: 50,
    renderCell: (props) => {
      if (props.row.status === 'edited' || props.row.status === 'reversed' || props.row.status === 'failed') return null;

      const options = [
        { value: 'View Details', callback: () => showDetails(props.id) },
        { value: 'Edit', callback: () => editPayment(props) },
        { value: 'Reverse', callback: () => reversePayment(props.id) },
      ];
      return <ActionMenu options={options} />;
    },
  },
];

export const gridSx = {
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
  '& .payment-theme--edited': {
    backgroundColor: COLORS.disabledColor,
    color: COLORS.fadedTextColor,
    '&:hover': {
      backgroundColor: COLORS.disabledColor,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.disabledColor,
      '&:hover': {
        backgroundColor: COLORS.disabledColor,
      },
    },
  },
  '& .payment-theme--reversed': {
    backgroundColor: COLORS.fadedError,
    color: COLORS.fadedTextColor,
    '&:hover': {
      backgroundColor: COLORS.fadedError,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.fadedError,
      '&:hover': {
        backgroundColor: COLORS.fadedError,
      },
    },
  },
  '& .payment-theme--failed': {
    backgroundColor: COLORS.fadedError,
    color: COLORS.fadedTextColor,
    '&:hover': {
      backgroundColor: COLORS.fadedError,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.fadedError,
      '&:hover': {
        backgroundColor: COLORS.fadedError,
      },
    },
  },
  '& .payment-theme--pending': {
    backgroundColor: COLORS.fadedInfo,
    '&:hover': {
      backgroundColor: COLORS.fadedInfo,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.fadedInfo,
      '&:hover': {
        backgroundColor: COLORS.fadedInfo,
      },
    },
  },
};
