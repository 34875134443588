import styled from 'styled-components';

import { COLORS } from '../../constants/styles';

export const LogoContainer = styled.div`
  padding: 0px;
  margin: 0px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.borderColor};
`;

export const NavContainer = styled.div`
  padding: 8px;
  margin: 0px;
  height: calc(100% - 80px - 64px);
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AccountContainer = styled.div`
  padding: 8px;
  margin: 0px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  gap: 8px;
  ${({ loggedOut }) => !loggedOut && `cursor: pointer;`}
  ${({ loggedOut }) => !loggedOut && `border-top: 1px solid ${COLORS.borderColor};`}

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: background 0.3s;
  }
`;

export const Sidebar = styled.div`
  width: 240px;
  z-index: 1;
  position: fixed;
  box-sizing: border-box;
  height: 100%;
  border-right: 1px solid ${COLORS.borderColor};
`;

export const SidebarItemContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  gap: 16px;
  border-radius: 8px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.75);
  ${({ child }) => child && `padding: 8px 8px 8px 24px;`}
  ${({ parent }) => parent && `justify-content: space-between;`}
  overflow: hidden;
  transition: background 0.2 ease-in-out;

  ${({ isActive }) =>
    isActive &&
    `
    color: ${COLORS.primary};
    background: ${COLORS.primaryFaded};
    border: 1px solid ${COLORS.primary};
    // border-left: 5px solid ${COLORS.primary};
  `}

  &:hover {
    ${({ isActive }) => !isActive && `background: ${COLORS.primaryFaded};`}
  }

  &:before {
    ${({ isActive, child }) =>
      isActive &&
      `
      content: '';
      width: 6px;
      height: 28px;
      margin: ${child ? '-8px 4px -8px -27px' : '-8px -13px -8px -11px'};
      background-color: ${COLORS.primary};
      z-index: 0;
      border-radius: 8px;
    `}
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${COLORS.gradientBackground};
  background: ${COLORS.gradientPrimary};
  color: ${COLORS.primaryColorContrastText};
  padding: 8px;
  z-index: 100;
  position: fixed;
  box-sizing: border-box;
  height: 50px;
`;
