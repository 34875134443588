import { useContext, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import ReferencesContext from './context';
import NewReferenceFormModal from './NewReferenceFormModal';

const AddNewReference = ({ currentApplication }) => {
  const { id: applicationId } = currentApplication;

  const { referencesSectionProcessing, setReferencesSectionProcessing } = useContext(ReferencesContext);

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
        <div
          style={{
            padding: '20px 0',
            boxSizing: 'border-box',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F4F4F4',
            height: '100%',
            borderRadius: '5px',
          }}
        >
          <IconButton
            disabled={referencesSectionProcessing || showModal}
            size="large"
            color="primary"
            sx={{ fontSize: '5rem' }}
            onClick={async () => {
              setShowModal(true);
              setReferencesSectionProcessing(true);
            }}
          >
            {showModal ? <CircularProgress size="5rem" fontSize="inherit" /> : <AddCircleIcon fontSize="inherit" />}
          </IconButton>
        </div>
      </Grid>

      {showModal && (
        <NewReferenceFormModal
          applicationId={applicationId}
          onClose={() => {
            setShowModal(false);
            setReferencesSectionProcessing(false);
          }}
        />
      )}
    </>
  );
};

export default AddNewReference;
