import Typography from '@mui/material/Typography';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import Alert from '@mui/material/Alert';

import { Card, FlexRow } from 'components/Layouts';
import { formatPhoneNumber } from 'constants/formatters';

const ApplicantSection = ({ applicant, margin }) => {
  return (
    <>
      <Typography style={{ marginTop: margin ? '20px' : 0 }} variant="h6">{`${applicant?.first_name} ${applicant.last_name}`}</Typography>

      <div style={{ padding: '5px 0px', marginLeft: '10px', boxSizing: 'border-box', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <PhoneIphoneOutlinedIcon fontSize="small" />
        <Typography style={{ marginLeft: '10px' }} variant="body1">
          {applicant?.phone && formatPhoneNumber(applicant.phone)}
        </Typography>
        {applicant?.phone_verified ? (
          <VerifiedIcon style={{ marginLeft: '10px' }} color="primary" fontSize="small" />
        ) : (
          <NewReleasesOutlinedIcon style={{ marginLeft: '10px' }} color="error" fontSize="small" />
        )}
      </div>

      <div style={{ padding: '5px 0px', marginLeft: '10px', boxSizing: 'border-box', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <MailOutlinedIcon fontSize="small" />
        <Typography style={{ marginLeft: '10px' }} variant="body1">
          {applicant?.email}
        </Typography>
        {applicant?.email_verified ? (
          <VerifiedIcon style={{ marginLeft: '10px' }} color="primary" fontSize="small" />
        ) : (
          <NewReleasesOutlinedIcon style={{ marginLeft: '10px' }} color="error" fontSize="small" />
        )}
      </div>
    </>
  );
};

const Verify = ({ hasCoApplicant, applicant, coApplicant }) => {
  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="Verification.">
      <FlexRow padding="0" margin="0px 0px 20px" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Verify Contact Information
        </Typography>
      </FlexRow>

      <Alert severity="info" sx={{ mb: '20px' }}>{`The ${
        hasCoApplicant ? "applicants'" : "applicant's"
      } contact information must be verified before they can proceed to signing.`}</Alert>

      <ApplicantSection applicant={applicant} />
      {hasCoApplicant && <ApplicantSection margin applicant={coApplicant} />}
    </Card>
  );
};

export default Verify;
