import Typography from '@mui/material/Typography';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { format, parseISO } from 'date-fns';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { Card, FlexRow, DataCard } from 'components/Layouts';
import { currencyFormatter } from 'constants/formatters';

const ContractInformation = ({ formie, allValues, incompleteActiveRequiredFields, progress }) => {
  const {
    contract_values: { amount_financed, apr, payment_amount, total_down_payment, cash_down, required_amount_down },
    dates: { payment_start_date },
    helper_values: { net_trade_in, total_down_fits_approval, amount_financed_fits_approval, max_amount_loaned },
  } = allValues;
  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Contract Information
        </Typography>
      </FlexRow>

      {formie.buildFields({
        fields: [{ dataPath: 'dates.contract_date' }, { dataPath: 'helper_values.days_to_first_payment' }],
        fieldsPerRow: 2,
      })}

      {amount_financed && apr && payment_amount && payment_start_date ? (
        <FlexRow margin="12px 0px 0px" padding="0px" alignItems="center" justifyContent="space-between" flexWrap>
          <DataCard icon={<AttachMoneyIcon />} label="Amount Financed" value={currencyFormatter.format(amount_financed)} />
          <DataCard icon={<CurrencyExchangeIcon />} label="Monthly Payment" value={currencyFormatter.format(payment_amount)} />
          <DataCard icon={<CalendarTodayIcon />} label="First Payment Date" value={format(parseISO(payment_start_date), 'MM/dd/yyyy')} />
          <FlexRow flexColumn margin="12px 0px 0px" padding="0px" gap="12px 0px">
            {!amount_financed_fits_approval && (
              <FlexRow margin="0" padding="0">
                <Alert sx={{ width: '100%' }} severity="error">
                  <AlertTitle>Amount Financed</AlertTitle>
                  {`The amount financed of ${currencyFormatter.format(amount_financed)} is greater than the max approved amount of ${currencyFormatter.format(
                    max_amount_loaned,
                  )}.`}
                </Alert>
              </FlexRow>
            )}
            {!total_down_fits_approval && (
              <FlexRow margin="0" padding="0">
                <Alert sx={{ width: '100%' }} severity="error">
                  <AlertTitle>Down Payment</AlertTitle>
                  {`The total down payment of ${currencyFormatter.format(total_down_payment)} (${currencyFormatter.format(
                    cash_down,
                  )} cash down payment and ${currencyFormatter.format(
                    net_trade_in,
                  )} trade equity) is less than the required amount of ${currencyFormatter.format(required_amount_down)}.`}
                </Alert>
              </FlexRow>
            )}
          </FlexRow>
        </FlexRow>
      ) : (
        <FlexRow margin="12px 0px 0px" padding="0">
          <Alert sx={{ width: '100%' }} severity="error">
            <AlertTitle>Finish Selections</AlertTitle>
            Please make sure you've selected a contract date and a lending option in order to see the contract values.
          </Alert>
        </FlexRow>
      )}
      {progress < 1 && (
        <FlexRow margin="12px 0px 0px" padding="0">
          <Alert sx={{ width: '100%' }} severity="error">
            <AlertTitle>Required Fields</AlertTitle>
            {`The following field(s) are incomplete or invalid: ${incompleteActiveRequiredFields.map((field) => field.label).join(', ')}.`}
          </Alert>
        </FlexRow>
      )}
    </Card>
  );
};

export default ContractInformation;
