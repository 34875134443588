import { TYPES } from 'components/Formie/inputs';
import { stringNotEmpty, validateNotNull } from 'components/Formie/validators';

export const paymentProfileFormConfig = {
  account_type: {
    type: TYPES.basicSelect,
    label: 'Account Type',
    required: true,
    validator: validateNotNull,
    dataPath: 'account_type',
    options: [
      { value: 'checking', label: 'Checking' },
      { value: 'savings', label: 'Savings' },
    ],
  },
  institution_name: {
    type: TYPES.text,
    label: 'Bank Name', // Institution Name
    required: true,
    validator: stringNotEmpty,
    dataPath: 'institution_name',
  },
  account_name: {
    type: TYPES.text,
    label: 'Name on Account',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'account_name',
  },
  account_number: {
    type: TYPES.text,
    label: 'Account Number',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'account_number',
  },
  routing_number: {
    type: TYPES.text,
    label: 'Routing Number',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'routing_number',
  },
};

export const initialValues = {
  account_type: null,
  institution_name: null,
  account_name: null,
  account_number: null,
  routing_number: null,
};
