import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { generateApplicationLinkTokenThunk } from 'store/thunks/application';
import PaymentProfilesContext from '../context';
import PlaidLink from '../PlaidLink';

const ACCOUNT_OWNER = {
  APPLICANT: 'applicant',
  CO_APPLICANT: 'co-applicant',
};

const PaymentProfileOwnerModal = ({ currentApplication, open, onClose, onInputPaymentProfileDataSuccess, onPaymentProfileConnectSuccess }) => {
  const applicant = currentApplication?.applicant;
  const coApplicant = currentApplication?.co_applicant;
  const applicationId = currentApplication?.id;

  const dispatch = useDispatch();

  const { setPaymentProfilesSectionErrorMsg, setShowPaymentProfilesSectionError } = useContext(PaymentProfilesContext);

  const [accountOwner, setAccountOwner] = useState(ACCOUNT_OWNER.APPLICANT);
  const [fetchingLinkToken, setFetchingLinkToken] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [plaidLinkOpened, setPlaidLinkOpened] = useState(false);

  return (
    <>
      {!plaidLinkOpened && (
        <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={open}>
          <DialogTitle>
            <Stack direction={'row'} spacing={1}>
              <AccountBoxIcon sx={{ fontSize: '2rem' }} />
              <Typography variant="h6">Who Owns The Account?</Typography>
            </Stack>
          </DialogTitle>

          <DialogContent>
            <Typography>
              By adding a payment profile, you acknowledge that the account name of the payment profile to be added is the same name as the owner of the
              account.
            </Typography>

            <RadioGroup defaultValue="applicant" name="radio-buttons-group">
              <FormControlLabel
                disabled={fetchingLinkToken || !!linkToken}
                value={ACCOUNT_OWNER.APPLICANT}
                control={<Radio onClick={() => setAccountOwner(ACCOUNT_OWNER.APPLICANT)} />}
                label={`Applicant (${applicant?.first_name} ${applicant?.last_name})`}
              />
              <FormControlLabel
                disabled={!coApplicant?.id || fetchingLinkToken || !!linkToken}
                value={ACCOUNT_OWNER.CO_APPLICANT}
                control={<Radio onClick={() => setAccountOwner(ACCOUNT_OWNER.CO_APPLICANT)} />}
                label={coApplicant?.first_name && coApplicant?.last_name ? `Co-Applicant (${coApplicant.first_name} ${coApplicant.last_name})` : `Co-Applicant`}
              />
            </RadioGroup>
          </DialogContent>

          <DialogActions>
            <Button disabled={fetchingLinkToken || !!linkToken} variant="outlined" color="error" onClick={onClose}>
              Cancel
            </Button>

            <LoadingButton
              loading={fetchingLinkToken || !!linkToken}
              loadingPosition="start"
              startIcon={<AddCardIcon />}
              variant="contained"
              onClick={async () => {
                try {
                  setFetchingLinkToken(true);
                  const response = await dispatch(
                    generateApplicationLinkTokenThunk({
                      applicantId: accountOwner === ACCOUNT_OWNER.APPLICANT ? applicant?.id : coApplicant?.id,
                      applicationId,
                      profileType: null,
                    }),
                  ).unwrap();
                  setFetchingLinkToken(false);

                  setLinkToken(response.linkToken.link_token);
                } catch (err) {
                  console.log('Generating application link token error: ', err);

                  setPaymentProfilesSectionErrorMsg('Generating application link token failed.');
                  setShowPaymentProfilesSectionError(true);

                  setFetchingLinkToken(false);
                }
              }}
            >
              {fetchingLinkToken || !!linkToken ? 'Processing...' : 'Add'}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {linkToken && (
        <PlaidLink
          applicationId={applicationId}
          applicantId={accountOwner === ACCOUNT_OWNER.APPLICANT ? applicant?.id : coApplicant?.id}
          accountOwner={accountOwner === ACCOUNT_OWNER.APPLICANT ? 'applicant' : 'co-applicant'}
          accountName={
            accountOwner === ACCOUNT_OWNER.APPLICANT
              ? `${applicant?.first_name} ${applicant?.last_name}`
              : `${coApplicant?.first_name} ${coApplicant?.last_name}`
          }
          linkToken={linkToken}
          profileType={null}
          onOpen={() => setPlaidLinkOpened(true)}
          onError={() => {
            setLinkToken(null);
            setPlaidLinkOpened(false);

            onClose();
            setPaymentProfilesSectionErrorMsg('Failed to process payment profile');
            setShowPaymentProfilesSectionError(true);
          }}
          onExit={() => {
            setLinkToken(null);
            setPlaidLinkOpened(false);
          }}
          onInputPaymentProfileDataSuccess={() => {
            setLinkToken(null);
            setPlaidLinkOpened(false);
            onClose();

            if (typeof onInputPaymentProfileDataSuccess === 'function') {
              onInputPaymentProfileDataSuccess();
            }
          }}
          onInputPaymentProfileDataFail={() => {
            setLinkToken(null);
            setPlaidLinkOpened(false);
          }}
          onPaymentProfileConnectSuccess={() => {
            setLinkToken(null);
            setPlaidLinkOpened(false);
            onClose();

            if (typeof onPaymentProfileConnectSuccess === 'function') {
              onPaymentProfileConnectSuccess();
            }
          }}
          onPaymentProfileConnectFail={() => {
            setLinkToken(null);
            setPlaidLinkOpened(false);
          }}
        />
      )}
    </>
  );
};

export default PaymentProfileOwnerModal;
