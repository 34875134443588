import { Typography, Divider } from '@mui/material';

import { Card } from 'components/Layouts';
import { currencyFormatter, percentageFormatterV2 } from 'constants/formatters';
import ListItem from 'components/ListItem';

const Snapshot = ({ regularPaymentAmount, interestRate, accruedInterest, payoffAmount, currentPrincipal, originalPrincipal, feesAmountDue, tenDayPayoff }) => {
  return (
    <Card fullHeight padding="10px 20px" variant="outlined">
      <Typography variant="h6" mb="16px">
        Snapshot
      </Typography>

      <Typography sx={{ fontWeight: 500 }}>Original</Typography>
      <ListItem value={originalPrincipal ? currencyFormatter.format(originalPrincipal) : '-'} label="Principal" />
      <ListItem value={interestRate ? percentageFormatterV2(interestRate) : '-'} label="Interest Rate" />
      <ListItem value={regularPaymentAmount ? currencyFormatter.format(regularPaymentAmount) : '-'} label="Monthly Payment" />

      <Divider sx={{ m: '16px 0px' }} />

      <Typography sx={{ fontWeight: 500 }}>Current</Typography>
      <ListItem value={currentPrincipal ? currencyFormatter.format(currentPrincipal) : '-'} label="Principal" />
      <ListItem value={feesAmountDue ? currencyFormatter.format(feesAmountDue) : '-'} label="Unpaid Fees" />
      <ListItem value={accruedInterest ? currencyFormatter.format(accruedInterest) : '-'} label="Accrued Interest" />

      <Divider sx={{ m: '16px 0px' }} />

      <Typography sx={{ fontWeight: 500 }}>Payoff</Typography>
      <ListItem value={payoffAmount ? currencyFormatter.format(payoffAmount) : '-'} label="Today" />
      <ListItem value={tenDayPayoff ? currencyFormatter.format(tenDayPayoff) : '-'} label="10 Day" />
    </Card>
  );
};

export default Snapshot;
