import { LINKS } from 'constants/menus';

export const buildBreadcrumbs = ({ currentApplication }) => [
  currentApplication?.id ? LINKS.DEALER.VIEW_APPLICATION(currentApplication.id) : '',
  [
    { text: 'Home', link: LINKS.DEALER.DASHBOARD },
    { text: 'Applications', link: LINKS.DEALER.ALL_APPLICATIONS },
    {
      text:
        currentApplication?.id && currentApplication?.applicant?.first_name && currentApplication?.applicant?.last_name
          ? `${currentApplication.applicant.first_name} ${currentApplication.applicant.last_name}`
          : currentApplication?.id && currentApplication?.applicant?.email
          ? currentApplication.applicant.email
          : 'View Application',
      link: currentApplication?.id ? LINKS.DEALER.VIEW_APPLICATION(currentApplication.id) : '',
    },
    { text: 'Credit Report', link: '' },
  ],
];
