import { TYPES } from 'components/Formie/inputs';
import { validateNotNull, validatePositiveInteger, validatePastDate110, stringNotEmpty } from 'components/Formie/validators';
import { PAYMENT_APPLICATION_SCHEDULES, paymentMethodMap } from './Payments/helpers';
import { FEE_TYPES } from './Fees/helpers';

export const configuration = {
  payment: {
    payment_application_date: {
      type: TYPES.date,
      dataPath: 'payment.payment_application_date',
      required: true,
      validator: validatePastDate110,
      label: 'Payment Date',
    },
    payment_application_schedule: {
      type: TYPES.basicSelect,
      dataPath: 'payment.payment_application_schedule',
      required: true,
      validator: validateNotNull,
      label: 'Payment Type',
      options: [
        { value: PAYMENT_APPLICATION_SCHEDULES.IPF, label: 'IPF' },
        { value: PAYMENT_APPLICATION_SCHEDULES.IFP, label: 'IFP' },
        { value: PAYMENT_APPLICATION_SCHEDULES.FIP, label: 'FIP' },
        { value: PAYMENT_APPLICATION_SCHEDULES.INTEREST_ONLY, label: 'Interest Only' },
        { value: PAYMENT_APPLICATION_SCHEDULES.FEES_ONLY, label: 'Fees Only' },
        { value: PAYMENT_APPLICATION_SCHEDULES.PRINCIPAL_ONLY, label: 'Principal Only' },
      ],
    },
    payment_amount: {
      type: TYPES.currency,
      dataPath: 'payment.payment_amount',
      label: 'Payment Amount',
      required: true,
      validator: validatePositiveInteger,
    },
    payment_method: {
      type: TYPES.basicSelect,
      dataPath: 'payment.payment_method',
      required: true,
      validator: validateNotNull,
      label: 'Payment Method',
      options: paymentMethodMap,
    },
  },
};

export const initialValues = {
  payment: {
    payment_application_date: null,
    payment_application_schedule: null,
    payment_amount: null,
    payment_method: null,
  },
};

export const createFeesConfiguration = (pastDues) => ({
  fee: {
    fee_date: {
      type: TYPES.date,
      dataPath: 'fee.fee_date',
      required: true,
      validator: validatePastDate110,
      label: 'Fee Date',
    },
    type: {
      type: TYPES.basicSelect,
      dataPath: 'fee.type',
      required: true,
      validator: validateNotNull,
      label: 'Type',
      options: [
        { value: FEE_TYPES.LATE_PAYMENT_FEE, label: 'Late Payment Fee' },
        { value: FEE_TYPES.NSF_FEE, label: 'NSF Fee' },
      ],
    },
    title: {
      type: TYPES.text,
      dataPath: 'fee.title',
      label: 'Title',
      required: true,
      validator: stringNotEmpty,
    },
    amount: {
      type: TYPES.currency,
      dataPath: 'fee.amount',
      label: 'Amount',
      required: true,
      validator: validatePositiveInteger,
    },
  },
});

export const feeInitialValues = {
  fee: {
    fee_date: null,
    type: null,
    title: null,
    amount: null,
  },
};
