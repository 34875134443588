import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaymentPeriodAPI } from 'store/api';

export const fetchLoanPaymentPeriodsThunk = createAsyncThunk(
  'paymentPeriods/fetchLoanPaymentPeriods',
  async ({ subdata, loanId, limit = 30, sort = 'created_at|ASC', offset = 1 }, thunkAPI) => {
    try {
      let queryString;

      if (!subdata || !subdata?.length || subdata.length < 1) {
        queryString = `?limit=${limit}&sort=${sort}&offset=${offset}`;
      } else {
        queryString = `?subdata=${subdata.join(',')}&limit=${limit}&sort=${sort}&offset=${offset}`;
      }

      const { paymentPeriods, metadata } = await PaymentPeriodAPI.fetchLoanPaymentPeriods({ queryString, loanId });

      /**
       * This will dispatch action creator with type of "paymentPeriods/fetchLoanPaymentPeriods/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return { paymentPeriods, metadata };
    } catch (err) {
      /**
       * This will dispatch action creator with type of "paymentPeriods/fetchLoanPaymentPeriods/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);
