import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoansThunk } from 'store/thunks/lender';
import { Tab, Tabs, CircularProgress, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { setAlert } from 'store/sagas/app';
import { COLORS } from 'constants/styles';
import { FlexRow } from 'components/Layouts';
import { LOAN_FREQUENCIES, LOAN_SORTS, LOAN_STATUSES } from './helpers.js';
import SortAndFilterMenu from './SortAndFilterMenu.js';
import LoansGrid from './LoansGrid.js';
import PortfolioStats from './PortfolioStats.js';

const AllLoans = () => {
  const dispatch = useDispatch();

  const [loans, setLoans] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('loans'); // loans || stats

  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  const active = searchParams.get('active');
  const frequency = searchParams.get('frequency');
  const sort = searchParams.get('sort');
  const limit = searchParams.get('limit');
  const offset = searchParams.get('offset');
  const lenderId = useSelector((state) => state?.user?.active_profile?.lender_id);

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { loans, metadata } = await dispatch(
        fetchLoansThunk({
          subdata: ['metrics', 'customers'],
          status: status.split(','),
          active,
          frequency: frequency.split(','),
          sort,
          offset,
          limit,
        }),
      ).unwrap();
      if (!loans) {
        setLoans([]);
      } else {
        setMetadata(metadata);
        setLoans(loans);
      }
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch loans. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!status || !active || !frequency || !sort || !limit || !offset) {
      setSearchParams({
        status: status || LOAN_STATUSES.OPEN,
        active: active || 'true',
        frequency: frequency || LOAN_FREQUENCIES.MONTHLY,
        sort: sort || LOAN_SORTS.CREATED_AT_ASC,
        limit: limit || 25,
        offset: offset || 1,
      });
    } else if (lenderId) {
      hydrateData();
    }
  }, [lenderId, status, active, frequency, sort, limit, offset]);

  const updateSearchParams = (newParams) => {
    let existingParams = {};

    searchParams.forEach((value, key) => {
      existingParams = {
        ...existingParams,
        [key]: value,
      };
    });

    const newSearchParams = {
      ...existingParams,
      ...newParams,
    };

    setSearchParams(newSearchParams);
  };

  const updatePagination = (newParams) => {
    if (newParams.limit != limit || newParams.offset != offset) {
      updateSearchParams(newParams);
    }
  };

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <div
      style={{
        background: COLORS.backgroundColor,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <div style={{ width: '100%', height: '100%', boxSizing: 'border-box', maxWidth: '1080px', padding: '20px', overflow: 'hidden' }}>
        <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Loans</Typography>
          <SortAndFilterMenu
            status={searchParams.get('status')}
            active={searchParams.get('active')}
            frequency={searchParams.get('frequency')}
            sort={searchParams.get('sort')}
            updateSearchParams={updateSearchParams}
          />
        </FlexRow>

        <div style={{ width: '100%', boxSizing: 'border-box', marginTop: '8px', borderBottom: `1px solid ${COLORS.borderColor}` }}>
          <Tabs sx={{ minHeight: '32px' }} value={tab} onChange={(e, newValue) => setTab(newValue)}>
            <Tab
              sx={{
                textTransform: 'none',
                borderRadius: '8px',
                minHeight: '32px',
                p: 0,
                '&:hover': { backgroundColor: COLORS.primaryFaded, color: COLORS.primary },
              }}
              label="Loans"
              value="loans"
            />

            <Tab
              sx={{
                textTransform: 'none',
                borderRadius: '8px',
                minHeight: '32px',
                p: 0,
                '&:hover': { backgroundColor: COLORS.primaryFaded, color: COLORS.primary },
              }}
              label="Statistics"
              value="stats"
            />
          </Tabs>
        </div>

        {/* Data Grid */}
        {tab === 'loans' && <LoansGrid limit={limit} offset={offset} loans={loans} updatePagination={updatePagination} metadata={metadata} />}

        {/* Portfolio Statistics */}
        {tab === 'stats' && <PortfolioStats metadata={metadata} status={status} active={active} />}
      </div>
    </div>
  );
};

export default AllLoans;
