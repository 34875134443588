import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { format, parseISO } from 'date-fns';

import { STATUS, STATUS_LABELS } from 'constants/status';
import { updateApplicationStatus } from 'store/sagas/applications';
import { getApplicationStatusIcon } from 'components/Icon';
import { Card } from 'components/Layouts';
import { ActionMenu } from 'components/Menu';
import { DoubleConfirmNoButton } from 'components/DoubleConfirm';
import DataListItem from './dataListItem';

const { FUNDING_REVIEW, FUNDED, SERVICING } = STATUS_LABELS;
const nameObj = { name: '' };

const Overview = ({ currentApplication, primaryPaymentProfile }) => {
  const { id, status, type, lender, dealer, stipulations, metadata } = currentApplication;

  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [copyTooltipText, setCopyTooltipText] = useState('Click to copy');

  const statusData = () => {
    const allStipsFulfilled = stipulations ? stipulations.every((s) => s.fulfilled) : false;
    const statusObj = !!status ? (status.name ? STATUS.find((item) => item.label == status.name) : nameObj) : nameObj;

    if (!statusObj || !statusObj.label) return '';

    return (
      <Stack spacing={1} direction="row" alignContent="center" alignItems="center">
        {getApplicationStatusIcon({ statusObj })}

        {allStipsFulfilled && primaryPaymentProfile && [FUNDING_REVIEW, FUNDED].includes(statusObj.label) ? (
          <ActionMenu
            icon={<EditOutlinedIcon fontSize="16px" />}
            options={[
              status.name === FUNDING_REVIEW
                ? {
                    value: getApplicationStatusIcon({ statusObj: STATUS.find((s) => s.label === FUNDED) }),
                    callback: () => setModalOpen(true),
                  }
                : status.name === FUNDED
                ? {
                    value: getApplicationStatusIcon({ statusObj: STATUS.find((s) => s.label === SERVICING) }),
                    callback: () => setModalOpen(true),
                  }
                : null,
            ]}
          />
        ) : null}
      </Stack>
    );
  };

  return (
    <Fragment>
      <Card margin="0px 0px 20px" padding="20px 20px 0px" fullwidth variant="outlined" aria-label="Basic info.">
        <Typography style={{ margin: '0px' }} variant="h5">
          Basic Info
        </Typography>

        <List>
          {[
            {
              title: 'ID',
              data: (
                <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                  <Typography>{id}</Typography>
                  <Tooltip title={copyTooltipText} onOpen={() => setCopyTooltipText('Click to copy')}>
                    <IconButton
                      size="small"
                      aria-label="copy"
                      onClick={() => {
                        copy(id);
                        setCopyTooltipText('Copied');
                      }}
                    >
                      <ContentCopyIcon fontSize="16px" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ),
            },
            { title: 'Status', data: statusData() },
            { title: 'Type', data: type?.name },
            { title: 'Lender', data: lender?.name },
            { title: 'Dealer', data: dealer?.name },
            { title: 'Created On', data: metadata?.created_at && format(parseISO(metadata.created_at), 'MM/dd/yyyy') },
            { title: 'Updated On', data: metadata?.updated_at && format(parseISO(metadata.updated_at), 'MM/dd/yyyy'), noDivider: true },
          ].map(({ title, data, noDivider }, i) => (
            <DataListItem key={`${title}-${i}`} title={title} data={data} noDivider={noDivider} />
          ))}
        </List>
      </Card>

      {status && stipulations && stipulations.every((s) => s.fulfilled) ? (
        <DoubleConfirmNoButton
          title="Are you sure?"
          description={`Marking this application as "${status.name === FUNDING_REVIEW ? 'Funded' : 'Servicing'}" is irreversible.`}
          action={() => {
            /**
             * ! Warning
             * ! We are hardcoding and assuming that the id of application status of "Funded" is 10 and "Servicing" is 11.
             *
             * TODO: Properly fetch application status from DB so we can use the actual id of the application status on the DB.
             */
            const application_status_id =
              status.name === FUNDING_REVIEW
                ? // Funding Review -> Funded
                  10
                : status.name === FUNDED
                ? // Funded -> Servicing
                  11
                : null;

            if (application_status_id) {
              dispatch(updateApplicationStatus({ data: { application_status_id }, applicationId: id }));
            }
          }}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </Fragment>
  );
};

export default Overview;
