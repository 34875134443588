import { createContext, useState } from 'react';

const PaymentProfilesContext = createContext({});

export const PaymentProfilesContextProvider = ({ children }) => {
  const [paymentProfilesSectionProcessing, setPaymentProfilesSectionProcessing] = useState(false);
  const [paymentProfilesSectionErrorMsg, setPaymentProfilesSectionErrorMsg] = useState('Process failed.');
  const [showPaymentProfilesSectionError, setShowPaymentProfilesSectionError] = useState(false);

  return (
    <PaymentProfilesContext.Provider
      value={{
        paymentProfilesSectionProcessing,
        paymentProfilesSectionErrorMsg,
        showPaymentProfilesSectionError,
        setPaymentProfilesSectionProcessing,
        setPaymentProfilesSectionErrorMsg,
        setShowPaymentProfilesSectionError,
      }}
    >
      {children}
    </PaymentProfilesContext.Provider>
  );
};

export default PaymentProfilesContext;
