import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CircularProgress, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';

import { setAlert, startAppLoading, stopAppLoading } from 'store/sagas/app';
import { Card, FileContainer, FlexRow } from 'components/Layouts';
import { deleteTitleImgThunk, fetchLoanCollateralThunk, updateCollateralThunk, uploadTitleImgThunk } from 'store/thunks/loanCollateral';
import DragAndDropFileUpload from 'components/FileUpload';
import DoubleConfirm from 'components/DoubleConfirm';
import { LoanCollateralAPI } from 'store/api';

const CollateralInformation = () => {
  const dispatch = useDispatch();

  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [collateral, setCollateral] = useState({});
  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { collateral } = await dispatch(
        fetchLoanCollateralThunk({
          loanId,
        }),
      ).unwrap();

      setCollateral(collateral);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch collateral. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const updateTitleReceived = async (titleReceived) => {
    setLoading(true);
    try {
      const { collateral: updatedCollateral } = await dispatch(
        updateCollateralThunk({ data: { title_received: titleReceived }, loanId, collateralId: collateral.id }),
      ).unwrap();
      setCollateral(updatedCollateral);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to update collateral. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const uploadTitleImg = async (file) => {
    setUploading(true);
    try {
      const { collateral: updatedCollateral } = await dispatch(uploadTitleImgThunk({ file, loanId, collateralId: collateral.id })).unwrap();
      setCollateral(updatedCollateral);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to upload title image. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setUploading(false);
    }
  };

  const downloadTitleImg = async () => {
    try {
      dispatch(startAppLoading());
      const { link } = await LoanCollateralAPI.downloadTitleImg({ loanId, collateralId: collateral.id });
      window.open(link);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to download image. Try again later.',
          showing: true,
        }),
      );
    } finally {
      dispatch(stopAppLoading());
    }
  };

  const deleteTitleImg = async () => {
    dispatch(startAppLoading());
    try {
      const { collateral: updatedCollateral } = await dispatch(deleteTitleImgThunk({ loanId, collateralId: collateral.id })).unwrap();
      setCollateral(updatedCollateral);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to delete title image. Try again later.',
          showing: true,
        }),
      );
    } finally {
      dispatch(stopAppLoading());
    }
  };

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <>
      {/* Header row with action button */}
      <FlexRow padding="0px" margin="0px 0px 10px" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Title</Typography>
      </FlexRow>

      <Card variant="outlined" padding="20px">
        <FlexRow padding="0px" margin="0px 0px 20px" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Has the title been received?</Typography>

          <ToggleButtonGroup
            onChange={(event, newVal) => {
              updateTitleReceived(newVal);
            }}
            color="primary"
            exclusive
            value={collateral.title_received}
            size="small"
          >
            <ToggleButton sx={{ width: 100 }} value={true}>
              Yes
            </ToggleButton>
            <ToggleButton sx={{ width: 100 }} value={false}>
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </FlexRow>

        {collateral.title_img_key ? (
          <FileContainer variant="permanent">
            <FlexRow padding="0px" margin="0px" alignItems="center">
              <InsertDriveFileIcon color="primary" />
              <Typography noWrap variant="body1" style={{ marginLeft: '10px' }}>
                {collateral.title_img_file_name || 'Uploaded Title Image'}
              </Typography>
            </FlexRow>

            <IconButton onClick={downloadTitleImg}>
              <VisibilityIcon />
            </IconButton>

            <DoubleConfirm
              baseButton={
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              }
              title="Are you sure?"
              description="Deleting this file is irreversible."
              action={deleteTitleImg}
            />
          </FileContainer>
        ) : (
          <DragAndDropFileUpload uploading={uploading} uploadFn={({ file }) => uploadTitleImg(file)} />
        )}
      </Card>
    </>
  );
};

export default CollateralInformation;
