import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaymentAPI } from 'store/api';

export const fetchLoanPaymentsThunk = createAsyncThunk(
  'payments/fetchLoanPayments',
  async ({ subdata, status, loanId, limit = 30, sort = 'created_at|ASC', offset = 1 }, thunkAPI) => {
    try {
      let queryString;

      if (!subdata || !subdata?.length || subdata.length < 1) {
        queryString = `?limit=${limit}&sort=${sort}&offset=${offset}`;
      } else {
        queryString = `?subdata=${subdata.join(',')}&limit=${limit}&sort=${sort}&offset=${offset}`;
      }

      if (status) {
        queryString = `${queryString}&status=${status}`;
      }

      const { loanPayments, metadata } = await PaymentAPI.fetchLoanPayments({ queryString, loanId });

      /**
       * This will dispatch action creator with type of "payments/fetchLoanPayments/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return { payments: loanPayments, metadata };
    } catch (err) {
      /**
       * This will dispatch action creator with type of "payments/fetchLoanPayments/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createLoanPaymentThunk = createAsyncThunk('payments/createLoanPayment', async ({ data, loanId }, thunkAPI) => {
  try {
    const { loanPayment } = await PaymentAPI.createLoanPayment({ data, loanId });

    /**
     * This will dispatch action creator with type of "payments/createLoanPayment/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { payment: loanPayment };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payments/createLoanPayment/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const editLoanPaymentThunk = createAsyncThunk('payments/editLoanPayment', async ({ data, loanId, paymentId }, thunkAPI) => {
  try {
    const { loanPayment } = await PaymentAPI.editLoanPayment({ data, loanId, paymentId });

    /**
     * This will dispatch action creator with type of "payments/editLoanPayment/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { payment: loanPayment };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payments/editLoanPayment/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const reverseLoanPaymentThunk = createAsyncThunk('payments/reverseLoanPayment', async ({ loanId, paymentId }, thunkAPI) => {
  try {
    const { loanPayment } = await PaymentAPI.reverseLoanPayment({ loanId, paymentId });

    /**
     * This will dispatch action creator with type of "payments/reverseLoanPayment/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { payment: loanPayment };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "payments/reverseLoanPayment/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});
