import { Alert, Typography, Accordion, AccordionSummary, AccordionDetails, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { differenceInCalendarDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FlexRow } from 'components/Layouts';
import { currencyFormatter, formatDateOnly } from 'constants/formatters';
import ListItem from 'components/ListItem';
import { COLORS } from 'constants/styles';

const PastDue = ({ period, timezone }) => {
  const pastDue = period?.payment_period_past_due ? period.payment_period_past_due : {};
  let pastDueStatus, originalAmountDue, amountPaid, amountRemaining, dueDate, paidOnDate, daysLate;

  if (pastDue) {
    const { fully_settled_date, amount_settled, amount, payment_due_date } = pastDue;
    pastDueStatus = !fully_settled_date ? 'Due' : 'Paid';
    originalAmountDue = amount ? currencyFormatter.format(Number(amount)) : currencyFormatter.format(Number(0));
    amountPaid = amount_settled ? currencyFormatter.format(Number(amount_settled)) : currencyFormatter.format(Number(0));
    const amountRemainingNumber = amount && amount_settled ? Number(amount) - Number(amount_settled) : 0;
    amountRemaining = currencyFormatter.format(amountRemainingNumber);
    dueDate = payment_due_date ? formatDateOnly(payment_due_date) : '-';
    paidOnDate = fully_settled_date ? formatDateOnly(fully_settled_date) : '-';

    const todayInBrowserTimeZone = new Date();
    const todayInLoansTimeZone = formatInTimeZone(todayInBrowserTimeZone, timezone, 'MM/dd/yy');
    daysLate = fully_settled_date
      ? differenceInCalendarDays(new Date(paidOnDate), new Date(dueDate))
      : differenceInCalendarDays(new Date(todayInLoansTimeZone), new Date(dueDate));
  }
  return (
    <>
      <Typography sx={{ m: '32px 0px 12px' }} variant="h6">
        Past Due
      </Typography>
      {period?.payment_period_past_due ? (
        <Accordion
          disableGutters
          elevation={0}
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            borderRadius: '8px',
            border: `1px solid ${COLORS.borderColor}`,
            '&:before': {
              display: 'none',
            },
            background: COLORS.backgroundColor,
            marginBottom: '10px',
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container spacing={0} sx={{ width: '100%' }}>
              <Grid xs={5}>
                <Typography sx={{ fontWeight: 700 }}>{amountRemaining}</Typography>
              </Grid>

              <Grid xs={7}>
                <Chip label={pastDueStatus} size="small" color={pastDueStatus === 'Due' ? 'error' : 'primary'} />
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={4}>
              <Grid xs={6}>
                <ListItem label="Original Due" value={originalAmountDue} />
                <ListItem label="Paid" value={amountPaid} />
                <ListItem label="Still Due" value={amountRemaining} />
              </Grid>

              <Grid xs={6}>
                <ListItem label="Due On" value={dueDate} />
                <ListItem label="Paid On" value={paidOnDate} />
                <ListItem label="Days Late" value={daysLate} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : (
        <FlexRow padding="0px" margin="0px" justifyContent="center" alignItems="center">
          <Alert sx={{ width: '275px' }} severity="success">{`Period ${period?.period_number} has never been late`}</Alert>
        </FlexRow>
      )}
    </>
  );
};

export default PastDue;
