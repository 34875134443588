import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CircularProgress, Button, Alert, AlertTitle } from '@mui/material';

import { FlexRow } from 'components/Layouts';
import { fetchLoanThunk, openAndActivateLoanThunk } from 'store/thunks/loan';
import { fetchCustomerThunk } from 'store/thunks/customer';
import { setAlert } from 'store/sagas/app';
import { SectionContainer, SectionContentContainer } from '../styled-components';
import Collateral from './Collateral';
import Customers from './Customers';
import PaymentProfiles from './PaymentProfiles';
import LoanTerms from './LoanTerms';
import AdvancedSettings from './AdvancedSettings';
import { FloatingActionBar } from './styled-components';
import { validateLoan } from './helpers';

const Onboarding = ({ rehydrateTopLevel }) => {
  const dispatch = useDispatch();

  const [readyToActivate, setReadyToActivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loan, setLoan] = useState({});
  const [customerPaymentProfiles, setCustomerPaymentProfiles] = useState([]);

  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { loan } = await dispatch(
        fetchLoanThunk({
          subdata: ['collateral', 'customers', 'payment-profiles', 'payment-periods'],
          loanId,
        }),
      ).unwrap();
      if (!loan) {
        setLoan({});
      } else {
        setLoan(loan);
      }

      if (loan?.customers) {
        const cust1 = loan.customers[0];
        const cust2 = loan.customers[1];

        const { customer: cust1Detail } = cust1
          ? await dispatch(
              fetchCustomerThunk({
                subdata: ['payment-profiles'],
                customerId: cust1?.id,
              }),
            ).unwrap()
          : { customer: { customer_payment_profiles: [] } };

        const { customer: cust2Detail } = cust2
          ? await dispatch(
              fetchCustomerThunk({
                customerId: cust2?.id,
              }),
            ).unwrap()
          : { customer: { customer_payment_profiles: [] } };

        const loanPaymentProfiles = loan?.loan_payment_profiles?.map((profile) => profile.id);
        const profiles = [...cust1Detail?.customer_payment_profiles, ...cust2Detail?.customer_payment_profiles].filter(
          (profile) => !loanPaymentProfiles.includes(profile?.id),
        );

        setCustomerPaymentProfiles(profiles);
      }
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch this loan. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const openAndActivateLoan = async () => {
    setLoading(true);
    try {
      await dispatch(openAndActivateLoanThunk({ loanId })).unwrap();
      await hydrateData();
      await rehydrateTopLevel();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to open and activate this loan. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  useEffect(() => {
    setReadyToActivate(validateLoan(loan));
  }, [loan, validateLoan]);

  if (loading) {
    return (
      <FlexRow justifyContent="center" alignItems="center" padding="20px" fullHeight>
        <CircularProgress />
      </FlexRow>
    );
  }

  const { collateral, customers, loan_payment_profiles, payment_periods } = loan;

  return (
    <SectionContainer>
      <SectionContentContainer>
        <Collateral collateral={collateral} rehydrateTopLevel={rehydrateTopLevel} />
        <Customers customers={customers} rehydrateOnboarding={hydrateData} rehydrateTopLevel={rehydrateTopLevel} />
        <PaymentProfiles
          customerPaymentProfiles={customerPaymentProfiles}
          customers={customers}
          paymentProfiles={loan_payment_profiles}
          rehydrateOnboarding={hydrateData}
        />
        <LoanTerms paymentPeriods={payment_periods} loan={loan} rehydrateOnboarding={hydrateData} />
        <AdvancedSettings readyToActivate={readyToActivate} loan={loan} rehydrateOnboarding={hydrateData} />

        {readyToActivate && (
          <FloatingActionBar>
            <Alert
              color="primary"
              variant="filled"
              sx={{ width: '75%', borderRadius: '8px' }}
              action={
                <Button variant="contained" onClick={openAndActivateLoan} color="white" size="small">
                  Activate
                </Button>
              }
            >
              <AlertTitle>This loan is ready to open and activate!</AlertTitle>
            </Alert>
          </FloatingActionBar>
        )}
      </SectionContentContainer>
    </SectionContainer>
  );
};

export default Onboarding;
