import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_LOAN_PAST_DUES: ({ loanId }) => `/servicing/loans/${loanId}/past-dues`,
};

export default {
  fetchLoanPastDues: ({ queryString, loanId }) =>
    request({
      url: `${URIS.FETCH_LOAN_PAST_DUES({ loanId })}${queryString || ''}`,
      method: METHODS.GET,
    }),
};
