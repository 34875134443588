import React, { useState, Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DoubleConfirm({ baseButton, title, description, cancelText, confirmText, action }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    action();
  };

  return (
    <Fragment>
      {baseButton &&
        React.cloneElement(baseButton, {
          onClick: handleClickOpen,
        })}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title || 'Are you sure?'}</DialogTitle>

        <DialogContent>
          <DialogContentText>{description || 'This action is irreversible.'}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>{cancelText || 'Cancel'}</Button>
          <Button onClick={handleConfirm} autoFocus>
            {confirmText || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export const DoubleConfirmNoButton = ({ title, description, cancelText, confirmText, action, open, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onClose();
    action();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title || 'Are you sure?'}</DialogTitle>

      <DialogContent>
        <DialogContentText>{description || 'This action is irreversible.'}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>{cancelText || 'Cancel'}</Button>
        <Button onClick={handleConfirm} autoFocus>
          {confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
