import { useSearchParams } from 'react-router-dom';

import { SectionContainer, SectionContentContainer, ViewLoanPageLayout } from '../styled-components';
import AdvancedSettings from './AdvancedSettings';

const Settings = () => {
  const [searchParams] = useSearchParams();

  const SECTIONS = {
    GENERAL: 'General Settings',
  };

  const sectionObjs = [{ label: 'General Settings', value: SECTIONS.GENERAL, component: <AdvancedSettings /> }];

  const defaultSection = SECTIONS.GENERAL;
  const activeSection = searchParams.get('section') || defaultSection;
  const activeComponent = sectionObjs.find((section) => section.value === activeSection)?.component;

  return (
    <ViewLoanPageLayout defaultItem={defaultSection} menuItems={sectionObjs}>
      <SectionContainer>
        <SectionContentContainer fullWidth={sectionObjs.find((section) => section.value === activeSection)?.fullWidth}>
          {activeComponent ? activeComponent : <div>Error</div>}
        </SectionContentContainer>
      </SectionContainer>
    </ViewLoanPageLayout>
  );
};

export default Settings;
