import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { LINKS } from 'constants/menus';
import { currencyFormatter, integerFormatter } from 'constants/formatters';
import { Card } from 'components/Layouts';
import DataListItem from '../dataListItem';

const Unit = ({ unit, isTradeIn, appId, canEdit }) => {
  const [copyTooltipText, setCopyTooltipText] = useState('Click to copy');

  const navigate = useNavigate();

  const editFn = () => (canEdit ? navigate(LINKS.DEALER.EDIT_APPLICATION(appId)) : null);

  return (
    <Card margin="0px 0px 20px" padding="20px 20px 0px" fullwidth variant="outlined">
      <Typography style={{ margin: '0px' }} variant="h5">
        {isTradeIn ? 'Trade-In' : 'Unit'}
      </Typography>
      <List onClick={editFn}>
        {[
          {
            title: 'VIN',
            data: (
              <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                <Typography>{unit?.vin}</Typography>
                <Tooltip title={copyTooltipText} onOpen={() => setCopyTooltipText('Click to copy')}>
                  <IconButton
                    size="small"
                    aria-label="copy"
                    onClick={(e) => {
                      copy(unit?.vin);
                      setCopyTooltipText('Copied');
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ContentCopyIcon fontSize="16px" />
                  </IconButton>
                </Tooltip>
              </Stack>
            ),
          },
          { title: 'Condition', data: unit?.condition },
          { title: 'Year', data: unit?.year },
          { title: 'Make', data: unit?.make },
          { title: 'Model', data: unit?.model },
          { title: 'Mileage', data: unit?.mileage && integerFormatter.format(unit.mileage) },
          { title: 'Trade-In Value', data: unit?.trade_allowance && currencyFormatter.format(unit.trade_allowance), tradeInOnly: true },
          { title: 'Payoff Amount', data: unit?.payoff_amount && currencyFormatter.format(unit.payoff_amount), tradeInOnly: true, noDivider: true },
          { title: 'Price', data: unit?.price && currencyFormatter.format(unit.price), unitOnly: true },
          { title: 'Value', data: unit?.value && currencyFormatter.format(unit.value), unitOnly: true },
          { title: 'Value Source', data: unit?.value_source, unitOnly: true, noDivider: true },
        ]
          .filter((item) => {
            if (!item.tradeInOnly && !item.unitOnly) return true;
            if (isTradeIn && item.tradeInOnly) return true;
            if (!isTradeIn && item.unitOnly) return true;

            return false;
          })
          .map(({ title, data, noDivider }, i) => (
            <DataListItem key={`${title}-${i}`} title={title} data={data} noDivider={noDivider} />
          ))}
      </List>
    </Card>
  );
};

export default Unit;
