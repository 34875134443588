import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import get from 'lodash/get';

import { fieldTypes, baseValues } from './constants';

const inputVariant = 'filled';
const baseWidth = '100%';
const baseMargin = '0px';
const size = 'small';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.label,
});

const inputs = {
  // PASSWORD
  [fieldTypes.password]: ({ formik, parameterName, inputWidth, label }) => (
    <TextField
      id={`${Date.now().toString()}-${Math.random() * 100000}-${parameterName}-password`}
      key={parameterName}
      name={parameterName}
      label={label}
      value={get(formik.values, parameterName) || baseValues.text}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      variant={inputVariant}
      type="password"
      autoComplete="new-password"
      inputProps={{
        autoComplete: 'new-password',
      }}
      style={{
        width: inputWidth || baseWidth,
        margin: baseMargin,
      }}
      size={size}
      required={true}
    />
  ),

  // TEXTFIELD
  [fieldTypes.text]: ({ formik, errorText, parameterName, inputWidth, label, required, multiline }) => {
    const error = get(formik.touched, parameterName) && Boolean(get(formik.errors, parameterName));

    return (
      <TextField
        id={`${Date.now().toString()}-${Math.random() * 100000}-${parameterName}-textfield`}
        key={parameterName}
        name={parameterName}
        label={label}
        value={get(formik.values, parameterName) || baseValues.text}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={error}
        helperText={error && errorText}
        variant={inputVariant}
        multiline={multiline}
        inputProps={{
          autoComplete: `${Date.now().toString()}-${Math.random() * 100000}-${parameterName}-textfield`,
        }}
        style={{
          width: inputWidth || baseWidth,
          margin: baseMargin,
        }}
        size={size}
        required={required}
      />
    );
  },

  // SELECT
  [fieldTypes.select]: ({ formik, errorText, parameterName, inputWidth, label, required, options, customRenderOption }) => {
    const error = get(formik.touched, parameterName) && Boolean(get(formik.errors, parameterName));
    const value = get(formik.values, parameterName) || (get(formik.values, parameterName) === 0 ? 0 : null);

    return (
      <Autocomplete
        options={options}
        autoHighlight
        disablePortal
        name={parameterName}
        filterOptions={filterOptions}
        onChange={(e, newVal) => {
          const val = newVal ? newVal.id : null;
          formik.setFieldValue(parameterName, val);
        }}
        onBlur={formik.handleBlur}
        value={value}
        getOptionLabel={(id) => {
          const option = options.find((option) => option.id === id);
          return option ? option.label : '';
        }}
        renderOption={(props, option) => {
          return customRenderOption ? (
            customRenderOption(props, option)
          ) : (
            <Box component="li" {...props} key={option.id}>
              {option.label}
            </Box>
          );
        }}
        isOptionEqualToValue={(option, value) => {
          return value === option.id;
        }}
        fullWidth
        style={{
          width: inputWidth || baseWidth,
          margin: baseMargin,
        }}
        key={parameterName}
        renderInput={(params) => (
          <TextField
            {...params}
            id={`${Date.now().toString()}-${Math.random() * 100000}-${parameterName}-select`}
            size={size}
            error={error}
            helperText={error && errorText}
            label={label}
            variant={inputVariant}
            name={parameterName}
            required={required}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
          />
        )}
      />
    );
  },

  // INTEGER
  [fieldTypes.integer]: ({ formik, errorText, parameterName, inputWidth, label, required }) => {
    const error = get(formik.touched, parameterName) && Boolean(get(formik.errors, parameterName));

    return (
      <NumericFormat
        id={`${Date.now().toString()}-${Math.random() * 100000}-${parameterName}-integer`}
        key={parameterName}
        label={label}
        value={get(formik.values, parameterName)}
        onBlur={formik.handleBlur}
        onValueChange={({ formattedValue, value, floatValue }) => formik.setFieldValue(parameterName, floatValue)}
        error={error}
        helperText={error && errorText}
        variant={inputVariant}
        required={required}
        customInput={TextField}
        allowNegative={false}
        decimalScale={0}
        thousandSeparator=","
        style={{
          width: inputWidth || baseWidth,
          margin: baseMargin,
        }}
        size={size}
      />
    );
  },
};

export default inputs;
