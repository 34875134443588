import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { format, parseISO } from 'date-fns';
import Chip from '@mui/material/Chip';

import { currencyFormatter } from 'constants/formatters';
import { COLORS } from 'constants/styles';

import { Card } from 'components/Layouts';

const Tradeline = ({ tradelineData }) => {
  const {
    subscriberName,
    type,
    ecoa,
    openDate,
    accountNumber,
    kob,
    financials,
    revolvingOrInstallment,
    status,
    bankruptcyChapterNumber,
    consumerDisputeFlag,
    derogatoryMonths,
    history,
    comments,
    delinquencies,
  } = tradelineData;
  return (
    <Grid xs={12}>
      <Card variant="outlined" background padding="0px">
        {/* Title - Subscriber, Type, ECOA, Account # */}
        <div style={{ padding: '20px 20px 5px', borderBottom: `1px solid ${COLORS.borderColor}` }}>
          <Typography sx={{ fontWeight: 700 }}>
            {`${subscriberName} | ${type?.description} | ${ecoa?.description} | ${accountNumber && `****${accountNumber.slice(-4)}`}`}
          </Typography>
        </div>

        {/* Content */}
        <div style={{ padding: '0px 20px 20px' }}>
          {/* Subheader - Opened Date, Kind of Business */}
          <Typography variant="caption">{`Opened: ${openDate ? format(parseISO(openDate), 'PP') : ''} | Kind of Business: ${kob?.description}`}</Typography>

          {/* Chips for Bankruptcy, consumer dispute, derogatory months, charge-off, past due */}
          {(bankruptcyChapterNumber || consumerDisputeFlag || derogatoryMonths || financials?.amountChargeOff || financials?.amountPastDue) && (
            <>
              <Typography sx={{ mt: '10px', fontWeight: 700 }}>Tags</Typography>
              <div>
                {bankruptcyChapterNumber && (
                  <Chip style={{ marginRight: '5px' }} label={`Chapter ${bankruptcyChapterNumber} Bankruptcy`} size="small" color="error" variant="outlined" />
                )}
                {consumerDisputeFlag && <Chip style={{ marginRight: '5px' }} label="Consumer Dispute" size="small" color="error" variant="outlined" />}
                {derogatoryMonths > 0 && (
                  <Chip style={{ marginRight: '5px' }} label={`${derogatoryMonths} Derogatory Months`} size="small" color="error" variant="outlined" />
                )}
                {financials?.amountChargeOff > 0 && <Chip style={{ marginRight: '5px' }} label="Chargeoff" size="small" color="error" variant="outlined" />}
                {financials?.amountPastDue > 0 && <Chip style={{ marginRight: '5px' }} label="Past Due" size="small" color="error" variant="outlined" />}
              </div>
            </>
          )}

          <Grid sx={{ m: '10px 0px 0px 0px', p: 0 }} container>
            {/* Status */}
            <Grid sx={{ mt: -1, ml: -1 }} xs={4}>
              <Typography sx={{ fontWeight: 700 }}>{`Status | ${status?.date ? format(parseISO(status?.date), 'PP') : ''}`}</Typography>
              <Typography variant="body2">{status?.description}</Typography>
            </Grid>

            {/* Payment History */}
            <Grid sx={{ mt: -1, ml: -1 }} xs={8}>
              <Typography sx={{ fontWeight: 700 }}>{`Payment History | ${history?.months} Months of History`}</Typography>
              <Typography variant="body2">{history?.paymentHistory}</Typography>
            </Grid>
          </Grid>

          {/* Financials */}
          <Typography sx={{ mt: '10px', fontWeight: 700 }}>{`Financials | ${
            financials?.balanceDate ? format(parseISO(financials?.balanceDate), 'PP') : ''
          }`}</Typography>
          <Grid sx={{ m: 0, p: 0 }} container>
            {/* Current Balance, Original Balance, Credit Limit, High Balance */}
            <Grid sx={{ mt: -1, ml: -1 }} xs={4}>
              <Typography variant="body2">{`Current Balance: ${currencyFormatter.format(financials?.currentBalance)}`}</Typography>
              {revolvingOrInstallment === 'I' && (
                <Typography variant="body2">{`Original Balance: ${currencyFormatter.format(financials?.originalBalance)}`}</Typography>
              )}
              {revolvingOrInstallment === 'R' && (
                <Typography variant="body2">{`Credit Limit: ${currencyFormatter.format(financials?.creditLimit)}`}</Typography>
              )}
              <Typography variant="body2">{`High Balance: ${currencyFormatter.format(financials?.highBalance)}`}</Typography>
              <Typography variant="body2">{`30/60/90: ${delinquencies['30']}/${delinquencies['60']}/${delinquencies['90']}`}</Typography>
            </Grid>

            {/* Terms, Payment Frequency, Monthly Payment */}
            <Grid sx={{ mt: -1 }} xs={4}>
              <Typography variant="body2">{`Terms: ${financials?.terms}`}</Typography>
              <Typography variant="body2">{`Payment Frequency: ${financials?.paymentFrequency}`}</Typography>
              <Typography variant="body2">{`Monthly Payment: ${currencyFormatter.format(financials?.monthlyPayment)}`}</Typography>
              <Typography variant="body2">{`Last Payment Date: ${
                financials?.lastPaymentDate ? format(parseISO(financials?.lastPaymentDate), 'PP') : ''
              }`}</Typography>
            </Grid>

            {/* Charge Off, Past Due, Baloon Payment */}
            <Grid sx={{ mt: -1 }} xs={4}>
              <Typography variant="body2">{`Charged Off: ${currencyFormatter.format(financials?.amountChargeOff)}`}</Typography>
              <Typography variant="body2">{`Past Due: ${currencyFormatter.format(financials?.amountPastDue)}`}</Typography>
              <Typography variant="body2">{`Baloon Payment: ${currencyFormatter.format(financials?.amountBaloonPayment)}`}</Typography>
              <Typography variant="body2">{`Baloon Date: ${
                financials?.baloonPaymentDate ? format(parseISO(financials?.baloonPaymentDate), 'PP') : ''
              }`}</Typography>
            </Grid>
          </Grid>

          {/* Special Comment */}
          {comments?.specialComment && (
            <>
              <Typography sx={{ mt: '10px', fontWeight: 700 }}>Special Comments</Typography>
              <Typography variant="body2">{comments?.specialComment}</Typography>
            </>
          )}

          {/* Consumer Comment */}
          {comments?.consumerComment && (
            <>
              <Typography sx={{ mt: '10px', fontWeight: 700 }}>Consumer Comments</Typography>
              <Typography variant="body2">{comments?.consumerComment}</Typography>
            </>
          )}
        </div>
      </Card>
    </Grid>
  );
};

export default Tradeline;
