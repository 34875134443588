import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';

import { useFormie } from 'components/Formie';
import { Card, FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import { createSourceCompanyThunk, fetchSourceCompaniesThunk } from 'store/thunks/sourceCompany';
import { createLoanThunk } from 'store/thunks/loan';
import { setAlert } from 'store/sagas/app';
import { configuration, initialValues } from './formInfo';
import { LINKS } from 'constants/menus';
import AddSourceCompany from './AddSourceCompany';

const AddLoan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sourceCompanies, setSourceCompanies] = useState([]);
  const [addingCompanyError, setAddingCompanyError] = useState(false);
  const [open, setOpen] = useState(false);

  const lenderId = useSelector((state) => state.user?.active_profile?.lender_id);

  const formie = useFormie({
    configuration: configuration(sourceCompanies && sourceCompanies.map((company) => ({ label: company?.name, value: company?.id }))),
    initialValues,
    setLoading,
  });
  const { values, resetForm } = formie;

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { sourceCompanies } = await dispatch(fetchSourceCompaniesThunk()).unwrap();
      if (!sourceCompanies) {
        setSourceCompanies([]);
      } else {
        setSourceCompanies(sourceCompanies);
      }
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to find source companies for this lender. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const create = async () => {
    setLoading(true);
    try {
      // create the loan and redirect.
      const { id } = await dispatch(
        createLoanThunk({ lenderId, sourceCompanyId: values?.sourceCompanyId, collateral: values?.collateral, timezone: values?.timezone?.timezone }),
      ).unwrap();
      navigate(LINKS.LENDER.LOANS.VIEW_ONE(id, 'onboarding'));
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Error creating loan. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const createCompany = async (name) => {
    setLoading(true);
    try {
      await dispatch(createSourceCompanyThunk({ data: { name, lender_id: lenderId } })).unwrap();
      setAddingCompanyError(false);
      setOpen(false);
      await hydrateData();
      dispatch(
        setAlert({
          type: 'success',
          message: 'Source company created!',
          showing: true,
        }),
      );
    } catch (err) {
      setAddingCompanyError(true);
      dispatch(
        setAlert({
          type: 'error',
          message: 'Error creating source company. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (lenderId) {
      hydrateData();
    }
  }, [lenderId]);

  const formComplete =
    !!values?.collateral?.vin &&
    !!values?.collateral?.year &&
    !!values?.collateral?.make &&
    !!values?.collateral?.model &&
    !!values?.collateral?.condition &&
    !!values?.collateral?.mileage &&
    !!values?.timezone?.timezone;

  return (
    <FlexRow style={{ background: COLORS.backgroundColor }} justifyContent="center" alignItems="center" padding="20px" fullHeight>
      <Card variant="outlined" style={{ maxWidth: '800px' }}>
        <Backdrop sx={{ color: COLORS.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Typography variant="h4" gutterBottom>
          Create New Loan
        </Typography>

        <FlexRow margin="0px" padding="0px" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Where did this loan originate?</Typography>
          <Button onClick={() => setOpen(true)} size="small">
            Add Source
          </Button>
        </FlexRow>

        {formie.buildField('sourceCompanyId')}

        <Typography variant="h6" sx={{ mt: '32px', mb: '-8px' }}>
          What is the collateral?
        </Typography>
        {formie.buildFields({
          fields: [
            { dataPath: 'collateral.vin' },
            { dataPath: 'collateral.year' },
            { dataPath: 'collateral.make' },
            { dataPath: 'collateral.model' },
            { dataPath: 'collateral.condition' },
            { dataPath: 'collateral.mileage' },
          ],
          fieldsPerRow: 2,
        })}

        <Typography variant="h6" sx={{ mt: '32px', mb: '-8px' }}>
          Which timezone does the loan reside in?
        </Typography>
        {formie.buildFields({ fields: [{ dataPath: 'timezone.timezone' }] })}

        <FlexRow
          width="calc(100% + 40px)"
          padding="8px 20px"
          margin="10px -20px -20px"
          style={{ margin: '10px -10px -10px' }}
          justifyContent="flex-end"
          borderedTop
        >
          <Button onClick={resetForm} sx={{ mr: '24px' }}>
            Clear
          </Button>
          <Button onClick={create} disabled={!formComplete} variant="contained">
            Create
          </Button>
        </FlexRow>
      </Card>

      {open && (
        <AddSourceCompany
          setAddingCompanyError={setAddingCompanyError}
          addingCompanyError={addingCompanyError}
          createCompany={createCompany}
          setOpen={setOpen}
          open={open}
        />
      )}
    </FlexRow>
  );
};

export default AddLoan;
