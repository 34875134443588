import { formatISO, isValid } from 'date-fns';

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};

export const formatPhoneNumberV2 = (input) => {
  const cleanedInput = input.replace(/\D/g, '');

  let formattedNumber = '';
  for (let i = 0; i < cleanedInput.length; i++) {
    if (i === 3 || i === 6) {
      formattedNumber += '-';
    }
    formattedNumber += cleanedInput[i];
  }

  return formattedNumber;
};

export const formatSSN = (ssnString) => {
  let cleaned = ('' + ssnString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
});

export const percentageFormatterV2 = (value, digits) =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: digits ? digits : 2,
  }).format(value);

export const integerFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' });

export const roundAccurately = (number, decimalPlaces) => {
  return Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
};

export const formatNumber = (val) => {
  return val && Number(val.toString().replace(/[^0-9.]/g, ''));
};

export const formatStringNumber = (val) => {
  return val && val.toString().replace(/[^0-9.]/g, '');
};

export const stringNotEmpty = (val) => {
  return (typeof val === 'string' || val instanceof String) && val && val.replace(/\s/g, '').length > 0;
};

export const formatCreditCard = (creditCardString) => {
  return creditCardString.replace(/(.{4})/g, '$1 '); // Yes has trailing space but its not visible so its not a big deal.
};

export const formatCurrencyForDataGrid = (params) => {
  if (params.value == null) {
    return '';
  }
  return currencyFormatter.format(params.value);
};

export const formatDateOnly = (inputDate) => {
  if (!inputDate || inputDate.length !== 10) {
    return '';
  }

  const [year, month, day] = inputDate.split('-');
  const transformedDate = `${month}/${day}/${year.slice(-2)}`;
  return transformedDate;
};

export const parseDateOnlyForInputs = (inputDate) => {
  return inputDate && new Date(`${inputDate}T01:00:00`) && isValid(new Date(`${inputDate}T01:00:00`)) && formatISO(new Date(`${inputDate}T01:00:00`));
};
