import { Fragment, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { useDispatch } from 'react-redux';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';

import { ApplicationAPI, DocumentAPI } from 'store/api';
import { startAppLoading, stopAppLoading } from 'store/sagas/app';
import { fetchApplicationById } from 'store/sagas/applications';
import { Card, FlexRow, ListItem } from 'components/Layouts';
import { STATUS_LABELS } from 'constants/status';
import { generateDocuments } from 'store/sagas/applications';

const { PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING } = STATUS_LABELS;

const Signing = ({
  signing,
  hasCoApplicant,
  applicant,
  coApplicant,
  user,
  documents,
  currentStatus,
  appId,
  activeSection,
  SECTIONS,
  appLoading,
  overviewShouldShow,
  application,
}) => {
  const dispatch = useDispatch();
  const signingDocuments = documents.filter((doc) => doc?.document_template?.is_signable);
  const isDoneSigning = signingDocuments.length > 0 ? !!signingDocuments.map((doc) => doc.signed).reduce((curr, prev) => curr * prev) : false;

  useEffect(() => {
    const cb = () => {
      if (activeSection == SECTIONS.SIGNING && !appLoading && signing.ready_to_sign && !isDoneSigning && documents.length > 0) {
        dispatch(fetchApplicationById(appId));
      }
    };

    window.addEventListener('focus', cb);

    return () => {
      window.removeEventListener('focus', cb);
    };
  }, [activeSection, documents]);

  const generateDocumentsFn = () => {
    dispatch(
      generateDocuments({
        data: {
          dealer_employee_name: `${user.first_name} ${user.last_name}`,
          dealer_employee_email: user.email,
          dealer_employee_title: user.active_profile && user.active_profile.title ? user.active_profile.title : null,
        },
        applicationId: appId,
      }),
    );
  };

  const handleDownloadSigningDocuments = async () => {
    try {
      dispatch(startAppLoading('Please wait. This might take a while...'));
      const response = await ApplicationAPI.downloadSignedDocuments({ applicationId: appId });

      const blob = new Blob([response], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${appId}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(stopAppLoading());
    } catch (err) {
      dispatch(stopAppLoading());
      console.log(err);
    }
  };

  const getSigningLinkFn = async ({ email, role }) => {
    try {
      dispatch(startAppLoading());
      const { link } = await ApplicationAPI.getSigningLink(
        role === 'dealer'
          ? {
              applicationId: appId,
              role,
              dealerEmail: email,
            }
          : {
              applicationId: appId,
              role,
            },
      );
      dispatch(stopAppLoading());
      window.open(link);
    } catch (err) {
      console.log(`Error fetching singing link: ${err}`);
      dispatch(stopAppLoading());
    }
  };

  const downloadDocument = async (doc) => {
    try {
      dispatch(startAppLoading());

      if (doc.s3_id) {
        const { link } = await DocumentAPI.downloadDocumentFromS3({ documentId: doc.id });
        dispatch(stopAppLoading());
        window.open(link);
      } else if (doc.sign_now_id) {
        const { link } = await DocumentAPI.getDocumentDownloadLink({ signNowId: doc.sign_now_id });
        dispatch(stopAppLoading());
        window.open(link);
      } else {
        dispatch(stopAppLoading());
      }
    } catch (err) {
      dispatch(stopAppLoading());
    }
  };

  const signers = hasCoApplicant
    ? [
        { ...applicant, role: 'applicant' },
        { ...coApplicant, role: 'coApplicant' },
        { name: application.contract_values.dealer_employee_name, email: application.contract_values.dealer_employee_email, role: 'dealer' },
      ]
    : [
        { ...applicant, role: 'applicant' },
        { name: application.contract_values.dealer_employee_name, email: application.contract_values.dealer_employee_email, role: 'dealer' },
      ];

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="Signing.">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Signing
        </Typography>
      </FlexRow>

      {[PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus) ? (
        <Fragment>
          <FlexRow margin="12px 0px 0px" padding="0">
            <Alert sx={{ width: '100%' }} severity="success">
              <AlertTitle>{isDoneSigning ? 'Success!' : 'Documents Ready to Sign'}</AlertTitle>
              {isDoneSigning ? 'All Documents have been signed!' : 'These documents are ready to sign. Please have each party complete the signing ceremony.'}
            </Alert>
          </FlexRow>

          <Typography sx={{ margin: '20px 0px 10px' }} variant="h6">
            Signers
          </Typography>
          <Divider />
          {signers.map((signer) => {
            const name = signer.first_name ? `${signer.first_name} ${signer.last_name}` : signer.name;
            const { email, role } = signer;
            let signed;
            let formattedRole;

            if (role == 'applicant') {
              signed = signing.applicant_signed;
              formattedRole = 'Applicant';
            }
            if (role == 'coApplicant') {
              signed = signing.co_applicant_signed;
              formattedRole = 'Co-Applicant';
            }
            if (role == 'dealer') {
              signed = signing.dealer_signed;
              formattedRole = 'Dealer';
            }

            const signingLinkFn = () => {
              !signed && getSigningLinkFn({ email, role });
            };

            return (
              <ListItem
                key={role}
                primaryText={name}
                secondaryText={email ? `${formattedRole} | ${email}` : `${formattedRole}`}
                stackArray={!signed ? [{ display: !signed, label: 'Not Signed', icon: <DoDisturbIcon />, color: 'default' }] : []}
                icon={<AccountCircleIcon color="primary" sx={{ fontSize: '40px' }} />}
                action={
                  !signed ? (
                    <Button disabled={signed} variant="outlined" onClick={signingLinkFn}>
                      Sign
                    </Button>
                  ) : (
                    <Chip color="primary" label="Signed" icon={<CheckBoxIcon size="small" />} />
                  )
                }
              />
            );
          })}

          {!overviewShouldShow && (
            <Fragment>
              <FlexRow padding="0" margin="30px 0px 10px" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Documents</Typography>
                <Button startIcon={<DownloadIcon />} onClick={handleDownloadSigningDocuments}>
                  Download All
                </Button>
              </FlexRow>

              <Divider />
              {signingDocuments
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((doc) => (
                  <ListItem
                    key={doc.id}
                    primaryText={doc.name}
                    icon={<InsertDriveFileIcon color="primary" />}
                    stackArray={[
                      { display: doc.signed, label: 'Signed', icon: <CheckBoxIcon />, color: 'primary' },
                      { display: !doc.signed, label: 'Signing Incomplete', icon: <DoDisturbIcon />, color: 'default' },
                    ]}
                    action={
                      <IconButton onClick={() => downloadDocument(doc)}>
                        <VisibilityIcon />
                      </IconButton>
                    }
                  />
                ))}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <FlexRow margin="12px 0px 0px" padding="0">
            <Alert sx={{ width: '100%' }} severity="info">
              <AlertTitle>Documents Not Generated</AlertTitle>
              The signing documents have not yet been generated. Please generate them using the button below. This will lock the application so it cannot be
              edited.
            </Alert>
          </FlexRow>
          <FlexRow alignItems="center" justifyContent="center" margin="12px 0px 0px" padding="0">
            <Button onClick={generateDocumentsFn} startIcon={<RestorePageIcon />} variant="contained">
              Generate Documents & Lock Application
            </Button>
          </FlexRow>
        </Fragment>
      )}
    </Card>
  );
};

export default Signing;
