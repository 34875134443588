import { useState, useEffect, useCallback } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { differenceInCalendarDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { fetchLoanThunk } from 'store/thunks/loan';
import { setAlert } from 'store/sagas/app';
import { SectionContainer, SectionContentContainer } from '../styled-components';
import PrincipalRepayment from './PrincipalRepayment';
import Snapshot from './Snapshot';
import PaymentProgress from './PaymentProgress';
import PastDue from './PastDue';
import PaymentBreakdown from './PaymentBreakdown';
import AmountDue from './AmountDue';
import Position from './Position';
import Profitability from './Profitability';
import { currencyFormatter, percentageFormatterV2, roundAccurately } from 'constants/formatters';
import { FlexRow, Card } from 'components/Layouts';
import { COLORS } from 'constants/styles';

export const DataCard = ({ label, value, background, error, xs }) => {
  return (
    <Grid xs={xs || 3}>
      <Card fullHeight noOutline={!!background} backgroundColor={background && COLORS.primary} variant="outlined" padding="10px 20px">
        <FlexRow fullHeight padding="0px" margin="0px" flexColumn justifyContent="center" alignItems="center">
          <Typography sx={{ color: error ? COLORS.error : background ? COLORS.primaryColorContrastText : 'inherit' }} variant="h6">
            {value}
          </Typography>
          <Typography variant="body1" sx={{ color: background ? COLORS.primaryColorContrastTextFaded : 'text.secondary' }}>
            {label}
          </Typography>
        </FlexRow>
      </Card>
    </Grid>
  );
};

const Summary = ({ timezone }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loan, setLoan] = useState({});
  const { loanId } = useParams();

  const hydrateData = useCallback(async () => {
    setLoading(true);
    try {
      const { loan } = await dispatch(
        fetchLoanThunk({
          subdata: ['payment-periods', 'metrics', 'payments', 'fees'],
          loanId,
        }),
      ).unwrap();
      setLoan(loan);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch this loan. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [setLoading, dispatch, setLoan, loanId]);

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId, hydrateData]);

  const mostRecentMetrics = loan?.metrics && loan.metrics.length && loan.metrics.length > 0 ? loan.metrics[0] : {};
  const todayInLoansTimeZone = formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd');

  // #region [Payment Periods] ==============================================================================================================
  const paymentPeriodsPassed =
    loan.payment_periods && loan.payment_periods.length && loan.payment_periods.length > 0
      ? loan.payment_periods.filter((period) => new Date(period.due_date) < new Date(todayInLoansTimeZone))
      : [];

  const paymentPeriodsTodayAndFuture =
    loan.payment_periods && loan.payment_periods.length && loan.payment_periods.length > 0
      ? loan.payment_periods.filter((period) => new Date(period.due_date) >= new Date(todayInLoansTimeZone))
      : [];
  const paidPaymentPeriods = paymentPeriodsPassed.filter((period) => !!period.fulfillment_date);
  const unpaidPaymentPeriods = paymentPeriodsPassed.filter((period) => !period.fulfillment_date);
  const nextPaymentPeriodObj =
    paymentPeriodsTodayAndFuture.length > 0 ? paymentPeriodsTodayAndFuture.sort((a, b) => new Date(a.date) - new Date(b.date))[0] : {};
  // #endregion [Payment Periods] ===========================================================================================================

  // #region [Past Due] =====================================================================================================================
  const oldestPastDueObj = unpaidPaymentPeriods.length > 0 ? unpaidPaymentPeriods.sort((a, b) => new Date(a.date) - new Date(b.date))[0] : {};
  const totalAmountPastDue = mostRecentMetrics?.total_running_past_due_amount && Number(mostRecentMetrics?.total_running_past_due_amount);
  const oldestPastDue = oldestPastDueObj?.due_date;
  const oldestPastDueDaysOld = oldestPastDue ? differenceInCalendarDays(new Date(todayInLoansTimeZone), new Date(oldestPastDue)) : '-';
  const numberPaymentsPastDue = unpaidPaymentPeriods?.length;
  // #endregion [Past Due] ==================================================================================================================

  // #region [Repayment Metrics] ============================================================================================================
  const regularPaymentAmount = loan?.payment_amount && Number(loan.payment_amount);
  const originalPrincipal = loan?.principal_amount && Number(loan.principal_amount);
  const currentPrincipal = mostRecentMetrics?.total_running_principal_amount && Number(mostRecentMetrics?.total_running_principal_amount);
  const principalPaid = originalPrincipal && currentPrincipal ? roundAccurately(originalPrincipal - currentPrincipal, 2) : 0;
  const accruedInterest = mostRecentMetrics?.total_running_accrued_interest && Number(mostRecentMetrics?.total_running_accrued_interest);
  const payoffAmount = mostRecentMetrics?.payoff_amount && Number(mostRecentMetrics?.payoff_amount);
  const tenDayPayoff = mostRecentMetrics?.ten_day_payoff_amount && Number(mostRecentMetrics?.ten_day_payoff_amount);
  const paymentsMade = paidPaymentPeriods.length;
  const paymentsScheduled = paymentPeriodsPassed?.length;
  const totalPayments = loan?.terms;
  const maturityDate = loan?.last_payment_date;
  const nextPaymentDate = nextPaymentPeriodObj?.due_date;
  const nextPaymentAmount = nextPaymentPeriodObj?.payment_amount && Number(nextPaymentPeriodObj?.payment_amount);
  const totalInterestPaid = mostRecentMetrics?.total_interests_paid && Number(mostRecentMetrics?.total_interests_paid);
  const feesAmountDue = mostRecentMetrics?.total_running_fees ? Number(mostRecentMetrics?.total_running_fees) : 0;
  const totalFeesPaid = mostRecentMetrics?.total_fees_paid ? Number(mostRecentMetrics?.total_fees_paid) : 0;
  // #endregion [Repayment Metrics] =========================================================================================================

  // #region [Profitability] ================================================================================================================
  const chargedOff = 0; // TODO
  const profit = roundAccurately(totalInterestPaid + totalFeesPaid + chargedOff, 2);
  const position = roundAccurately(originalPrincipal - totalInterestPaid - totalFeesPaid - principalPaid, 2);
  const roi = roundAccurately(profit / originalPrincipal, 4);
  const moneyReturned = totalInterestPaid + totalFeesPaid + principalPaid;
  const percentageMoneyReturned = roundAccurately(moneyReturned / originalPrincipal, 4);
  // #endregion [Profitability] =============================================================================================================

  return (
    <SectionContainer padding="20px">
      <SectionContentContainer>
        {loading ? (
          <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
            <CircularProgress />
          </FlexRow>
        ) : (
          <Grid container spacing={2}>
            <DataCard background label="Current Profit" value={profit ? currencyFormatter.format(profit) : '-'} />
            <DataCard label="Total Collected" value={moneyReturned ? currencyFormatter.format(moneyReturned) : '-'} />
            <DataCard label="ROI" value={roi ? percentageFormatterV2(roi) : '-'} />
            <DataCard label="% Money Returned" value={percentageMoneyReturned ? percentageFormatterV2(percentageMoneyReturned) : '-'} />

            <Grid xs={6}>
              <PastDue oldestPastDueDaysOld={oldestPastDueDaysOld} totalAmountPastDue={totalAmountPastDue} numberPaymentsPastDue={numberPaymentsPastDue} />
            </Grid>

            <Grid xs={6}>
              <PrincipalRepayment principalPaid={principalPaid} currentPrincipal={currentPrincipal} originalPrincipal={originalPrincipal} />
            </Grid>

            <Grid xs={6}>
              <PaymentBreakdown processedPayments={loan?.payments ? loan?.payments.filter((payment) => payment.status === 'processed') : []} />
            </Grid>

            <Grid xs={6}>
              <Snapshot
                accruedInterest={accruedInterest}
                payoffAmount={payoffAmount}
                interestRate={loan?.interest_rate}
                regularPaymentAmount={regularPaymentAmount}
                currentPrincipal={currentPrincipal}
                originalPrincipal={originalPrincipal}
                feesAmountDue={feesAmountDue}
                tenDayPayoff={tenDayPayoff}
              />
            </Grid>

            <Grid xs={6}>
              <AmountDue
                totalAmountPastDue={totalAmountPastDue}
                nextPaymentDate={nextPaymentDate}
                nextPaymentAmount={nextPaymentAmount}
                feesAmountDue={feesAmountDue}
              />
            </Grid>

            <Grid xs={6}>
              <PaymentProgress
                nextPaymentDate={nextPaymentDate}
                maturityDate={maturityDate}
                paymentsMade={paymentsMade}
                paymentsScheduled={paymentsScheduled}
                totalPayments={totalPayments}
              />
            </Grid>

            <Grid xs={6}>
              <Profitability profit={profit} interestPaid={totalInterestPaid} feesPaid={totalFeesPaid} chargedOff={chargedOff} />
            </Grid>

            <Grid xs={6}>
              <Position
                position={position}
                interestPaid={totalInterestPaid}
                feesPaid={totalFeesPaid}
                originalPrincipal={originalPrincipal}
                principalPaid={principalPaid}
              />
            </Grid>
          </Grid>
        )}
      </SectionContentContainer>
    </SectionContainer>
  );
};

export default Summary;
