import { SectionContainer, SectionContentContainer, ViewLoanPageLayout } from '../styled-components';

const Reporting = () => {
  const SECTIONS = {
    DEFAULT: 'default',
  };

  const menuItems = [{ label: 'Default', value: SECTIONS.DEFAULT }];

  return (
    <ViewLoanPageLayout defaultItem={SECTIONS.DEFAULT} menuItems={menuItems}>
      <SectionContainer>
        <SectionContentContainer>Reporting</SectionContentContainer>
      </SectionContainer>
    </ViewLoanPageLayout>
  );
};

export default Reporting;
