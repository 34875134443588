import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import { getApplicationPaymentProfilesThunk } from 'store/thunks/application';
import PaymentProfilesContext from '../context';
import CCPaymentProfileFormModal from '../CCPaymentProfileFormModal';
import PaymentProfileOwnerModal from './PaymentProfileOwnerModal';
import ExistingPaymentProfilesModal from './ExistingPaymentProfilesModal';

const AddNewPaymentProfile = ({ currentApplication }) => {
  const applicantId = currentApplication?.applicant?.id;
  const coApplicantId = currentApplication?.co_applicant?.id;
  const applicationId = currentApplication?.id;
  const paymentProfiles = currentApplication?.payment_profiles ?? [];

  const dispatch = useDispatch();
  const { paymentProfilesSectionProcessing, setPaymentProfilesSectionProcessing, setPaymentProfilesSectionErrorMsg, setShowPaymentProfilesSectionError } =
    useContext(PaymentProfilesContext);
  const [fetchingExistingPaymentProfiles, setFetchingExistingPaymentProfiles] = useState(false);
  const [existingPaymentProfiles, setExistingPaymentProfiles] = useState([]);
  const [showProfileOwnerModal, setShowProfileOwnerModal] = useState(false);
  const [showCCPPFormModal, setShowCCPPFormModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const addBank = async () => {
    try {
      setFetchingExistingPaymentProfiles(true);
      const response = await dispatch(getApplicationPaymentProfilesThunk({ applicantId, coApplicantId, applicationId })).unwrap();
      setFetchingExistingPaymentProfiles(false);

      if (response.paymentProfiles.length > 0) {
        setExistingPaymentProfiles(response.paymentProfiles);
      } else {
        setShowProfileOwnerModal(true);
      }
    } catch (err) {
      console.log('Fetching existing payment profiles error: ', err);

      setPaymentProfilesSectionErrorMsg('Failed to fetch existing payment profiles.');
      setShowPaymentProfilesSectionError(true);

      setFetchingExistingPaymentProfiles(false);
      setExistingPaymentProfiles([]);
    }
  };

  const handleAddButtonClick = async (event) => {
    if (!paymentProfiles.length) {
      /**
       * If there is no payment profile yet, show the add bank pop immediately.
       * We don't need to show credit card pop up as we do not allow credit cards as primary payment profiles.
       */
      await addBank();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleAddBankClick = async () => {
    setPaymentProfilesSectionProcessing(true);
    setAnchorEl(null);
    await addBank();
  };
  const handleAddCreditCardClick = () => {
    setPaymentProfilesSectionProcessing(true);
    setAnchorEl(null);
    setShowCCPPFormModal(true);
  };
  const handelMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
        <div
          style={{
            padding: '20px 0',
            boxSizing: 'border-box',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F4F4F4',
            height: '100%',
            borderRadius: '5px',
          }}
        >
          <IconButton
            size="large"
            color="primary"
            sx={{ fontSize: '5rem' }}
            disabled={paymentProfilesSectionProcessing || fetchingExistingPaymentProfiles || existingPaymentProfiles.length > 0}
            onClick={handleAddButtonClick}
          >
            {fetchingExistingPaymentProfiles || existingPaymentProfiles.length > 0 || showProfileOwnerModal || showCCPPFormModal ? (
              <CircularProgress size="5rem" fontSize="inherit" />
            ) : (
              <AddCircleIcon fontSize="inherit" />
            )}
          </IconButton>

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handelMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuItem onClick={handleAddBankClick}>
              <Stack direction="row" spacing={1}>
                <AccountBalanceOutlinedIcon />
                <Typography>Bank</Typography>
              </Stack>
            </MenuItem>

            <MenuItem onClick={handleAddCreditCardClick}>
              <Stack direction="row" spacing={1}>
                <CreditCardOutlinedIcon />
                <Typography>Credit/Debit Card</Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </div>

        {existingPaymentProfiles.length > 0 && (
          <ExistingPaymentProfilesModal
            currentApplication={currentApplication}
            applicantId={applicantId}
            applicationId={applicationId}
            existingPaymentProfiles={existingPaymentProfiles}
            onClose={() => setExistingPaymentProfiles([])}
          />
        )}
      </Grid>

      {showProfileOwnerModal && (
        <PaymentProfileOwnerModal
          currentApplication={currentApplication}
          open={showProfileOwnerModal}
          onClose={() => {
            setShowProfileOwnerModal(false);
            setPaymentProfilesSectionProcessing(false);
          }}
        />
      )}

      {showCCPPFormModal && (
        <CCPaymentProfileFormModal
          applicationId={applicationId}
          accountOwner={'applicant'}
          firstName={currentApplication?.applicant?.first_name}
          lastName={currentApplication?.applicant?.last_name}
          onSuccess={() => {
            setShowCCPPFormModal(false);
            setPaymentProfilesSectionProcessing(false);
          }}
          onClose={() => {
            setShowCCPPFormModal(false);
            setPaymentProfilesSectionProcessing(false);
          }}
        />
      )}
    </>
  );
};

export default AddNewPaymentProfile;
