import { Chip } from '@mui/material';

import { ActionMenu } from 'components/Menu';
import { currencyFormatter, percentageFormatterV2, roundAccurately, formatDateOnly, formatCurrencyForDataGrid } from 'constants/formatters';
import { COLORS } from 'constants/styles';

export const FEE_TYPES = {
  LATE_PAYMENT_FEE: 'late-payment-fee',
  NSF_FEE: 'nsf-fee',
};

export const gridSx = {
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
  '& .payment-theme--Edited': {
    backgroundColor: COLORS.disabledColor,
    color: COLORS.fadedTextColor,
    '&:hover': {
      backgroundColor: COLORS.disabledColor,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.disabledColor,
      '&:hover': {
        backgroundColor: COLORS.disabledColor,
      },
    },
  },
  '& .payment-theme--Reversed': {
    backgroundColor: COLORS.fadedError,
    color: COLORS.fadedTextColor,
    '&:hover': {
      backgroundColor: COLORS.fadedError,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.fadedError,
      '&:hover': {
        backgroundColor: COLORS.fadedError,
      },
    },
  },
  '& .payment-theme--Waived': {
    backgroundColor: COLORS.fadedError,
    color: COLORS.fadedTextColor,
    '&:hover': {
      backgroundColor: COLORS.fadedError,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.fadedError,
      '&:hover': {
        backgroundColor: COLORS.fadedError,
      },
    },
  },
};

export const findStatus = (fee) => {
  if (fee.status === 'waived' || fee.status === 'reversed' || fee.status === 'edited')
    return `${fee.status.split('')[0].toUpperCase()}${fee.status.split('').slice(1).join('')}`;
  else if (Number(fee.amount) - Number(fee.amount_paid) === 0) return 'Paid';
  else return 'Unpaid';
};

export const findColor = (status) => {
  if (status === 'Unpaid') return 'error';
  else if (status === 'Paid') return 'success';
  return 'default';
};

export const findStatistics = (fee) => {
  const amountCharged = fee?.amount ? currencyFormatter.format(Number(fee.amount)) : currencyFormatter.format(0);
  const amountPaid = fee?.amount_paid ? currencyFormatter.format(Number(fee.amount_paid)) : currencyFormatter.format(0);
  const amountRemaining =
    fee?.amount && fee?.amount_paid ? currencyFormatter.format(Number(fee.amount) - Number(fee.amount_paid)) : currencyFormatter.format(0);
  const percentagePaid = fee?.amount_paid && fee?.amount ? roundAccurately(fee.amount_paid / fee.amount, 4) : 0;
  const percentagePaidFormatted = percentageFormatterV2(percentagePaid);
  const paid = fee?.amount && fee?.amount_paid && !(Number(fee.amount) - Number(fee.amount_paid) > 0);
  const date = fee?.fee_date ? formatDateOnly(fee?.fee_date) : '-';
  const paidDate = fee?.fully_paid_date ? formatDateOnly(fee?.fully_paid_date) : '-';
  const editedDate = fee?.edited_date ? formatDateOnly(fee?.edited_date) : '-';
  const waivedDate = fee?.waived_date ? formatDateOnly(fee?.waived_date) : '-';
  const reversedDate = fee?.reversed_date ? formatDateOnly(fee?.reversed_date) : '-';
  const status = findStatus(fee);

  return {
    amountCharged,
    amountPaid,
    amountRemaining,
    percentagePaid,
    percentagePaidFormatted,
    paid,
    date,
    paidDate,
    editedDate,
    waivedDate,
    reversedDate,
    status,
  };
};

export const createColumns = ({ reverseFee, showDetails, editFee }) => [
  {
    field: 'date',
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    headerName: 'Date',
    width: 90,
    valueFormatter: (params) => formatDateOnly(params.value),
  },
  {
    field: 'title',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Title',
    flex: 1.5,
  },
  {
    field: 'amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Amount Due',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'amount_paid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Amount Paid',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'status',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Status',
    flex: 1,
    renderCell: (props) => {
      if (props.value === 'Paid') return <Chip size="small" label={props.value} color="success" />;
      else if (props.value === 'Unpaid') return <Chip size="small" label={props.value} color="error" />;
      else return <Chip size="small" label={props.value} />;
    },
  },
  {
    field: 'action',
    headerName: '',
    editable: false,
    width: 50,
    renderCell: (props) => {
      if (props.row.status === 'Edited' || props.row.status === 'Reversed' || props.row.status === 'Waived') return null;

      const options = [
        { value: 'View Details', callback: () => showDetails(props.id) },
        { value: 'Edit', callback: () => editFee(props) },
        { value: 'Reverse', callback: () => reverseFee(props.id) },
      ];
      return <ActionMenu options={options} />;
    },
  },
];
