import { differenceInCalendarDays, subDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { formatCurrencyForDataGrid, formatDateOnly } from 'constants/formatters';

export const options = [
  { value: '30', label: 'Last 30 Days' },
  { value: '60', label: 'Last 60 Days' },
  { value: '90', label: 'Last 90 Days' },
  { value: 'year', label: 'This Year' },
  { value: 'time', label: 'All time' },
];

export const createFetchConfiguration = (rangeOption, contractDate, timezone) => {
  const todayInBrowserTimeZone = new Date();
  const todayInLoansTimeZone = formatInTimeZone(todayInBrowserTimeZone, timezone, 'yyyy-MM-dd');

  let startOfRangeInLoansTimeZone, limit;

  if (Number(rangeOption)) {
    limit = Number(rangeOption);
    const startOfRangeInBrowserTimeZone = subDays(todayInBrowserTimeZone, Number(rangeOption) - 1);
    startOfRangeInLoansTimeZone = formatInTimeZone(startOfRangeInBrowserTimeZone, timezone, 'yyyy-MM-dd');
  } else if (rangeOption === 'year') {
    startOfRangeInLoansTimeZone = `${todayInLoansTimeZone.split('-')[0]}-01-01`;
    limit = differenceInCalendarDays(new Date(todayInLoansTimeZone), new Date(startOfRangeInLoansTimeZone)) + 1;
  } else if (rangeOption === 'time') {
    startOfRangeInLoansTimeZone = contractDate;
    limit = differenceInCalendarDays(new Date(todayInLoansTimeZone), new Date(startOfRangeInLoansTimeZone)) + 1;
  }

  return {
    to_date: todayInLoansTimeZone,
    from_date: startOfRangeInLoansTimeZone,
    limit: limit,
  };
};

export const columns = [
  {
    field: 'date',
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    headerName: 'Date',
    minWidth: 80,
    flex: 1,
    valueFormatter: (params) => formatDateOnly(params.value),
  },
  {
    field: 'total_running_principal_amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Principal',
    minWidth: 100,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_running_accrued_interest',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Unpaid Interest',
    minWidth: 120,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_running_fees',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    minWidth: 100,
    flex: 1,
    headerName: 'Unpaid Fees',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'payoff_amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    minWidth: 100,
    flex: 1,
    headerName: 'Payoff',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_running_past_due_amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    minWidth: 100,
    flex: 1,
    headerName: 'Past Due',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'diem_interest',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Diem Interest',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'diem_fees',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    minWidth: 100,
    flex: 1,
    headerName: 'Diem Fees',
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'diem_past_due_amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Diem Past Due',
    minWidth: 120,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_principal_paid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Principal Paid',
    minWidth: 110,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_interests_paid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Interest Paid',
    minWidth: 110,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'total_fees_paid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    minWidth: 100,
    flex: 1,
    headerName: 'Fees Paid',
    valueFormatter: formatCurrencyForDataGrid,
  },
];
