import { METHODS, request } from 'services/axios';

const URIS = {
  //APPLICATION
  UPDATE_APPLICATION: ({ applicationId }) => `/applications/${applicationId}`,
  UPDATE_APPLICATION_STATUS: ({ applicationId }) => `/applications/${applicationId}/status`,
  UPDATE_APPROVED_APPLICATION: ({ applicationId }) => `/applications/${applicationId}/approved`,
  SUBMIT_APPLICATION: ({ applicationId }) => `/applications/${applicationId}/submit`,
  CREATE_APPLICATION: () => `/applications`,
  FETCH_APPLICATION_BY_ID: ({ id }) => `/applications/${id}`,
  UPDATE_LENDING_OPTION: ({ applicationId, lendingOptionId }) => `/applications/${applicationId}/lending-options/${lendingOptionId}`,
  CREATE_LENDING_OPTION: ({ applicationId }) => `/applications/${applicationId}/lending-options`,
  GENERATE_DOCUMENTS: ({ applicationId }) => `/applications/${applicationId}/generate-documents`,
  DOWNLOAD_SIGNED_DOCUMENTS: ({ applicationId }) => `/applications/${applicationId}/download-signed-documents`,
  UPDATE_STIPULATION: ({ stipulationId }) => `/stipulations/${stipulationId}`,
  FETCH_SIGNING_LINK: ({ applicationId, role, dealerEmail }) =>
    dealerEmail
      ? `/applications/${applicationId}/signing-link?role=${role}&dealerEmail=${dealerEmail}`
      : `/applications/${applicationId}/signing-link?role=${role}`,
  FETCH_APPLICATION_SHAPE: () => '/applications/helpers/shape',
  FETCH_APPLICATION_TYPES: () => `/application-types`,

  // Application Payment Profiles
  APPLICATION_PLAID_LINK_TOKEN: ({ applicationId }) => `/applications/${applicationId}/link-token`,
  APPLICATION_PLAID_ACCESS_TOKEN: ({ applicationId }) => `/applications/${applicationId}/access-token`,
  CONNECT_APPLICATION_PAYMENT_PROFILE: ({ applicationId }) => `/applications/${applicationId}/payment-profiles/connect`,
  CONNECT_EXISTING_APPLICATION_PAYMENT_PROFILE: ({ applicationId }) => `/applications/${applicationId}/payment-profiles/connect-existing`,
  APPLICATION_PAYMENT_PROFILES: ({ applicationId }) => `/applications/${applicationId}/payment-profiles`,
  APPLICATION_PRIMARY_PAYMENT_PROFILE: ({ applicationId }) => `/applications/${applicationId}/payment-profiles/primary`,
  APPLICATION_CREDIT_CARD_PAYMENT_PROFILE: ({ applicationId }) => `/applications/${applicationId}/payment-profiles/credit-card`,

  // Application References
  APPLICATION_REFERENCES: ({ applicationId }) => `/applications/${applicationId}/references`,
  APPLICATION_REFERENCE: ({ applicationId, referenceId }) => `/applications/${applicationId}/references/${referenceId}`,
  APPLICATION_REFERENCE_SEND_OTP: ({ applicationId, referenceId }) => `/applications/${applicationId}/references/${referenceId}/send-otp`,
  APPLICATION_REFERENCE_CHECK_OTP: ({ applicationId, referenceId }) => `/applications/${applicationId}/references/${referenceId}/check-otp`,
};

export default {
  updateApplication: ({ data, applicationId }) =>
    request({
      url: URIS.UPDATE_APPLICATION({ applicationId }),
      method: METHODS.PATCH,
      data,
    }),

  updateApplicationStatus: ({ data, applicationId }) =>
    request({
      url: URIS.UPDATE_APPLICATION_STATUS({ applicationId }),
      method: METHODS.PATCH,
      data,
    }),

  updateApprovedApplication: ({ data, applicationId }) =>
    request({
      url: URIS.UPDATE_APPROVED_APPLICATION({ applicationId }),
      method: METHODS.PATCH,
      data,
    }),

  submitApplication: ({ data, applicationId }) =>
    request({
      url: URIS.SUBMIT_APPLICATION({ applicationId }),
      method: METHODS.POST,
      data,
    }),

  createApplication: ({ data }) =>
    request({
      url: URIS.CREATE_APPLICATION(),
      method: METHODS.POST,
      data,
    }),

  fetchApplicationById: ({ id }) =>
    request({
      url: URIS.FETCH_APPLICATION_BY_ID({ id }),
      method: METHODS.GET,
    }),

  updateLendingOption: ({ applicationId, lendingOptionId, data }) =>
    request({
      url: URIS.UPDATE_LENDING_OPTION({ applicationId, lendingOptionId }),
      method: METHODS.PATCH,
      data,
    }),

  addLendingOption: ({ applicationId, data }) =>
    request({
      url: URIS.CREATE_LENDING_OPTION({ applicationId }),
      method: METHODS.POST,
      data,
    }),

  generateDocuments: ({ applicationId, data }) =>
    request({
      url: URIS.GENERATE_DOCUMENTS({ applicationId }),
      method: METHODS.POST,
      data,
    }),

  downloadSignedDocuments: ({ applicationId }) =>
    request({
      url: URIS.DOWNLOAD_SIGNED_DOCUMENTS({ applicationId }),
      method: METHODS.GET,
      responseType: 'arraybuffer',
    }),

  updateStipulation: ({ stipulationId, data }) =>
    request({
      url: URIS.UPDATE_STIPULATION({ stipulationId }),
      method: METHODS.PATCH,
      data,
    }),

  getSigningLink: ({ applicationId, role, dealerEmail = undefined }) =>
    request({
      url: URIS.FETCH_SIGNING_LINK({ applicationId, role, dealerEmail }),
      method: METHODS.GET,
    }),

  getApplicationShape: () =>
    request({
      url: URIS.FETCH_APPLICATION_SHAPE(),
      method: METHODS.GET,
    }),

  fetchApplicationTypes: () =>
    request({
      url: URIS.FETCH_APPLICATION_TYPES(),
      method: METHODS.GET,
    }),

  // #region [Application Payment Profiles] ====================================================================================================================

  generateApplicationLinkToken: ({ applicantId, applicationId, profileType = null }) =>
    request({
      url: URIS.APPLICATION_PLAID_LINK_TOKEN({ applicationId }),
      method: METHODS.POST,
      data: { applicant_id: applicantId, profile_type: profileType },
    }),
  generateApplicationAccessToken: ({
    applicationId,
    applicantId,
    accountOwner,
    accountName,
    publicToken,
    accountId,
    institutionId = null,
    institutionName = null,
    verificationStatus,
  }) =>
    request({
      url: URIS.APPLICATION_PLAID_ACCESS_TOKEN({ applicationId }),
      method: METHODS.POST,
      data: {
        applicant_id: applicantId,
        account_owner: accountOwner,
        account_name: accountName,
        public_token: publicToken,
        account_id: accountId,
        institution_id: institutionId,
        institution_name: institutionName,
        verification_status: verificationStatus,
      },
    }),
  updateApplicationAccessToken: ({ applicationId, profileType, verificationStatus }) =>
    request({
      url: URIS.APPLICATION_PLAID_ACCESS_TOKEN({ applicationId }),
      method: METHODS.PATCH,
      data: { profile_type: profileType, verification_status: verificationStatus },
    }),
  connectApplicationPaymentProfile: ({ applicationId, profileType }) =>
    request({
      url: URIS.CONNECT_APPLICATION_PAYMENT_PROFILE({ applicationId }),
      method: METHODS.POST,
      data: { profile_type: profileType },
    }),
  connectExistingApplicationPaymentProfile: ({ applicationId, accountOwner, accountName, itemId }) =>
    request({
      url: URIS.CONNECT_EXISTING_APPLICATION_PAYMENT_PROFILE({ applicationId }),
      method: METHODS.POST,
      data: { account_owner: accountOwner, account_name: accountName, item_id: itemId },
    }),
  updateApplicationPaymentProfile: ({
    applicationId,
    profileType,
    institutionName,
    accountType = null,
    accountName = null,
    accountNumber = null,
    routingNumber = null,
  }) =>
    request({
      url: URIS.APPLICATION_PAYMENT_PROFILES({ applicationId }),
      method: METHODS.PATCH,
      data: {
        profile_type: profileType,
        institution_name: institutionName,
        account_type: accountType,
        account_name: accountName,
        account_number: accountNumber,
        routing_number: routingNumber,
      },
    }),
  setApplicationPrimaryPaymentProfile: ({ applicationId, profileType }) =>
    request({
      url: URIS.APPLICATION_PRIMARY_PAYMENT_PROFILE({ applicationId }),
      method: METHODS.PATCH,
      data: { profile_type: profileType },
    }),
  deleteApplicationPaymentProfile: ({ applicationId, profileType }) =>
    request({
      url: URIS.APPLICATION_PAYMENT_PROFILES({ applicationId }),
      method: METHODS.DELETE,
      data: { profile_type: profileType },
    }),
  getApplicationPaymentProfiles: ({ applicationId, applicantId, coApplicantId }) =>
    request({
      url: URIS.APPLICATION_PAYMENT_PROFILES({ applicationId }),
      method: METHODS.GET,
      params: { applicant_id: applicantId, co_applicant_id: coApplicantId },
    }),
  createApplicationCreditCardPaymentProfile: ({ applicationId, data }) =>
    request({
      url: URIS.APPLICATION_CREDIT_CARD_PAYMENT_PROFILE({ applicationId }),
      method: METHODS.POST,
      data,
    }),

  // #endregion

  // #region [Application References] ==========================================================================================================================

  createApplicationReference: ({ applicationId, data }) => request({ url: URIS.APPLICATION_REFERENCES({ applicationId }), method: METHODS.POST, data }),

  updateApplicationReference: ({ applicationId, referenceId, data }) =>
    request({ url: URIS.APPLICATION_REFERENCE({ applicationId, referenceId }), method: METHODS.PATCH, data }),

  deleteApplicationReference: ({ applicationId, referenceId }) =>
    request({ url: URIS.APPLICATION_REFERENCE({ applicationId, referenceId }), method: METHODS.DELETE }),

  applicationReferenceSendOTP: ({ applicationId, referenceId, data }) =>
    request({ url: URIS.APPLICATION_REFERENCE_SEND_OTP({ applicationId, referenceId }), method: METHODS.POST, data }),

  applicationReferenceCheckOTP: ({ applicationId, referenceId, data }) =>
    request({ url: URIS.APPLICATION_REFERENCE_CHECK_OTP({ applicationId, referenceId }), method: METHODS.POST, data }),

  // #endregion
};
