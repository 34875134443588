import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const SettingsGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0;
  ${({ noPadding }) => `padding: ${noPadding ? '0px' : '20px 0px 0px'}`};
  gap: 20px;
  align-items: center;
  justify-items: start;
`;
