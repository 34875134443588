import { useDispatch } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import { addLendingOption } from 'store/sagas/applications';
import { useFormie } from 'components/Formie';

import { prepareLendingOptionProposalConfiguation, initialValues } from './forminfo';

const ProposeLendingOptionModal = ({ applicationId, dealerId, open, onClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(addLendingOption({ ...values, applicationId, dealer_id: dealerId }));
    onClose();
  };

  const formie = useFormie({
    configuration: prepareLendingOptionProposalConfiguation,
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <Dialog scroll="paper" fullWidth open={open}>
      <DialogTitle>Request Lending Option</DialogTitle>

      <DialogContent>
        {formie.buildFields({
          fields: [{ dataPath: 'max_amount_loaned' }, { dataPath: 'min_percent_down' }, { dataPath: 'term' }, { dataPath: 'apr' }],
          fieldsPerRow: 2,
        })}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={formie.handleSubmit} variant="contained">
          Request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProposeLendingOptionModal;
