import { baseTextField, baseEmailField, basePasswordField } from 'components/Formiks - Deprecated/constants';

export const userFormInfo = {
  email: {
    ...baseEmailField,
    label: 'Email',
    errorText: 'Please provide a valid email.',
    parameterName: 'email',
  },
  first_name: {
    ...baseTextField,
    label: 'First Name',
    errorText: 'Please provide a valid first name.',
    parameterName: 'first_name',
  },
  last_name: {
    ...baseTextField,
    label: 'Last Name',
    errorText: 'Please provide a valid last name.',
    parameterName: 'last_name',
  },
  title: {
    ...baseTextField,
    label: 'Title',
    errorText: 'Please provide a valid title.',
    parameterName: 'title',
  },
  password: {
    ...basePasswordField,
    label: 'Password',
    parameterName: 'password',
  },
  confirm_password: {
    ...basePasswordField,
    label: 'Confirm Password',
    parameterName: 'confirm_password',
  },
};
