import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_ALL_STIPULATION_TEMPLATES: ({ lenderId }) => `/stipulation-templates/lender/${lenderId}`,
  CREATE_STIPULATION_TEMPLATE: () => '/stipulation-templates',
  UPDATE_STIPULATION_TEMPLATE: ({ templateId }) => `/stipulation-templates/${templateId}`,
};

export default {
  getAllStipulationTemplates: ({ lenderId }) =>
    request({
      url: URIS.FETCH_ALL_STIPULATION_TEMPLATES({ lenderId }),
      method: METHODS.GET,
    }),

  createStipulationTemplate: ({ data }) =>
    request({
      url: URIS.CREATE_STIPULATION_TEMPLATE(),
      method: METHODS.POST,
      data,
    }),

  updateStipulationTemplate: ({ templateId, data }) =>
    request({
      url: URIS.UPDATE_STIPULATION_TEMPLATE({ templateId }),
      method: METHODS.PUT,
      data,
    }),

  archiveStipulationTemplate: ({ templateId, data }) =>
    request({
      url: URIS.UPDATE_STIPULATION_TEMPLATE({ templateId }),
      method: METHODS.DELETE,
      data,
    }),
};
