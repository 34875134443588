import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_LOAN_PAYMENTS: ({ loanId }) => `/servicing/loans/${loanId}/payments`,
  CREATE_PAYMENT: ({ loanId }) => `/servicing/loans/${loanId}/payments`,
  EDIT_PAYMENT: ({ loanId, paymentId }) => `/servicing/loans/${loanId}/payments/${paymentId}`,
};

export default {
  fetchLoanPayments: ({ queryString, loanId }) =>
    request({
      url: `${URIS.FETCH_LOAN_PAYMENTS({ loanId })}${queryString || ''}`,
      method: METHODS.GET,
    }),

  createLoanPayment: ({ loanId, data }) =>
    request({
      url: URIS.CREATE_PAYMENT({ loanId }),
      method: METHODS.POST,
      data,
    }),

  editLoanPayment: ({ loanId, paymentId, data }) =>
    request({
      url: URIS.EDIT_PAYMENT({ loanId, paymentId }),
      method: METHODS.PATCH,
      data,
    }),

  reverseLoanPayment: ({ loanId, paymentId }) =>
    request({
      url: URIS.EDIT_PAYMENT({ loanId, paymentId }),
      method: METHODS.DELETE,
    }),
};
