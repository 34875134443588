import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_LOAN_PAYMENT_PERIODS: ({ loanId }) => `/servicing/loans/${loanId}/payment-periods`,
};

export default {
  fetchLoanPaymentPeriods: ({ queryString, loanId }) =>
    request({
      url: `${URIS.FETCH_LOAN_PAYMENT_PERIODS({ loanId })}${queryString || ''}`,
      method: METHODS.GET,
    }),
};
