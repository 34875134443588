import { METHODS, request } from 'services/axios';

const URIS = {
  CREATE_LOAN: () => `/servicing/loans`,
  CREATE_CUSTOMER: ({ loanId }) => `/servicing/loans/${loanId}/customers`,
  FETCH_LOAN: ({ loanId }) => `/servicing/loans/${loanId}`,
  EDIT_CUSTOMER_ROLES: ({ loanId }) => `/servicing/loans/${loanId}/customers`,
  GENERATE_TERMS: () => `/servicing/loans/generate-terms`,
  UPDATE_LOAN: ({ loanId }) => `/servicing/loans/${loanId}`,
  UPDATE_LOAN_STATUS: ({ loanId }) => `/servicing/loans/${loanId}/status`,
  ACTIVATE_LOAN: ({ loanId }) => `/servicing/loans/${loanId}/activate`,
  DEACTIVATE_LOAN: ({ loanId }) => `/servicing/loans/${loanId}/deactivate`,
};

export default {
  createLoan: ({ data }) =>
    request({
      url: URIS.CREATE_LOAN(),
      method: METHODS.POST,
      data,
    }),

  fetchLoan: ({ queryString, loanId }) =>
    request({
      url: `${URIS.FETCH_LOAN({ loanId })}${queryString || ''}`,
      method: METHODS.GET,
    }),

  createCustomer: ({ data, loanId }) =>
    request({
      url: URIS.CREATE_CUSTOMER({ loanId }),
      method: METHODS.POST,
      data,
    }),

  editCustomerRoles: ({ loanId, data }) =>
    request({
      url: URIS.EDIT_CUSTOMER_ROLES({ loanId }),
      method: METHODS.PATCH,
      data,
    }),

  generateTerms: ({ data }) =>
    request({
      url: URIS.GENERATE_TERMS(),
      method: METHODS.POST,
      data,
    }),

  updateLoan: ({ data, loanId }) =>
    request({
      url: URIS.UPDATE_LOAN({ loanId }),
      method: METHODS.PATCH,
      data,
    }),

  updateLoanStatus: ({ data, loanId }) =>
    request({
      url: URIS.UPDATE_LOAN_STATUS({ loanId }),
      method: METHODS.PATCH,
      data,
    }),

  activateLoan: ({ loanId }) =>
    request({
      url: URIS.ACTIVATE_LOAN({ loanId }),
      method: METHODS.PATCH,
    }),

  deactivateLoan: ({ loanId }) =>
    request({
      url: URIS.DEACTIVATE_LOAN({ loanId }),
      method: METHODS.PATCH,
    }),
};
