import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';

import { formatPhoneNumber } from 'constants/formatters';

const Reference = ({ reference }) => {
  const { first_name, last_name, phone, phone_verified, email, email_verified } = reference;

  return (
    <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
      <Card>
        <CardHeader
          sx={{ backgroundColor: '#5301FF', minHeight: '60px' }}
          avatar={
            <Avatar sx={{ width: 52, height: 52, backgroundColor: '#fff' }} aria-label="Bank logo">
              <PersonIcon sx={{ width: 38, height: 38, color: '#5301FF' }} />
            </Avatar>
          }
          title={
            <Typography sx={{ color: '#fff' }} variant="subtitle2" gutterBottom>
              {`${first_name} ${last_name}`}
            </Typography>
          }
          subheader={
            phone_verified && email_verified ? (
              <Chip size="small" label="Verified" color="success" />
            ) : !phone_verified && !email_verified ? (
              <Chip size="small" label="Unverified" color="error" />
            ) : (
              <Chip size="small" label="Partially Verified" color="warning" />
            )
          }
        />

        <CardContent>
          <Stack direction="row" spacing={1} alignContent={'center'} alignItems={'center'}>
            <Typography sx={{ fontWeight: 600, marginBottom: 0 }} variant="body2" gutterBottom>
              Phone:
            </Typography>

            <Typography variant="body2">{formatPhoneNumber(phone)}</Typography>

            {phone_verified ? (
              <Typography color="primary" sx={{ fontSize: '18px' }} variant="body2" gutterBottom>
                <VerifiedIcon sx={{ fontSize: 'inherit', position: 'relative', top: '2px' }} />
              </Typography>
            ) : (
              <Typography sx={{ color: 'red', fontSize: '18px' }} variant="body2" gutterBottom>
                <NewReleasesOutlinedIcon sx={{ fontSize: 'inherit', position: 'relative', top: '2px' }} />
              </Typography>
            )}
          </Stack>

          <Stack direction="row" spacing={1} alignContent={'center'} alignItems={'center'}>
            <Typography sx={{ fontWeight: 600, marginBottom: 0 }} variant="body2" gutterBottom>
              Email:
            </Typography>

            <Typography variant="body2">{email}</Typography>

            {email_verified ? (
              <Typography color="primary" sx={{ fontSize: '18px' }} variant="body2" gutterBottom>
                <VerifiedIcon sx={{ fontSize: 'inherit', position: 'relative', top: '2px' }} />
              </Typography>
            ) : (
              <Typography sx={{ color: 'red', fontSize: '18px' }} variant="body2" gutterBottom>
                <NewReleasesOutlinedIcon sx={{ fontSize: 'inherit', position: 'relative', top: '2px' }} />
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Reference;
