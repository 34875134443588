import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useNavigate } from 'react-router-dom';

import { UtilitySideBarItem, UtilitySidebar } from './../../../components/Layouts';
import { LINKS } from '../../../constants/menus';

const Sidebar = ({ activeSection, SECTIONS, appId }) => {
  const navigate = useNavigate();

  return (
    <UtilitySidebar>
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.EDIT_APPLICATION(appId, SECTIONS.BASIC_INFO))}
        active={activeSection === SECTIONS.BASIC_INFO || !activeSection}
        icon={<ArticleOutlinedIcon />}
        label="Basic Info"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.EDIT_APPLICATION(appId, SECTIONS.APPLICANT))}
        active={activeSection === SECTIONS.APPLICANT}
        icon={<PersonOutlineIcon />}
        label="Applicant"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.EDIT_APPLICATION(appId, SECTIONS.CO_APPLICANT))}
        active={activeSection === SECTIONS.CO_APPLICANT}
        icon={<PeopleOutlineIcon />}
        label="Co-Applicant"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.EDIT_APPLICATION(appId, SECTIONS.UNIT))}
        active={activeSection === SECTIONS.UNIT}
        icon={<LocalOfferOutlinedIcon />}
        label="Unit"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.EDIT_APPLICATION(appId, SECTIONS.TRADE_IN))}
        active={activeSection === SECTIONS.TRADE_IN}
        icon={<CurrencyExchangeIcon />}
        label="Trade-In"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.EDIT_APPLICATION(appId, SECTIONS.REVIEW))}
        active={activeSection === SECTIONS.REVIEW}
        icon={<TaskOutlinedIcon />}
        label="Review"
      />
    </UtilitySidebar>
  );
};

export default Sidebar;
