import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import isEqual from 'lodash/isEqual';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { FlexRow } from 'components/Layouts';
import { useBuildFormik, buildFormikFields } from 'components/Formiks - Deprecated';

import { fillableFieldFormInfo, types } from './formInfo';
import { COLORS } from 'constants/styles';

const EditField = ({ setEditingField, field, setField, isSignable, addField }) => {
  const cancelEdit = () => {
    setEditingField(false);
    setField({});
  };

  const initialValues = { ...field, data_path: field.data_path ? field.data_path.join(',') : '' };

  const formik = useBuildFormik({
    formInfo: Object.values(fillableFieldFormInfo),
    onSubmit: (values) => {
      handleSave(values);
    },
    values: initialValues,
    validate: (values) => {
      const errors = {};
      if (values.type === types.signature || values.type === types.initials) {
        Object.values(fillableFieldFormInfo)
          .filter((field) => field.signatureValue)
          .forEach((field) => {
            if (values[field.parameterName] !== 0 && !values[field.parameterName]) errors[field.parameterName] = `${field.label} is required.`;
          });
      }

      return errors;
    },
  });

  const handleSave = (values) => {
    if (values.data_path) {
      const cleanedString = values.data_path.replace(/[^a-zA-Z0-9.,_]/g, '').toLowerCase();
      const splitString = cleanedString.split(',');
      values.data_path = splitString;
    } else {
      values.data_path = [];
    }

    if (values.type === types.multiline) {
      values.format_as = null;
    } else if (values.type === types.checkbox) {
      values.font_alignment = null;
      values.format_as = null;
    } else if (values.type === types.signature || values.type === types.initials) {
      values.data_path = [];
      values.font_alignment = null;
      values.format_as = null;
    }

    if (values.type !== types.signature && values.type !== types.initials) {
      values.party = null;
      values.page = null;
      values.x = null;
      values.y = null;
      values.height = null;
      values.width = null;
    }

    addField(values);
  };

  const nonSignatureFields = Object.values(fillableFieldFormInfo).filter((field) => !field.signatureValue);
  const signatureFields = Object.values(fillableFieldFormInfo)
    .filter((field) => field.signatureValue)
    .map((field) => ({
      ...field,
      required: !!(formik?.values?.type === types.signature || formik?.values?.type === types.initials),
    }));

  return (
    <FlexRow rounded style={{ background: COLORS.backgroundColor }} outlined margin="0" padding="20px" flexColumn>
      <Typography variant="h6">{initialValues.id ? 'Edit Field' : 'Add Field'}</Typography>
      <Alert severity="warning">
        <AlertTitle>Rules</AlertTitle>
        <ul>
          <li>- "Name" is the name of the fillable field in the pdf document.</li>
          <li>
            - "Data Path" is the path to the data on the application. For example: applicant.first_name. If you want to use two or more values, separate them by
            a comma.
          </li>
          <li>- "Format" will apply a formatter to the value when inserting it into the PDF document.</li>
          <li>- "Page" (for signature or initials fields) is zero indexed - 0 is the first page in the document.</li>
        </ul>
      </Alert>
      {buildFormikFields({
        formik,
        fields: nonSignatureFields,
        fieldsPerRow: 3,
      })}

      {isSignable && (formik?.values?.type === types.signature || formik?.values?.type === types.initials) && (
        <Fragment>
          <Typography sx={{ marginTop: '20px' }} variant="h6">
            Edit Signature Values
          </Typography>
          {buildFormikFields({
            formik,
            fields: signatureFields,
            fieldsPerRow: 3,
          })}
        </Fragment>
      )}

      <FlexRow margin="0" padding="10px 0px 0px" justifyContent="flex-end">
        <Button onClick={cancelEdit}>Cancel</Button>
        <Button onClick={formik.handleSubmit} disabled={isEqual(initialValues, formik.values)} sx={{ marginLeft: '10px' }} variant="contained">
          Save
        </Button>
      </FlexRow>
    </FlexRow>
  );
};

export default EditField;
