import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';

import { setAlert } from 'store/sagas/app';
import { FlexRow } from 'components/Layouts';
import { useFormie } from 'components/Formie';
import { fetchLoanCollateralThunk, updateCollateralThunk } from 'store/thunks/loanCollateral';
import { ActionMenu } from 'components/Menu';
import { integerFormatter } from 'constants/formatters';
import { COLORS } from 'constants/styles';
import Drawer from 'components/Drawer';
import { configuration } from '../../Onboarding/formInfo';

const CollateralInformation = ({ rehydrateTopLevel }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [collateral, setCollateral] = useState({});
  const [open, setOpen] = useState(false);
  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { collateral } = await dispatch(
        fetchLoanCollateralThunk({
          loanId,
        }),
      ).unwrap();
      setCollateral(collateral);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch collateral. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      resetForm();
    }
  };

  const handleStartEdit = () => {
    setOpen(true);
    formie.setAllValues({
      collateral: {
        vin: collateral?.vin,
        year: collateral?.year,
        make: collateral?.make,
        model: collateral?.model,
        condition: collateral?.condition,
        mileage: collateral?.mileage,
      },
    });
  };

  const updateCollateral = async () => {
    setLoading(true);
    setOpen(false);
    try {
      const { collateral: updatedCollateral = {} } = await dispatch(
        updateCollateralThunk({ data: { ...formie?.values?.collateral }, loanId, collateralId: collateral?.id }),
      ).unwrap();
      setCollateral(updatedCollateral);
      rehydrateTopLevel();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to update collateral. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const formie = useFormie({
    configuration,
    initialValues: {
      collateral: {
        vin: null,
        year: null,
        make: null,
        model: null,
        condition: null,
        mileage: null,
      },
    },
    setLoading,
    onSubmit: updateCollateral,
  });

  const { resetForm, handleSubmit, values } = formie;
  const disabled =
    !values?.collateral?.vin ||
    !values?.collateral?.year ||
    !values?.collateral?.make ||
    !values?.collateral?.model ||
    !values?.collateral?.condition ||
    !values?.collateral?.mileage;

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <>
      {/* Header row with action button */}
      <FlexRow padding="0px" margin="0px 0px 10px" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Collateral Information</Typography>
      </FlexRow>

      <FlexRow key={collateral?.id} justifyContent="space-between" padding="10px 20px" margin="0px" outlined rounded backgroundColor={COLORS.surfaceColor}>
        <FlexRow padding="0px" margin="0px">
          <DirectionsCarOutlinedIcon fontSize="large" color="primary" sx={{ mr: '12px' }} />

          <div>
            <Typography
              variant="body1"
              sx={{ fontWeight: 700 }}
            >{`${collateral?.condition} ${collateral?.year} ${collateral?.make} ${collateral?.model}`}</Typography>
            <Typography sx={{ display: 'block' }} variant="caption">{`VIN: ${collateral?.vin}`}</Typography>
            <Typography sx={{ display: 'block' }} variant="caption">{`Mileage: ${integerFormatter.format(collateral?.mileage)}`}</Typography>
            <Typography sx={{ display: 'block' }} variant="caption">{`Id: ${collateral?.id}`}</Typography>
          </div>
        </FlexRow>

        <ActionMenu options={[{ value: 'Edit', callback: handleStartEdit }]} />
      </FlexRow>

      <Drawer title="Edit Collateral" open={open} disabled={disabled} actionTitle="Edit" handleClose={handleClose} handleSubmit={handleSubmit}>
        <Typography variant="h6">What is the VIN?</Typography>
        {formie.buildFields({
          fields: [{ dataPath: 'collateral.vin' }],
          fieldsPerRow: 1,
        })}

        <Typography variant="h6" sx={{ mt: '32px' }}>
          What are the details?
        </Typography>
        {formie.buildFields({
          fields: [{ dataPath: 'collateral.condition' }, { dataPath: 'collateral.year' }, { dataPath: 'collateral.make' }, { dataPath: 'collateral.model' }],
          fieldsPerRow: 2,
        })}

        <Typography variant="h6" sx={{ mt: '32px' }}>
          How many miles does it have?
        </Typography>
        {formie.buildFields({
          fields: [{ dataPath: 'collateral.mileage' }],
          fieldsPerRow: 1,
        })}
      </Drawer>
    </>
  );
};

export default CollateralInformation;
