import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { logUserOut, setActiveProfile } from 'store/sagas/user';
import { dealerMenu, lenderMenu, LINKS } from 'constants/menus';
import { SidebarItemContainer } from 'components/AppSidebar/styled-components';

export const SideBarMenu = () => {
  const user = useSelector((state) => state.user);
  const menu = user?.active_profile.dealer_id ? dealerMenu(user?.active_profile, user?.is_super_admin) : lenderMenu(user?.active_profile, user?.is_super_admin);
  const itemsWithChildren = menu.map((item, i) => ({ name: item.name, key: `${item.name}${i}`, children: item.children })).filter((item) => !!item.children);
  const [selections, setSelections] = useState(itemsWithChildren.map((item) => item.key));

  const handleClick = (id) => {
    if (selections.includes(id)) {
      setSelections(selections.filter((selection) => selection !== id));
    } else {
      setSelections([...selections, id]);
    }
  };

  return (
    <>
      {menu.map((item, i) => {
        const hasChildren = !!item?.children;
        const uniqueId = `${item.name}${i}`;
        const open = selections.includes(uniqueId);

        return hasChildren ? (
          <div key={uniqueId}>
            <SidebarItemContainer parent onClick={() => handleClick(uniqueId)}>
              <Typography>{item.name}</Typography>
              {open ? <ExpandLess /> : <ExpandMore />}
            </SidebarItemContainer>

            <Collapse in={open} timeout="auto" unmountOnExit>
              {item.children.map((child) => {
                return (
                  <NavLink style={{ width: '100%' }} key={child.name} to={child.link} end={!!child.end}>
                    {({ isActive }) => (
                      <SidebarItemContainer isActive={isActive} child>
                        {child.icon()}
                        <Typography variant="body2">{child.name}</Typography>
                      </SidebarItemContainer>
                    )}
                  </NavLink>
                );
              })}
            </Collapse>
          </div>
        ) : (
          <NavLink style={{ width: '100%' }} key={item.name} to={item.link}>
            {({ isActive }) => (
              <SidebarItemContainer isActive={isActive}>
                {item.icon()}
                <Typography>{item.name}</Typography>
              </SidebarItemContainer>
            )}
          </NavLink>
        );
      })}
    </>
  );
};

export const MobileMenu = ({ drawerOpen, setDrawerOpen }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleClick = (link) => {
    setDrawerOpen(false);
    navigate(link);
  };

  const menu = user?.active_profile.dealer_id ? dealerMenu(user?.active_profile, user?.is_super_admin) : lenderMenu(user?.active_profile, user?.is_super_admin);

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      sx={{
        width: 250,
        '& .MuiDrawer-paper': {
          width: 250,
          boxSizing: 'border-box',
        },
      }}
    >
      <List>
        {menu.map((section, i) => (
          <div key={`${section.name}-${i}`}>
            <ListSubheader component="div">{section.name}</ListSubheader>

            {!!section?.children ? (
              section.children.map(({ name, link, icon }) => (
                <ListItem onClick={() => handleClick(link)} key={name} disablePadding>
                  <ListItemButton>
                    <ListItemIcon fontSize="small">{icon()}</ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <ListItem onClick={() => handleClick(section.link)} key={section.name} disablePadding>
                <ListItemButton>
                  <ListItemIcon fontSize="small">{section.icon()}</ListItemIcon>
                  <ListItemText primary={section.name} />
                </ListItemButton>
              </ListItem>
            )}

            {i < menu.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </Drawer>
  );
};

export const AccountMenu = ({ handleClose }) => {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    handleClose();
    dispatch(logUserOut());
    navigate('/');
  };

  const activeProfileId = user?.active_profile.id;
  const { profiles } = user;

  const setEntity = async (profile) => {
    handleClose();
    if (profile.id !== activeProfileId) {
      dispatch(setActiveProfile({ newProfile: profile, oldProfile: user?.active_profile, user }));

      if (profile.lender_id) {
        navigate(LINKS.LENDER.DASHBOARD);
      } else if (profile.dealer_id) {
        navigate(LINKS.DEALER.DASHBOARD);
      } else {
        navigate('/');
      }
    }
  };

  return (
    <Fragment>
      <MenuItem>
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>

        {`${user.first_name} ${user.last_name}`}
      </MenuItem>
      <Divider />
      {profiles
        .map((profile) => ({ ...profile, name: profile.dealer_id ? profile.dealer.name : profile.lender.name, type: profile.dealer_id ? 'dealer' : 'lender' }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((profile) => {
          const isActiveProfile = activeProfileId === profile.id;

          return (
            <MenuItem onClick={() => setEntity(profile)} key={profile.name}>
              <ListItemIcon>{isActiveProfile && <CheckIcon color="success" fontSize="small" />}</ListItemIcon>

              {profile.name}
            </MenuItem>
          );
        })}
      <Divider />

      <MenuItem onClick={() => navigate(LINKS.ACCOUNT.SETTINGS)}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        Account Settings
      </MenuItem>

      <MenuItem onClick={logout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Fragment>
  );
};

export const ActionMenu = (props) => {
  const { sx, icon, options } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="action-menu" sx={{ display: 'inline-block', position: 'relative' }}>
      <IconButton
        className="action-menu-button"
        aria-label="More"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        id="long-button"
        onClick={handleClick}
        sx={{ ...sx }}
      >
        {icon ? icon : <MoreVertIcon />}
      </IconButton>

      <Menu anchorEl={anchorEl} MenuListProps={{ 'aria-labelledby': 'long-button' }} id="long-menu" open={open} onClose={handleClose}>
        {options.map(({ icon, value, callback, divider }, idx) =>
          divider ? (
            <Divider key={idx} />
          ) : (
            <MenuItem
              key={value}
              onClick={async () => {
                if (typeof callback === 'function') {
                  await callback();
                }

                handleClose();
              }}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText>{value}</ListItemText>
            </MenuItem>
          ),
        )}
      </Menu>
    </Box>
  );
};

export const SortMenu = (props) => {
  const { options, selected, setSelected } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'inline-block', position: 'relative' }}>
      <IconButton
        aria-label="Sort"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        id="long-button"
        onClick={handleClick}
        sx={{
          width: 40,
          height: 40,
          borderRadius: '5px',
          border: '1px solid',
          borderColor: selected ? 'primary.main' : '#C4C4C4',
        }}
      >
        <SortIcon sx={{ color: selected ? 'primary.main' : 'primary.secondary' }} />
      </IconButton>

      <Menu anchorEl={anchorEl} MenuListProps={{ 'aria-labelledby': 'long-button' }} id="long-menu" open={open} onClose={handleClose}>
        {options.map(({ value, label, callback }) => (
          <MenuItem
            key={value}
            onClick={async () => {
              if (typeof callback === 'function') {
                await callback();
              }

              setSelected(value === selected ? null : value); // Toggle
            }}
          >
            {value === selected ? (
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon />
            )}

            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
