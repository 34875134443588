import { isValid, differenceInYears, parseISO, parse, isAfter } from 'date-fns';
import validator from 'validator';

export const REGEX = {
  isOnlyLetters: /^[a-zA-Z]+$/,
  isAlphaNumeric: /^[a-z0-9]+$/i,
  isEmail: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  isSSN: /^\d{9}$/,
  isPhoneNumber: /^\d{10}$/,
  isZIP: /^\d{5}$/,
};

export const stringNotEmpty = (val) => {
  return (typeof val === 'string' || val instanceof String) && val && val.replace(/\s/g, '').length > 0;
};

export const validateNotNull = (val) => {
  if (val === null || val === undefined) {
    return false;
  } else {
    return true;
  }
};

export const validateEmail = (val) => {
  return val && REGEX.isEmail.test(val);
};

export const validateOTP = (code) => {
  // Ensure the code is a string of length 6
  if (typeof code !== 'string' || code.length !== 6) {
    return false;
  }

  // Ensure the passcode contains only digits
  if (!/^\d+$/.test(code)) {
    return false;
  }

  return true;
};

export const validatePhone = (val) => {
  return val && REGEX.isPhoneNumber.test(val.toString().replace(/[^A-Z0-9]/gi, ''));
};

export const validatePastDate110 = (val) => {
  const dif = differenceInYears(new Date(), parseISO(val));
  return isValid(parseISO(val)) && dif < 110;
};

export const validateSSN = (val) => {
  return val && REGEX.isSSN.test(val.toString().replace(/[^A-Z0-9]/gi, ''));
};

export const validateZip = (val) => {
  return val && REGEX.isZIP.test(val);
};

export const validatePositiveInteger = (val) => {
  return val !== '' && val !== null && val !== undefined && (Math.sign(val) == 0 || (!!val && Math.sign(val) == 1));
};

export const validateInteger = (val) => {
  return val !== '' && val !== null && val !== undefined && typeof val === 'number';
};

export const validatePositiveIntegerAllowNull = (val) => {
  return !val || (val !== '' && val !== null && val !== undefined && typeof val === 'number' && (Math.sign(val) == 0 || (!!val && Math.sign(val) == 1)));
};

export const validatePercentage = (val) => {
  if (typeof val !== 'number' || val === '' || val === null || val === undefined) {
    return false; // Not a number
  }
  if (val < 0) {
    return false; // Less than or equal to 0
  }
  return true; // Valid number in range
};

export const validateCreditCard = (val) => {
  return val && validator.isCreditCard(val);
};

export const validateCreditCardExpirationDate = (val) => {
  const parsedDate = parse(val, 'MMyy', new Date());

  return val && isValid(parsedDate) && isAfter(parsedDate, new Date());
};
