import { useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { AccountMenu } from '../Menu';
import { AccountContainer } from './styled-components';
import { COLORS } from 'constants/styles';

export default function FullAccountMenu({ loggedOut, user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const accountMenuOpen = Boolean(anchorEl);
  const entityType = user?.active_profile?.dealer ? 'dealer' : 'lender';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={!loggedOut && 'Account Settings'}>
        <AccountContainer loggedOut={loggedOut} pointer={!loggedOut} onClick={(e) => !loggedOut && handleClick(e)}>
          {loggedOut ? (
            <Stack justifyContent={'center'} sx={{ width: '100%' }}>
              <Button variant="contained" role="Login">
                <Link to={'/login'} style={{ color: '#fff' }} aria-label="Login">
                  Log In
                </Link>
              </Button>
            </Stack>
          ) : (
            <>
              {/* TODO: Bug handle if there is no first or last name. handle no entity selected.*/}
              <Avatar sx={{ width: 48, height: 48, bgcolor: COLORS.primary }}>{`${user?.first_name.split('')[0]}${user?.last_name.split('')[0]}`}</Avatar>
              <div style={{ flexGrow: 1, overflow: 'hidden' }}>
                <div style={{ display: 'block', width: '168px' }}>
                  <Typography noWrap variant="body1">
                    {user.first_name} {user.last_name}
                  </Typography>
                </div>
                <div style={{ display: 'block', width: '168px' }}>
                  <Typography noWrap variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.75)' }}>
                    {user?.active_profile[entityType]?.name}
                  </Typography>
                </div>
              </div>
            </>
          )}
        </AccountContainer>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={accountMenuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.2))',
            ml: '240px',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: 14,
              left: -5,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 1000,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <AccountMenu handleClose={handleClose} />
      </Menu>
    </>
  );
}
