const validateCollateral = (collateral, loanId) => {
  if (!collateral?.id) return false;
  if (collateral?.loan_id !== loanId) return false;
  if (!collateral?.condition) return false;
  if (!collateral?.make) return false;
  if (!collateral?.mileage && collateral?.mileage != 0) return false;
  if (!collateral?.model) return false;
  if (!collateral?.vin) return false;
  if (!collateral?.year) return false;

  return true;
};

const validateCustomers = (customers, loanId) => {
  if (!customers || !customers?.length || customers?.length == 0) return false;
  const primaryCustomer = customers.find(
    (customer) => customer?.loans_customers?.customer_role === 'primary-debtor' && customer?.loans_customers?.loan_id === loanId,
  );
  if (!primaryCustomer) return false;
  if (!primaryCustomer?.id) return false;
  if (!primaryCustomer?.dob) return false;
  if (!primaryCustomer?.email) return false;
  if (!primaryCustomer?.first_name) return false;
  if (!primaryCustomer?.last_name) return false;
  if (!primaryCustomer?.phone) return false;
  if (!primaryCustomer?.ssn) return false;

  return true;
};

const validatePaymentProfiles = (paymentProfiles, loanId) => {
  if (!paymentProfiles || !paymentProfiles?.length || paymentProfiles?.length === 0) return false;
  const primaryPaymentProfile = paymentProfiles.find(
    (paymentProfile) => paymentProfile?.loans_payment_profiles?.profile_type === 1 && paymentProfile?.loans_payment_profiles?.loan_id === loanId,
  );
  if (!primaryPaymentProfile) return false;
  if (!primaryPaymentProfile?.id) return false;
  if (!primaryPaymentProfile?.account_first_name) return false;
  if (!primaryPaymentProfile?.account_last_name) return false;
  if (!primaryPaymentProfile?.account_number) return false;
  if (!primaryPaymentProfile?.account_type) return false;
  if (!primaryPaymentProfile?.profile_name) return false;

  return true;
};

const validateTermConfiguration = (loan) => {
  if (!loan) return false;
  if (!loan?.principal_amount) return false;
  if (!loan?.interest_rate) return false;
  if (!loan?.contract_date) return false;
  if (!loan?.first_payment_date) return false;
  if (!loan?.terms) return false;
  if (!loan?.schedule_round && loan?.schedule_round != 0) return false;
  if (!loan?.payment_frequency) return false;
  if (!loan?.interest_calculation_type) return false;
  if (!loan?.calculation_type) return false;
  if (!loan?.first_day_interest && loan?.first_day_interest !== false) return false;
  if (!loan?.last_as_final && loan?.last_as_final !== false) return false;
  if (!loan?.beginning_end) return false;
  if (!loan?.schedule_smoothing) return false;
  if (!loan?.odd_days && loan?.odd_days != 0) return false;
  if (!loan?.unit_periods && loan?.unit_periods != 0) return false;

  return true;
};

const validatePaymentPeriods = (loan, paymentPeriods) => {
  if (!loan) return false;
  if (!loan?.terms) return false;
  if (!paymentPeriods) return false;
  if (!paymentPeriods?.length || paymentPeriods?.length === 0) return false;
  if (loan?.terms != paymentPeriods?.length) return false;

  return true;
};

const validateTerms = (loan) => {
  if (!loan) return false;
  if (!loan?.apr) return false;
  if (!loan?.finance_charge) return false;
  if (!loan?.total_payments) return false;
  if (!loan?.total_sales_price) return false;
  if (!loan?.payment_amount) return false;
  if (!loan?.last_payment_amount && loan?.last_payment_amount != 0) return false;
  if (!loan?.last_payment_date) return false;

  return true;
};

const validateAdvancedSettings = (loan) => {
  if (!loan) return false;
  if (!loan?.late_fee_type) return false;
  if (!loan?.late_fee_amount && loan?.late_fee_amount != 0) return false;
  if (!loan?.grace_period && loan?.grace_period != 0) return false;
  if (!loan?.nsf_fee && loan?.nsf_fee != 0) return false;
  if (!loan?.type) return false;
  if (!loan?.reporting_type) return false;
  if (!loan?.category) return false;
  if (!loan?.timezone) return false;

  return true;
};

export const validateLoan = (loan) => {
  if (!loan?.id) return false;

  const collateralIsValid = validateCollateral(loan?.collateral, loan.id);
  const customersAreValid = validateCustomers(loan?.customers, loan.id);
  const paymentProfilesAreValid = validatePaymentProfiles(loan?.loan_payment_profiles, loan.id);
  const termConfigurationIsValid = validateTermConfiguration(loan);
  const paymentPeriodsAreValid = validatePaymentPeriods(loan, loan?.payment_periods);
  const termsAreValid = validateTerms(loan);
  const advancedSettingsAreValid = validateAdvancedSettings(loan);

  if (
    !collateralIsValid ||
    !customersAreValid ||
    !paymentPeriodsAreValid ||
    !paymentProfilesAreValid ||
    !termConfigurationIsValid ||
    !termsAreValid ||
    !advancedSettingsAreValid
  )
    return false;

  return true;
};
