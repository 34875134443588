import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddCardIcon from '@mui/icons-material/AddCard';

import { fetchApplicationById } from 'store/sagas/applications';
import { connectExistingApplicationPaymentProfileThunk } from 'store/thunks/application';
import PaymentProfilesContext from '../context';
import PaymentProfile from '../PaymentProfile';
import PaymentProfileOwnerModal from './PaymentProfileOwnerModal';

const ExistingPaymentProfilesModal = ({ currentApplication, applicantId, applicationId, existingPaymentProfiles, onClose }) => {
  const dispatch = useDispatch();
  const { setPaymentProfilesSectionProcessing, setPaymentProfilesSectionErrorMsg, setShowPaymentProfilesSectionError } = useContext(PaymentProfilesContext);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedAccountOwner, setSelectedAccountOwner] = useState(null);
  const [selectedAccountName, setSelectedAccountName] = useState(null);
  const [connectingExistingPaymentProfile, setConnectingExistingPaymentProfile] = useState(false);
  const [addingNewPaymentProfile, setAddingNewPaymentProfile] = useState(false);
  const [showProfileOwnerModal, setShowProfileOwnerModal] = useState(false);

  return (
    <>
      {!showProfileOwnerModal && (
        <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={true}>
          <DialogTitle>Existing Payment Profiles</DialogTitle>

          <DialogContent>
            <Grid className={'payment-profiles-list'} container spacing={2}>
              {existingPaymentProfiles.map((paymentProfile) => (
                <PaymentProfile
                  key={paymentProfile.itemId}
                  width={{ xs: 6 }}
                  mode="selectable"
                  selectable={{
                    selected: selectedItemId === paymentProfile.itemId,
                    setSelected: () => {
                      if (selectedItemId === paymentProfile.itemId) {
                        setSelectedItemId(null);
                        setSelectedAccountOwner(null);
                        setSelectedAccountName(null);
                      } else {
                        setSelectedItemId(paymentProfile.itemId);
                        setSelectedAccountOwner(paymentProfile.account.accountOwner);
                        setSelectedAccountName(paymentProfile.account.accountName);
                      }
                    },
                  }}
                  data={{
                    institutionName: paymentProfile.institution.name,
                    institutionLogo: paymentProfile.institution.logo,
                    institutionPrimaryColor: paymentProfile.institution.primaryColor,
                    accountOwner: paymentProfile.account.accountOwner,
                    accountType: paymentProfile.account.accountType,
                    accountName: paymentProfile.account.accountName,
                    accountNumber: paymentProfile.account.accountNumber,
                    routingNumber: paymentProfile.account.routingNumber,
                  }}
                />
              ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Stack direction="row" spacing={5}>
              <Stack direction="row" spacing={2}>
                <Button disabled={connectingExistingPaymentProfile || addingNewPaymentProfile} variant="outlined" color="error" onClick={onClose}>
                  Cancel
                </Button>

                <LoadingButton
                  disabled={selectedItemId === null || addingNewPaymentProfile}
                  loading={connectingExistingPaymentProfile}
                  loadingPosition="start"
                  startIcon={<CreditScoreIcon />}
                  variant="contained"
                  onClick={async () => {
                    try {
                      setPaymentProfilesSectionProcessing(true);
                      setConnectingExistingPaymentProfile(true);

                      await dispatch(
                        connectExistingApplicationPaymentProfileThunk({
                          applicationId,
                          accountOwner: selectedAccountOwner,
                          accountName: selectedAccountName,
                          itemId: selectedItemId,
                        }),
                      ).unwrap();

                      dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

                      setConnectingExistingPaymentProfile(false);
                      setPaymentProfilesSectionProcessing(false);
                      onClose();
                    } catch (err) {
                      console.log('Checking verification status error: ', err);

                      setPaymentProfilesSectionErrorMsg('Failed to check verification status.');
                      setShowPaymentProfilesSectionError(true);

                      setConnectingExistingPaymentProfile(false);
                      setPaymentProfilesSectionProcessing(false);
                      onClose();
                    }
                  }}
                >
                  {connectingExistingPaymentProfile ? 'Connecting...' : 'Connect'}
                </LoadingButton>
              </Stack>

              <Stack direction="row" spacing={1}>
                <LoadingButton
                  disabled={!!selectedItemId || connectingExistingPaymentProfile}
                  loading={addingNewPaymentProfile || showProfileOwnerModal}
                  loadingPosition="start"
                  startIcon={<AddCardIcon />}
                  variant="contained"
                  onClick={async () => {
                    setPaymentProfilesSectionProcessing(true);
                    setAddingNewPaymentProfile(true);
                    setShowProfileOwnerModal(true);
                  }}
                >
                  {addingNewPaymentProfile ? 'Processing...' : 'Add New'}
                </LoadingButton>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
      )}

      {showProfileOwnerModal && (
        <PaymentProfileOwnerModal
          currentApplication={currentApplication}
          open={showProfileOwnerModal}
          onClose={() => {
            setShowProfileOwnerModal(false);
            setAddingNewPaymentProfile(false);
            setPaymentProfilesSectionProcessing(false);
          }}
          onPaymentProfileConnectSuccess={() => {
            onClose();
          }}
          onInputPaymentProfileDataSuccess={() => {
            onClose();
          }}
        />
      )}
    </>
  );
};

export default ExistingPaymentProfilesModal;
