import { useEffect, useState, Fragment } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import PaidIcon from '@mui/icons-material/Paid';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

import { currencyFormatter } from 'constants/formatters';
import { STATUS_LABELS } from 'constants/status';
import { LINKS } from 'constants/menus';
import { PLAID_AUTH_STATUS } from 'constants/plaid';
import { storeApplication, fetchApplicationById, updateApplicationStatus } from 'store/sagas/applications';
import NavBar from 'components/NavBar';
import { UnderNavBarContainer, FlexRow, Card } from 'components/Layouts';
import { DoubleConfirmNoButton } from 'components/DoubleConfirm';

import Sidebar from './sidebar';
import AppInfo from './appInfo';
import Overview from './overview';
import LendingOptions from './LendingOptions';
import Documents from './documents';
import ContractPrepOverview from './contractPrepOverview';
import PaymentProfiles from './PaymentProfiles';
import Verify from './verify';
import References from './References';
import Signing from './signing';

const { APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING } = STATUS_LABELS;
const { PRE_PLAID, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED } = PLAID_AUTH_STATUS;

export const SECTIONS = {
  OVERVIEW: 'overview',
  APP_INFO: 'app_info',
  LENDING_OPTIONS: 'lending_options',
  STIPS_AND_DOCS: 'stips_and_docs',
  CONTRACT_PREP_OVERVIEW: 'contract_prep_overview',
  PAYMENT_PROFILES: 'payment_profiles',
  VERIFY: 'verify',
  REFERENCES: 'references',
  SIGNING: 'signing',
};

const ViewApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const appLoading = useSelector((state) => state.app.loading);
  const currentApplication = useSelector((state) => state.applications.currentApplication);
  const currentStatus = currentApplication && currentApplication.status && currentApplication.status.name;
  const { status, stipulations } = currentApplication;
  const allStipsFulfilled = stipulations ? stipulations.every((s) => s.fulfilled) : false;

  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const activeSection = searchParams.get('section');
  const overviewShouldShow = activeSection === SECTIONS.OVERVIEW || !activeSection;
  const readyToSign = currentApplication?.signing?.ready_to_sign;
  const hasCoApplicant = !!currentApplication?.co_applicant?.id;
  const hasTradeIn = !!currentApplication?.trade_unit?.id;

  /**
   * Check if there is a contract already prepared.
   */
  const { contract_values, trade_unit, signing, dates, lending_options } = currentApplication;
  const selectedLendingOption = lending_options && lending_options.length > 0 ? lending_options.find((option) => option.selected_by_dealer) : null;
  const hasContractPrepared =
    selectedLendingOption?.apr &&
    selectedLendingOption?.max_amount_loaned &&
    contract_values?.amount_financed &&
    contract_values?.payment_amount &&
    dates?.payment_start_date &&
    contract_values?.required_amount_down &&
    contract_values?.total_down_payment &&
    contract_values?.cash_down;

  /**
   * ! Very Important
   * This payment profile needs to be verified or pre-plaid because we are going to use this to determine whether
   * to allow changing the status of an application to funded or not.
   */
  const primaryPaymentProfile = currentApplication?.payment_profiles
    ? currentApplication.payment_profiles.find(
        ({ profile_type, institution_name, account_type, account_name, account_number, routing_number, verification_status }) => {
          if (
            profile_type === 1 &&
            institution_name &&
            account_type &&
            account_name &&
            account_number &&
            routing_number &&
            [PRE_PLAID, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED].includes(verification_status)
          ) {
            return true;
          } else {
            return false;
          }
        },
      )
    : null;

  /**
   * Get application reference requirements.
   */
  const { no_of_ref, no_of_verified_ref } = currentApplication?.lending_rules ?? {};

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (id != 'new') {
      dispatch(fetchApplicationById(id));
    } else {
      dispatch(storeApplication({}));
    }
    return () => {
      dispatch(storeApplication({}));
    };
  }, [id]);

  const breadcrumbs = [
    { text: 'Home', link: LINKS.LENDER.DASHBOARD },
    { text: 'Applications', link: LINKS.LENDER.APPLICATIONS.BASE },
    // { text: id == 'new' ? 'New App' : 'View Application', link: '' },
    {
      text:
        id == 'new'
          ? 'New App'
          : currentApplication?.applicant?.first_name && currentApplication?.applicant?.last_name
          ? `${currentApplication.applicant.first_name} ${currentApplication.applicant.last_name}`
          : currentApplication?.applicant?.email
          ? currentApplication.applicant.email
          : 'View Application',
      link: '',
    },
  ];

  const sectionCanShow = {
    lendingOptions: [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    stipsAndDocs: [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    contractPrepOverview: [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    paymentProfile:
      hasContractPrepared && [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    verify: hasContractPrepared && [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    references:
      (no_of_ref || no_of_verified_ref) &&
      [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus),
    signing: [APPROVED, PENDING_SIGNATURES, PENDING_DOCUMENTS, FUNDING_REVIEW, FUNDING_DELAY, FUNDED, SERVICING].includes(currentStatus) && readyToSign,
  };

  let navBarActions = [
    {
      title: 'Mark as Servicing',
      action: () => setModalOpen(true),
      icon: <CloudDoneIcon size="small" />,
      forStatus: [FUNDED],
      disabled: !allStipsFulfilled,
    },
  ];

  if (primaryPaymentProfile) {
    navBarActions.push({
      title: 'Mark as Funded',
      action: () => setModalOpen(true),
      icon: <PaidIcon size="small" />,
      forStatus: [FUNDING_REVIEW],
      disabled: !allStipsFulfilled,
    });
  }

  return (
    <Fragment>
      <NavBar
        backButton={LINKS.LENDER.APPLICATIONS.BASE}
        breadcrumbs={breadcrumbs}
        actions={navBarActions.filter((option) => !option.forStatus || option.forStatus.includes(currentStatus))}
      />

      <UnderNavBarContainer padding="0px">
        <FlexRow fullHeight padding="0px">
          <Sidebar appId={id} sectionCanShow={sectionCanShow} activeSection={activeSection} SECTIONS={SECTIONS} />

          <FlexRow alignItems="center" overflowScroll fullHeight flexColumn padding="20px">
            {contract_values && selectedLendingOption && contract_values.amount_financed > selectedLendingOption.max_amount_loaned && (
              <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Amount financed error.">
                <Alert severity="error">
                  <AlertTitle>Amount Financed</AlertTitle>
                  <Typography>
                    {`The amount financed of ${currencyFormatter.format(
                      contract_values.amount_financed,
                    )} is greater than the max approved amount of ${currencyFormatter.format(selectedLendingOption.max_amount_loaned)}.`}
                  </Typography>
                </Alert>
              </Card>
            )}

            {contract_values && contract_values.total_down_payment < contract_values.required_amount_down && (
              <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Down payment error.">
                <Alert severity="error">
                  <AlertTitle>Down Payment</AlertTitle>
                  <Typography>
                    {`The total down payment of ${currencyFormatter.format(contract_values.total_down_payment)} (${currencyFormatter.format(
                      contract_values.cash_down,
                    )} cash down payment and ${currencyFormatter.format(
                      !!trade_unit?.equity && trade_unit.equity > 0 ? trade_unit.equity : 0,
                    )} trade equity) is less than the required amount of ${currencyFormatter.format(contract_values.required_amount_down)}.`}
                  </Typography>
                </Alert>
              </Card>
            )}

            {signing && !signing.applicant_signed && !signing.co_applicant_signed && !signing.dealer_signed && signing.ready_to_sign && (
              <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Primary payment profile error.">
                <Alert severity="success">
                  <AlertTitle>Ready to Sign</AlertTitle>
                  <Typography>
                    Contract preparation is finished and the dealer can proceed to the signing screen. This does not mean that all stipulation have been
                    fulfilled.
                  </Typography>
                </Alert>
              </Card>
            )}

            {!currentApplication.id && !appLoading
              ? [
                  <Card key={1} margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Application not found.">
                    <Alert severity="error">
                      <AlertTitle>Application not found.</AlertTitle>
                    </Alert>
                  </Card>,
                ]
              : [
                  {
                    section: SECTIONS.OVERVIEW,
                    canShow: true,
                    component: <Overview key="overview" currentApplication={currentApplication} primaryPaymentProfile={primaryPaymentProfile} />,
                  },
                  {
                    section: SECTIONS.APP_INFO,
                    canShow: true,
                    component: (
                      <AppInfo
                        key="appInfo"
                        currentApplication={currentApplication}
                        hasCoApplicant={hasCoApplicant}
                        hasTradeIn={hasTradeIn}
                        overviewShouldShow={overviewShouldShow}
                        setActiveSectionToAppInfo={() => navigate(LINKS.LENDER.APPLICATIONS.VIEW_ONE(id, SECTIONS.APP_INFO))}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.LENDING_OPTIONS,
                    canShow: sectionCanShow.lendingOptions,
                    component: (
                      <LendingOptions
                        key="lendingOptions"
                        lendingOptions={currentApplication.lending_options ? currentApplication.lending_options : []}
                        applicationStatus={currentStatus}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.STIPS_AND_DOCS,
                    canShow: sectionCanShow.stipsAndDocs,
                    component: (
                      <Documents
                        key="documents"
                        overviewShouldShow={overviewShouldShow}
                        SECTIONS={SECTIONS}
                        currentApplication={currentApplication}
                        primaryPaymentProfile={primaryPaymentProfile}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.CONTRACT_PREP_OVERVIEW,
                    canShow: sectionCanShow.contractPrepOverview,
                    component: (
                      <ContractPrepOverview
                        key="contractPrepOverview"
                        showDetailed={!overviewShouldShow}
                        currentApplication={currentApplication}
                        hasContractPrepared={hasContractPrepared}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.PAYMENT_PROFILES,
                    canShow: sectionCanShow.paymentProfile,
                    component: <PaymentProfiles key="paymentProfiles" currentApplication={currentApplication} />,
                  },
                  {
                    section: SECTIONS.VERIFY,
                    canShow: sectionCanShow.verify,
                    component: (
                      <Verify
                        key="verify"
                        hasCoApplicant={hasCoApplicant}
                        applicant={currentApplication?.applicant ? currentApplication.applicant : {}}
                        coApplicant={currentApplication?.co_applicant ? currentApplication.co_applicant : {}}
                      />
                    ),
                  },
                  {
                    section: SECTIONS.REFERENCES,
                    canShow: sectionCanShow.references,
                    component: <References key="references" currentApplication={currentApplication} />,
                  },
                  {
                    section: SECTIONS.SIGNING,
                    canShow: sectionCanShow.signing,
                    component: (
                      <Signing
                        key="signing"
                        currentStatus={currentStatus}
                        hasCoApplicant={hasCoApplicant}
                        documents={currentApplication?.documents ? currentApplication.documents : []}
                        signing={currentApplication?.signing ? currentApplication.signing : {}}
                        applicant={currentApplication?.applicant ? currentApplication.applicant : {}}
                        coApplicant={currentApplication?.co_applicant ? currentApplication.co_applicant : {}}
                        dealer={currentApplication?.dealer ? currentApplication.dealer : {}}
                        user={user}
                        overviewShouldShow={overviewShouldShow}
                        application={currentApplication}
                      />
                    ),
                  },
                ]
                  .filter((item) => {
                    if (activeSection == item.section && item.canShow) return true;
                    if (activeSection == SECTIONS.OVERVIEW && item.canShow) return true;
                    if (!activeSection && item.section == SECTIONS.OVERVIEW) return true;

                    return false;
                  })
                  .map(({ component }) => component)}
          </FlexRow>
        </FlexRow>
      </UnderNavBarContainer>

      {status && primaryPaymentProfile && allStipsFulfilled ? (
        <DoubleConfirmNoButton
          title="Are you sure?"
          description={`Marking this application as "${status.name === FUNDING_REVIEW ? 'Funded' : 'Servicing'}" is irreversible.`}
          action={() => {
            /**
             * ! Warning
             * ! We are hardcoding and assuming that the id of application status of "Funded" is 10 and "Servicing" is 11.
             *
             * TODO: Properly fetch application status from DB so we can use the actual id of the application status on the DB.
             */
            const application_status_id =
              status.name === FUNDING_REVIEW
                ? // Funding Review -> Funded
                  10
                : status.name === FUNDED
                ? // Funded -> Servicing
                  11
                : null;

            if (application_status_id) {
              dispatch(updateApplicationStatus({ data: { application_status_id }, applicationId: id }));
            }
          }}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </Fragment>
  );
};

export default ViewApp;
