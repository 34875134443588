import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { differenceInCalendarDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { determinePeriodStatus } from '../helpers';
import Overview from './Overview';
import Payments from './Payments';
import PastDue from './PastDue';
import { fetchLoanMetricsThunk } from 'store/thunks/loanMetric';
import { setAlert } from 'store/sagas/app';
import { formatDateOnly } from 'constants/formatters';

const DetailModal = ({ open, handleClose, period, paymentsEnteredDuringPaymentPeriod, loanId, timezone }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loanMetrics, setLoanMetrics] = useState([]);
  const periodId = period?.id;

  const from_date = period?.from_date;
  const to_date = period?.due_date;

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { metrics } = await dispatch(
        fetchLoanMetricsThunk({
          loanId,
          from_date,
          to_date,
          limit: 100,
        }),
      ).unwrap();
      setLoanMetrics(metrics);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch payment periods. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (periodId) {
      hydrateData();
    }
  }, [periodId]);

  const statusOfPeriod = period && determinePeriodStatus(period, true, timezone);
  const todayInBrowserTimeZone = new Date();
  const todayInLoansTimeZone = formatInTimeZone(todayInBrowserTimeZone, timezone, 'yyyy-MM-dd');
  const daysUntilDueDate = period ? differenceInCalendarDays(new Date(period.due_date), new Date(todayInLoansTimeZone)) : 0;
  const periodIsComplete = daysUntilDueDate < 0;
  const firstDayLoanMetrics = loanMetrics && loanMetrics.find((day) => day.date === from_date);
  const lastDayLoanMetrics = loanMetrics && period && loanMetrics.find((day) => day.date === period.to_date);
  const dueDayLoanMetrics = loanMetrics && loanMetrics.find((day) => day.date === to_date);
  const dateFulfilled = period?.fulfillment_date ? formatDateOnly(period.fulfillment_date) : null;
  const hasPaid = !!dateFulfilled;

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" onClose={handleClose} open={open}>
      <DialogTitle>
        {`Payment Period ${period?.period_number}`}
        {statusOfPeriod}
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Overview
          loading={loading}
          paymentsEnteredDuringPaymentPeriod={paymentsEnteredDuringPaymentPeriod}
          firstDayLoanMetrics={firstDayLoanMetrics}
          lastDayLoanMetrics={lastDayLoanMetrics}
          dueDayLoanMetrics={dueDayLoanMetrics}
          period={period}
          periodIsComplete={periodIsComplete}
          hasPaid={hasPaid}
          dateFulfilled={dateFulfilled}
        />

        <Payments period={period} />

        <PastDue timezone={timezone} period={period} />
      </DialogContent>
    </Dialog>
  );
};

export default DetailModal;
