import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { fetchApplicationById } from 'store/sagas/applications';
import { createApplicationReferenceThunk } from 'store/thunks/application';
import { useFormie } from 'components/Formie';
import { newReferenceFormConfig, initialValues } from './formInfo';

const NewReferenceFormModal = ({ applicationId, onClose }) => {
  const dispatch = useDispatch();

  const [hasErrors, setHasErrors] = useState(false);
  const [saving, setSaving] = useState(false);

  const formie = useFormie({ configuration: newReferenceFormConfig, initialValues });
  const { first_name, last_name, phone, email } = formie.values;
  const { first_name: first_name_error, last_name: last_name_error, phone: phone_error, email: email_error } = formie.errors;

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={true}>
      <DialogTitle>
        <Stack direction={'row'} spacing={1}>
          <AccountCircleIcon sx={{ fontSize: '2rem' }} />
          <Typography variant="h6">New Reference</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        {hasErrors && <Alert severity="error">Failed to add new reference.</Alert>}

        {formie.buildFields({
          fields: [{ dataPath: 'first_name' }, { dataPath: 'last_name' }, { dataPath: 'phone' }, { dataPath: 'email' }],
          fieldsPerRow: 2,
        })}
      </DialogContent>

      <DialogActions>
        {typeof onClose === 'function' && (
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
        )}

        <LoadingButton
          disabled={
            !first_name ||
            !last_name ||
            !phone ||
            !email ||
            first_name_error !== false ||
            last_name_error !== false ||
            phone_error !== false ||
            email_error !== false
          }
          loading={saving}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={async () => {
            try {
              setHasErrors(false);
              setSaving(true);

              await dispatch(createApplicationReferenceThunk({ applicationId, data: formie.values })).unwrap();

              setSaving(false);

              dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

              onClose();
            } catch (err) {
              setSaving(false);
              setHasErrors(true);
              console.log('Adding application reference error: ', err);
            }
          }}
        >
          {saving ? 'Creating...' : 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewReferenceFormModal;
