import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import SyncIcon from '@mui/icons-material/Sync';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import DoNotDisturbOffOutlinedIcon from '@mui/icons-material/DoNotDisturbOffOutlined';

import { currencyFormatter, percentageFormatter } from 'constants/formatters';
import { STATUS_LABELS } from 'constants/status';
import { updateLendingOption } from 'store/sagas/applications';
import { Card, FlexRow } from 'components/Layouts';
import { ActionMenu } from 'components/Menu';
import LendingOptionModal from './LendingOptionModal';

const { SAVED, PROCESSING, REFERRED, APPROVED } = STATUS_LABELS;

const rowStyle = {
  width: '20%',
  margin: '0',
  padding: '0',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const headerStyle = {
  ...rowStyle,
  fontWeight: 700,
};

const LendingOptions = ({ lendingOptions, applicationStatus }) => {
  const active_profile = useSelector((state) => state.user?.active_profile);

  const dispatch = useDispatch();
  const { id: applicationId } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [lendingOptionId, setLendingOptionId] = useState(undefined);
  const [initialValues, setInitialValues] = useState({
    max_amount_loaned: undefined,
    min_percent_down: undefined,
    term: undefined,
    apr: undefined,
  });

  const rows =
    lendingOptions && lendingOptions.length
      ? lendingOptions
          .map(({ id, selected_by_dealer, max_amount_loaned, min_percent_down, term, apr, approved_by_lender, proposed_by }) => ({
            id,
            selected_by_dealer,
            approved_by_lender,
            max_amount_loaned,
            min_percent_down,
            term,
            apr,
            proposed_by,
          }))
          .sort((a, b) => a.term - b.term)
      : [];

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="Lending options.">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Lending Options
        </Typography>

        {[SAVED, PROCESSING, REFERRED, APPROVED].includes(applicationStatus) ? (
          <Button
            onClick={() => {
              setLendingOptionId(undefined);
              setInitialValues({ max_amount_loaned: undefined, min_percent_down: undefined, term: undefined, apr: undefined });
              setModalOpen(true);
            }}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        ) : null}
      </FlexRow>

      <List>
        <ListItem sx={{ padding: '5px 10px 5px !important' }}>
          <FlexRow padding="0" margin="0" justifyContent="space-between">
            {[
              { title: 'Selected', style: { ...headerStyle, width: '85px' } },
              { title: 'Max $ Financed' },
              { title: 'Down Payment %' },
              { title: 'Term' },
              { title: 'APR' },
              { title: '', style: { ...headerStyle, width: '60px' } },
            ].map(({ title, style }, i) => (
              <Typography key={`${title}-${i}`} variant="body2" component="div" style={style || headerStyle}>
                {title}
              </Typography>
            ))}
          </FlexRow>
        </ListItem>

        <Divider />

        {rows.map((row) => (
          <Fragment key={`lending-option-${row.id}`}>
            <Tooltip placement="left" title={row.approved_by_lender === null ? 'Pending review' : row.approved_by_lender === false ? 'Rejected' : ''}>
              <ListItem disablePadding>
                <ListItemButton selected={row.selected_by_dealer} readOnly disableRipple sx={{ padding: '10px !important' }}>
                  <FlexRow padding="0" margin="0" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1" component="div" sx={{ ...rowStyle, width: '85px' }}>
                      {row.approved_by_lender === null ? (
                        <SyncIcon />
                      ) : row.approved_by_lender === false ? (
                        <DoDisturbIcon color="error" />
                      ) : (
                        <Checkbox sx={{ padding: 0 }} checked={!!row.selected_by_dealer} disabled />
                      )}
                    </Typography>

                    <Typography variant="body1" component="div" sx={rowStyle}>
                      {currencyFormatter.format(row.max_amount_loaned)}
                    </Typography>

                    <Typography variant="body1" component="div" sx={rowStyle}>
                      {percentageFormatter.format(row.min_percent_down)}
                    </Typography>

                    <Typography variant="body1" component="div" sx={rowStyle}>
                      {row.term}
                    </Typography>

                    <Typography variant="body1" component="div" sx={rowStyle}>
                      {percentageFormatter.format(row.apr)}
                    </Typography>

                    <Typography variant="body1" component="div" sx={{ ...rowStyle, width: '60px', justifyContent: 'flex-end' }}>
                      {!row.selected_by_dealer && [SAVED, PROCESSING, REFERRED, APPROVED].includes(applicationStatus) ? (
                        <ActionMenu
                          options={[
                            {
                              icon: <EditIcon />,
                              value: 'Edit',
                              callback: () => {
                                const { id, max_amount_loaned, min_percent_down, term, apr } = row;

                                setLendingOptionId(id);
                                setInitialValues({ max_amount_loaned, min_percent_down, term, apr });
                                setModalOpen(true);
                              },
                            },
                            // Approve/Un-approve
                            row.approved_by_lender === true
                              ? {
                                  icon: <UnpublishedOutlinedIcon />,
                                  value: 'Un-Approve',
                                  callback: () =>
                                    dispatch(
                                      updateLendingOption({
                                        applicationId,
                                        lendingOptionId: row.id,
                                        approved_by_lender: null,
                                        lender_id: active_profile.lender_id,
                                      }),
                                    ),
                                }
                              : {
                                  icon: <CheckCircleOutlinedIcon color={'success'} />,
                                  value: 'Approve',
                                  callback: () =>
                                    dispatch(
                                      updateLendingOption({
                                        applicationId,
                                        lendingOptionId: row.id,
                                        approved_by_lender: true,
                                        lender_id: active_profile.lender_id,
                                      }),
                                    ),
                                },
                            // Reject/Un-reject
                            row.approved_by_lender === false
                              ? {
                                  icon: <DoNotDisturbOffOutlinedIcon />,
                                  value: 'Un-Reject',
                                  callback: () =>
                                    dispatch(
                                      updateLendingOption({
                                        applicationId,
                                        lendingOptionId: row.id,
                                        approved_by_lender: null,
                                        lender_id: active_profile.lender_id,
                                      }),
                                    ),
                                }
                              : {
                                  icon: <DoDisturbOnOutlinedIcon color={'error'} />,
                                  value: 'Reject',
                                  callback: () =>
                                    dispatch(
                                      updateLendingOption({
                                        applicationId,
                                        lendingOptionId: row.id,
                                        approved_by_lender: false,
                                        lender_id: active_profile.lender_id,
                                      }),
                                    ),
                                },
                          ]}
                        />
                      ) : null}
                    </Typography>
                  </FlexRow>
                </ListItemButton>
              </ListItem>
            </Tooltip>

            <Divider />
          </Fragment>
        ))}
      </List>

      {modalOpen && (
        <LendingOptionModal
          applicationId={applicationId}
          lendingOptionId={lendingOptionId}
          lenderId={active_profile?.lender_id}
          initialValues={initialValues}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Card>
  );
};

export default LendingOptions;
