import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import LockResetIcon from '@mui/icons-material/LockReset';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';

import { fetchLenderUsers, updateLenderUser } from 'store/sagas/admin';
import { manuallyCallAlert } from 'store/sagas/app';
import { REGEX } from 'constants/regex';
import { COLORS } from 'constants/styles';
import { Card, FlexRow } from 'components/Layouts';
import { ActionMenu } from 'components/Menu';
import { DoubleConfirmNoButton } from 'components/DoubleConfirm';
import AddUserModal from './AddUserModal';
import ResetPasswordModal from './ResetPasswordModal';

const getBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5));
const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4));
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .is_archived': {
    backgroundColor: getBackgroundColor(COLORS.disabledColor, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(COLORS.disabledColor, theme.palette.mode),
    },
  },
}));

const Users = () => {
  const dispatch = useDispatch();

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserIsArchive, setSelectedUserIsArchive] = useState(null);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [passResetModalOpen, setPassResetModalOpen] = useState(false);
  const [archiveUserModalOpen, setArchiveUserModalOpen] = useState(false);

  const loading = useSelector((state) => state.admin?.loading?.users);
  const users = useSelector((state) => state.admin?.users);
  const currentUser = useSelector((state) => state.user);
  const activeProfile = useSelector((state) => state.user?.active_profile);

  useEffect(() => {
    dispatch(fetchLenderUsers({ lenderId: activeProfile?.lender_id }));
  }, []);

  const columns = [
    { field: 'email', headerName: 'Email', width: 250, editable: true },
    { field: 'first_name', headerName: 'First', width: 180, editable: true },
    { field: 'last_name', headerName: 'Last', width: 180, editable: true },
    { field: 'title', headerName: 'Job Title', width: 250, editable: true },
    { field: 'is_admin', headerName: 'Admin', type: 'boolean', editable: true },
    {
      field: 'action',
      headerName: '',
      editable: false,
      width: 60,
      /**
       * ! Important
       *
       * So can only edit if:
       * * Current user is super admin.
       * * If user to edit is not a dealer admin.
       * * If user to edit is a dealer admin and the current user is himself or super admin.
       * * User is not archived. (Can un-archived).
       */
      renderCell: ({ value: { userId, isArchived, isAdmin } }) => {
        let options = [];

        if (!isArchived) {
          options.push({
            icon: <LockResetIcon />,
            value: 'Reset Password',
            callback: () => {
              setSelectedUserId(userId);
              setPassResetModalOpen(true);
            },
          });
        }

        /**
         * ! Important.
         *
         * We don't allow current user changing their own archived status.
         */
        if (userId !== currentUser.id) {
          if (isArchived) {
            options.push({
              icon: <UnarchiveIcon />,
              value: 'Un-archive User',
              callback: () => {
                setSelectedUserId(userId);
                setSelectedUserIsArchive(false);
                setArchiveUserModalOpen(true);
              },
            });
          } else {
            options.push({
              icon: <ArchiveIcon />,
              value: 'Archive User',
              callback: () => {
                setSelectedUserId(userId);
                setSelectedUserIsArchive(true);
                setArchiveUserModalOpen(true);
              },
            });
          }
        }

        return currentUser.is_super_admin || !isAdmin || (isAdmin && userId === activeProfile.user_id) ? <ActionMenu options={options} /> : <></>;
      },
    },
  ];

  const rows = users
    .map((user) => {
      const profile = user.lender_user_profiles?.find((profile) => profile.lender_id === activeProfile.lender_id);

      return profile && !user.archived_at
        ? {
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            title: profile.title,
            is_admin: profile.is_admin,
            action: { userId: user.id, isArchived: user.archived_at, isAdmin: profile.is_admin },
          }
        : undefined;
    })
    .filter((user) => user);

  const validateRow = (row) => {
    const keyMap = {
      first_name: 'First Name',
      last_name: 'Last Name',
      title: 'Job Title',
    };

    let errors = [];

    Object.entries(row).forEach(([key, val]) => {
      if (key === 'email' && !REGEX.isEmail.test(val)) {
        errors.push('Please provide a valid Email.');
      } else if (typeof val !== 'boolean' && !val) {
        errors.push(`Please provide a valid ${keyMap[key]}.`);
      }
    });

    return errors;
  };

  const processRowUpdate = (newRow, oldRow) => {
    const errors = validateRow(newRow);

    if (errors.length > 0) {
      dispatch(
        manuallyCallAlert({
          type: 'error',
          message: errors.join(' '),
          showing: true,
        }),
      );
      return oldRow;
    } else {
      dispatch(updateLenderUser({ lenderId: activeProfile.lender_id, userId: newRow.id, data: newRow }));
      return newRow;
    }
  };

  return (
    <Card margin="0" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0px 0px 20px" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Users</Typography>
        <Button onClick={() => setAddUserModalOpen(true)} startIcon={<AddIcon />}>
          Add
        </Button>
      </FlexRow>

      <StyledDataGrid
        columnBuffer={6} // Required for testing to work, else on test rendering, only 3 columns are rendered
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        components={{ Toolbar: GridToolbar }}
        loading={loading}
        autoHeight
        rows={rows || []}
        columns={columns}
        editMode="row"
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        isCellEditable={(props) => {
          /**
           * ! Important
           *
           * So can only edit if:
           * * Current user is super admin.
           * * If user to edit is not a dealer admin.
           * * If user to edit is a dealer admin and the current user is himself or super admin.
           * * User is not archived. (Can un-archived).
           */
          const { action } = props.row;
          const { userId, isArchived, isAdmin } = action;

          return (currentUser.is_super_admin || !isAdmin || (isAdmin && userId === activeProfile.user_id)) && !isArchived;
        }}
        isRowSelectable={(params) => (params.row.action.isArchived ? false : true)}
        getRowClassName={(params) => (params.row.action.isArchived ? 'is_archived' : '')}
      />

      {addUserModalOpen && <AddUserModal lenderId={activeProfile?.lender_id} open={addUserModalOpen} onClose={() => setAddUserModalOpen(false)} />}

      {passResetModalOpen && (
        <ResetPasswordModal
          lenderId={activeProfile?.lender_id}
          userId={selectedUserId}
          currentUserId={currentUser.id}
          open={passResetModalOpen}
          onClose={() => setPassResetModalOpen(false)}
        />
      )}

      <DoubleConfirmNoButton
        title="Confirm action?"
        description={`Are you sure you want to "${selectedUserIsArchive ? 'archive' : 'un-archive'}" this user?.`}
        action={() => dispatch(updateLenderUser({ lenderId: activeProfile.lender_id, userId: selectedUserId, data: { is_archived: selectedUserIsArchive } }))}
        open={archiveUserModalOpen}
        onClose={() => {
          setSelectedUserId(null);
          setSelectedUserIsArchive(null);
          setArchiveUserModalOpen(false);
        }}
      />
    </Card>
  );
};

export default Users;
