import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApplicantAPI } from 'store/api';

export const fetchApplicantCreditReportThunk = createAsyncThunk('applicant/fetchApplicantCreditReport', async ({ applicantId }, thunkAPI) => {
  try {
    const response = await ApplicantAPI.fetchApplicantCreditReport({ applicantId });

    /**
     * This will dispatch action creator with type of "applicant/fetchApplicantCreditReport/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return response;
  } catch (err) {
    /**
     * This will dispatch action creator with type of "applicant/fetchApplicantCreditReport/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});
