import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Alert, CircularProgress, Drawer } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useDispatch } from 'react-redux';

import { DrawerContainer, DrawerHeaderContainer, DrawerContentContainer, DrawerActionsContainer } from 'components/Drawer';
import GoogleMaps from 'components/GooglePlaces';
import { queryCustomersThunk } from 'store/thunks/customer';
import { setAlert } from 'store/sagas/app';
import { CustomerCardForDrawer } from './CustomerCard';
import { FlexRow } from 'components/Layouts';

const CustomerDrawer = ({ handleClose, role, open, formie, handleAddress, handleSubmit, editing, linkCustomerToLoan, primaryCustomerId }) => {
  const dispatch = useDispatch();

  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [foundCustomer, setFoundCustomer] = useState(null);

  const actionWord = editing ? 'Edit' : 'Add';
  const noun = role === 'primary' ? 'Customer' : 'Secondary Customer';
  const title = `${actionWord} ${noun}`;

  const queryCustomers = async () => {
    setLoading(true);
    try {
      const { customers } = await dispatch(queryCustomersThunk({ ssn: formie?.values?.customer?.ssn })).unwrap();
      setSearched(true);

      if (customers && customers[0]) {
        setFoundCustomer(customers[0]);
      } else {
        setFoundCustomer(null);
      }
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to query customers. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const linkCustomer = (customerId) => {
    if (role === 'primary') {
      linkCustomerToLoan(customerId, null);
    } else if (primaryCustomerId) {
      linkCustomerToLoan(primaryCustomerId, customerId);
    }
  };

  const { customer, address_residence, address_mailing, address_helper } = formie?.values;
  const shouldCopyAddress = address_helper?.use_primary_address;

  const disabled = shouldCopyAddress
    ? !customer?.first_name ||
      !customer?.last_name ||
      !customer?.ssn ||
      !customer?.dob ||
      !customer?.email ||
      !customer?.phone ||
      !address_residence?.address_1 ||
      !address_residence?.city ||
      !address_residence?.state ||
      !address_residence?.zip
    : !customer?.first_name ||
      !customer?.last_name ||
      !customer?.ssn ||
      !customer?.dob ||
      !customer?.email ||
      !customer?.phone ||
      !address_residence?.address_1 ||
      !address_residence?.city ||
      !address_residence?.state ||
      !address_residence?.zip ||
      !address_mailing?.address_1 ||
      !address_mailing?.city ||
      !address_mailing?.state ||
      !address_mailing?.zip;
  const searchDisabled = !customer?.ssn;

  useEffect(() => {
    if (!open) {
      setSearched(false);
      setFoundCustomer(null);
    }
  }, [open]);

  return (
    <Drawer onClose={handleClose} open={open} anchor="right" ModalProps={{ disableEscapeKeyDown: true }}>
      {/* Used to set the width of the drawer.  */}
      <DrawerContainer>
        <DrawerHeaderContainer>
          <Typography align="center" variant="h5">
            {title}
          </Typography>
        </DrawerHeaderContainer>

        <DrawerContentContainer>
          {!editing && !searched && (
            <>
              <Typography sx={{ fontWeight: 700 }}>Search for the customer:</Typography>

              <Alert sx={{ borderRadius: '8px', m: '8px 0px 16px' }} severity="info">
                Each lender can only add a specific SSN to their customer list once. If this SSN already exists, you must link that customer to the loan.
              </Alert>

              {loading ? (
                <FlexRow padding="0px" margin="0px" justifyContent="center">
                  <CircularProgress />
                </FlexRow>
              ) : (
                <Grid container spacing={2}>
                  <Grid display="flex" justifyContent="center" xs={9}>
                    {formie.buildField('customer.ssn')}
                  </Grid>
                  <Grid display="flex" justifyContent="center" xs={3}>
                    <Button onClick={queryCustomers} sx={{ height: '48px' }} disabled={searchDisabled} fullWidth variant="contained">
                      Search
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {!editing && searched && foundCustomer && (
            <>
              <Typography sx={{ fontWeight: 700, mb: '8px' }}>Is this the right customer?</Typography>

              <Alert sx={{ borderRadius: '8px', m: '8px 0px 16px' }} severity="info">
                We've found a customer with that SSN in your customer list. Each lender can only add a specific SSN to their customer list once. To add the
                customer to this loan, you must link the existing customer.
              </Alert>

              <CustomerCardForDrawer customer={foundCustomer} />

              <FlexRow padding="0px" margin="16px 0px 0px" alignItems="center" justifyContent="flex-end">
                <Button onClick={() => linkCustomer(foundCustomer?.id)} variant="contained">
                  Yes, Add Them
                </Button>
              </FlexRow>
            </>
          )}
          {(editing || (searched && !foundCustomer)) && (
            <>
              <Typography sx={{ fontWeight: 700 }}>Customer Details</Typography>
              {formie.buildFields({
                fields: [
                  { dataPath: 'customer.first_name' },
                  { dataPath: 'customer.last_name' },
                  { dataPath: 'customer.ssn' },
                  { dataPath: 'customer.dob' },
                  { dataPath: 'customer.phone' },
                  { dataPath: 'customer.email' },
                ],
                fieldsPerRow: 2,
              })}

              <Typography sx={{ fontWeight: 700, mt: '32px' }}>Primary Address</Typography>
              <GoogleMaps addressExtractFn={(addr) => handleAddress(addr, 'address_residence')} />
              {formie.buildFields({
                fields: [
                  { dataPath: 'address_residence.address_1' },
                  { dataPath: 'address_residence.address_2' },
                  { dataPath: 'address_residence.city' },
                  { dataPath: 'address_residence.state' },
                  { dataPath: 'address_residence.zip' },
                ],
                fieldsPerRow: 2,
              })}

              {formie.buildFields({
                fields: [{ dataPath: 'address_helper.use_primary_address' }],
                fieldsPerRow: 1,
              })}
              {!formie?.values?.address_helper?.use_primary_address && (
                <>
                  <Typography sx={{ fontWeight: 700, mt: '16px' }}>Mailing Address</Typography>
                  <GoogleMaps addressExtractFn={(addr) => handleAddress(addr, 'address_mailing')} />
                  {formie.buildFields({
                    fields: [
                      { dataPath: 'address_mailing.address_1' },
                      { dataPath: 'address_mailing.address_2' },
                      { dataPath: 'address_mailing.city' },
                      { dataPath: 'address_mailing.state' },
                      { dataPath: 'address_mailing.zip' },
                    ],
                    fieldsPerRow: 2,
                  })}
                </>
              )}
            </>
          )}
        </DrawerContentContainer>

        <DrawerActionsContainer>
          <Button onClick={handleClose} sx={{ mr: '16px' }}>
            Cancel
          </Button>

          {(editing || (searched && !foundCustomer)) && (
            <Button onClick={handleSubmit} disabled={disabled} variant="contained">
              {editing ? 'Edit' : 'Add'}
            </Button>
          )}
        </DrawerActionsContainer>
      </DrawerContainer>
    </Drawer>
  );
};

export default CustomerDrawer;
