import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined';

import { SECTIONS as VIEW_APP_SECTIONS } from '../views/Dealer/ViewApp';
import { SECTIONS as EDIT_APP_SECTIONS } from '../views/Dealer/EditApp';
import { SECTIONS as PREPARE_CONTRACT_SECTIONS } from '../views/Dealer/PrepareContract';

export const LINKS = {
  DEALER: {
    DASHBOARD: '/app/dealer/dashboard',
    ALL_APPLICATIONS: '/app/dealer/applications',
    SETTINGS: '/app/dealer/admin/settings/users',
    EDIT_APPLICATION: (id, section) => `/app/dealer/application/${id}/edit?section=${section || EDIT_APP_SECTIONS.BASIC_INFO}`,
    VIEW_APPLICATION: (id, section) => `/app/dealer/application/${id}?section=${section || VIEW_APP_SECTIONS.OVERVIEW}`,
    PREPARE_CONTRACT: (id, section) => `/app/dealer/application/${id}/prepare-contract?section=${section || PREPARE_CONTRACT_SECTIONS.OVERVIEW}`,
    CREDIT_REPORT: (id) => `/app/dealer/application/${id}/credit-report`,
  },
  LENDER: {
    DASHBOARD: '/app/lender/dashboard',
    APPLICATIONS: {
      BASE: '/app/lender/applications',
      VIEW_ONE: (id, section) => `/app/lender/application/${id}?section=${section || VIEW_APP_SECTIONS.OVERVIEW}`,
      CREDIT_REPORT: (id) => `/app/lender/application/${id}/credit-report`,
    },
    LOANS: {
      BASE: '/app/lender/loans',
      ADD_LOAN: '/app/lender/loans/add',
      VIEW_ONE: (id, section) => `/app/lender/loans/${id}/view/${section}`,
    },
    SETTINGS: {
      BASE: '/app/lender/admin/settings',
      ORGANIZATION_DETAILS: '/app/lender/admin/settings/organization-details',
      LENDING_RULES: '/app/lender/admin/settings/lending-rules',
      STIPULATION_TEMPLATES: '/app/lender/admin/settings/stipulation-templates',
      USERS: '/app/lender/admin/settings/users',
    },
  },
  ACCOUNT: {
    SETTINGS: '/app/account/settings',
  },
  SUPER_ADMIN: {
    INDEX: '/app/super-admin',
    DOCUMENT_TEMPLATES: '/app/super-admin/document-templates',
  },
};

const superAdminObj = {
  name: 'Super Admin',
  link: LINKS.SUPER_ADMIN.DOCUMENT_TEMPLATES,
  icon: (props) => <AdminPanelSettingsOutlinedIcon {...props} />,
};

export const dealerMenu = (activeProfile, isSuperAdmin) => {
  const filteredMenu = [
    {
      name: 'Dashboard',
      link: LINKS.DEALER.DASHBOARD,
      icon: (props) => <HomeOutlinedIcon {...props} />,
    },
    {
      name: 'Applications',
      children: [
        {
          name: 'New Application',
          link: LINKS.DEALER.EDIT_APPLICATION('new'),
          icon: (props) => <PostAddOutlinedIcon {...props} />,
        },
        {
          name: 'All Applications',
          link: LINKS.DEALER.ALL_APPLICATIONS,
          icon: (props) => <AppsOutlinedIcon {...props} />,
        },
      ],
    },
    {
      name: 'Admin',
      adminOnly: true,
      children: [
        {
          name: 'Settings',
          link: LINKS.DEALER.SETTINGS,
          icon: (props) => <SettingsOutlinedIcon {...props} />,
        },
      ],
    },
  ].filter((item) => !item.adminOnly || activeProfile.is_admin);

  if (isSuperAdmin) {
    filteredMenu.push(superAdminObj);
  }

  return filteredMenu;
};

export const lenderMenu = (activeProfile, isSuperAdmin) => {
  const filteredMenu = [
    {
      name: 'Dashboard',
      link: LINKS.LENDER.DASHBOARD,
      icon: (props) => <HomeOutlinedIcon {...props} />,
    },
    {
      name: 'Applications',
      children: [
        {
          name: 'All Apps',
          link: LINKS.LENDER.APPLICATIONS.BASE,
          icon: (props) => <AppsOutlinedIcon {...props} />,
        },
      ],
    },
    {
      name: 'Loans',
      children: [
        {
          name: 'Add Loan',
          link: LINKS.LENDER.LOANS.ADD_LOAN,
          icon: (props) => <PostAddOutlinedIcon {...props} />,
        },
        {
          name: 'All Loans',
          link: LINKS.LENDER.LOANS.BASE,
          icon: (props) => <WebStoriesOutlinedIcon {...props} />,
          end: true,
        },
      ],
    },
    {
      name: 'Admin',
      children: [
        {
          name: 'Settings',
          link: LINKS.LENDER.SETTINGS.BASE,
          icon: (props) => <SettingsOutlinedIcon {...props} />,
        },
      ],
      adminOnly: true,
    },
  ].filter((item) => !item.adminOnly || activeProfile.is_admin);

  if (isSuperAdmin) {
    filteredMenu.push(superAdminObj);
  }

  return filteredMenu;
};
