import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, DialogActions, DialogContentText } from '@mui/material';

import { setAlert } from 'store/sagas/app';
import { FlexRow } from 'components/Layouts';
import { createLoanPaymentThunk, editLoanPaymentThunk, fetchLoanPaymentsThunk, reverseLoanPaymentThunk } from 'store/thunks/payment';
import Drawer from 'components/Drawer';
import { useFormie } from 'components/Formie';
import { configuration, initialValues } from '../formInfo';
import { COLORS } from 'constants/styles';
import { createColumns, gridSx } from './helpers';
import DetailsDialog from './DetailsDialog';
import { parseDateOnlyForInputs } from 'constants/formatters';

const Payments = ({}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [paymentReverseId, setPaymentReverseId] = useState(null);
  const [paymentDetailId, setPaymentDetailId] = useState(null);
  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { payments } = await dispatch(
        fetchLoanPaymentsThunk({
          subdata: ['period-payments', 'due-payments', 'fee-payments'],
          loanId,
          limit: 1000,
        }),
      ).unwrap();
      setPayments(payments);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch payments. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const createOrEditPayment = async () => {
    setLoading(true);
    try {
      const data = {
        payment_application_date:
          formie?.values?.payment?.payment_application_date && format(parseISO(formie.values.payment.payment_application_date), 'yyyy-MM-dd'),
        payment_amount: formie?.values?.payment?.payment_amount,
        payment_application_schedule: formie?.values?.payment?.payment_application_schedule,
        payment_method: formie?.values?.payment?.payment_method,
      };

      editing
        ? await dispatch(editLoanPaymentThunk({ data, loanId, paymentId: formie?.values?.payment?.id })).unwrap()
        : await dispatch(createLoanPaymentThunk({ data, loanId })).unwrap();
      hydrateData();
      setDrawerOpen(false);
      setEditing(false);
      resetForm();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to ${editing ? 'edit' : 'create'} payment. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleShowReverseModal = (id) => {
    setPaymentReverseId(id);
  };

  const handleCloseReverseModal = () => {
    setPaymentReverseId(null);
  };

  const reversePayment = async () => {
    setLoading(true);
    try {
      await dispatch(
        reverseLoanPaymentThunk({
          loanId,
          paymentId: paymentReverseId,
        }),
      ).unwrap();
      handleCloseReverseModal();
      hydrateData();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to reverse payment. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleStartPaymentEdit = (props) => {
    const payment = props.row;
    setAllValues({
      payment: {
        payment_application_date: parseDateOnlyForInputs(payment?.date),
        payment_application_schedule: payment?.type,
        payment_amount: payment?.amount && Number(payment.amount),
        payment_method: payment?.method,
        id: payment?.id,
      },
    });
    setEditing(true);
    handleDrawerOpen();
  };

  const handleCloseDrawer = (e, reason) => {
    if (reason !== 'backdropClick') {
      setDrawerOpen(false);
      setEditing(false);
      resetForm();
    }
  };

  const handleShowDetails = (id) => {
    setPaymentDetailId(id);
  };

  const handleCloseDetails = () => {
    setPaymentDetailId(null);
  };

  const formie = useFormie({
    configuration,
    initialValues,
    onSubmit: createOrEditPayment,
  });

  const { values, handleSubmit, resetForm, setAllValues } = formie;
  const disabled = !values?.payment?.payment_application_date || !values?.payment?.payment_application_schedule || !values?.payment?.payment_amount;
  const columns = useMemo(() => {
    return createColumns({
      showDetails: (id) => {
        handleShowDetails(id);
      },
      editPayment: (payment) => {
        handleStartPaymentEdit(payment);
      },
      reversePayment: (id) => {
        handleShowReverseModal(id);
      },
    });
  }, [createColumns]);
  const rows = payments
    .map((payment) => ({
      id: payment.id,
      date: payment.payment_application_date,
      amount: payment.payment_amount,
      feesAmount: payment.fees_payment_amount,
      principalAmount: payment.principal_payment_amount,
      interestAmount: payment.interest_payment_amount,
      type: payment.payment_application_schedule,
      status: payment.status,
      method: payment.payment_method,
    }))
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const paymentForDetailModal = payments.find((payment) => payment.id === paymentDetailId);

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <>
      {/* Header row with action button */}
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Payments</Typography>
        <Button size="small" variant="contained" onClick={handleDrawerOpen}>
          Add Payment
        </Button>
      </FlexRow>

      {/* Data Grid */}
      <div style={{ margin: '16px 0px 0px', height: 'calc(100vh - 215px)', background: COLORS.surfaceColor }}>
        <DataGrid
          onRowClick={(params) => setPaymentDetailId(params.id)}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          disableColumnMenu
          density="compact"
          columns={columns}
          rows={rows}
          getRowClassName={(params) => `payment-theme--${params.row.status}`}
          sx={gridSx}
        />
      </div>

      {/* Reverse Confirmation Dialog */}
      <Dialog onClose={handleCloseReverseModal} open={!!paymentReverseId}>
        <DialogTitle>Reverse this payment?</DialogTitle>
        <DialogContent>
          <DialogContentText>Reversing this payment will remove it from the loan. This is irreversible.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReverseModal}>Cancel</Button>
          <Button onClick={reversePayment}>Reverse Payment</Button>
        </DialogActions>
      </Dialog>

      {/* Details Dialog */}
      <DetailsDialog onClose={handleCloseDetails} open={!!paymentDetailId} payment={paymentForDetailModal} />

      {/* Drawer with drawer content */}
      <Drawer
        title={editing ? 'Edit Payment' : 'Add Payment'}
        open={drawerOpen}
        disabled={disabled}
        actionTitle={editing ? 'edit' : 'add'}
        handleClose={handleCloseDrawer}
        handleSubmit={handleSubmit}
      >
        <Typography variant="h6">How much is the payment for?</Typography>
        {formie.buildField('payment.payment_amount')}

        <Typography variant="h6" sx={{ mt: '32px' }}>
          When and how was the payment made?
        </Typography>
        {formie.buildFields({
          fields: [{ dataPath: 'payment.payment_application_date' }, { dataPath: 'payment.payment_method' }],
          fieldsPerRow: 2,
        })}

        <Typography variant="h6" sx={{ mt: '32px' }}>
          What type of payment is this?
        </Typography>
        <Alert severity="info" sx={{ m: '8px 0px 16px' }}>
          This setting determines the order the payment will be applied. For example, IPF is interest, then principal, then fees. It will pay off all interest,
          and if any is remaining pay off principal. If any still remains, it will pay off fees. Any excess payment amount that cannot be applied to the
          selected option will apply based on the "Extra Towards" setting. The default is for any excess to apply to principal without reducing the future
          amount due.
        </Alert>
        {formie.buildField('payment.payment_application_schedule')}
      </Drawer>
    </>
  );
};

export default Payments;
