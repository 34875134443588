import { createAsyncThunk } from '@reduxjs/toolkit';

import { CustomerAPI } from 'store/api';

export const editCustomerThunk = createAsyncThunk('customer/edit', async ({ customer, residenceAddress, mailingAddress }, thunkAPI) => {
  try {
    await CustomerAPI.editCustomer({
      data: { ...Object.fromEntries(Object.entries(customer).filter(([key, value]) => key !== 'id')) },
      customerId: customer?.id,
    });

    const residenceAddressData = {
      ...residenceAddress,
      address_2: residenceAddress?.address_2 === '' ? null : residenceAddress?.address_2,
      id: null,
    };

    const mailingAddressData = {
      ...mailingAddress,
      address_2: mailingAddress?.address_2 === '' ? null : mailingAddress?.address_2,
      id: null,
    };

    await Promise.all([
      CustomerAPI.editCustomerAddress({ customerId: customer?.id, addressId: residenceAddress?.id, data: residenceAddressData }),
      CustomerAPI.editCustomerAddress({ customerId: customer?.id, addressId: mailingAddress?.id, data: mailingAddressData }),
    ]);

    const { customer: updatedCustomer } = await CustomerAPI.fetchCustomer({ customerId: customer?.id });

    /**
     * This will dispatch action creator with type of "customer/edit/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { customer: updatedCustomer };
  } catch (err) {
    console.log('err: ', err);
    /**
     * This will dispatch action creator with type of "customer/edit/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchCustomerThunk = createAsyncThunk('customer/fetch', async ({ customerId }, thunkAPI) => {
  try {
    const { customer } = await CustomerAPI.fetchCustomer({ customerId });

    /**
     * This will dispatch action creator with type of "customer/fetch/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { customer };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "customer/fetch/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const queryCustomersThunk = createAsyncThunk('customer/query', async ({ ssn }, thunkAPI) => {
  try {
    const { customers } = await CustomerAPI.queryCustomers({ ssn });

    /**
     * This will dispatch action creator with type of "customer/query/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { customers };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "customer/query/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchLoanCustomersThunk = createAsyncThunk('customer/fetchLoanCustomers', async ({ loanId, subdata }, thunkAPI) => {
  try {
    let queryString = '';

    if (subdata && subdata?.length && subdata.length > 0) {
      queryString = `?subdata=${subdata.join(',')}`;
    }

    const { customers } = await CustomerAPI.fetchLoanCustomers({ loanId, queryString });

    /**
     * This will dispatch action creator with type of "customer/fetchLoanCustomers/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { customers };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "customer/fetchLoanCustomers/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});
