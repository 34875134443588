import { format, parseISO } from 'date-fns';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { Card, FlexRow, DataCard } from '../../../components/Layouts';
import { currencyFormatter, percentageFormatter, roundAccurately } from '../../../constants/formatters';
import { Button } from '@mui/material';
import DataListItem from './dataListItem';

const ContractPrepOverview = ({ showDetailed, prepareContractAction, canPrepare, currentApplication, hasContractPrepared, primaryPaymentProfile }) => {
  const { contract_values, dates, trade_unit, unit, signing, lending_options } = currentApplication;
  const selectedLendingOption = lending_options.length > 0 ? lending_options.find((option) => option.selected_by_dealer) : null;

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="Contract preparation overview.">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Contract Prep Overview
        </Typography>

        <Button disabled={!canPrepare} onClick={prepareContractAction} variant="outlined">
          Prepare Contract
        </Button>
      </FlexRow>

      {hasContractPrepared ? (
        <FlexRow margin="12px 0px 0px" padding="0px" alignItems="center" justifyContent="space-between" flexWrap>
          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }}>
            <DataCard icon={<AttachMoneyIcon />} label="Amount Financed" value={currencyFormatter.format(contract_values.amount_financed)} />
            <DataCard icon={<CurrencyExchangeIcon />} label="Monthly Payment" value={currencyFormatter.format(contract_values.payment_amount)} />
            <DataCard icon={<CalendarTodayIcon />} label="Contract Date" value={format(parseISO(dates.contract_date), 'MM/dd/yyyy')} />
            <DataCard icon={<CalendarTodayIcon />} label="First Payment Date" value={format(parseISO(dates.payment_start_date), 'MM/dd/yyyy')} />
          </Stack>

          {showDetailed && (
            <List style={{ width: '100%', marginTop: '12px' }}>
              <DataListItem title="APR" data={selectedLendingOption?.apr && percentageFormatter.format(selectedLendingOption.apr)} />
              <DataListItem title="Term" data={selectedLendingOption?.term ? `${selectedLendingOption.term} Months` : null} />
              <DataListItem title="Unit Price" data={unit?.price && currencyFormatter.format(unit.price)} />
              <DataListItem title="Sales Tax" data={contract_values?.sales_tax && currencyFormatter.format(contract_values.sales_tax)} />
              <DataListItem
                title="Trade Equity"
                data={trade_unit?.equity && trade_unit.equity >= 0 ? currencyFormatter.format(trade_unit.equity) : currencyFormatter.format(0)}
              />
              <DataListItem title="Cash Down" data={contract_values?.cash_down && currencyFormatter.format(contract_values.cash_down)} />
              <DataListItem
                title="Total Down Payment"
                data={contract_values?.total_down_payment && currencyFormatter.format(contract_values.total_down_payment)}
              />
              <DataListItem title="Unpaid Balance" data={contract_values?.unpaid_balance && currencyFormatter.format(contract_values.unpaid_balance)} />
              <DataListItem title="VSI" data={contract_values?.vsi_cost && currencyFormatter.format(contract_values.vsi_cost)} />
              <DataListItem title="ESC" data={contract_values?.esc_cost && currencyFormatter.format(contract_values.esc_cost)} />
              <DataListItem title="GAP" data={contract_values?.gap_cost && currencyFormatter.format(contract_values.gap_cost)} />
              <DataListItem title="PPM" data={contract_values?.ppm_cost && currencyFormatter.format(contract_values.ppm_cost)} />
              <DataListItem title="License/Reg" data={contract_values?.license_reg_fees && currencyFormatter.format(contract_values.license_reg_fees)} />
              <DataListItem title="Title Fees" data={contract_values?.title_fees && currencyFormatter.format(contract_values.title_fees)} />
              <DataListItem title="Document Fees" data={contract_values?.doc_fee && currencyFormatter.format(contract_values.doc_fee)} />
              <DataListItem
                title="Neg. Trade Equity"
                data={trade_unit?.equity && trade_unit.equity < 0 ? currencyFormatter.format(trade_unit.equity) : currencyFormatter.format(0)}
              />
              <DataListItem
                title="Custom Fields"
                data={
                  contract_values?.custom_field_1_cost &&
                  currencyFormatter.format(
                    roundAccurately(
                      contract_values.custom_field_1_cost +
                        contract_values.custom_field_2_cost +
                        contract_values.custom_field_3_cost +
                        contract_values.custom_field_4_cost,
                      2,
                    ),
                  )
                }
              />
              <DataListItem
                title="Total Other Charges"
                data={contract_values?.total_other_charges && currencyFormatter.format(contract_values.total_other_charges)}
              />
              <DataListItem title="Amount Financed" data={contract_values?.amount_financed && currencyFormatter.format(contract_values.amount_financed)} />

              <DataListItem title="Bank Name" data={primaryPaymentProfile?.institution_name} />
              <DataListItem
                title="Bank Account Type"
                data={primaryPaymentProfile ? primaryPaymentProfile.account_type[0].toUpperCase() + primaryPaymentProfile.account_type.slice(1) : null}
              />
              <DataListItem title="Bank Account Name" data={primaryPaymentProfile?.account_name} />
              <DataListItem title="Bank Account Number" data={primaryPaymentProfile?.account_number} />
              <DataListItem title="Bank Routing Number" data={primaryPaymentProfile?.routing_number} noDivider />
            </List>
          )}
        </FlexRow>
      ) : (
        <FlexRow margin="12px 0px 0px" padding="0">
          <Alert sx={{ width: '100%' }} severity="error">
            <AlertTitle>Finish Selections</AlertTitle>
            Please make sure you've selected a contract date and a lending option in order to see the contract values.
          </Alert>
        </FlexRow>
      )}
    </Card>
  );
};

export default ContractPrepOverview;
