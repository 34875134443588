import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

export const MultipleSelectCheckmarks = (props) => {
  const { id, label, size = 'small', width = 250, options, selectedOptions, setSelectedOptions } = props;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedOptions(typeof value === 'string' ? value.split(',') : value); // On autofill we get a stringified value.
  };

  return (
    <FormControl size={size} sx={{ m: 0, width }}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>

      <Select
        labelId={`${id}-label`}
        id={id}
        multiple
        value={selectedOptions}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        MenuProps={{ PaperProps: { sx: { maxHeight: '385px' } } }}
        renderValue={(selected) => {
          return selected.reduce((acc, curr) => {
            const selectedOption = options.find((option) => option.value === curr);

            if (selectedOption) {
              const selectedLabel = typeof selectedOption.label === 'string' ? selectedOption.label : selectedOption.label.text;
              acc += acc === '' ? `${selectedLabel}` : `,${selectedLabel}`;
            }

            return acc;
          }, '');
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem dense={true} key={value} value={value}>
            <Checkbox checked={selectedOptions.indexOf(value) > -1} />
            {/* TODO: Add support for displaying both icon and label later */}
            {typeof label === 'string' ? label : label.icon}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
