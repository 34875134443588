import { takeLatest, call, put } from 'redux-saga/effects';
import { ApplicationAPI, LenderAPI, DealerAPI, DocumentTemplateAPI, StipulationTemplateAPI } from 'store/api';

import {
  fetchDocumentTemplates,
  storeDocumentTemplates,
  startLoader,
  stopLoader,
  saveDocumentTemplate,
  createDocumentTemplate,
  archiveDocumentTemplate,
  storeStipulationTemplates,
  fetchStipulationTemplates,
  fetchApplicationShape,
  storeApplicationShape,
  createStipulationTemplate,
  updateStipulationTemplate,
  archiveStipulationTemplate,
  addDealerUser,
  updateDealerUser,
  fetchDealerUsers,
  storeDealerUsers,
  updateLender,
  fetchLender,
  storeLender,
  addLenderUser,
  updateLenderUser,
  fetchLenderUsers,
  storeLenderUsers,
} from './index';
import { logUserOut } from '../user/index';
import { setAlertSaga } from '../app/sagas';

export function* fetchDocumentTemplatesSaga() {
  try {
    yield put(startLoader('document_templates'));
    const { templates } = yield call(DocumentTemplateAPI.getAllDocumentTemplates);
    yield put(storeDocumentTemplates(templates));
    yield put(stopLoader('document_templates'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('document_templates'));
    }
  }
}

export function* saveDocumentTemplateSaga({ payload: { data, templateId } }) {
  try {
    yield put(startLoader('document_templates'));
    yield call(DocumentTemplateAPI.updateDocumentTemplate, { data, templateId });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Document Template Updated',
        showing: true,
      },
    });
    const { templates } = yield call(DocumentTemplateAPI.getAllDocumentTemplates);
    yield put(storeDocumentTemplates(templates));
    yield put(stopLoader('document_templates'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('document_templates'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Document Template Not Updated. Try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* createDocumentTemplateSaga({ payload: { data } }) {
  try {
    yield put(startLoader('document_templates'));
    yield call(DocumentTemplateAPI.createDocumentTemplate, { data });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Document Template Created',
        showing: true,
      },
    });
    const { templates } = yield call(DocumentTemplateAPI.getAllDocumentTemplates);
    yield put(storeDocumentTemplates(templates));
    yield put(stopLoader('document_templates'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('document_templates'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Document Template Not Created. Try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* archiveDocumentTemplateSaga({ payload: { templateId } }) {
  try {
    yield put(startLoader('document_templates'));
    yield call(DocumentTemplateAPI.archiveDocumentTemplate, { templateId });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Document Template Archived',
        showing: true,
      },
    });
    const { templates } = yield call(DocumentTemplateAPI.getAllDocumentTemplates);
    yield put(storeDocumentTemplates(templates));
    yield put(stopLoader('document_templates'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('document_templates'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Document Template Not Archived. Try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* fetchStipulationTemplatesSaga({ payload: { lenderId } }) {
  try {
    yield put(startLoader('stipulation_templates'));
    const { stipulation_templates } = yield call(StipulationTemplateAPI.getAllStipulationTemplates, { lenderId });
    yield put(storeStipulationTemplates(stipulation_templates));
    yield put(stopLoader('stipulation_templates'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('stipulation_templates'));
    }
  }
}

export function* fetchApplicationShapeSaga() {
  try {
    yield put(startLoader('application_shape'));
    const { application_shape } = yield call(ApplicationAPI.getApplicationShape);
    yield put(storeApplicationShape(application_shape));
    yield put(stopLoader('application_shape'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('application_shape'));
    }
  }
}

export function* createStipulationTemplateSaga({ payload: { data } }) {
  try {
    yield put(startLoader('stipulation_templates'));
    yield call(StipulationTemplateAPI.createStipulationTemplate, { data });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Stipulation Template Created',
        showing: true,
      },
    });
    const { lender_id } = data;
    const { stipulation_templates } = yield call(StipulationTemplateAPI.getAllStipulationTemplates, { lenderId: lender_id });
    yield put(storeStipulationTemplates(stipulation_templates));
    yield put(stopLoader('stipulation_templates'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('stipulation_templates'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Stipulation Template Not Created. Try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* updateStipulationTemplateSaga({ payload: { data, templateId } }) {
  try {
    yield put(startLoader('stipulation_templates'));
    yield call(StipulationTemplateAPI.updateStipulationTemplate, { data, templateId });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Stipulation Template Updated',
        showing: true,
      },
    });
    const { lender_id } = data;
    const { stipulation_templates } = yield call(StipulationTemplateAPI.getAllStipulationTemplates, { lenderId: lender_id });
    yield put(storeStipulationTemplates(stipulation_templates));
    yield put(stopLoader('stipulation_templates'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('stipulation_templates'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Stipulation Template Not Updated. Try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* archiveStipulationTemplateSaga({ payload: { templateId, lenderId } }) {
  try {
    yield put(startLoader('stipulation_templates'));
    yield call(StipulationTemplateAPI.archiveStipulationTemplate, { templateId, data: { lender_id: lenderId } });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Stipulation Template Archived',
        showing: true,
      },
    });
    const { stipulation_templates } = yield call(StipulationTemplateAPI.getAllStipulationTemplates, { lenderId });
    yield put(storeStipulationTemplates(stipulation_templates));
    yield put(stopLoader('stipulation_templates'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('stipulation_templates'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Stipulation Template Not Archived. Try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* addDealerUserSaga({ payload: { dealerId, data } }) {
  try {
    yield put(startLoader('users'));
    yield call(DealerAPI.addDealerUser, { dealerId, data });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'User added!',
        showing: true,
      },
    });
    const { users } = yield call(DealerAPI.fetchUsersForDealer, { dealerId });
    yield put(storeDealerUsers(users));
    yield put(stopLoader('users'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('users'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Could not add user. Please try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* updateDealerUserSaga({ payload: { dealerId, logOutAfterUpdate, userId, data } }) {
  try {
    yield put(startLoader('users'));
    yield call(DealerAPI.updateDealerUser, { dealerId, userId, data });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'User updated!',
        showing: true,
      },
    });

    if (logOutAfterUpdate) {
      yield put(logUserOut());
    } else {
      const { users } = yield call(DealerAPI.fetchUsersForDealer, { dealerId });
      yield put(storeDealerUsers(users));
      yield put(stopLoader('users'));
    }
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('users'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Could not update user. Please try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* fetchDealerUsersSaga({ payload: { dealerId } }) {
  try {
    yield put(startLoader('users'));
    const { users } = yield call(DealerAPI.fetchUsersForDealer, { dealerId });
    yield put(storeDealerUsers(users));
    yield put(stopLoader('users'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('users'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Could not find users. Please try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* updateLenderSaga({ payload: { lenderId, data } }) {
  try {
    yield put(startLoader('lender'));
    const { lender } = yield call(LenderAPI.updateLender, { lenderId, data });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Information Updated!',
        showing: true,
      },
    });
    yield put(storeLender(lender));
    yield put(stopLoader('lender'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('lender'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Could not update information. Please try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* fetchLenderSaga({ payload: { lenderId } }) {
  try {
    yield put(startLoader('lender'));
    const { lender } = yield call(LenderAPI.fetchLender, { lenderId });
    yield put(storeLender(lender));
    yield put(stopLoader('lender'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('lender'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Could not fetch Lender information. Please try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* addLenderUserSaga({ payload: { lenderId, data } }) {
  try {
    yield put(startLoader('users'));
    yield call(LenderAPI.addLenderUser, { lenderId, data });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'User added!',
        showing: true,
      },
    });
    const { users } = yield call(LenderAPI.fetchUsersForLender, { lenderId });
    yield put(storeLenderUsers(users));
    yield put(stopLoader('users'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('users'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Could not add user. Please try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* updateLenderUserSaga({ payload: { lenderId, logOutAfterUpdate, userId, data } }) {
  try {
    yield put(startLoader('users'));
    yield call(LenderAPI.updateLenderUser, { lenderId, userId, data });
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'User updated!',
        showing: true,
      },
    });

    if (logOutAfterUpdate) {
      yield put(logUserOut());
    } else {
      const { users } = yield call(LenderAPI.fetchUsersForLender, { lenderId });
      yield put(storeLenderUsers(users));
      yield put(stopLoader('users'));
    }
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('users'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Could not update user. Please try again later.',
          showing: true,
        },
      });
    }
  }
}

export function* fetchLenderUsersSaga({ payload: { lenderId } }) {
  try {
    yield put(startLoader('users'));
    const { users } = yield call(LenderAPI.fetchUsersForLender, { lenderId });
    yield put(storeLenderUsers(users));
    yield put(stopLoader('users'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      console.log(err);
      yield put(stopLoader('users'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Could not find users. Please try again later.',
          showing: true,
        },
      });
    }
  }
}

export const adminSagas = [
  takeLatest(fetchDocumentTemplates.type, fetchDocumentTemplatesSaga),
  takeLatest(saveDocumentTemplate.type, saveDocumentTemplateSaga),
  takeLatest(createDocumentTemplate.type, createDocumentTemplateSaga),
  takeLatest(archiveDocumentTemplate.type, archiveDocumentTemplateSaga),
  takeLatest(fetchStipulationTemplates.type, fetchStipulationTemplatesSaga),
  takeLatest(fetchApplicationShape.type, fetchApplicationShapeSaga),
  takeLatest(createStipulationTemplate.type, createStipulationTemplateSaga),
  takeLatest(updateStipulationTemplate.type, updateStipulationTemplateSaga),
  takeLatest(archiveStipulationTemplate.type, archiveStipulationTemplateSaga),
  takeLatest(addDealerUser.type, addDealerUserSaga),
  takeLatest(updateDealerUser.type, updateDealerUserSaga),
  takeLatest(fetchDealerUsers.type, fetchDealerUsersSaga),
  takeLatest(updateLender.type, updateLenderSaga),
  takeLatest(fetchLender.type, fetchLenderSaga),
  takeLatest(addLenderUser.type, addLenderUserSaga),
  takeLatest(updateLenderUser.type, updateLenderUserSaga),
  takeLatest(fetchLenderUsers.type, fetchLenderUsersSaga),
];

export default adminSagas;
