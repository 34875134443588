import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_LENDER_APPLICATIONS: ({ lenderId }) => `/lenders/${lenderId}/applications`,
  UPDATE_LENDER: ({ lenderId }) => `/lenders/${lenderId}`,
  FETCH_LENDER: ({ lenderId }) => `/lenders/${lenderId}`,
  ADD_LENDER_USER: ({ lenderId }) => `/lenders/${lenderId}/users`,
  UPDATE_LENDER_USER: ({ lenderId, userId }) => `/lenders/${lenderId}/users/${userId}`,
  FETCH_USERS_FOR_LENDER: ({ lenderId }) => `/lenders/${lenderId}/users`,
  FETCH_LOANS: () => `/servicing/loans`,
};

export default {
  fetchLenderApplications: ({ lenderId, ...params }) =>
    request({
      url: URIS.FETCH_LENDER_APPLICATIONS({ lenderId }),
      method: METHODS.GET,
      params,
    }),

  updateLender: ({ lenderId, data }) =>
    request({
      url: URIS.UPDATE_LENDER({ lenderId }),
      method: METHODS.PATCH,
      data,
    }),

  fetchLender: ({ lenderId }) =>
    request({
      url: URIS.FETCH_LENDER({ lenderId }),
      method: METHODS.GET,
    }),

  addLenderUser: ({ lenderId, data }) =>
    request({
      url: URIS.ADD_LENDER_USER({ lenderId }),
      method: METHODS.POST,
      data,
    }),

  updateLenderUser: ({ lenderId, userId, data }) =>
    request({
      url: URIS.UPDATE_LENDER_USER({ lenderId, userId }),
      method: METHODS.PATCH,
      data,
    }),

  fetchUsersForLender: ({ lenderId }) =>
    request({
      url: URIS.FETCH_USERS_FOR_LENDER({ lenderId }),
      method: METHODS.GET,
    }),

  fetchLoans: ({ queryString }) =>
    request({
      url: `${URIS.FETCH_LOANS()}${queryString || ''}`,
      method: METHODS.GET,
    }),
};
