import { TYPES } from 'components/Formie/inputs';
import { stringNotEmpty, validateCreditCard, validateCreditCardExpirationDate } from 'components/Formie/validators';

export const configuration = {
  first_name: {
    type: TYPES.text,
    label: 'First Name',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'first_name',
  },
  last_name: {
    type: TYPES.text,
    label: 'Last Name',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'last_name',
  },
  card_number: {
    type: TYPES.creditCard,
    label: 'Credit/Debit Card Number',
    mask: '9999 9999 9999 9999',
    required: true,
    validator: validateCreditCard,
    dataPath: 'card_number',
  },
  expiry_date: {
    type: TYPES.masked,
    label: 'Expiration Date',
    mask: '99/99',
    required: true,
    validator: validateCreditCardExpirationDate,
    dataPath: 'expiry_date',
    placeholder: 'MM/YY',
    errorMessage: 'Please provide a valid expiration date.',
  },
  cvv_code: {
    type: TYPES.text,
    label: 'Security Code',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'cvv_code',
  },
  card_zip: {
    type: TYPES.text,
    label: 'Billing Zip Code',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'card_zip',
  },
};

export const initialValues = {
  first_name: null,
  last_name: null,
  card_number: null,
  expiry_date: null,
  cvv_code: null,
  card_zip: null,
};
