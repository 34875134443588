import SearchIcon from '@mui/icons-material/SearchOutlined';
import { isValidVin } from '@shaggytools/nhtsa-api-wrapper';

import { FlexRow } from 'components/Layouts';
import { TYPES } from 'components/Formie/inputs';
import { STATES } from 'components/Formie/constants';
import {
  validateNotNull,
  stringNotEmpty,
  validatePositiveInteger,
  validateEmail,
  validatePhone,
  validatePastDate110,
  validateSSN,
  validateZip,
  validateCreditCard,
  validateCreditCardExpirationDate,
  validatePercentage,
  validateInteger,
  validatePositiveIntegerAllowNull,
} from 'components/Formie/validators';
import { TIMEZONES } from '../../AddLoan/formInfo';

export const configuration = {
  collateral: {
    vin: {
      type: TYPES.vinLookup,
      dataPath: 'collateral.vin',
      required: true,
      label: (
        <FlexRow inline padding="0px" margin="0px">
          <SearchIcon sx={{ margin: '0px 5px 0px 0px', padding: '0px' }} />
          VIN *
        </FlexRow>
      ),
      validator: isValidVin,
    },
    year: {
      type: TYPES.text,
      dataPath: 'collateral.year',
      required: true,
      label: 'Year',
      validator: stringNotEmpty,
    },
    make: {
      type: TYPES.text,
      dataPath: 'collateral.make',
      required: true,
      label: 'Make',
      validator: stringNotEmpty,
    },
    model: {
      type: TYPES.text,
      dataPath: 'collateral.model',
      required: true,
      label: 'Model',
      validator: stringNotEmpty,
    },
    condition: {
      type: TYPES.basicSelect,
      dataPath: 'collateral.condition',
      required: true,
      validator: validateNotNull,
      label: 'Condition',
      options: [
        { value: 'New', label: 'New' },
        { value: 'Used', label: 'Used' },
      ],
    },
    mileage: {
      type: TYPES.integer,
      dataPath: 'collateral.mileage',
      required: true,
      validator: validatePositiveInteger,
      label: 'Mileage',
    },
  },
};

export const customerConfiguration = {
  customer: {
    first_name: {
      type: TYPES.text,
      dataPath: 'customer.first_name',
      required: true,
      validator: stringNotEmpty,
      label: 'First Name',
    },
    last_name: {
      type: TYPES.text,
      dataPath: 'customer.last_name',
      required: true,
      validator: stringNotEmpty,
      label: 'Last Name',
    },
    dob: {
      type: TYPES.date,
      dataPath: 'customer.dob',
      required: true,
      validator: validatePastDate110,
      label: 'Birthday',
    },
    ssn: {
      type: TYPES.masked,
      dataPath: 'customer.ssn',
      required: true,
      validator: validateSSN,
      label: 'SSN',
      mask: '999-99-9999',
    },
    email: {
      type: TYPES.email,
      dataPath: 'customer.email',
      required: true,
      validator: validateEmail,
      label: 'Email',
    },
    phone: {
      type: TYPES.phone,
      dataPath: 'customer.phone',
      required: true,
      validator: validatePhone,
      label: 'Phone',
    },
  },

  address_residence: {
    address_1: {
      type: TYPES.text,
      dataPath: 'address_residence.address_1',
      required: true,
      validator: stringNotEmpty,
      label: 'Address 1',
    },
    address_2: {
      type: TYPES.text,
      dataPath: 'address_residence.address_2',
      required: false,
      label: 'Address 2',
    },
    city: {
      type: TYPES.text,
      dataPath: 'address_residence.city',
      required: true,
      validator: stringNotEmpty,
      label: 'City',
    },
    state: {
      type: TYPES.select,
      dataPath: 'address_residence.state',
      label: 'State',
      required: true,
      validator: validateNotNull,
      options: STATES,
    },
    zip: {
      type: TYPES.masked,
      dataPath: 'address_residence.zip',
      label: 'Zip Code',
      required: true,
      mask: '99999',
      validator: validateZip,
    },
  },

  address_mailing: {
    address_1: {
      type: TYPES.text,
      dataPath: 'address_mailing.address_1',
      required: true,
      validator: stringNotEmpty,
      label: 'Address 1',
    },
    address_2: {
      type: TYPES.text,
      dataPath: 'address_mailing.address_2',
      required: false,
      label: 'Address 2',
    },
    city: {
      type: TYPES.text,
      dataPath: 'address_mailing.city',
      required: true,
      validator: stringNotEmpty,
      label: 'City',
    },
    state: {
      type: TYPES.select,
      dataPath: 'address_mailing.state',
      label: 'State',
      required: true,
      validator: validateNotNull,
      options: STATES,
    },
    zip: {
      type: TYPES.masked,
      dataPath: 'address_mailing.zip',
      label: 'Zip Code',
      required: true,
      mask: '99999',
      validator: validateZip,
    },
  },

  address_helper: {
    use_primary_address: {
      type: TYPES.switch,
      dataPath: 'address_helper.use_primary_address',
      label: 'Use Primary Address for Mailing Address',
    },
  },
};

export const customerInitialValues = {
  customer: {
    first_name: null,
    last_name: null,
    dob: null,
    ssn: null,
    email: null,
    phone: null,
  },
  address_residence: {
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    zip: null,
    type: 'residence',
  },
  address_mailing: {
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    zip: null,
    type: 'mailing',
  },
  address_helper: {
    use_primary_address: true,
  },
};

export const paymentProfileConfiguration = (customers) => ({
  paymentProfile: {
    customer_id: {
      type: TYPES.basicSelect,
      dataPath: 'paymentProfile.customer_id',
      label: 'Customer',
      required: true,
      validator: validateNotNull,
      options: customers,
    },
    account_type: {
      type: TYPES.basicSelect,
      dataPath: 'paymentProfile.account_type',
      label: 'Account Type',
      required: true,
      validator: validateNotNull,
      options: [
        { value: 'checking', label: 'Checking' },
        { value: 'savings', label: 'Savings' },
      ],
    },
    account_first_name: {
      type: TYPES.text,
      dataPath: 'paymentProfile.account_first_name',
      required: true,
      validator: stringNotEmpty,
      label: 'First Name',
    },
    account_last_name: {
      type: TYPES.text,
      dataPath: 'paymentProfile.account_last_name',
      required: true,
      validator: stringNotEmpty,
      label: 'Last Name',
    },
    account_number: {
      type: TYPES.text,
      dataPath: 'paymentProfile.account_number',
      required: true,
      validator: stringNotEmpty,
      label: 'Account Number',
    },
    routing_number: {
      type: TYPES.text,
      dataPath: 'paymentProfile.routing_number',
      required: true,
      validator: stringNotEmpty,
      label: 'Routing Number',
    },
    institution_name: {
      type: TYPES.text,
      dataPath: 'paymentProfile.institution_name',
      required: true,
      validator: stringNotEmpty,
      label: 'Institution Name',
    },
    profile_name: {
      type: TYPES.text,
      dataPath: 'paymentProfile.profile_name',
      required: true,
      validator: stringNotEmpty,
      label: 'Profile Name',
    },
  },
});

export const paymentProfileInitialValues = {
  paymentProfile: {
    customer_id: null,
    account_type: null,
    account_first_name: null,
    account_last_name: null,
    account_number: null,
    routing_number: null,
    institution_name: null,
    profile_name: null,
  },
};

export const creditCardPaymentProfileConfiguration = (customers) => ({
  paymentProfile: {
    customer_id: {
      type: TYPES.basicSelect,
      dataPath: 'paymentProfile.customer_id',
      label: 'Customer',
      required: true,
      validator: validateNotNull,
      options: customers,
    },
    account_first_name: {
      type: TYPES.text,
      dataPath: 'paymentProfile.account_first_name',
      required: true,
      validator: stringNotEmpty,
      label: 'First Name',
    },
    account_last_name: {
      type: TYPES.text,
      dataPath: 'paymentProfile.account_last_name',
      required: true,
      validator: stringNotEmpty,
      label: 'Last Name',
    },
    profile_name: {
      type: TYPES.text,
      dataPath: 'paymentProfile.profile_name',
      required: true,
      validator: stringNotEmpty,
      label: 'Profile Name',
    },
    account_number: {
      type: TYPES.creditCard,
      label: 'Credit/Debit Card Number',
      mask: '9999 9999 9999 9999',
      required: true,
      validator: validateCreditCard,
      dataPath: 'paymentProfile.account_number',
    },
    expiry_date: {
      type: TYPES.masked,
      label: 'Expiration Date',
      mask: '99/99',
      required: true,
      validator: validateCreditCardExpirationDate,
      dataPath: 'paymentProfile.expiry_date',
      placeholder: 'MM/YY',
      errorMessage: 'Please provide a valid expiration date.',
    },
    cvv_code: {
      type: TYPES.text,
      label: 'Security Code',
      required: true,
      validator: stringNotEmpty,
      dataPath: 'paymentProfile.cvv_code',
    },
    card_zip: {
      type: TYPES.text,
      label: 'Billing Zip Code',
      required: true,
      validator: stringNotEmpty,
      dataPath: 'paymentProfile.card_zip',
    },
  },
});

export const creditCardPaymentProfileInitialValues = {
  paymentProfile: {
    customer_id: null,
    profile_name: null,
    account_number: null,
    account_first_name: null,
    account_last_name: null,
    cvv_code: null,
    expiry_date: null,
    card_zip: null,
  },
};

export const configureLoanConfiguration = {
  configuration: {
    principal_amount: {
      type: TYPES.currencyV2,
      dataPath: 'configuration.principal_amount',
      label: 'Principal Amount',
      required: true,
      validator: validatePositiveInteger,
    },
    interest_rate: {
      type: TYPES.percentageV2,
      dataPath: 'configuration.interest_rate',
      label: 'Interest Rate',
      required: true,
      validator: validatePercentage,
    },
    terms: {
      type: TYPES.integer,
      dataPath: 'configuration.terms',
      label: 'Terms',
      required: true,
      validator: validatePositiveInteger,
    },
    contract_date: {
      type: TYPES.date,
      dataPath: 'configuration.contract_date',
      label: 'Contract Date',
      required: true,
      validator: validatePastDate110,
    },
    first_payment_date: {
      type: TYPES.date,
      dataPath: 'configuration.first_payment_date',
      label: 'First Payment Date',
      required: true,
      validator: validatePastDate110,
    },
    schedule_round: {
      type: TYPES.currencyV2,
      dataPath: 'configuration.schedule_round',
      label: 'Schedule Round',
      required: true,
      validator: validateInteger,
    },
    payment_frequency: {
      type: TYPES.basicSelect,
      dataPath: 'configuration.payment_frequency',
      label: 'Payment Frequency',
      required: true,
      validator: validateNotNull,
      options: [{ value: 'monthly', label: 'Monthly' }],
    },
    interest_calculation_type: {
      type: TYPES.basicSelect,
      dataPath: 'configuration.interest_calculation_type',
      label: 'Interest Calculation Type',
      required: true,
      validator: validateNotNull,
      options: [{ value: '30/360', label: '30/360' }],
    },
    down_payment_amount: {
      type: TYPES.currencyV2,
      dataPath: 'configuration.down_payment_amount',
      label: 'Down Payment',
      validator: validatePositiveIntegerAllowNull,
    },
    payment_amount: {
      type: TYPES.currencyV2,
      dataPath: 'configuration.payment_amount',
      label: 'Forced Payment Amount',
      validator: validatePositiveIntegerAllowNull,
    },
  },
};

export const configureAdvancedSettings = (lateFeeType) => ({
  advancedSettings: {
    late_fee_type: {
      type: TYPES.basicSelect,
      dataPath: 'advancedSettings.late_fee_type',
      label: 'Late Fee Type',
      required: true,
      options: [
        { value: 'percent-of-payment', label: 'Percent of Late Payment' },
        { value: 'dollars', label: 'Flat Fee' },
      ],
      validator: validateNotNull,
    },
    late_fee_amount: {
      type: lateFeeType === 'percent-of-payment' ? TYPES.percentageV2 : TYPES.currencyV2,
      dataPath: 'advancedSettings.late_fee_amount',
      label: 'Late Fee Amount',
      required: true,
      validator: lateFeeType === 'percent-of-payment' ? validatePercentage : validatePositiveInteger,
    },
    grace_period: {
      type: TYPES.integer,
      dataPath: 'advancedSettings.grace_period',
      label: 'Grace Period',
      required: true,
      validator: validatePositiveInteger,
    },
    nsf_fee: {
      type: TYPES.currencyV2,
      dataPath: 'advancedSettings.nsf_fee',
      label: 'NSF Fee',
      required: true,
      validator: validatePositiveInteger,
    },
    type: {
      type: TYPES.basicSelect,
      dataPath: 'advancedSettings.type',
      label: 'Loan Type',
      disabled: true,
      required: true,
      options: [{ value: 'installment', label: 'Installment' }],
      validator: validateNotNull,
    },
    reporting_type: {
      type: TYPES.basicSelect,
      dataPath: 'advancedSettings.reporting_type',
      label: 'Reporting Type',
      disabled: true,
      required: true,
      options: [{ value: 'installment', label: 'Installment' }],
      validator: validateNotNull,
    },
    category: {
      type: TYPES.basicSelect,
      dataPath: 'advancedSettings.category',
      label: 'Reporting Category',
      required: true,
      disabled: true,
      options: [{ value: 'automobile', label: '00 - Automobile' }],
      validator: validateNotNull,
    },
    timezone: {
      type: TYPES.basicSelect,
      dataPath: 'advancedSettings.timezone',
      label: 'Timezone',
      required: true,
      options: TIMEZONES,
      validator: validateNotNull,
    },
  },
});
