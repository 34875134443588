import { Drawer, Typography, Button } from '@mui/material';
import styled from 'styled-components';

import { COLORS } from 'constants/styles';

export const DrawerContainer = styled.div`
  padding: 0px;
  margin: 0px;
  height: 100%;
  box-sizing: border-box;
  width: 600px;
  overflow: hidden;
`;

export const DrawerHeaderContainer = styled.div`
  padding: 10px 20px;
  margin: 0px;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.borderColor};
`;

export const DrawerContentContainer = styled.div`
  margin: 0px;
  padding: 10px 20px;
  height: calc(100% - 56.5px - 52px);
  box-sizing: border-box;
  overflow-y: auto;
  ${({ background }) => background && `background: ${COLORS.backgroundColor}`};
`;

export const DrawerActionsContainer = styled.div`
  margin: 0px;
  padding: 10px 20px;
  border-top: 1px solid ${COLORS.borderColor};
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DrawerComponent = ({ title, disabled, actionTitle, handleClose, open, handleSubmit, children }) => {
  return (
    <Drawer onClose={handleClose} open={open} anchor="right" ModalProps={{ disableEscapeKeyDown: true }}>
      {/* Used to set the width of the drawer.  */}
      <DrawerContainer>
        <DrawerHeaderContainer>
          <Typography align="center" variant="h5">
            {title}
          </Typography>
        </DrawerHeaderContainer>

        <DrawerContentContainer>{children}</DrawerContentContainer>

        <DrawerActionsContainer>
          <Button onClick={handleClose} sx={{ mr: '16px' }}>
            Cancel
          </Button>

          <Button onClick={handleSubmit} disabled={disabled} variant="contained">
            {actionTitle || 'Submit'}
          </Button>
        </DrawerActionsContainer>
      </DrawerContainer>
    </Drawer>
  );
};

export default DrawerComponent;
