import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import { fetchApplicationById } from 'store/sagas/applications';
import { updateApplicationPaymentProfileThunk } from 'store/thunks/application';
import { useFormie } from 'components/Formie';
import { paymentProfileFormConfig, initialValues } from './formInfo';

const PaymentProfileFormModal = ({ applicationId, profileType, fullForm, onSuccess = null, onError = null, onClose = null }) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const formie = useFormie({ configuration: paymentProfileFormConfig, initialValues });
  const { account_type, institution_name, account_name, account_number, routing_number } = formie.values;
  const {
    account_type: account_type_error,
    institution_name: institution_name_error,
    account_name: account_name_error,
    account_number: account_number_error,
    routing_number: routing_number_error,
  } = formie.errors;

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={true}>
      <DialogTitle>
        <Stack direction={'row'} spacing={1}>
          <CreditCardIcon sx={{ fontSize: '2rem' }} />
          <Typography variant="h6">Payment Profile</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        {hasErrors && <Alert severity="error">Failed to save payment profile.</Alert>}

        {fullForm ? (
          <>
            <DialogContentText>
              We're working on verifying your account. In the meantime, please provide us your account details once more so we can finish the signing process
              today.
            </DialogContentText>

            {formie.buildFields({
              fields: [{ dataPath: 'account_type' }],
            })}

            {formie.buildFields({
              fields: [{ dataPath: 'institution_name' }, { dataPath: 'account_name' }, { dataPath: 'account_number' }, { dataPath: 'routing_number' }],
              fieldsPerRow: 2,
            })}
          </>
        ) : (
          <>
            <DialogContentText>Please enter bank name.</DialogContentText>

            {formie.buildFields({
              fields: [{ dataPath: 'institution_name' }],
            })}
          </>
        )}
      </DialogContent>

      <DialogActions>
        {typeof onClose === 'function' && (
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
        )}

        <LoadingButton
          disabled={
            (fullForm &&
              (!account_type ||
                !institution_name ||
                !account_name ||
                !account_number ||
                !routing_number ||
                account_type_error !== false ||
                institution_name_error !== false ||
                account_name_error !== false ||
                account_number_error !== false ||
                routing_number_error !== false)) ||
            (!fullForm && (!institution_name || institution_name_error !== false))
          }
          loading={saving}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={async () => {
            setSaving(true);

            let data = { applicationId, profileType };

            if (fullForm && account_type && institution_name && account_name && account_number && routing_number) {
              data['institutionName'] = institution_name;
              data['accountType'] = account_type;
              data['accountName'] = account_name;
              data['accountNumber'] = account_number;
              data['routingNumber'] = routing_number;
            } else if (institution_name) {
              data['institutionName'] = institution_name;
            } else {
              setHasErrors(true);
              setSaving(false);
              return;
            }

            try {
              setHasErrors(false);

              await dispatch(updateApplicationPaymentProfileThunk({ ...data })).unwrap();
              await dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

              setSaving(false);

              if (typeof onSuccess === 'function') {
                onSuccess({ data });
              }
            } catch (error) {
              console.log('Saving payment profile form error: ', error);

              setHasErrors(true);
              setSaving(false);

              if (typeof onError === 'function') {
                onError({ error });
              }
            }
          }}
        >
          {saving ? 'Saving...' : 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentProfileFormModal;
