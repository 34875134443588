import { LinearProgress, Typography } from '@mui/material';

import { Card, FlexRow } from 'components/Layouts';
import { currencyFormatter, percentageFormatterV2, roundAccurately } from 'constants/formatters';

const PrincipalRepayment = ({ originalPrincipal, currentPrincipal, principalPaid }) => {
  const remainingPercentage = currentPrincipal && originalPrincipal ? percentageFormatterV2(roundAccurately(currentPrincipal / originalPrincipal, 4)) : '-';

  return (
    <Card fullHeight padding="10px 20px" variant="outlined">
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Principal Repayment</Typography>

        <Typography>{`${currencyFormatter.format(principalPaid)} paid`}</Typography>
      </FlexRow>

      <FlexRow padding="0px" margin="16px 0px 8px" alignItems="center">
        <Typography sx={{ mr: '5px' }}>{`Remaining Principal: ${currencyFormatter.format(currentPrincipal)}`}</Typography>
        <Typography variant="body2" color="text.secondary">{`(${remainingPercentage})`}</Typography>
      </FlexRow>

      <LinearProgress sx={{ height: '12px', borderRadius: '8px' }} variant="determinate" value={remainingPercentage * 100} />

      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="Center">
        <Typography color="text.secondary">{currencyFormatter.format(0)}</Typography>

        <Typography color="text.secondary">{currencyFormatter.format(originalPrincipal)}</Typography>
      </FlexRow>
    </Card>
  );
};

export default PrincipalRepayment;
