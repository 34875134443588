import { Typography, Box, CircularProgress, Divider } from '@mui/material';

import { Card, FlexRow } from 'components/Layouts';
import { formatDateOnly } from 'constants/formatters';
import ListItem from 'components/ListItem';

const ProgressMeter = ({ paymentsMade, totalPayments }) => {
  const value = (paymentsMade / totalPayments) * 100;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        size={150}
        value={100}
        sx={{
          color: (theme) => theme.palette.grey[300],
        }}
      />
      <CircularProgress
        variant="determinate"
        size={150}
        value={value}
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" component="div">
          {`${paymentsMade} of ${totalPayments}`}
        </Typography>
      </Box>
    </Box>
  );
};

const PaymentProgress = ({ paymentsMade, paymentsScheduled, totalPayments, maturityDate, nextPaymentDate }) => {
  const paymentsMissed = paymentsScheduled - paymentsMade;

  return (
    <Card fullHeight padding="10px 20px" variant="outlined">
      <Typography variant="h6">Payment Progress</Typography>

      <FlexRow padding="0px" margin="16px 0px 16px" alignItems="center" justifyContent="center" flexColumn>
        <ProgressMeter paymentsMade={paymentsMade} totalPayments={totalPayments} />
        <Typography mt="12px" variant="caption">{`${paymentsScheduled} payments scheduled (${paymentsMissed} missed)`}</Typography>
      </FlexRow>

      <Divider sx={{ mb: '8px' }} />

      <ListItem label="Next Due Date" value={nextPaymentDate && formatDateOnly(nextPaymentDate)} />
      <ListItem label="Maturity Date" value={maturityDate && formatDateOnly(maturityDate)} />
    </Card>
  );
};

export default PaymentProgress;
