import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Alert, AlertTitle, CircularProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { addDays } from 'date-fns';

import { setAlert } from 'store/sagas/app';
import { FlexRow } from 'components/Layouts';
import { fetchLoanPaymentPeriodsThunk } from 'store/thunks/paymentPeriod';
import { columns, determinePeriodStatus } from './helpers';
import { COLORS } from 'constants/styles';
import DetailModal from './DetailModal';
import { fetchLoanPaymentsThunk } from 'store/thunks/payment';

// TODO: Add Starting Principal, Accrued Interest, Ending Principal on DataGrid?
const PaymentPeriods = ({ timezone }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [paymentPeriods, setPaymentPeriods] = useState([]);
  const [payments, setPayments] = useState([]);
  const [periodDetailId, setPeriodDetailId] = useState(null);
  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { paymentPeriods } = await dispatch(
        fetchLoanPaymentPeriodsThunk({
          subdata: ['payments', 'dues'],
          loanId,
          limit: 1000,
        }),
      ).unwrap();
      setPaymentPeriods(paymentPeriods);

      const { payments } = await dispatch(
        fetchLoanPaymentsThunk({
          loanId,
          limit: 1000,
          status: 'processed',
        }),
      ).unwrap();
      setPayments(payments);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch payment periods. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const handleShowDetails = (id) => {
    setPeriodDetailId(id);
  };

  const handleCloseDetails = () => {
    setPeriodDetailId(null);
  };

  const sortedPaymentPeriods = paymentPeriods && paymentPeriods?.length ? paymentPeriods.sort((a, b) => a.period_number - b.period_number) : [];
  const rows = sortedPaymentPeriods.map((period) => {
    const status = determinePeriodStatus(period, false, timezone);

    return {
      id: period.id,
      period_number: period.period_number,
      due_date: period.due_date,
      amount_due: period.payment_amount,
      amount_paid: period.amount_paid,
      status: status,
    };
  });

  const periodForDetailModal = sortedPaymentPeriods.find((period) => period.id === periodDetailId);
  const endDateOfPeriod = periodForDetailModal?.due_date && new Date(periodForDetailModal.due_date);
  let startDateOfPeriod;
  if (periodForDetailModal?.period_number === 1) {
    startDateOfPeriod = periodForDetailModal?.from_date && new Date(periodForDetailModal.from_date);
  } else {
    startDateOfPeriod = periodForDetailModal?.from_date && addDays(new Date(periodForDetailModal.from_date), 1);
  }
  const paymentsEnteredDuringPaymentPeriod = payments
    ? payments.filter(
        (payment) => new Date(payment.payment_application_date) >= startDateOfPeriod && new Date(payment.payment_application_date) <= endDateOfPeriod,
      )
    : [];

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <>
      <Typography sx={{ mb: '8px' }} variant="h4">
        Payment Periods
      </Typography>

      {sortedPaymentPeriods && sortedPaymentPeriods.length && sortedPaymentPeriods.length > 0 ? (
        <div style={{ margin: '16px 0px 0px', height: 'calc(100vh - 215px)', background: COLORS.surfaceColor }}>
          <DataGrid
            onRowClick={(params) => handleShowDetails(params.id)}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            disableColumnMenu
            density="compact"
            columns={columns}
            rows={rows}
            sx={{
              '.MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </div>
      ) : (
        <Alert severity="error">
          <AlertTitle>No Payment Periods!</AlertTitle>
          This loan has no payment periods. Navigate to the setup terms page to configure and activate terms.
        </Alert>
      )}

      {/* Details Dialog */}
      <DetailModal
        loanId={loanId}
        paymentsEnteredDuringPaymentPeriod={paymentsEnteredDuringPaymentPeriod}
        period={periodForDetailModal}
        handleClose={handleCloseDetails}
        open={!!periodDetailId}
        timezone={timezone}
      />
    </>
  );
};

export default PaymentPeriods;
