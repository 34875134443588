import { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Menu, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

import { COLORS } from 'constants/styles';
import { LOAN_FREQUENCIES, LOAN_SORTS, LOAN_STATUS_MAP } from './helpers';
import BasicSelect from 'components/BasicSelect';

const SortAndFilterMenu = ({ status, active, frequency, sort, updateSearchParams }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [localStatus, setLocalStatus] = useState(status);
  const [localActive, setLocalActive] = useState(active);
  const [localFrequency, setLocalFrequency] = useState(frequency);
  const [localSort, setLocalSort] = useState(sort);

  useEffect(() => {
    setLocalStatus(status);
    setLocalActive(active);
    setLocalFrequency(frequency);
    setLocalSort(sort);
  }, [status, active, frequency, sort]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loanStatusArray = localStatus ? localStatus.split(',') : [];
  const handleLoanStatusChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    if (checked && !loanStatusArray.includes(name)) {
      setLocalStatus([...loanStatusArray, name].join(','));
    } else if (!checked) {
      setLocalStatus([...loanStatusArray].filter((status) => status !== name).join(','));
    }
  };

  const loanFrequencyArray = localFrequency ? localFrequency.split(',') : [];
  const handleLoanFrequencyChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    if (checked && !loanFrequencyArray.includes(name)) {
      setLocalFrequency([...loanFrequencyArray, name].join(','));
    } else if (!checked) {
      setLocalFrequency([...loanFrequencyArray].filter((frequency) => frequency !== name).join(','));
    }
  };

  const resetForm = () => {
    setLocalStatus(status);
    setLocalActive(active);
    setLocalFrequency(frequency);
    setLocalSort(sort);
  };

  const handleUpdateSearchParams = () => {
    updateSearchParams({
      status: localStatus,
      active: localActive,
      frequency: localFrequency,
      sort: localSort,
    });
    handleClose();
  };

  const isDifferent = localStatus != status || localActive != active || localFrequency != frequency || localSort != sort;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
      <Button
        startIcon={<TuneIcon />}
        onClick={handleClick}
        aria-controls={open ? 'long-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        Sort & Filter
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'hidden',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            minWidth: '300px',
            borderRadius: '8px',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* Header Row */}
        <div
          style={{
            boxSizing: 'border-box',
            padding: '8px 10px',
            margin: '-10px 0px 0px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottom: `1px solid ${COLORS.borderColor}`,
          }}
        >
          <Typography colr="text.secondary" variant="h6">
            Sort and Filter
          </Typography>
        </div>

        {/* Content Row */}
        <div
          style={{
            boxSizing: 'border-box',
            padding: '0px 10px',
            marginTop: '10px',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
          }}
        >
          <Typography sx={{ fontWeight: 700 }}>Sort By</Typography>
          <BasicSelect
            width="100%"
            value={localSort}
            options={[
              { value: LOAN_SORTS.CREATED_AT_ASC, label: 'Created At: Newest to Oldest' },
              { value: LOAN_SORTS.CREATED_AT_DESC, label: 'Created At: Oldest to Newest' },
              { value: LOAN_SORTS.UPDATED_AT_ASC, label: 'Updated At: Newest to Oldest' },
              { value: LOAN_SORTS.UPDATED_AT_DESC, label: 'Updated At: Oldest to Newest' },
            ]}
            handleChange={(e) => setLocalSort(e.target.value)}
          />

          <Typography sx={{ fontWeight: 700, mt: '16px' }}>Active Status</Typography>
          <ToggleButtonGroup size="small" sx={{ width: '100%' }} color="primary" value={localActive} exclusive onChange={(e) => setLocalActive(e.target.value)}>
            <ToggleButton sx={{ width: '100%' }} value="true">
              Active
            </ToggleButton>
            <ToggleButton sx={{ width: '100%' }} value="false">
              Inactive
            </ToggleButton>
          </ToggleButtonGroup>

          <Typography sx={{ fontWeight: 700, mt: '16px' }}>Loan Status</Typography>
          <FormGroup>
            {LOAN_STATUS_MAP.map((opt) => (
              <FormControlLabel
                key={opt.name}
                label={opt.label}
                control={<Checkbox size="small" onChange={handleLoanStatusChange} name={opt.name} checked={loanStatusArray.includes(opt.name)} />}
              />
            ))}
          </FormGroup>

          <Typography sx={{ fontWeight: 700, mt: '16px' }}>Payment Frequency</Typography>
          <FormGroup>
            {[
              { name: LOAN_FREQUENCIES.MONTHLY, label: 'Monthly' },
              { name: LOAN_FREQUENCIES.SEMI_MONTHLY, label: 'Semi-Monthly' },
              { name: LOAN_FREQUENCIES.BI_WEEKLY, label: 'Bi-Weekly' },
              { name: LOAN_FREQUENCIES.WEEKLY, label: 'Weekly' },
            ].map((opt) => (
              <FormControlLabel
                key={opt.name}
                label={opt.label}
                control={<Checkbox size="small" onChange={handleLoanFrequencyChange} name={opt.name} checked={loanFrequencyArray.includes(opt.name)} />}
              />
            ))}
          </FormGroup>
        </div>

        {/* Action Row */}
        {!!isDifferent && (
          <div style={{ boxSizing: 'border-box', padding: '10px 10px 0px', marginTop: '10px', width: '100%', borderTop: `1px solid ${COLORS.borderColor}` }}>
            <Button onClick={resetForm} sx={{ width: '50%' }}>
              Reset
            </Button>
            <Button onClick={handleUpdateSearchParams} sx={{ width: '50%' }} variant="contained">
              Apply
            </Button>
          </div>
        )}
      </Menu>
    </Box>
  );
};

export default SortAndFilterMenu;
