import { Divider, Typography } from '@mui/material';

import { Card, FlexRow } from 'components/Layouts';
import { currencyFormatter } from 'constants/formatters';
import ListItem from 'components/ListItem';

const Profitability = ({ interestPaid, feesPaid, chargedOff, profit }) => {
  return (
    <Card variant="outlined" padding="10px 20px">
      <Typography variant="h6" sx={{ mb: '8px' }}>
        Profit
      </Typography>
      <ListItem labelPrepend="+" label="Interest Paid" value={interestPaid ? currencyFormatter.format(interestPaid) : '-'} />
      <ListItem labelPrepend="+" label="Fees Paid" value={feesPaid ? currencyFormatter.format(feesPaid) : '-'} />
      <ListItem labelPrepend="-" label="Charged Off" value={chargedOff ? currencyFormatter.format(chargedOff) : '-'} />

      <Divider sx={{ m: '32px 0px 8px' }} />

      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: 500 }}>Profit</Typography>
        <Typography sx={{ fontWeight: 500 }}>{profit ? currencyFormatter.format(profit) : '-'}</Typography>
      </FlexRow>
    </Card>
  );
};

export default Profitability;
