import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { Card, FlexRow } from 'components/Layouts';
import Reference from './Reference';

const References = ({ currentApplication }) => {
  const { no_of_ref, no_of_verified_ref } = currentApplication?.lending_rules ?? {};
  const references = currentApplication?.references ?? [];
  const verifiedReferences = references.filter((r) => r.phone_verified && r.email_verified);

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="References">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ marginBottom: '1rem' }} variant="h5">
          References
        </Typography>
      </FlexRow>

      <Grid sx={{ marginBottom: '0.4rem' }} className={'references-requirements'} container spacing={2}>
        <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
          <Typography variant="subtitle2" gutterBottom>
            Required References: <span style={{ color: references.length >= no_of_ref ? 'green' : 'red' }}>{references.length}</span>/
            {!no_of_ref ? 0 : no_of_ref}
          </Typography>
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
          <Typography variant="subtitle2" gutterBottom>
            Required Verified References:{' '}
            <span style={{ color: verifiedReferences.length >= no_of_verified_ref ? 'green' : 'red' }}>{verifiedReferences.length}</span>/
            {!no_of_verified_ref ? 0 : no_of_verified_ref}
          </Typography>
        </Grid>
      </Grid>

      <Grid className={'references-list'} container spacing={2}>
        {references &&
          references.length > 0 &&
          references
            /**
             * Reference:
             * https://stackoverflow.com/a/9592774
             *
             * We need to slice the array of payment profiles first
             * so we can sort it without mutating the original array.
             */
            .slice()
            .sort((r1, r2) => Math.round(new Date(r1.created_at).getTime() / 1000) - Math.round(new Date(r2.created_at).getTime() / 1000))
            .map((r) => <Reference key={r.id} reference={r} />)}
      </Grid>
    </Card>
  );
};

export default References;
