import { useSearchParams } from 'react-router-dom';

import { SectionContainer, SectionContentContainer, ViewLoanPageLayout } from '../styled-components';
import CollateralInformation from './CollateralInformation';
import Title from './Title';

const Collateral = ({ rehydrateTopLevel }) => {
  const [searchParams] = useSearchParams();

  const SECTIONS = {
    INFO: 'info',
    INSURANCE: 'insurance',
    TITLE: 'title',
  };

  const sectionObjs = [
    { label: 'Info', value: SECTIONS.INFO, component: <CollateralInformation rehydrateTopLevel={rehydrateTopLevel} /> },
    { label: 'Insurance', value: SECTIONS.INSURANCE },
    { label: 'Title', value: SECTIONS.TITLE, component: <Title /> },
  ];

  const defaultSection = SECTIONS.INFO;
  const activeSection = searchParams.get('section') || defaultSection;
  const activeComponent = sectionObjs.find((section) => section.value === activeSection)?.component;

  return (
    <ViewLoanPageLayout defaultItem={defaultSection} menuItems={sectionObjs}>
      <SectionContainer>
        <SectionContentContainer fullWidth={sectionObjs.find((section) => section.value === activeSection)?.fullWidth}>
          {activeComponent ? activeComponent : <div>Error</div>}
        </SectionContentContainer>
      </SectionContainer>
    </ViewLoanPageLayout>
  );
};

export default Collateral;
