import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_DOCUMENT_DOWNLOAD_LINK: ({ signNowId }) => `/documents/${signNowId}/download`,
  DOWNLOAD_DOCUMENT_FROM_S3: ({ documentId }) => `/documents/${documentId}/s3-download`,
};

export default {
  getDocumentDownloadLink: ({ signNowId }) =>
    request({
      url: URIS.FETCH_DOCUMENT_DOWNLOAD_LINK({ signNowId }),
      method: METHODS.GET,
    }),

  downloadDocumentFromS3: ({ documentId }) =>
    request({
      url: URIS.DOWNLOAD_DOCUMENT_FROM_S3({ documentId }),
      method: METHODS.GET,
    }),
};
