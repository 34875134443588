import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { StyledNavBar, StyledBackButton, StyledBreadcrumbContainer } from './styled-components';
import { COLORS } from '../../constants/styles';
import NavAction from './navAction';

const NavBar = ({ backButton, breadcrumbs, actions }) => {
  const navigate = useNavigate();

  const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

  return (
    <StyledNavBar>
      <StyledNavBar noBorder>
        {!!backButton && (
          <StyledBackButton onClick={() => navigate(backButton)}>
            <ArrowBackIcon />
          </StyledBackButton>
        )}

        {!!breadcrumbs && (
          <StyledBreadcrumbContainer>
            <Breadcrumbs separator="›">
              {breadcrumbs.map((breadcrumb, i) => {
                const last = i === breadcrumbs.length - 1;
                const { link } = breadcrumb;

                return last ? (
                  <Typography variant="body1" key={breadcrumb.text} style={{ color: COLORS.primary }}>
                    {breadcrumb.text}
                  </Typography>
                ) : link ? (
                  <LinkRouter underline="hover" color="inherit" to={link} key={link}>
                    {breadcrumb.text}
                  </LinkRouter>
                ) : (
                  <Typography variant="body1" key={breadcrumb.text} style={{ color: 'inherit' }}>
                    {breadcrumb.text}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          </StyledBreadcrumbContainer>
        )}
      </StyledNavBar>

      {!!actions && !!actions.length && <NavAction actions={actions} />}
    </StyledNavBar>
  );
};

export default NavBar;
