import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { Card, FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import GooglePlaces from 'components/GooglePlaces';

const Applicant = ({ isCoApplicant, showCoApplicant, setShowCoApplicant, ssnChecked, activeSection, section, progress, continueFn, formie }) => {
  const prefix = isCoApplicant ? 'co_applicant' : 'applicant';
  const { values } = formie;

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography style={{ margin: '0px' }} variant="h5">
          {isCoApplicant ? 'Co-Applicant' : 'Applicant'}
        </Typography>
        {isCoApplicant && (
          <FormControlLabel style={{ margin: '0px' }} control={<Switch checked={showCoApplicant} onChange={(e) => setShowCoApplicant(e.target.checked)} />} />
        )}
      </FlexRow>

      {(showCoApplicant || !isCoApplicant) && (
        <Fragment>
          {formie.buildFields({
            fields: [{ dataPath: `${prefix}.ssn` }],
          })}
          {!ssnChecked && <Button variant="text" style={{ width: 0, height: 0, padding: 0, margin: 0, lineHeight: 0, fontSize: 0, fontWeight: 0 }} />}

          {ssnChecked && (
            <Fragment>
              <Typography variant="overline" display="block" style={{ marginTop: '20px', color: COLORS.primary }}>
                Personal Details
              </Typography>
              {formie.buildFields({
                fields: [
                  { dataPath: `${prefix}.first_name` },
                  { dataPath: `${prefix}.last_name` },
                  { dataPath: `${prefix}.phone` },
                  { dataPath: `${prefix}.dob` },
                ],
                fieldsPerRow: 2,
              })}
              {formie.buildFields({
                fields: [{ dataPath: `${prefix}.email` }],
              })}

              <Typography variant="overline" display="block" style={{ marginTop: '20px', color: COLORS.primary }}>
                Address
              </Typography>
              <GooglePlaces addressExtractFn={(data) => formie.handleAddressValues(data, prefix)} />
              {formie.buildFields({
                fields: [
                  { dataPath: `${prefix}.address_1` },
                  { dataPath: `${prefix}.address_2` },
                  { dataPath: `${prefix}.city` },
                  { dataPath: `${prefix}.state` },
                  { dataPath: `${prefix}.zip` },
                  { dataPath: `${prefix}.address_months_at` },
                  { dataPath: `${prefix}.home_ownership` },
                  {
                    dataPath: `${prefix}.home_monthly_payment`,
                    extraProps: {
                      label:
                        values[prefix]?.home_ownership === 'Own'
                          ? 'Monthly Mortgage'
                          : values[prefix]?.home_ownership === 'Rent'
                          ? 'Monthly Rent'
                          : 'Home Monthly Payment',
                    },
                  },
                ],
                fieldsPerRow: 2,
              })}

              <Typography variant="overline" display="block" style={{ marginTop: '20px', color: COLORS.primary }}>
                Employment
              </Typography>

              {formie.buildFields({
                fields: [{ dataPath: `${prefix}.employment_status` }, { dataPath: `${prefix}.annual_income` }],
                fieldsPerRow: 2,
              })}

              {formie.buildFields({
                fields: [
                  {
                    dataPath: `${prefix}.employer`,
                    extraProps: { label: ['Unemployed', 'Retired'].includes(values[prefix]?.employment_status) ? 'Income Source' : 'Employer Name' },
                  },
                  {
                    dataPath: `${prefix}.employer_months_at`,
                    extraProps: {
                      label:
                        values[prefix]?.employment_status === 'Unemployed'
                          ? '# Months Unemployed'
                          : values[prefix]?.employment_status === 'Retired'
                          ? '# Months Retired'
                          : '# Months Employed Here',
                    },
                  },
                ],
                fieldsPerRow: 2,
              })}

              {['Employed', 'Self Employed'].includes(values[prefix]?.employment_status) &&
                formie.buildFields({
                  fields: [{ dataPath: `${prefix}.employer_phone` }, { dataPath: `${prefix}.title` }],
                  fieldsPerRow: 2,
                })}
            </Fragment>
          )}
        </Fragment>
      )}
      {activeSection === section && (
        <FlexRow padding="0px" margin={!ssnChecked ? '8px 0px 0px' : '20px 0px 0px'} alignItems="center" justifyContent="flex-end">
          <Button onClick={continueFn} disabled={progress < 1} variant="contained">
            Continue
          </Button>
        </FlexRow>
      )}
    </Card>
  );
};

export default Applicant;
