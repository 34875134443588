import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApplicationAPI } from 'store/api';

// #region [Payment Profiles] ==================================================================================================================================

export const generateApplicationLinkTokenThunk = createAsyncThunk(
  'application/generateApplicationLinkToken',
  async ({ applicantId, applicationId, profileType = null }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.generateApplicationLinkToken({ applicantId, applicationId, profileType });

      /**
       * This will dispatch action creator with type of "application/generateApplicationLinkToken/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/generateApplicationLinkToken/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const generateApplicationAccessTokenThunk = createAsyncThunk(
  'application/generateApplicationAccessToken',
  async (
    { applicationId, applicantId, accountOwner, accountName, publicToken, accountId, institutionId = null, institutionName = null, verificationStatus },
    thunkAPI,
  ) => {
    try {
      const response = await ApplicationAPI.generateApplicationAccessToken({
        applicationId,
        applicantId,
        accountOwner,
        accountName,
        publicToken,
        accountId,
        institutionId,
        institutionName,
        verificationStatus,
      });

      /**
       * This will dispatch action creator with type of "application/generateApplicationAccessToken/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/generateApplicationAccessToken/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateApplicationAccessTokenThunk = createAsyncThunk(
  'application/updateApplicationAccessToken',
  async ({ applicationId, profileType, verificationStatus }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.updateApplicationAccessToken({ applicationId, profileType, verificationStatus });

      /**
       * This will dispatch action creator with type of "application/updateApplicationAccessToken/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/updateApplicationAccessToken/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const connectApplicationPaymentProfileThunk = createAsyncThunk(
  'application/connectApplicationPaymentProfile',
  async ({ applicationId, profileType }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.connectApplicationPaymentProfile({ applicationId, profileType });

      /**
       * This will dispatch action creator with type of "application/connectApplicationPaymentProfile/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/connectApplicationPaymentProfile/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const connectExistingApplicationPaymentProfileThunk = createAsyncThunk(
  'application/connectExistingApplicationPaymentProfile',
  async ({ applicationId, accountOwner, accountName, itemId }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.connectExistingApplicationPaymentProfile({ applicationId, accountOwner, accountName, itemId });

      /**
       * This will dispatch action creator with type of "application/connectExistingApplicationPaymentProfile/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/connectExistingApplicationPaymentProfile/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateApplicationPaymentProfileThunk = createAsyncThunk(
  'application/updateApplicationPaymentProfile',
  async ({ applicationId, profileType, institutionName, accountType = null, accountName = null, accountNumber = null, routingNumber = null }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.updateApplicationPaymentProfile({
        applicationId,
        profileType,
        institutionName,
        accountType,
        accountName,
        accountNumber,
        routingNumber,
      });

      /**
       * This will dispatch action creator with type of "application/updateApplicationPaymentProfile/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/updateApplicationPaymentProfile/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const setApplicationPrimaryPaymentProfileThunk = createAsyncThunk(
  'application/setApplicationPrimaryPaymentProfile',
  async ({ applicationId, profileType }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.setApplicationPrimaryPaymentProfile({ applicationId, profileType });

      /**
       * This will dispatch action creator with type of "application/setApplicationPrimaryPaymentProfile/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/setApplicationPrimaryPaymentProfile/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteApplicationPaymentProfileThunk = createAsyncThunk(
  'application/deleteApplicationPaymentProfile',
  async ({ applicationId, profileType }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.deleteApplicationPaymentProfile({ applicationId, profileType });

      /**
       * This will dispatch action creator with type of "application/deleteApplicationPaymentProfile/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/deleteApplicationPaymentProfile/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getApplicationPaymentProfilesThunk = createAsyncThunk(
  'application/getApplicationPaymentProfiles',
  async ({ applicantId, coApplicantId, applicationId }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.getApplicationPaymentProfiles({ applicantId, coApplicantId, applicationId });

      /**
       * This will dispatch action creator with type of "application/getApplicationPaymentProfiles/fulfilled" and payload of response
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/getApplicationPaymentProfiles/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createApplicationCreditCardPaymentProfileThunk = createAsyncThunk(
  'application/createApplicationCreditCardPaymentProfile',
  async ({ applicationId, data }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.createApplicationCreditCardPaymentProfile({ applicationId, data });

      /**
       * This will dispatch action creator with type of "application/createApplicationCreditCardPaymentProfile/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/createApplicationCreditCardPaymentProfile/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// #endregion

// #region [References] ========================================================================================================================================

export const createApplicationReferenceThunk = createAsyncThunk('application/createApplicationReference', async ({ applicationId, data }, thunkAPI) => {
  try {
    const response = await ApplicationAPI.createApplicationReference({ applicationId, data });

    /**
     * This will dispatch action creator with type of "application/createApplicationReference/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return response;
  } catch (err) {
    /**
     * This will dispatch action creator with type of "application/createApplicationReference/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateApplicationReferenceThunk = createAsyncThunk(
  'application/updateApplicationReference',
  async ({ applicationId, referenceId, data }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.updateApplicationReference({ applicationId, referenceId, data });

      /**
       * This will dispatch action creator with type of "application/updateApplicationReference/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/updateApplicationReference/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteApplicationReferenceThunk = createAsyncThunk('application/deleteApplicationReference', async ({ applicationId, referenceId }, thunkAPI) => {
  try {
    const response = await ApplicationAPI.deleteApplicationReference({ applicationId, referenceId });

    /**
     * This will dispatch action creator with type of "application/deleteApplicationReference/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return response;
  } catch (err) {
    /**
     * This will dispatch action creator with type of "application/deleteApplicationReference/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const applicationReferenceSendOTPThunk = createAsyncThunk(
  'application/applicationReferenceSendOTP',
  async ({ applicationId, referenceId, data }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.applicationReferenceSendOTP({ applicationId, referenceId, data });

      /**
       * This will dispatch action creator with type of "application/applicationReferenceSendOTP/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/applicationReferenceSendOTP/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const applicationReferenceCheckOTPThunk = createAsyncThunk(
  'application/applicationReferenceCheckOTP',
  async ({ applicationId, referenceId, data }, thunkAPI) => {
    try {
      const response = await ApplicationAPI.applicationReferenceCheckOTP({ applicationId, referenceId, data });

      /**
       * This will dispatch action creator with type of "application/applicationReferenceCheckOTP/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return response;
    } catch (err) {
      /**
       * This will dispatch action creator with type of "application/applicationReferenceCheckOTP/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// #endregion
