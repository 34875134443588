import { TYPES } from 'components/Formie/inputs';
import { validatePositiveInteger } from 'components/Formie/validators';

export const prepareLendingOptionConfiguation = {
  max_amount_loaned: {
    type: TYPES.currency,
    label: 'Max $ Financed',
    dataPath: 'max_amount_loaned',
    required: true,
    validator: validatePositiveInteger,
  },
  min_percent_down: {
    type: TYPES.percentage,
    label: 'Down Payment %',
    dataPath: 'min_percent_down',
    required: true,
    validator: validatePositiveInteger,
  },
  term: {
    type: TYPES.integer,
    label: 'Term',
    dataPath: 'term',
    required: true,
    validator: validatePositiveInteger,
  },
  apr: {
    type: TYPES.percentage,
    label: 'APR',
    dataPath: 'apr',
    required: true,
    validator: validatePositiveInteger,
  },
};
