import { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CategoryIcon from '@mui/icons-material/Category';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ErrorIcon from '@mui/icons-material/Error';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ApprovalIcon from '@mui/icons-material/Approval';

import { Card, FlexRow, BasicMenu, ListItem } from 'components/Layouts';
import { fetchStipulationTemplates, fetchDocumentTemplates, fetchApplicationShape, archiveStipulationTemplate } from 'store/sagas/admin';
import StipulationTemplateModal, { blankTrigger } from './StipulationTemplateModal';

const StipulationTemplates = () => {
  const dispatch = useDispatch();

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const active_profile = useSelector((state) => state.user?.active_profile);

  const stipulation_templates = useSelector((state) => state.admin?.stipulation_templates) || [];
  const document_templates = useSelector((state) => state.admin?.document_templates) || [];
  const applicationShape = useSelector((state) => state.admin?.application_shape);

  const loading = useSelector((state) => state.admin?.loading?.stipulation_templates);
  const docTemplateLoading = useSelector((state) => state.admin?.loading?.document_templates);
  const applicationShapeLoading = useSelector((state) => state.admin?.loading?.application_shape);

  const blankStipulationTemplate = {
    description: '',
    document_template: null,
    document_template_id: null,
    fulfill_if_document_is_signed: false,
    lender_id: active_profile?.lender_id,
    name: '',
    should_auto_fulfill: false,
    show_for_all_applications: false,
    stipulation_template_triggers: [blankTrigger],
  };

  useEffect(() => {
    dispatch(fetchStipulationTemplates({ lenderId: active_profile?.lender_id }));
    dispatch(fetchDocumentTemplates());
    dispatch(fetchApplicationShape());
  }, []);

  return (
    <Card margin="0" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0px 0px 20px" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Stipulation Templates</Typography>
        <Button onClick={() => setSelectedTemplate(blankStipulationTemplate)} startIcon={<AddIcon />}>
          Add
        </Button>
      </FlexRow>
      {loading || docTemplateLoading || applicationShapeLoading ? (
        <FlexRow padding="20px" margin="0px" justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexRow>
      ) : (
        <Fragment>
          <Divider />
          {stipulation_templates.map((template) => {
            return (
              <ListItem
                key={template.id}
                primaryText={template.name}
                secondaryText={template.description}
                icon={<CategoryIcon color="primary" sx={{ fontSize: '40px' }} />}
                stackArray={[
                  {
                    display: template.fulfill_if_document_is_signed,
                    label: 'Fulfill if Signed',
                    icon: <HistoryEduIcon />,
                    color: 'default',
                    tip: 'This stipulation will generate a signing document and will be fulfilled once that document is signed in the system.',
                  },
                  {
                    display: template.show_for_all_applications,
                    label: 'Always Triggered',
                    icon: <ErrorIcon />,
                    color: 'default',
                    tip: 'This stipulation will be triggered for every application.',
                  },
                  {
                    display: template.should_auto_fulfill,
                    label: 'Auto-fulfill',
                    icon: <SmartToyIcon />,
                    color: 'default',
                    tip: 'The system will try to auto-fulfill this stipulation by pulling values out of the uploaded documents.',
                  },
                  {
                    display: !template.show_for_all_applications,
                    label: 'Triggered by Rules',
                    icon: <ApprovalIcon />,
                    color: 'default',
                    tip: 'This stipulation is triggered by these rules.',
                  },
                ]}
                action={
                  <BasicMenu
                    menuItems={[
                      { onClick: () => setSelectedTemplate(template), icon: <EditIcon />, label: 'Edit' },
                      {
                        onClick: () => dispatch(archiveStipulationTemplate({ templateId: template.id, lenderId: active_profile?.lender_id })),
                        icon: <DeleteIcon />,
                        label: 'Delete',
                      },
                    ]}
                  />
                }
              />
            );
          })}
        </Fragment>
      )}

      {!!selectedTemplate && (
        <StipulationTemplateModal
          open={!!selectedTemplate}
          stipulationTemplate={selectedTemplate}
          onClose={() => setSelectedTemplate(null)}
          documentTemplates={document_templates}
          applicationShape={applicationShape}
        />
      )}
    </Card>
  );
};

export default StipulationTemplates;
