import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_ALL_DOCUMENT_TEMPLATES: () => '/document-templates',
  UPDATE_DOCUMENT_TEMPLATE: ({ templateId }) => `/document-templates/${templateId}`,
  CREATE_DOCUMENT_TEMPLATE: () => '/document-templates',
  ARCHIVE_DOCUMENT_TEMPLATE: ({ templateId }) => `/document-templates/${templateId}`,
};

export default {
  getAllDocumentTemplates: () =>
    request({
      url: URIS.FETCH_ALL_DOCUMENT_TEMPLATES(),
      method: METHODS.GET,
    }),

  updateDocumentTemplate: ({ templateId, data }) =>
    request({
      url: URIS.UPDATE_DOCUMENT_TEMPLATE({ templateId }),
      method: METHODS.PUT,
      data,
    }),

  createDocumentTemplate: ({ data }) =>
    request({
      url: URIS.CREATE_DOCUMENT_TEMPLATE(),
      method: METHODS.POST,
      data,
    }),

  archiveDocumentTemplate: ({ templateId }) =>
    request({
      url: URIS.ARCHIVE_DOCUMENT_TEMPLATE({ templateId }),
      method: METHODS.DELETE,
    }),
};
