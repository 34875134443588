import { takeLatest, call, put } from 'redux-saga/effects';

import { fetchHydrationData, startAppLoading, stopAppLoading, manuallyCallAlert } from 'store/sagas/app';
import { storeUserInfo } from 'store/sagas/user';
import { UserAPI } from 'store/api';

import { setAlert } from './index';

export function* setAlertSaga({ payload }) {
  try {
    yield put(setAlert(payload));
  } catch (err) {
    err.tip = 'Error at setAlertSaga';
    console.log(err);
  }
}

export function* fetchHydrationDataSaga() {
  try {
    yield put(startAppLoading());
    const { user } = yield call(UserAPI.fetchUserInfo);

    const active_profile = user.profiles?.find((profile) => profile.is_active_profile);
    yield put(storeUserInfo({ ...user, active_profile }));
    yield put(stopAppLoading());
  } catch (err) {
    yield put(stopAppLoading());
    err.tip = 'Error at fetchHydrationDataSaga';
    console.log(err);
  }
}

export const appSagas = [takeLatest(fetchHydrationData.type, fetchHydrationDataSaga), takeLatest(manuallyCallAlert.type, setAlertSaga)];

export default appSagas;
