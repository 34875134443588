import { formatCurrencyForDataGrid, formatDateOnly, percentageFormatterV2 } from 'constants/formatters';

export const LOAN_STATUSES = {
  DRAFT: 'draft',
  OPEN: 'open',
  CLOSED: 'closed',
  PAID_OFF: 'paid-off',
  CHARGED_OFF: 'charged-off',
};

export const LOAN_STATUS_MAP = [
  { name: LOAN_STATUSES.OPEN, label: 'Open' },
  { name: LOAN_STATUSES.CLOSED, label: 'Closed' },
  { name: LOAN_STATUSES.PAID_OFF, label: 'Paid Off' },
  { name: LOAN_STATUSES.CHARGED_OFF, label: 'Charged Off' },
  { name: LOAN_STATUSES.DRAFT, label: 'Draft' },
];

export const LOAN_FREQUENCIES = {
  MONTHLY: 'monthly',
  SEMI_MONTHLY: 'semi-monthly',
  BI_WEEKLY: 'bi-weekly',
  WEEKLY: 'weekly',
};

export const LOAN_SORTS = {
  CREATED_AT_ASC: 'created_at|ASC',
  CREATED_AT_DESC: 'created_at|DESC',
  UPDATED_AT_ASC: 'updated_at|ASC',
  UPDATED_AT_DESC: 'updated_at|DESC',
};

export const parsePaymentFrequency = (frequency) => {
  if (!frequency) return null;

  const map = [
    { value: 'monthly', label: 'Monthly' },
    { value: 'semi-monthly', label: 'Semi-Monthly' },
    { value: 'bi-weekly', label: 'Bi-Weekly' },
    { value: 'weekly', label: 'Weekly' },
  ];

  return map.find((obj) => obj.value === frequency)?.label;
};

export const columns = [
  {
    field: 'name',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Name',
    flex: 1,
    minWidth: 170,
  },
  {
    field: 'pastDue',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Past Due',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 100,
    cellClassName: (params) => (params.value > 0 ? 'cell-has-past-due' : 'cell-has-no-past-due'),
  },
  {
    field: 'unpaidFees',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Unpaid Fees',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 110,
    cellClassName: (params) => (params.value > 0 ? 'cell-has-past-due' : 'cell-has-no-past-due'),
  },
  {
    field: 'interestPaid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Interest Paid',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 110,
  },
  {
    field: 'feesPaid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Fees Paid',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 90,
  },
  {
    field: 'roi',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'ROI',
    flex: 1,
    valueFormatter: (params) => percentageFormatterV2(params.value),
    minWidth: 70,
  },
  {
    field: 'principalPaid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Principal Paid',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 110,
  },
  {
    field: 'totalPaid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Total Paid',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 90,
  },
  {
    field: 'percentReturned',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: '% Returned',
    flex: 1,
    valueFormatter: (params) => percentageFormatterV2(params.value),
    minWidth: 100,
  },
  {
    field: 'originalPrincipal',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Original Principal',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 130,
  },
  {
    field: 'currentPrincipal',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Current Principal',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 130,
  },
  {
    field: 'payoff',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Payoff',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 100,
  },
  {
    field: 'terms',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Terms',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'interestRate',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Interest Rate',
    valueFormatter: (params) => percentageFormatterV2(params.value),
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'payment',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Payment',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
    minWidth: 90,
  },
  {
    field: 'paymentFrequency',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Frequency',
    flex: 1,
    minWidth: 100,
    valueFormatter: (params) => parsePaymentFrequency(params.value),
  },
  {
    field: 'date',
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    headerName: 'Contract Date',
    flex: 1,
    valueFormatter: (params) => formatDateOnly(params.value),
    minWidth: 110,
  },
];
