import { baseTextField, baseSelect, baseInteger, STATES } from 'components/Formiks - Deprecated/constants';

// Shared with API
export const types = {
  text: 'text',
  multiline: 'multiline',
  signature: 'signature',
  checkbox: 'checkbox',
  initials: 'initials',
};

// Shared with API
export const formatAsTypes = {
  date: 'date',
  float: 'float',
  percentage: 'percentage',
  integer: 'integer',
  phone: 'phone',
};
// Shared with API
export const parties = {
  applicant: 'applicant',
  coApplicant: 'coApplicant',
  dealer: 'dealer',
  lender: 'lender',
};

export const possibleValuesFormInfo = {
  value_name: {
    ...baseTextField,
    label: 'Name',
    errorText: 'Please provide a valid name.',
    parameterName: 'value_name',
  },
  possible_keys: {
    ...baseTextField,
    label: 'Possible Keys',
    errorText: 'Please provide valid possible keys.',
    parameterName: 'possible_keys',
  },
};

export const fillableFieldFormInfo = {
  field_name: {
    ...baseTextField,
    label: 'Name',
    errorText: 'Please provide a valid name.',
    parameterName: 'field_name',
  },
  data_path: {
    ...baseTextField,
    label: 'Data Path',
    errorText: 'Please provide a valid data path.',
    parameterName: 'data_path',
    required: false,
    validator: null,
  },
  type: {
    ...baseSelect,
    label: 'Type',
    errorText: 'Please provide a valid type.',
    parameterName: 'type',
    options: Object.values(types).map((type) => ({ id: type, label: type })),
  },
  font_alignment: {
    ...baseSelect,
    label: 'Alignment',
    errorText: 'Please provide a valid alignment.',
    parameterName: 'font_alignment',
    options: [
      { id: 0, label: 'Left' },
      { id: 1, label: 'Middle' },
      { id: 2, label: 'Right' },
    ],
    required: false,
    validator: null,
  },
  format_as: {
    ...baseSelect,
    label: 'Format',
    errorText: 'Please provide a valid format.',
    parameterName: 'format_as',
    options: Object.values(formatAsTypes).map((type) => ({ id: type, label: type })),
    required: false,
    validator: null,
  },
  party: {
    ...baseSelect,
    label: 'Party',
    parameterName: 'party',
    errorText: 'Please provide a valid party',
    options: Object.values(parties).map((type) => ({ id: type, label: type })),
    signatureValue: true,
    required: false,
    validator: null,
  },
  page: {
    ...baseInteger,
    label: 'Page',
    errorText: 'Please provide a valid page number.',
    parameterName: 'page',
    signatureValue: true,
    required: false,
    validator: null,
  },
  x: {
    ...baseInteger,
    label: 'X Coordinate',
    parameterName: 'x',
    signatureValue: true,
    required: false,
    validator: null,
  },
  y: {
    ...baseInteger,
    label: 'Y Coordinate',
    parameterName: 'y',
    signatureValue: true,
    required: false,
    validator: null,
  },
  width: {
    ...baseInteger,
    label: 'Width',
    parameterName: 'width',
    signatureValue: true,
    required: false,
    validator: null,
  },
  height: {
    ...baseInteger,
    label: 'Height',
    parameterName: 'height',
    signatureValue: true,
    required: false,
    validator: null,
  },
};

export const documentTemplateFormFields = {
  name: {
    ...baseTextField,
    label: 'Name',
    errorText: 'Please provide a valid name.',
    parameterName: 'name',
  },
  state: {
    ...baseSelect,
    label: 'State',
    errorText: 'Please provide a valid state.',
    parameterName: 'state',
    required: false,
    validator: null,
    options: STATES.map((state) => ({ id: state, label: state })),
  },
  path: {
    ...baseTextField,
    label: 'Path',
    errorText: 'Please provide a valid path.',
    parameterName: 'path',
    required: false,
    validator: null,
  },
  remote_storage_url: {
    ...baseTextField,
    label: 'Remote Storage URL',
    errorText: 'Please provide a valid URL.',
    parameterName: 'remote_storage_url',
    required: false,
    validator: null,
  },
};
