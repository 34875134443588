import { Drawer, Typography, Button, Alert } from '@mui/material';

import { DrawerContainer, DrawerHeaderContainer, DrawerContentContainer, DrawerActionsContainer } from 'components/Drawer';

const ConfigurationDrawer = ({ handleClose, open, formie, handleSubmit }) => {
  const { values } = formie;
  const disabled =
    !values?.configuration?.principal_amount ||
    !values?.configuration?.interest_rate ||
    !values?.configuration?.terms ||
    !values?.configuration?.contract_date ||
    !values?.configuration?.first_payment_date ||
    (!values?.configuration?.schedule_round && values?.configuration?.schedule_round !== 0) ||
    !values?.configuration?.payment_frequency ||
    !values?.configuration?.interest_calculation_type;

  return (
    <Drawer onClose={handleClose} open={open} anchor="right" ModalProps={{ disableEscapeKeyDown: true }}>
      {/* Used to set the width of the drawer.  */}
      <DrawerContainer>
        <DrawerHeaderContainer>
          <Typography align="center" variant="h5">
            Configure Loan
          </Typography>
        </DrawerHeaderContainer>

        <DrawerContentContainer>
          <Typography variant="h6" sx={{ mb: '8px' }}>
            Essential Details
          </Typography>
          {formie.buildFields({
            fields: [
              { dataPath: 'configuration.principal_amount' },
              { dataPath: 'configuration.interest_rate' },
              { dataPath: 'configuration.contract_date' },
              { dataPath: 'configuration.first_payment_date' },
              { dataPath: 'configuration.terms' },
              { dataPath: 'configuration.schedule_round', extraProps: { allowNegative: true } },
              { dataPath: 'configuration.payment_frequency' },
              { dataPath: 'configuration.interest_calculation_type' },
            ],
            fieldsPerRow: 2,
          })}

          <Typography variant="h6" sx={{ m: '16px 0px 8px' }}>
            Optional Details
          </Typography>
          <Alert severity="warning">
            Adding a forced payment amount will force-apply this payment amount to all payment periods except the final period. The final period will payoff the
            rest of the principal and accrued interest. Down payment is used when calculating the "Total Sales Price" TILA term.
          </Alert>
          {formie.buildFields({
            fields: [{ dataPath: 'configuration.down_payment_amount' }, { dataPath: 'configuration.payment_amount' }],
            fieldsPerRow: 2,
          })}
        </DrawerContentContainer>

        <DrawerActionsContainer>
          <Button onClick={handleClose} sx={{ mr: '16px' }}>
            Cancel
          </Button>

          <Button onClick={handleSubmit} disabled={disabled} variant="contained">
            Configure
          </Button>
        </DrawerActionsContainer>
      </DrawerContainer>
    </Drawer>
  );
};

export default ConfigurationDrawer;
