import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  applications: [],
  metadata: {
    count: 0,
  },
  currentApplication: {},
  application_types: [],
  uploads: [],
  loading: {
    application_types: false,
  },
  shouldFetchApplications: false,
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    updateApplication(state, action) {},
    updateApplicationStatus(state, action) {},
    storeApplication(state, { payload }) {
      state.currentApplication = {
        ...payload,
      };
    },
    createApplication(state, action) {},
    submitApplication(state, action) {},
    fetchDealerApplications(state) {},
    fetchLenderApplications(state) {},
    storeApplications(state, { payload }) {
      state.applications = payload;
    },
    storeApplicationMetadata(state, { payload }) {
      state.metadata = payload;
    },
    fetchApplicationById(state) {},
    updateLendingOption(state, action) {},
    addLendingOption(state, action) {},
    fetchApplicationTypes(state, { payload }) {},
    storeApplicationTypes(state, { payload }) {
      state.application_types = payload;
    },
    generateDocuments(state, { payload }) {},
    updateStipulation(state, { payload }) {},
    uploadDocumentToStipulation(state, { payload }) {},
    deleteDocumentFromStipulation(state, { payload }) {},
    editUploads(state, { payload: { id } }) {
      state.uploads = [...state.uploads, id];
    },
    deleteUpload(state, { payload: { id } }) {
      state.uploads = state.uploads.filter((uploadId) => uploadId !== id);
    },
    updateApprovedApplication(state, action) {},
    startLoader(state, { payload }) {
      state.loading[payload] = true;
    },
    stopLoader(state, { payload }) {
      state.loading[payload] = false;
    },
    storeLendingOptions(state, { payload }) {
      state.currentApplication.lending_options = payload;
    },
    setShouldFetchApplications(state, { payload }) {
      state.shouldFetchApplications = payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = applicationsSlice;

// Extract and export each action creator by name
export const {
  updateApplication,
  updateApplicationStatus,
  storeApplication,
  storeLendingOptions,
  fetchDealerApplications,
  fetchLenderApplications,
  storeApplications,
  storeApplicationMetadata,
  fetchApplicationById,
  updateLendingOption,
  addLendingOption,
  generateDocuments,
  updateStipulation,
  createApplication,
  submitApplication,
  uploadDocumentToStipulation,
  editUploads,
  deleteUpload,
  deleteDocumentFromStipulation,
  updateApprovedApplication,
  fetchApplicationTypes,
  storeApplicationTypes,
  startLoader,
  stopLoader,
  setShouldFetchApplications,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
