import { createAsyncThunk } from '@reduxjs/toolkit';

import { CustomerAPI, LoanAPI, LoanCollateralAPI } from 'store/api';

export const openAndActivateLoanThunk = createAsyncThunk('loan/openAndActivate', async ({ loanId }, thunkAPI) => {
  try {
    await LoanAPI.updateLoanStatus({ loanId, data: { status: 'open' } });
    const { loan } = await LoanAPI.activateLoan({ loanId });

    /**
     * This will dispatch action creator with type of "loan/openAndActivate/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { loan };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loan/openAndActivate/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const generateTermsThunk = createAsyncThunk('loan/generateTerms', async ({ data }, thunkAPI) => {
  try {
    const { terms } = await LoanAPI.generateTerms({
      data,
    });

    /**
     * This will dispatch action creator with type of "loan/generateTerms/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { terms };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loan/generateTerms/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const createLoanThunk = createAsyncThunk('loan/create', async ({ lenderId, sourceCompanyId, collateral, timezone }, thunkAPI) => {
  try {
    // Create the loan. If successful, update the loan with the collateral. Return the loan ID so the UI can redirect to the loan edit page.
    const { loan } = await LoanAPI.createLoan({
      data: {
        lender_id: lenderId,
        source_company_id: sourceCompanyId,
        timezone,
      },
    });
    await LoanCollateralAPI.createLoanCollateral({ data: { ...collateral }, loanId: loan?.id });

    /**
     * This will dispatch action creator with type of "loan/create/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { id: loan?.id };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loan/create/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateLoanThunk = createAsyncThunk('loan/update', async ({ data, loanId }, thunkAPI) => {
  try {
    const { loan } = await LoanAPI.updateLoan({
      loanId,
      data,
    });

    /**
     * This will dispatch action creator with type of "loan/update/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { loan };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loan/update/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchLoanThunk = createAsyncThunk('loan/fetch', async ({ subdata, loanId }, thunkAPI) => {
  try {
    let queryString;

    if (!subdata || !subdata?.length || subdata.length < 1) {
      queryString = null;
    } else {
      queryString = `?subdata=${subdata.join(',')}`;
    }

    const { loan } = await LoanAPI.fetchLoan({ queryString, loanId });

    /**
     * This will dispatch action creator with type of "loan/fetch/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { loan };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loan/fetch/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const editCustomerRolesThunk = createAsyncThunk('loan/editCustomerRoles', async ({ primaryCustomerId, secondaryCustomerId, loanId }, thunkAPI) => {
  try {
    const { loan } = await LoanAPI.editCustomerRoles({ data: { primary_debtor: primaryCustomerId, secondary_debtor: secondaryCustomerId }, loanId });

    /**
     * This will dispatch action creator with type of "loan/editCustomerRoles/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { loan };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loan/editCustomerRoles/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const createCustomerThunk = createAsyncThunk('loan/createCustomer', async ({ customer, residenceAddress, mailingAddress, loanId, role }, thunkAPI) => {
  try {
    const { customer: newCustomer } = await LoanAPI.createCustomer({
      data: { customer_role: role, ...customer },
      loanId,
    });

    const residenceAddressData = {
      ...residenceAddress,
      address_2: residenceAddress?.address_2 === '' ? null : residenceAddress?.address_2,
    };

    const mailingAddressData = {
      ...mailingAddress,
      address_2: mailingAddress?.address_2 === '' ? null : mailingAddress?.address_2,
    };

    await Promise.all([
      CustomerAPI.createCustomerAddress({
        data: {
          ...residenceAddressData,
          address_type: 'residence',
        },
        customerId: newCustomer?.id,
      }),

      CustomerAPI.createCustomerAddress({
        data: {
          ...mailingAddressData,
          address_type: 'mailing',
        },
        customerId: newCustomer?.id,
      }),
    ]);

    /**
     * This will dispatch action creator with type of "loan/createCustomer/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { customer: newCustomer };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "loan/createCustomer/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});
