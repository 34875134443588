import { Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import isEqual from 'lodash/isEqual';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';

import { useBuildFormik, buildFormikFields } from 'components/Formiks - Deprecated';
import { FlexRow } from 'components/Layouts';

import { documentTemplateFormFields } from './formInfo';
import EditField from './editField';
import EditPossibleValue from './editPossibleValue';
import { COLORS } from 'constants/styles';

import { saveDocumentTemplate, createDocumentTemplate } from 'store/sagas/admin';

const DocumentTemplateModal = ({ documentTemplate, open, onClose }) => {
  const dispatch = useDispatch();

  const [editingField, setEditingField] = useState(false);
  const [field, setField] = useState({});
  const [editingPossibleValue, setEditingPossibleValue] = useState(false);
  const [possibleValue, setPossibleValue] = useState({});
  const editing = !!documentTemplate.id;

  const formik = useBuildFormik({
    formInfo: Object.values(documentTemplateFormFields),
    onSubmit: (values) => handleSubmit(values),
    values: documentTemplate,
  });

  const handleSubmit = (values) => {
    if (editing) {
      dispatch(saveDocumentTemplate({ data: values, templateId: formik.values.id }));
    } else {
      dispatch(createDocumentTemplate({ data: values }));
    }
    onClose();
  };

  const { values } = formik;
  const { name, state, path, remote_storage_url } = documentTemplateFormFields;

  const addField = (fieldValues) => {
    const template_fields = values?.document_template_fields || [];
    const index = template_fields.findIndex((field) => field.id === fieldValues.id);
    if (index > -1) {
      const fieldsCopy = structuredClone(template_fields);
      fieldsCopy.splice(index, 1, fieldValues);
      formik.setFieldValue('document_template_fields', fieldsCopy);
    } else if (index === -1) {
      const fieldsCopy = structuredClone(template_fields);
      fieldsCopy.unshift({ ...fieldValues, id: `new-field-${new Date().getTime()}` });
      formik.setFieldValue('document_template_fields', fieldsCopy);
    }

    setEditingField(false);
    setField({});
  };

  const addPossibleValue = (newPossibleValue) => {
    const template_values = values?.document_template_values || [];
    const index = template_values.findIndex((possibleValue) => possibleValue.id === newPossibleValue.id);
    if (index > -1) {
      const possibleValuesCopy = structuredClone(template_values);
      possibleValuesCopy.splice(index, 1, newPossibleValue);
      formik.setFieldValue('document_template_values', possibleValuesCopy);
    } else if (index === -1) {
      const possibleValuesCopy = structuredClone(template_values);
      possibleValuesCopy.unshift({ ...newPossibleValue, id: `new-possible-value-${new Date().getTime()}` });
      formik.setFieldValue('document_template_values', possibleValuesCopy);
    }

    setEditingPossibleValue(false);
    setPossibleValue({});
  };

  const deleteField = (id) => {
    const index = values?.document_template_fields.findIndex((field) => field.id === id);
    if (index > -1) {
      const fieldsCopy = structuredClone(values.document_template_fields);
      fieldsCopy.splice(index, 1);
      formik.setFieldValue('document_template_fields', fieldsCopy);
    }
  };

  const deletePossibleValue = (id) => {
    const index = values?.document_template_values.findIndex((value) => value.id === id);
    if (index > -1) {
      const valuesCopy = structuredClone(values.document_template_values);
      valuesCopy.splice(index, 1);
      formik.setFieldValue('document_template_values', valuesCopy);
    }
  };

  const createField = () => {
    setEditingField(true);
    setField({});
  };

  const createPossibleValue = () => {
    setEditingPossibleValue(true);
    setPossibleValue({});
  };

  const fillableFieldsColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          showInMenu
          label="Edit"
          onClick={() => {
            setEditingField(true);
            setField(row);
          }}
        />,
        <GridActionsCellItem icon={<DeleteIcon />} showInMenu label="Delete" onClick={() => deleteField(row.id)} />,
      ],
    },
    { field: 'field_name', headerName: 'Name', width: 250 },
    { field: 'data_path', headerName: 'Data Path', width: 250 },
    { field: 'type', headerName: 'Type' },
    { field: 'font_alignment', headerName: 'Alignment' },
    { field: 'format_as', headerName: 'Format' },
    { field: 'page', headerName: 'Page #' },
    { field: 'party', headerName: 'Party' },
    { field: 'x', headerName: 'X' },
    { field: 'y', headerName: 'Y' },
    { field: 'width', headerName: 'Width' },
    { field: 'height', headerName: 'Height' },
  ];

  const templateValuesColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          showInMenu
          label="Edit"
          onClick={() => {
            setEditingPossibleValue(true);
            setPossibleValue(row);
          }}
        />,
        <GridActionsCellItem icon={<DeleteIcon />} showInMenu label="Delete" onClick={() => deletePossibleValue(row.id)} />,
      ],
    },
    { field: 'value_name', headerName: 'Name', width: 250 },
    { field: 'possible_keys', headerName: 'Possible Keys', flex: 1 },
  ];

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth maxWidth="xl" open={open}>
      <DialogTitle>{`${editing ? 'Edit' : 'Add'}`} Document Template</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Settings</Typography>
        {[
          {
            checked: values.is_fillable,
            fieldName: 'is_fillable',
            label: 'Template has fillable fields',
            explanation:
              'Turning this on indicates that the form will have fillable fields. To use these, add fillable fields down below. This will map values from the application to the document template.',
          },
          {
            checked: values.is_signable,
            fieldName: 'is_signable',
            label: 'Template is signable',
            explanation:
              'Turning this on indicates that the template is signable. If a lender uses it with one of their stipulations, it will be included in the signing bundle.',
          },
          {
            checked: values.is_state_specific,
            fieldName: 'is_state_specific',
            label: 'Template is specific to one state',
            explanation:
              'Turning this on indicates that the form is specific to a single state. Some document templates, like Retail Installment Contracts, are governed by state law and therefore specific to only one state.',
          },
        ].map(({ checked, fieldName, label, explanation }) => (
          <Fragment key={`switch-${fieldName}`}>
            <FormControlLabel
              control={<Switch checked={checked || false} onChange={(e) => formik.setFieldValue(fieldName, e.target.checked)} name={fieldName} />}
              label={
                <Typography sx={{ fontWeight: 700 }} variant="body1">
                  {label}
                </Typography>
              }
            />
            <Typography sx={{ marginLeft: '47px' }} variant="body2">
              {explanation}
            </Typography>
          </Fragment>
        ))}
        <Divider sx={{ marginTop: '20px' }} />

        <Typography sx={{ marginTop: '20px' }} variant="h6">
          Values
        </Typography>
        {buildFormikFields({
          formik,
          fields: values.is_state_specific ? [name, state] : [name],
          fieldsPerRow: values.is_state_specific ? 2 : null,
        })}
        {buildFormikFields({
          formik,
          fields: [path, remote_storage_url],
          fieldsPerRow: 2,
        })}
        <Divider sx={{ marginTop: '20px' }} />

        {values.is_fillable && (
          <Fragment>
            <FlexRow padding="0" margin="20px 0px 5px" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Fillable Fields</Typography>
              {!editingField && (
                <Button onClick={createField} startIcon={<AddIcon />}>
                  Add
                </Button>
              )}
            </FlexRow>
            {editingField ? (
              <EditField field={field} setField={setField} setEditingField={setEditingField} isSignable={values.is_signable} addField={addField} />
            ) : (
              <div style={{ height: 300, width: '100%', background: COLORS.backgroundColor }}>
                <DataGrid rows={values.document_template_fields || []} columns={fillableFieldsColumns} />
              </div>
            )}
            <Divider sx={{ marginTop: '20px' }} />
          </Fragment>
        )}

        <FlexRow padding="0" margin="20px 0px 5px" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Possible Values</Typography>
          {!editingPossibleValue && (
            <Button onClick={createPossibleValue} startIcon={<AddIcon />}>
              Add
            </Button>
          )}
        </FlexRow>
        {editingPossibleValue ? (
          <EditPossibleValue
            setEditingPossibleValue={setEditingPossibleValue}
            possibleValue={possibleValue}
            setPossibleValue={setPossibleValue}
            addPossibleValue={addPossibleValue}
          />
        ) : (
          <div style={{ height: 300, width: '100%', background: COLORS.backgroundColor }}>
            <DataGrid rows={values.document_template_values || []} columns={templateValuesColumns} />
          </div>
        )}
      </DialogContent>

      {!editingField && !editingPossibleValue && (
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={formik.handleSubmit} disabled={isEqual(values, documentTemplate)} variant="contained">
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DocumentTemplateModal;
