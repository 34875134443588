import { TextField, MenuItem } from '@mui/material';

const BasicSelect = ({ options = [], handleChange, value, width = '150px' }) => {
  return (
    <TextField sx={{ width }} variant="outlined" size="small" select label={null} value={value} onChange={handleChange}>
      {options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default BasicSelect;
