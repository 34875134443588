import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { FlexRow, Card } from './../../components/Layouts';
import { logUserIn } from '../../store/sagas/user';
import { REGEX } from '../../constants/regex';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState({ err: false, tip: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!!user?.active_profile?.id) {
      const entityType = user.active_profile.dealer ? 'dealer' : 'lender';

      return navigate(`/app/${entityType}/dashboard`, { replace: true });
    }
  }, [user]);

  const validateEmail = () => {
    if (REGEX.isEmail.test(email) || email === '') {
      setEmailError({
        err: false,
        tip: '',
      });
    } else {
      setEmailError({
        err: true,
        tip: 'Please enter a valid email address.',
      });
    }
  };

  const login = () => {
    validateEmail();

    if (email === '') {
      setEmailError({
        err: true,
        tip: 'Please enter a valid email address.',
      });
    }

    if (!emailError.err) {
      dispatch(logUserIn({ username: email.toLowerCase(), password }));
    }
  };

  return (
    <FlexRow justifyContent="center" alignItems="center" padding="20px" fullHeight>
      <Card variant="outlined">
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>

        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={emailError.err}
          helperText={emailError.err ? emailError.tip : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={validateEmail}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') login();
          }}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button fullWidth variant="contained" style={{ marginTop: '20px' }} size="large" onClick={login} disabled={!email || !password}>
          Login
        </Button>
      </Card>
    </FlexRow>
  );
};

export default Login;
