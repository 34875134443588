import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  DialogContentText,
  LinearProgress,
  Tooltip,
  Divider,
  Chip,
  IconButton,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';

import { setAlert } from 'store/sagas/app';
import { FlexRow } from 'components/Layouts';
import Drawer from 'components/Drawer';
import { useFormie } from 'components/Formie';
import { COLORS } from 'constants/styles';
import { createLoanFeeThunk, editLoanFeeThunk, fetchLoanFeesThunk, reverseLoanFeeThunk } from 'store/thunks/fee';
import { createColumns, findStatistics, findStatus, findColor, gridSx, FEE_TYPES } from './helpers';
import ListItem from 'components/ListItem';
import { createFeesConfiguration, feeInitialValues } from '../formInfo';
import { fetchLoanPastDuesThunk } from 'store/thunks/pastDue';
import { parseDateOnlyForInputs } from 'constants/formatters';

const Fees = ({}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [fees, setFees] = useState([]);
  const [pastDues, setPastDues] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [feeReverseId, setFeeReverseId] = useState(null);
  const [feeDetailId, setFeeDetailId] = useState(null);
  const { loanId } = useParams();

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { fees } = await dispatch(
        fetchLoanFeesThunk({
          loanId,
          limit: 1000,
          subdata: ['fee-payments'],
        }),
      ).unwrap();
      setFees(fees);

      const { pastDues } = await dispatch(
        fetchLoanPastDuesThunk({
          loanId,
          limit: 1000,
        }),
      ).unwrap();
      setPastDues(pastDues);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch fees. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const createOrEditFee = async () => {
    setLoading(true);
    try {
      editing
        ? await dispatch(
            editLoanFeeThunk({
              data: {
                fee_date: values?.fee?.fee_date && format(parseISO(values.fee.fee_date), 'yyyy-MM-dd'),
                amount: values?.fee?.amount,
                type: values?.fee?.type,
                title: values?.fee?.title,
              },
              loanId,
              feeId: values?.fee?.id,
            }),
          ).unwrap()
        : await dispatch(
            createLoanFeeThunk({
              data: {
                fee_date: values?.fee?.fee_date && format(parseISO(values.fee.fee_date), 'yyyy-MM-dd'),
                amount: values?.fee?.amount,
                type: values?.fee?.type,
                title: values?.fee?.title,
              },
              loanId,
            }),
          ).unwrap();
      hydrateData();
      setDrawerOpen(false);
      setEditing(false);
      resetForm();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to ${editing ? 'edit' : 'create'} fee. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleShowReverseModal = (id) => {
    setFeeReverseId(id);
  };

  const handleCloseReverseModal = () => {
    setFeeReverseId(null);
  };

  const reverseFee = async () => {
    setLoading(true);
    try {
      await dispatch(
        reverseLoanFeeThunk({
          loanId,
          feeId: feeReverseId,
        }),
      ).unwrap();
      handleCloseReverseModal();
      hydrateData();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to reverse fee. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleStartFeeEdit = (props, currentFees) => {
    const feeForEditing = currentFees.find((fee) => fee.id === props.id);
    setAllValues({
      fee: {
        fee_date: parseDateOnlyForInputs(feeForEditing.fee_date),
        amount: Number(feeForEditing.amount),
        type: feeForEditing.type,
        title: feeForEditing.title,
        id: feeForEditing.id,
      },
    });
    setEditing(true);
    handleDrawerOpen();
  };

  const handleCloseDrawer = (e, reason) => {
    if (reason !== 'backdropClick') {
      setDrawerOpen(false);
      setEditing(false);
      resetForm();
    }
  };

  const handleShowDetails = (id) => {
    setFeeDetailId(id);
  };

  const handleCloseDetails = () => {
    setFeeDetailId(null);
  };

  const unchargedPastDues = pastDues.filter((pastDue) => !pastDue?.charged);
  const formie = useFormie({
    configuration: createFeesConfiguration(unchargedPastDues),
    initialValues: feeInitialValues,
    onSubmit: createOrEditFee,
  });

  const { values, handleSubmit, resetForm, setAllValues } = formie;

  const columns = useMemo(() => {
    return createColumns({
      showDetails: (id) => {
        handleShowDetails(id);
      },
      editFee: (fee) => {
        handleStartFeeEdit(fee, fees);
      },
      reverseFee: (id) => {
        handleShowReverseModal(id);
      },
    });
  }, [createColumns, fees]);
  const rows = fees
    .map((fee) => ({
      id: fee.id,
      date: fee.fee_date,
      title: fee.title,
      amount: fee.amount,
      amount_paid: fee.amount_paid,
      status: findStatus(fee),
    }))
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const feeForDetailModal = fees.find((fee) => fee.id === feeDetailId);
  const statistics = feeForDetailModal ? findStatistics(feeForDetailModal) : {};
  const disabled = !values?.fee?.amount || !values?.fee?.fee_date || !values?.fee?.type || !values?.fee?.title;

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <>
      {/* Header row with action button */}
      <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Fees</Typography>
        <Button size="small" variant="contained" onClick={handleDrawerOpen}>
          Add Fee
        </Button>
      </FlexRow>

      {/* Data Grid */}
      <div style={{ margin: '16px 0px 0px', height: 'calc(100vh - 215px)', background: COLORS.surfaceColor }}>
        <DataGrid
          onRowClick={(params) => setFeeDetailId(params.id)}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          disableColumnMenu
          density="compact"
          columns={columns}
          rows={rows}
          sx={gridSx}
          getRowClassName={(params) => `payment-theme--${params.row.status}`}
        />
      </div>

      {/* Reverse Confirmation Dialog */}
      <Dialog onClose={handleCloseReverseModal} open={!!feeReverseId}>
        <DialogTitle>Reverse this fee?</DialogTitle>

        <DialogContent>
          <DialogContentText>Reversing this fee will remove it from the loan. This is irreversible.</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseReverseModal}>Cancel</Button>
          <Button onClick={reverseFee}>Reverse fee</Button>
        </DialogActions>
      </Dialog>

      {/* Details Dialog */}
      <Dialog fullWidth maxWidth="xs" onClose={handleCloseDetails} open={!!feeDetailId}>
        <DialogTitle>Fee Details</DialogTitle>
        <IconButton
          onClick={handleCloseDetails}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <FlexRow flexColumn padding="0px" margin="0px" justifyContent="center" alignItems="center">
            <Typography textAlign="center" variant="h4">
              {statistics.amountCharged}
            </Typography>
            <Chip label={statistics.status} color={findColor(statistics.status)} size="small" />
          </FlexRow>

          <Tooltip title={`${statistics.percentagePaidFormatted} Paid`}>
            <LinearProgress sx={{ height: '12px', borderRadius: '8px', mt: '12px' }} variant="determinate" value={statistics.percentagePaid * 100} />
          </Tooltip>
          <FlexRow padding="0px" margin="0px 0px 16px" justifyContent="space-between" alignItems="center">
            <Typography variant="body2">0%</Typography>
            <Typography variant="body2">100%</Typography>
          </FlexRow>

          <Typography sx={{ fontWeight: 700 }}>{feeForDetailModal?.title}</Typography>
          <ListItem label="Amount Charged" value={statistics.amountCharged} />
          <ListItem label="Amount Paid" value={statistics.amountPaid} />
          <ListItem label="Remaining" value={statistics.amountRemaining} />

          <Divider sx={{ m: '16px 0px' }} />

          <ListItem label="Charged On" value={statistics.date} />
          <ListItem label="Paid On" value={statistics.paidDate} />
          {statistics.editedDate && statistics.editedDate !== '-' && <ListItem label="Edited On" value={statistics.editedDate} />}
          {statistics.reversedDate && statistics.reversedDate !== '-' && <ListItem label="Reversed On" value={statistics.reversedDate} />}
          {statistics.waivedDate && statistics.waivedDate !== '-' && <ListItem label="Waived On" value={statistics.waivedDate} />}

          <Divider sx={{ m: '16px 0px' }} />
          <ListItem label="Id" value={feeForDetailModal?.id} />

          {!!feeForDetailModal?.source_loan_fee_id && (
            <>
              <Divider sx={{ m: '16px 0px' }} />
              <ListItem label="Original Fee" value={feeForDetailModal?.source_loan_fee_id} />
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* Drawer with drawer content */}
      <Drawer
        title={editing ? 'Edit Fee' : 'Add Fee'}
        open={drawerOpen}
        disabled={disabled}
        actionTitle={editing ? 'edit' : 'add'}
        handleClose={handleCloseDrawer}
        handleSubmit={handleSubmit}
      >
        <Typography variant="h6">How much is the fee for?</Typography>
        {formie.buildField('fee.amount')}

        <Typography variant="h6" sx={{ mt: '32px' }}>
          When was the fee charged?
        </Typography>
        {formie.buildField('fee.fee_date')}

        <Typography variant="h6" sx={{ mt: '32px' }}>
          What type of fee is this?
        </Typography>
        {formie.buildField('fee.type')}

        <Typography variant="h6" sx={{ mt: '32px' }}>
          What should we call this fee?
        </Typography>
        {formie.buildField('fee.title')}
      </Drawer>
    </>
  );
};

export default Fees;
