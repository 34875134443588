import { TYPES } from 'components/Formie/inputs';
import { validatePositiveInteger, validatePercentage } from 'components/Formie/validators';

export const configuration = {
  accepting_application: {
    type: TYPES.switch,
    dataPath: 'accepting_application',
    label: 'Accepting Applications',
    explanation:
      'Turning this on means you are ready and willing to accept new applications. If you turn this off you will no longer appear in the list of lenders that dealers can submit applications to. However, any previously submitted applications can still be worked by the dealer.',
  },
  max_loan_size: {
    type: TYPES.currency,
    dataPath: 'max_loan_size',
    required: true,
    validator: validatePositiveInteger,
    label: 'Maximum Loan Size',
  },
  max_ltv: {
    type: TYPES.percentage,
    dataPath: 'max_ltv',
    required: true,
    validator: validatePercentage,
    label: 'Maximum Loan to Value',
  },
  max_dti: {
    type: TYPES.percentage,
    dataPath: 'max_dti',
    required: true,
    validator: validatePercentage,
    label: 'Maximum Debt to Income',
  },
  no_of_ref: {
    type: TYPES.integer,
    dataPath: 'no_of_ref',
    required: true,
    validator: validatePositiveInteger,
    label: 'Number of References',
  },
  no_of_verified_ref: {
    type: TYPES.integer,
    dataPath: 'no_of_verified_ref',
    required: true,
    validator: validatePositiveInteger,
    label: 'Number of Verified References',
  },
  no_of_payment_profiles: {
    type: TYPES.integer,
    dataPath: 'no_of_payment_profiles',
    required: true,
    validator: validatePositiveInteger,
    label: 'Number of Payment Profiles. Payment profiles provided must have all necessary data and be at least in pending verification status to be counted.',
  },
};
