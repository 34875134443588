import { Typography, Divider, Alert } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { Card } from 'components/Layouts';
import { currencyFormatter, percentageFormatterV2, roundAccurately } from 'constants/formatters';
import { DataCard } from '../ViewLoan/Summary/index.js';
import PrincipalRepayment from '../ViewLoan/Summary/PrincipalRepayment.js';
import { PaymentBreakdownCard } from '../ViewLoan/Summary/PaymentBreakdown.js';
import Position from '../ViewLoan/Summary/Position.js';
import Profitability from '../ViewLoan/Summary/Profitability.js';
import ListItem from 'components/ListItem';
import { LOAN_STATUSES } from './helpers.js';

const PortfolioStats = ({ metadata, status, active }) => {
  const showingInactiveLoans = active === 'false';
  const hasStatusOtherThanOpen =
    status.split(',').includes(LOAN_STATUSES.CLOSED) ||
    status.split(',').includes(LOAN_STATUSES.PAID_OFF) ||
    status.split(',').includes(LOAN_STATUSES.CHARGED_OFF) ||
    status.split(',').includes(LOAN_STATUSES.DRAFT);

  return (
    <div
      style={{
        margin: '0px -20px',
        height: 'calc(100vh - 102px)',
        boxSizing: 'border-box',
        overflowY: 'auto',
        padding: '20px',
      }}
    >
      {(showingInactiveLoans || hasStatusOtherThanOpen) && (
        <Alert color="warning" severity="info" sx={{ mb: '16px' }}>
          These statistics are based on your selected filters and may contain data about loans that are not open or active.
        </Alert>
      )}
      <Grid container spacing={2}>
        <DataCard background label="Profit" value={currencyFormatter.format(roundAccurately(metadata.totalFeesPaid + metadata.totalInterestsPaid, 2))} />
        <DataCard
          label="Collected"
          value={currencyFormatter.format(roundAccurately(metadata.totalFeesPaid + metadata.totalInterestsPaid + metadata.totalPrincipalPaid, 2))}
        />
        <DataCard
          label="ROI"
          value={
            metadata.totalFeesPaid && metadata.totalInterestsPaid && metadata.totalOriginalPrincipal
              ? percentageFormatterV2(roundAccurately((metadata.totalFeesPaid + metadata.totalInterestsPaid) / metadata.totalOriginalPrincipal, 4))
              : '-'
          }
        />
        <DataCard
          label="% Money Returned"
          value={
            metadata.totalFeesPaid && metadata.totalInterestsPaid && metadata.totalOriginalPrincipal && metadata.totalPrincipalPaid
              ? percentageFormatterV2(
                  roundAccurately((metadata.totalFeesPaid + metadata.totalInterestsPaid + metadata.totalPrincipalPaid) / metadata.totalOriginalPrincipal, 4),
                )
              : '-'
          }
        />

        <Grid xs={9}>
          <PrincipalRepayment
            principalPaid={metadata.totalPrincipalPaid}
            currentPrincipal={metadata.totalCurrentPrincipal}
            originalPrincipal={metadata.totalOriginalPrincipal}
          />
        </Grid>

        <DataCard error={metadata.totalPastDues > 0} label="Past Due" value={currencyFormatter.format(metadata.totalPastDues)} />

        <Grid xs={6}>
          <PaymentBreakdownCard data={{ principal: metadata.totalPrincipalPaid, interest: metadata.totalInterestsPaid, fees: metadata.totalFeesPaid }} />
        </Grid>

        <Grid xs={6}>
          <Card fullHeight padding="10px 20px" variant="outlined">
            <Typography variant="h6" mb="16px">
              Portfolio Metrics
            </Typography>

            <Typography sx={{ fontWeight: 500 }}>Origination</Typography>
            <ListItem value={metadata.count ? metadata.count : '-'} label="Total Accounts" />
            <ListItem
              value={
                metadata.count && metadata.totalOriginalPrincipal
                  ? currencyFormatter.format(roundAccurately(metadata.totalOriginalPrincipal / metadata.count, 2))
                  : '-'
              }
              label="Average Original Loan Size"
            />
            <ListItem value={metadata.averageInterestRate ? percentageFormatterV2(metadata.averageInterestRate) : '-'} label="Weighted Average Interest Rate" />
            <ListItem value={metadata.averageTerm ? metadata.averageTerm : '-'} label="Average Term" />
            <ListItem
              value={metadata.averageMonthlyPaymentAmount ? currencyFormatter.format(metadata.averageMonthlyPaymentAmount) : '-'}
              label="Average Monthly Collections"
            />

            <Divider sx={{ m: '32px 0px' }} />

            <Typography sx={{ fontWeight: 500 }}>Servicing</Typography>
            <ListItem
              value={
                metadata.count && metadata.totalCurrentPrincipal
                  ? currencyFormatter.format(roundAccurately(metadata.totalCurrentPrincipal / metadata.count, 2))
                  : '-'
              }
              label="Average Outstanding Principal"
            />
            <ListItem value={metadata.totalUnpaidInterest ? currencyFormatter.format(metadata.totalUnpaidInterest) : '-'} label="Unpaid Accrued Interest" />
            <ListItem value={metadata.totalUnpaidFees ? currencyFormatter.format(metadata.totalUnpaidFees) : '-'} label="Unpaid Fees" />
            <ListItem value={metadata.totalPayoff ? currencyFormatter.format(metadata.totalPayoff) : '-'} label="Current Payoff" />
          </Card>
        </Grid>

        <Grid xs={6}>
          <Profitability
            interestPaid={metadata.totalInterestsPaid}
            feesPaid={metadata.totalFeesPaid}
            profit={roundAccurately(metadata.totalFeesPaid + metadata.totalInterestsPaid, 2)}
          />
        </Grid>

        <Grid xs={6}>
          <Position
            interestPaid={metadata.totalInterestsPaid}
            feesPaid={metadata.totalFeesPaid}
            principalPaid={metadata.totalPrincipalPaid}
            originalPrincipal={metadata.totalOriginalPrincipal}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PortfolioStats;
