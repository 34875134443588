import { all } from 'redux-saga/effects';

import UserSagas from './user/sagas';
import AppSagas from './app/sagas';
import ApplicationsSagas from './applications/sagas';
import AdminSagas from './admin/sagas';

function* rootSaga() {
  yield all([...UserSagas, ...AppSagas, ...ApplicationsSagas, ...AdminSagas]);
}

export default rootSaga;
