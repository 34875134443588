import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import { Card, FlexRow } from 'components/Layouts';
import { useFormie } from 'components/Formie';
import { fetchLender, updateLender } from 'store/sagas/admin';

import { configuration } from './formInfo';
import { SettingsGrid } from './styled-components';

const valueSettings = [
  {
    title: 'Maximum Loan Size',
    explanation: 'This is the maximum size of loan the system will auto-generate for you during our automated underwriting.',
    dataPath: 'max_loan_size',
  },
  {
    title: 'Maximum Debt to Income Ratio',
    explanation:
      'This is the maximum debt to income ratio the automated underwriting system will allow for your lending options. The amount financed will be lowered until this threshhold is met. In cases where it is impossible to stay under this threshold, the system will return an amount financed equal to 70% LTV.',
    dataPath: 'max_dti',
  },
  {
    title: 'Maximum Loan to Value Ratio',
    explanation:
      'This is the maximum loan to value ratio the automated underwriting system will allow for your lending options. The amount financed will be lowered until this threshhold is met. In cases where it is impossible to stay under this threshold, the system will return an amount financed equal to 70% LTV.',
    dataPath: 'max_ltv',
  },
  {
    title: 'Number of References',
    explanation: 'Required number of references for the application.',
    dataPath: 'no_of_ref',
  },
  {
    title: 'Number of Verified References',
    explanation: 'Required number of verified references for the application.',
    dataPath: 'no_of_verified_ref',
  },
  {
    title: 'Number of Payment Profiles',
    explanation:
      'Required number of payment profiles. Payment profiles provided must have all necessary data and be at least in pending verification status to be counted.',
    dataPath: 'no_of_payment_profiles',
  },
];

const LendingRules = () => {
  const dispatch = useDispatch();

  const activeProfile = useSelector((state) => state?.user?.active_profile);
  const lender = useSelector((state) => state.admin?.lender);
  const loading = useSelector((state) => state.admin?.loading?.lender);
  const lenderId = activeProfile?.lender?.id;
  const { accepting_application, max_loan_size, max_ltv, max_dti, no_of_ref, no_of_verified_ref, no_of_payment_profiles } = lender;

  const handleSubmit = (values) => {
    dispatch(
      updateLender({
        lenderId,
        data: values,
      }),
    );
  };

  const formie = useFormie({
    configuration,
    initialValues: {
      accepting_application,
      max_loan_size,
      max_dti,
      max_ltv,
      no_of_ref,
      no_of_verified_ref,
      no_of_payment_profiles,
    },
    onSubmit: handleSubmit,
  });

  const { dirty } = formie;

  useEffect(() => {
    lenderId && dispatch(fetchLender({ lenderId }));
  }, [activeProfile]);

  useEffect(() => {
    const { accepting_application, max_dti, max_loan_size, max_ltv, no_of_ref, no_of_verified_ref, no_of_payment_profiles } = lender;

    formie.setAllValues({
      accepting_application,
      max_dti,
      max_loan_size,
      max_ltv,
      no_of_ref,
      no_of_verified_ref,
      no_of_payment_profiles,
    });
  }, [lender]);

  return (
    <Card margin="0" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0px 0px 20px" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Lending Rules</Typography>
        <Button onClick={formie.handleSubmit} disabled={!dirty} variant="contained">
          Save
        </Button>
      </FlexRow>

      {loading ? (
        <FlexRow padding="20px" margin="0px" justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexRow>
      ) : (
        <>
          <Typography variant="h6">Toggles</Typography>
          {formie.buildField('accepting_application')}

          <Typography sx={{ marginTop: '40px' }} variant="h6">
            Values
          </Typography>
          {valueSettings.map(({ title, explanation, dataPath }, i) => {
            return (
              <Fragment key={title}>
                <SettingsGrid noPadding={i === 0}>
                  <div>
                    <Typography sx={{ fontWeight: 700 }} variant="body1">
                      {title}
                    </Typography>
                    <Typography variant="body2">{explanation}</Typography>
                  </div>

                  {formie.buildField(dataPath)}
                </SettingsGrid>

                {i + 1 < valueSettings.length && <Divider sx={{ marginTop: '20px' }} />}
              </Fragment>
            );
          })}
        </>
      )}
    </Card>
  );
};

export default LendingRules;
