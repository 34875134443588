import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import PaidIcon from '@mui/icons-material/Paid';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import TaskIcon from '@mui/icons-material/Task';

import { LINKS } from 'constants/menus';
import { STATUS, STATUS_LABELS } from 'constants/status';
import { currencyFormatter, percentageFormatter } from 'constants/formatters';
import { PLAID_AUTH_STATUS } from 'constants/plaid';
import { fetchLenderApplications, storeApplications, fetchApplicationTypes, updateApplicationStatus } from 'store/sagas/applications';
import { FlexRow, TableContainer } from 'components/Layouts';
import NavBar from 'components/NavBar';
import { ActionMenu, SortMenu } from 'components/Menu';
import { MultipleSelectCheckmarks } from 'components/MultiSelect';
import { getApplicantIcon, getApplicationTypeIcon, getApplicationStatusIcon } from 'components/Icon';
import { DoubleConfirmNoButton } from 'components/DoubleConfirm';
import { StyledPaginationContainer, StyledDataContainer, StyledTableRow, StyledCellContainer } from 'components/Layouts/styled-components';

const { FUNDING_REVIEW, FUNDED } = STATUS_LABELS;
const { PRE_PLAID, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED } = PLAID_AUTH_STATUS;

const breadcrumbs = [
  { text: 'Home', link: LINKS.LENDER.DASHBOARD },
  { text: 'Applications', link: LINKS.LENDER.APPLICATIONS.BASE },
];

const Applications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const applications = useSelector((state) => state.applications.applications);
  const applicationsMetadata = useSelector((state) => state.applications.metadata);
  const applicationTypes = useSelector((state) => state.applications.application_types);
  const activeProfile = useSelector((state) => state.user && state.user.active_profile);
  const shouldFetchApplications = useSelector((state) => state.applications.shouldFetchApplications);

  const [searchParams, setSearchParams] = useSearchParams();

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(30);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState([]);
  const [dealerFilter, setDealerFilter] = useState([]);
  const [applicationTypeFilter, setApplicationTypeFilter] = useState([]);
  const [applicationSort, setApplicationSort] = useState(null);
  const [filterComponentWidth] = useState('200px');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const activeLender = activeProfile.lender;

  const handleModalClose = () => {
    setSelectedApplication(null);
    setModalOpen(false);
  };

  const handleModalOpen = (app) => {
    setSelectedApplication(app);
    setModalOpen(true);
  };

  /**
   * Fetch only application types on component load.
   * Well fetch the actual applications list after loading application types.
   */
  useEffect(() => {
    dispatch(fetchApplicationTypes());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * We need to do this and wait for application types to load before triggering the first API query because
   * we need to determine the list of application types first because our multi select components depends on it.
   * And since we are syncing in the data from url query string into our application type filter, the multi
   * select won't function properly if application types isn't loaded yet.
   */
  useEffect(() => {
    if (Array.isArray(applicationTypes) && applicationTypes.length > 0) {
      const queryParams = new URLSearchParams(searchParams);
      let search, status, dealer, type, sort;

      for (const [key, value] of queryParams) {
        switch (key) {
          case 'search':
            search = value && value.trim() !== '' ? value.trim() : '';
            setSearchTerm(search);
            continue;

          case 'status':
            status = value && value.trim() !== '' ? value.trim().split(',') : [];
            setStatusFilter(status);
            continue;

          case 'dealer':
            dealer = value && value.trim() !== '' ? value.trim().split(',') : [];
            setDealerFilter(dealer);
            continue;

          case 'type':
            type =
              value && value.trim() !== ''
                ? value
                    .trim()
                    .split(',')
                    .map((t) => +t)
                : [];
            setApplicationTypeFilter(type);
            continue;

          case 'sort':
            sort = value && value.trim() !== '' ? value.trim() : null;
            setApplicationSort(sort);
            continue;
        }
      }

      /**
       * Yes, we have to manually dispatch a fetch here as remember the setting of value of those states are asynchronous
       * and we won't know for sure when are those new state values are updated.
       *
       * Therefore we need to use the data we got from the query string here to construct the query string
       * for the API request.
       */
      const filters = { lenderId: activeLender.id, offset, limit };

      if (search && search.trim() !== '') filters['search'] = search;
      if (status && status.length > 0) filters['status'] = status.join();
      if (dealer && dealer.length > 0) filters['dealer'] = dealer.join();
      if (type && type.length > 0) filters['type'] = type.join();
      if (sort && sort) filters['sort'] = sort;

      dispatch(fetchLenderApplications(filters));
    }

    return () => {
      dispatch(storeApplications([]));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationTypes]);

  useEffect(() => {
    if (shouldFetchApplications) {
      searchApplications();
    }
  }, [shouldFetchApplications]);

  const navBarAction = [
    {
      title: 'Refresh Data',
      action: () => searchApplications(),
      icon: <RefreshIcon />,
    },
  ];

  const searchApplications = (page = undefined) => {
    const filters = {};

    if (searchTerm.trim() !== '') filters['search'] = searchTerm;
    if (statusFilter.length > 0) filters['status'] = statusFilter.join();
    if (dealerFilter.length > 0) filters['dealer'] = dealerFilter.join();
    if (applicationTypeFilter.length > 0) filters['type'] = applicationTypeFilter.join();
    if (applicationSort) filters['sort'] = applicationSort;

    setSearchParams(filters); // Sync filter state to browser url

    dispatch(fetchLenderApplications({ lenderId: activeLender.id, offset: page ? page : offset, limit, ...filters }));
  };

  const resetFilters = () => {
    setSearchParams({}); // Sync filter state to browser url

    dispatch(fetchLenderApplications({ lenderId: activeLender.id, offset: 1, limit }));
  };

  return (
    <>
      <TableContainer>
        <NavBar backButton={LINKS.LENDER.APPLICATIONS.BASE} breadcrumbs={breadcrumbs} actions={navBarAction} />

        <FlexRow borderBottom padding="10px 16px" margin="0" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <TextField
              aria-label="Search"
              id="search"
              placeholder="Search..."
              variant="outlined"
              size="small"
              style={{ width: filterComponentWidth }}
              value={searchTerm}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <MultipleSelectCheckmarks
              id="application-status-filter"
              label="Status"
              size="small"
              width={filterComponentWidth}
              options={Object.keys(STATUS_LABELS)
                .filter((key) => STATUS_LABELS[key] !== 'Saved') // Exclude Saved status
                .map((key) => ({
                  value: STATUS_LABELS[key],
                  label: {
                    icon: getApplicationStatusIcon({ statusObj: STATUS.find((status) => status.label === STATUS_LABELS[key]), size: 'small' }),
                    text: STATUS_LABELS[key],
                  },
                }))}
              selectedOptions={statusFilter}
              setSelectedOptions={setStatusFilter}
            />

            <MultipleSelectCheckmarks
              id="application-dealer-filter"
              label="Dealer"
              size="small"
              width={filterComponentWidth}
              options={activeLender && activeLender.dealers ? activeLender.dealers.map((dealer) => ({ value: dealer.id, label: dealer.name })) : []}
              selectedOptions={dealerFilter}
              setSelectedOptions={setDealerFilter}
            />

            <MultipleSelectCheckmarks
              id="application-type-filter"
              label="Type"
              size="small"
              width={filterComponentWidth}
              options={applicationTypes ? applicationTypes.map((types) => ({ value: types.id, label: types.name })) : []}
              selectedOptions={applicationTypeFilter}
              setSelectedOptions={setApplicationTypeFilter}
            />

            <SortMenu
              options={[
                { value: 'created_at|ASC', label: 'Application Date (ASC)' },
                { value: 'created_at|DESC', label: 'Application Date (DESC)' },
                { value: 'updated_at|ASC', label: 'Last Updated Date (ASC)' },
                { value: 'updated_at|DESC', label: 'Last Updated Date (DESC)' },
              ]}
              selected={applicationSort}
              setSelected={setApplicationSort}
            />
          </Stack>

          <Stack direction="row" spacing={1}>
            {/* When we are searching, we always start at page 1 */}
            <Button variant="outlined" color="primary" onClick={() => searchApplications(1)}>
              Search
            </Button>

            {(searchTerm.trim() !== '' || statusFilter.length > 0 || dealerFilter.length > 0 || applicationTypeFilter.length > 0 || applicationSort) && (
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setSearchTerm('');
                  setStatusFilter([]);
                  setDealerFilter([]);
                  setApplicationTypeFilter([]);
                  setApplicationSort(null);

                  resetFilters();
                }}
              >
                Clear
              </Button>
            )}
          </Stack>
        </FlexRow>

        <StyledDataContainer>
          {applications.map(
            ({ id, applicant, type, dealer, unit, status, trade_unit, co_applicant, metadata, lending_options }, i) =>
              id && (
                <ListItem key={id} id={id} aria-label="Application item" dense disablePadding divider={i < applications.length - 1}>
                  <ListItemButton>
                    <StyledTableRow onClick={() => navigate(LINKS.LENDER.APPLICATIONS.VIEW_ONE(id))}>
                      <StyledCellContainer flexColumn alignItems="flex-start" justifyContent="center">
                        <FlexRow alignItems="center" alignContent="center" padding="0" margin="0">
                          {getApplicantIcon({ hasCoApplicant: co_applicant?.email, size: 'small' })}
                          {(applicant?.first_name || applicant?.last_name) && (
                            <Typography sx={{ fontWeight: 600, ml: '10px' }} variant="subtitle1" noWrap>{`${applicant?.last_name || ''}, ${
                              applicant?.first_name || ''
                            }`}</Typography>
                          )}
                        </FlexRow>

                        <FlexRow alignItems="center" alignContent="center" padding="0" margin="0">
                          {getApplicationTypeIcon({ type: type.name, tradeIn: trade_unit?.vin, size: 'small' })}
                          {unit?.vin && (
                            <Typography sx={{ ml: '10px' }} variant="body2" noWrap>{`${unit?.year || ''} ${unit?.make || ''} ${unit?.model || ''}`}</Typography>
                          )}
                        </FlexRow>
                      </StyledCellContainer>

                      <StyledCellContainer flexColumn alignItems="flex-start" justifyContent="center">
                        <FlexRow alignItems="center" alignContent="center" padding="0" margin="0">
                          <StoreTwoToneIcon fontSize="small" sx={{ color: 'primary.main' }} />
                          {dealer?.name && <Typography sx={{ ml: '10px' }} variant="subtitle1" noWrap>{`${dealer?.name}`}</Typography>}
                        </FlexRow>

                        <FlexRow alignItems="center" alignContent="center" padding="0" margin="0">
                          <TaskIcon fontSize="small" sx={{ color: 'primary.main' }} />
                          {lending_options.find((option) => option.selected_by_dealer) ? (
                            <Typography sx={{ ml: '10px' }} variant="body2" noWrap>
                              {`${currencyFormatter.format(lending_options.find((option) => option.selected_by_dealer).max_amount_loaned)} | ${
                                lending_options.find((option) => option.selected_by_dealer).term
                              } Mo | ${percentageFormatter.format(lending_options.find((option) => option.selected_by_dealer).apr)}`}
                            </Typography>
                          ) : (
                            <Typography sx={{ ml: '10px' }} variant="body2" noWrap>
                              No Options Selected
                            </Typography>
                          )}
                        </FlexRow>
                      </StyledCellContainer>

                      <StyledCellContainer alignItems="center" justifyContent="flex-start">
                        {getApplicationStatusIcon({ statusObj: STATUS.find((stat) => stat.label === status?.name), size: 'large' })}
                      </StyledCellContainer>

                      <StyledCellContainer flexColumn alignItems="flex-end" justifyContent="center">
                        <Typography variant="caption" noWrap>
                          Updated: {format(parseISO(metadata.updated_at), 'PPp')}
                        </Typography>

                        <Typography variant="caption" noWrap>
                          Created: {format(parseISO(metadata.created_at), 'PPp')}
                        </Typography>
                      </StyledCellContainer>
                    </StyledTableRow>

                    <StyledCellContainer>
                      {getActions(
                        id,
                        applications.find((application) => application.id === id),
                        navigate,
                        handleModalOpen,
                      )}
                    </StyledCellContainer>
                  </ListItemButton>
                </ListItem>
              ),
          )}
        </StyledDataContainer>

        <StyledPaginationContainer>
          <Pagination
            page={offset}
            count={Math.ceil(applicationsMetadata.count / limit)}
            variant="outlined"
            shape="rounded"
            onChange={(event, page) => {
              if (page !== offset) {
                setOffset(page);
                searchApplications(page);
              }
            }}
          />
        </StyledPaginationContainer>
      </TableContainer>

      {selectedApplication && selectedApplication.stipulations && selectedApplication.stipulations.every((s) => s.fulfilled) ? (
        <DoubleConfirmNoButton
          title="Are you sure?"
          description={`Marking this application as "${selectedApplication.status.name === FUNDING_REVIEW ? 'Funded' : 'Servicing'}" is irreversible.`}
          action={() => {
            /**
             * ! Warning
             * ! We are hardcoding and assuming that the id of application status of "Funded" is 10 and "Servicing" is 11.
             *
             * TODO: Properly fetch application status from DB so we can use the actual id of the application status on the DB.
             */
            const application_status_id =
              selectedApplication.status.name === FUNDING_REVIEW
                ? // Funding Review -> Funded
                  10
                : selectedApplication.status.name === FUNDED
                ? // Funded -> Servicing
                  11
                : null;

            if (application_status_id) {
              dispatch(updateApplicationStatus({ data: { application_status_id }, applicationId: selectedApplication.id }));
            }
          }}
          open={modalOpen}
          onClose={handleModalClose}
        />
      ) : null}
    </>
  );
};

/**
 * Get available actions per application per application status.
 */
const getActions = (id, application, navigate, handleModalOpen) => {
  if (Array.isArray(application) && application.length <= 0) return [];

  const appStatus = application && application.status ? application.status.name : null;
  const allStipsFulfilled = application && application.stipulations ? application.stipulations.every((s) => s.fulfilled) : false;
  const primaryPaymentProfile = application?.payment_profiles
    ? application.payment_profiles.find(
        ({ profile_type, institution_name, account_type, account_name, account_number, routing_number, verification_status }) => {
          if (
            profile_type === 1 &&
            institution_name &&
            account_type &&
            account_name &&
            account_number &&
            routing_number &&
            [PRE_PLAID, AUTOMATICALLY_VERIFIED, MANUALLY_VERIFIED, VERIFIED].includes(verification_status)
          ) {
            return true;
          } else {
            return false;
          }
        },
      )
    : null;

  let actions = [
    {
      icon: <VisibilityIcon />,
      value: 'View Application',
      callback: () => navigate(LINKS.LENDER.APPLICATIONS.VIEW_ONE(id)),
    },
  ];

  if (allStipsFulfilled && primaryPaymentProfile && [FUNDING_REVIEW, FUNDED].includes(appStatus)) {
    if (appStatus === FUNDING_REVIEW) {
      actions.push({
        icon: <PaidIcon />,
        value: 'Mark as "Funded"',
        callback: () => handleModalOpen(application),
      });
    }

    if (appStatus === FUNDED) {
      actions.push({
        icon: <CloudDoneIcon />,
        value: 'Mark as "Servicing"',
        callback: () => handleModalOpen(application),
      });
    }
  }

  return <ActionMenu options={actions.filter((action) => !action.forStatus || action.forStatus.includes(application.status.name))} />;
};

export default Applications;
