import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { FlexRow } from 'components/Layouts';
import Sidebar from './sidebar';
import { useEffect } from 'react';
import { LINKS } from 'constants/menus';

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
`;

const LenderSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === LINKS.LENDER.SETTINGS.BASE) {
      navigate(LINKS.LENDER.SETTINGS.ORGANIZATION_DETAILS, {
        replace: true,
      });
    }
  }, []);

  return (
    <ContentContainer>
      <Sidebar />

      <FlexRow alignItems="center" overflowScroll fullHeight flexColumn padding="20px">
        <Outlet />
      </FlexRow>
    </ContentContainer>
  );
};

export default LenderSettings;
