import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Button, CircularProgress, Tooltip } from '@mui/material';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';

import { useFormie } from 'components/Formie';
import { FlexRow } from 'components/Layouts';
import { setAlert } from 'store/sagas/app';
import Accordion from 'components/Accordion';
import ConfigurationDrawer from './ConfigurationDrawer';
import { configureLoanConfiguration } from '../../Onboarding/formInfo';
import { fetchLoanThunk, generateTermsThunk } from 'store/thunks/loan';
import { currencyFormatter, percentageFormatterV2, formatDateOnly, parseDateOnlyForInputs } from 'constants/formatters';
import { columns, parsePaymentFrequency, TilaCard, SettingValueWithDescription } from './helpers';
import { COLORS } from 'constants/styles';

const SetupTerms = ({ rehydrateOnboarding }) => {
  const dispatch = useDispatch();

  const { loanId } = useParams();
  const [open, setOpen] = useState(false);
  const [loan, setLoan] = useState({});
  const [loading, setLoading] = useState(true);

  const hydrateData = async () => {
    setLoading(true);
    try {
      const { loan } = await dispatch(
        fetchLoanThunk({
          subdata: ['payment-periods'],
          loanId,
          limit: 1000,
        }),
      ).unwrap();
      setLoan(loan);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to fetch loan. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanId) {
      hydrateData();
    }
  }, [loanId]);

  const handleClose = () => {
    setOpen(false);
  };

  const generateTerms = async () => {
    setLoading(true);
    const contractDate = formie?.values?.configuration?.contract_date && format(parseISO(formie?.values?.configuration?.contract_date), 'yyyy-MM-dd');
    const firstPaymentDate =
      formie?.values?.configuration?.first_payment_date && format(parseISO(formie?.values?.configuration?.first_payment_date), 'yyyy-MM-dd');
    try {
      await dispatch(
        generateTermsThunk({
          data: {
            loan_id: loanId,
            principal_amount: formie?.values?.configuration?.principal_amount,
            interest_rate: formie?.values?.configuration?.interest_rate,
            terms: formie?.values?.configuration?.terms,
            contract_date: contractDate,
            first_payment_date: firstPaymentDate,
            schedule_round: formie?.values?.configuration?.schedule_round,
            down_payment_amount: formie?.values?.configuration?.down_payment_amount,
            payment_amount: formie?.values?.configuration?.payment_amount,
            interest_calculation_type: formie?.values?.configuration?.interest_calculation_type,
            payment_frequency: formie?.values?.configuration?.payment_frequency,

            type: 'installment',
            calculation_type: 'simple-interest',
          },
        }),
      ).unwrap();
      await rehydrateOnboarding();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to generate terms. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleStartEdit = () => {
    setOpen(true);
    formie.setAllValues({
      configuration: {
        principal_amount: loan?.principal_amount && Number(loan?.principal_amount),
        interest_rate: loan?.interest_rate && Number(loan?.interest_rate),
        terms: loan?.terms,
        contract_date: parseDateOnlyForInputs(loan?.contract_date),
        first_payment_date: parseDateOnlyForInputs(loan?.first_payment_date),
        schedule_round: loan?.schedule_round && Number(loan?.schedule_round),
        payment_frequency: loan?.payment_frequency,
        interest_calculation_type: loan?.interest_calculation_type,
        down_payment_amount: loan?.down_payment_amount && Number(loan?.down_payment_amount),
      },
    });
  };

  const formie = useFormie({
    configuration: configureLoanConfiguration,
    initialValues: {
      configuration: {
        principal_amount: loan?.principal_amount && Number(loan?.principal_amount),
        interest_rate: loan?.interest_rate && Number(loan?.interest_rate),
        terms: loan?.terms,
        contract_date: parseDateOnlyForInputs(loan?.contract_date),
        first_payment_date: parseDateOnlyForInputs(loan?.first_payment_date),
        schedule_round: loan?.schedule_round && Number(loan?.schedule_round),
        payment_frequency: loan?.payment_frequency,
        interest_calculation_type: loan?.interest_calculation_type,
        down_payment_amount: loan?.down_payment_amount && Number(loan?.down_payment_amount),
      },
    },
    setLoading,
    onSubmit: generateTerms,
  });

  const { handleSubmit } = formie;

  const rows = loan?.payment_periods
    ? loan.payment_periods
        .map((period) => ({
          id: period.period_number,
          date: period.due_date,
          startingPrincipal: period.starting_principal_amount,
          accruedInterest: period.accrued_interest,
          payment: period.payment_amount,
          interestPayment: period.interest_payment_amount,
          principalPayment: period.principal_payment_amount,
          endingPrincipal: period.ending_principal_amount,
        }))
        .sort((a, b) => a.id - b.id)
    : [];

  const finalPaymentPeriod = rows && rows?.length > 0 ? rows[rows.length - 1] : {};
  const disabled = loan?.active;

  return loading ? (
    <FlexRow margin="0px" padding="40px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </FlexRow>
  ) : (
    <>
      <FlexRow padding="0px" margin="0px 0px 8px" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Loan Terms</Typography>
        <Tooltip placement="left" title={disabled ? 'Loan terms cannot be configured when the loan is active.' : ''}>
          <span>
            <Button disabled={disabled} onClick={handleStartEdit} startIcon={<ConstructionOutlinedIcon />}>
              Configure
            </Button>
          </span>
        </Tooltip>
      </FlexRow>

      {/* TILA Terms */}
      <Grid container spacing={2} columns={20}>
        {[
          { label: 'APR', value: loan?.apr ? percentageFormatterV2(loan?.apr, 4) : '-' },
          { label: 'Finance Charge', value: loan?.finance_charge ? currencyFormatter.format(loan?.finance_charge) : '-' },
          { label: 'Amount Financed', value: loan?.principal_amount ? currencyFormatter.format(loan?.principal_amount) : '-' },
          { label: 'Total of Payments', value: loan?.total_payments ? currencyFormatter.format(loan?.total_payments) : '-' },
          { label: 'Total Sales Price', value: loan?.total_sales_price ? currencyFormatter.format(loan?.total_sales_price) : '-' },
        ].map((tila) => (
          <Grid key={`tilaCard-${tila.label}`} xs={4}>
            <TilaCard label={tila.label} value={tila.value} />
          </Grid>
        ))}
      </Grid>

      {/* Card with amortization schedule.  */}
      <Typography sx={{ mt: '20px' }} variant="h6">
        Amortization
      </Typography>
      <div style={{ height: '345px', background: COLORS.surfaceColor }}>
        <DataGrid disableColumnSelector disableDensitySelector disableRowSelectionOnClick disableColumnMenu density="compact" columns={columns} rows={rows} />
      </div>

      <Accordion defaultExpanded sx={{ mt: '20px' }} titleVariant="h5" summaryTitle="Details">
        {[
          {
            label: 'Contract Date',
            description: 'The date the contract was signed.',
            value: loan?.contract_date ? formatDateOnly(loan.contract_date) : '-',
          },
          {
            label: 'Payments Start',
            description: 'The date the first payment is due.',
            value: loan?.first_payment_date ? formatDateOnly(loan.first_payment_date) : '-',
          },
          {
            label: 'Payments End',
            description: 'The date of the final scheduled payment.',
            value: loan?.last_payment_date ? formatDateOnly(loan.last_payment_date) : '-',
          },
          {
            label: 'Payment Frequency',
            description:
              'How often payments will be made. Options are Monthly, Bi-Monthly (2x per month), Bi-Weekly (every 14 days), and Weekly (every 7 days).',
            value: parsePaymentFrequency(loan.payment_frequency),
          },
          { label: 'Number of Payments', description: 'How many total payments will be made.', value: loan?.terms || '-' },
          {
            label: 'Interest Rate',
            description: 'The annual interest rate of the loan. This is not the APR.',
            value: loan?.interest_rate ? percentageFormatterV2(loan.interest_rate) : '-',
          },
          {
            label: 'Principal Amount',
            value: loan?.principal_amount ? currencyFormatter.format(loan.principal_amount) : '-',
            description: 'The original amount of money loaned.',
          },
          {
            label: 'Down Payment Amount',
            value: loan?.down_payment_amount ? currencyFormatter.format(loan.down_payment_amount) : currencyFormatter.format(0),
            description: 'The amount of money the customer paid at the time of signing. This is used in calculating the total sales price TILA term.',
          },
          {
            label: 'Payment Amount',
            value: loan?.payment_amount ? currencyFormatter.format(loan.payment_amount) : '-',
            description: 'The regular monthly payment amount of the loan.',
          },
          {
            label: 'Last Payment Amount',
            value: loan?.last_payment_amount ? currencyFormatter.format(loan.last_payment_amount) : '-',
            description: 'The final monthly payment amount. This will often be different from the regular monthly payment amount.',
          },
          {
            label: 'Rounding',
            value: loan?.schedule_round ? currencyFormatter.format(loan.schedule_round) : '-',
            description:
              'This is used to adjust the Last Payment Amount. This is commonly used when the Lender wants the final monthly payment amount to match the regular monthly payment amount.',
          },
          {
            noDivider: true,
            label: 'Final Principal Amount',
            value: finalPaymentPeriod?.endingPrincipal ? currencyFormatter.format(finalPaymentPeriod.endingPrincipal) : '-',
            description:
              'After all the payments have made, what will the ending principal amount be? Ideally this will be $0.00, but depending on the rounding setting chosen by the lender the loan might end without all principal being repaid.',
          },
        ].map((setting) => (
          <SettingValueWithDescription
            key={setting.label}
            label={setting.label}
            noDivider={setting.noDivider}
            widerValue={setting.widerValue}
            description={setting.description}
            value={setting.value}
          />
        ))}
      </Accordion>

      <Accordion defaultExpanded titleVariant="h5" sx={{ mt: '20px' }} summaryTitle="Advanced Details">
        {[
          {
            label: 'Interest Calculation Type',
            value: loan?.interest_calculation_type,
            description:
              'This setting controls how daily interest is applied throughout the loan. The supported options are 30/360 and Actual/360. 30/360 divides the annual interest rate by 12 to find the monthly interest rate. It then divides the monthly interest rate by the number of days in the payment period to find the daily interest rate. Actual/360 divides the annual interest rate by 360 to find the daily interest rate.',
          },
          {
            label: 'Calculation Type',
            value: loan?.calculation_type === 'simple-interest' ? 'Simple Interest' : '-',
            description:
              'The supported options are Simple Interest and Interest Only. Interest Only loans only require repayment of the interest each payment period, until the final payment period where the entire balance is due. Simple Interest will collect roughly equal installment payments until the entire principal balance is paid.',
          },
          {
            label: 'First Day Interest',
            value: loan?.first_day_interest ? 'Yes' : 'No',
            description: 'Should the system charge interest on the contract date of the loan? We default to yes.',
          },
          {
            label: 'Last as Final',
            value: loan?.last_as_final ? 'Yes' : 'No',
            description:
              'Should the last payment be treated as the final payment? Meaning - should the final payment pay off all remaining fees and principal (as allowed by the rounding setting). We default to yes.',
          },
          {
            label: 'Beginning/End',
            value: loan?.beginning_end === 'end' ? 'End' : '-',
            description: 'Should interest by charged at the beginning or the end of the payment period? We default to end.',
          },
          {
            label: 'Schedule Smoothing',
            value: loan?.schedule_smoothing === 'basic' ? 'Basic' : '-',
            description:
              'If the first payment period is longer than a standard payment period the customer will incur excess interest during the first period. Schedule smoothing collects this excess payment a little bit each payment rather than having the first payment be extra large. The "Basic" option calculates this quickly with high precision as opposed to using an iterative approach that takes longer for a minimal gain in accuracy.',
          },
          {
            label: 'Odd Days',
            value: loan?.odd_days,
            description: 'How many odd days are in the first payment period when calculated using the approach prescribed by Regulation Z.',
          },
          {
            label: 'Unit Periods',
            value: loan?.unit_periods,
            description: 'How many unit periods are in the first payment period when calculated using the approach prescribed by Regulation Z.',
            noDivider: true,
          },
        ].map((setting) => (
          <SettingValueWithDescription
            key={setting.label}
            label={setting.label}
            noDivider={setting.noDivider}
            widerValue={setting.widerValue}
            description={setting.description}
            value={setting.value}
          />
        ))}
      </Accordion>

      <ConfigurationDrawer open={open} handleClose={handleClose} handleSubmit={handleSubmit} formie={formie} />
    </>
  );
};

export default SetupTerms;
