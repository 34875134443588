import { Chip } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { formatDateOnly, formatCurrencyForDataGrid } from 'constants/formatters';

export const determinePeriodStatus = (period, returnChip, timezone) => {
  let dateStatus = '';
  let status = '';

  const todayInBrowserTimeZone = new Date();
  const todayInLoansTimeZone = formatInTimeZone(todayInBrowserTimeZone, timezone, 'yyyy-MM-dd');
  const daysUntilDueDate = differenceInCalendarDays(new Date(period.due_date), new Date(todayInLoansTimeZone));

  if (daysUntilDueDate === 0) {
    status = 'Due Today';
    dateStatus = 'Due Today';
  } else if (daysUntilDueDate < 0) dateStatus = 'Past Due';
  else if (daysUntilDueDate > 0) {
    status = 'Upcoming';
    dateStatus = 'Upcoming';
  }

  if (dateStatus === 'Past Due') {
    const hasPastDue = !!period.payment_period_past_due?.id;
    const hasPaid = Number(period.amount_paid) >= Number(period.payment_amount);

    // If there is no past due, and amount_paid === amount_due, then the status is "Paid"
    // If there is a past due, and amount_paid === amount_due, then the status is "Paid Late"
    // If there is a past due, and amount_paid !== amount_due, then the status is "Past Due"
    if (hasPastDue) {
      status = hasPaid ? 'Paid Late' : 'Past Due';
    } else if (!hasPastDue && hasPaid) {
      status = 'Paid On Time';
    }
  }

  if (returnChip) {
    if (status === 'Paid Late') return <Chip sx={{ ml: '8px' }} size="small" label={status} color="warning" />;
    else if (status === 'Paid On Time') return <Chip sx={{ ml: '8px' }} size="small" label={status} color="success" />;
    else if (status === 'Past Due') return <Chip sx={{ ml: '8px' }} size="small" label={status} color="error" />;
    else if (status === 'Due Today') return <Chip sx={{ ml: '8px' }} size="small" variant="outlined" label={status} color="info" />;
    else if (status === 'Upcoming') return <Chip sx={{ ml: '8px' }} size="small" variant="outlined" label={status} />;
  }

  return status;
};

export const columns = [
  {
    field: 'period_number',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: '#',
    width: 50,
  },
  {
    field: 'due_date',
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    headerName: 'Due Date',
    flex: 1,
    valueFormatter: (params) => formatDateOnly(params.value),
  },
  {
    field: 'amount_due',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Amount Due',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'amount_paid',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Amount Paid',
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'status',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Status',
    flex: 1,
    renderCell: (props) => {
      if (props.value === 'Paid Late') return <Chip size="small" label={props.value} color="warning" />;
      else if (props.value === 'Paid On Time') return <Chip size="small" label={props.value} color="success" />;
      else if (props.value === 'Past Due') return <Chip size="small" label={props.value} color="error" />;
      else if (props.value === 'Due Today') return <Chip size="small" variant="outlined" label={props.value} color="info" />;
      else if (props.value === 'Upcoming') return <Chip size="small" variant="outlined" label={props.value} />;
    },
  },
];
