import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import { updateLenderUser } from 'store/sagas/admin';
import { buildFormikFields, useBuildFormik } from 'components/Formiks - Deprecated';
import { userFormInfo } from './formInfo';

const validatePassword = (password, passwordConfirm) => {
  const minLength = 8;
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSymbol = /[^a-zA-Z0-9]/.test(password);
  const passwordsMatch = !!password && password == passwordConfirm;

  return {
    isMinLength: password.length >= minLength,
    hasLowerCase,
    hasUpperCase,
    hasNumber,
    hasSymbol,
    passwordsMatch,
  };
};

const ResetPasswordModal = ({ open, onClose, lenderId, userId, currentUserId }) => {
  const dispatch = useDispatch();

  const formik = useBuildFormik({
    formInfo: Object.values(userFormInfo),
    onSubmit: (values) => handleSubmit(values),
    validate: (incomingVals) => {
      let errors = {};

      if (!incomingVals.password || !isMinLength || !hasLowerCase || !hasUpperCase || !hasNumber || !hasSymbol || !passwordsMatch) {
        errors.password = 'Password is not valid';
      }

      return errors;
    },
  });

  const { values } = formik;

  const { isMinLength, hasLowerCase, hasUpperCase, hasNumber, hasSymbol, passwordsMatch } = validatePassword(values.password, values.confirm_password);

  const handleSubmit = (values) => {
    const { confirm_password, password } = values;

    dispatch(updateLenderUser({ lenderId, logOutAfterUpdate: userId === currentUserId, userId, data: { password } }));

    onClose();
  };

  const { password, confirm_password } = userFormInfo;

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={open}>
      <DialogTitle>Reset Password</DialogTitle>

      <DialogContent>
        <Typography sx={{ marginTop: '20px' }} variant="h6">
          New Password
        </Typography>
        {buildFormikFields({
          formik,
          fields: [password, confirm_password],
          fieldsPerRow: 2,
        })}

        <FormGroup sx={{ marginTop: '10px' }}>
          {[
            { complete: isMinLength, label: 'Has a minimum length of 8 characters.' },
            { complete: hasLowerCase, label: 'Has a lowercase letter.' },
            { complete: hasUpperCase, label: 'Has an uppercase letter.' },
            { complete: hasNumber, label: 'Has a number.' },
            { complete: hasSymbol, label: 'Has a special character.' },
            { complete: passwordsMatch, label: 'Passwords match.' },
          ].map(({ complete, label }) => (
            <FormControlLabel
              key={label}
              disabled={!complete}
              control={<Checkbox sx={{ m: 0, height: '20px' }} disableRipple size="small" checked={complete} />}
              label={label}
            />
          ))}
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={formik.handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordModal;
