export const PLAID_AUTH_STATUS = {
  PRE_PLAID: 'pre_plaid', // The time before we integrate to plaid
  PENDING_AUTOMATIC_VERIFICATION: 'pending_automatic_verification', // Not utilized as we are not supporting Automatic microdeposit auth type.
  PENDING_MANUAL_VERIFICATION: 'pending_manual_verification',
  AUTOMATICALLY_VERIFIED: 'automatically_verified', // Not utilized as we are not supporting Automatic micro-deposit auth type.
  MANUALLY_VERIFIED: 'manually_verified',
  VERIFIED: 'verified', // Automatic and instant verification.
  VERIFICATION_EXPIRED: 'verification_expired',
  VERIFICATION_FAILED: 'verification_failed',
};
