import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import DeleteIcon from '@mui/icons-material/Delete';

import { fetchApplicationById } from 'store/sagas/applications';
import { deleteApplicationReferenceThunk } from 'store/thunks/application';

const DeleteReferenceConfirmModal = ({ applicationId, referenceId, firstName, lastName, phone, email, onClose }) => {
  const dispatch = useDispatch();

  const [hasErrors, setHasErrors] = useState(false);
  const [deleting, setDeleting] = useState(false);

  return (
    <Dialog disableEscapeKeyDown scroll="paper" fullWidth open={true}>
      <DialogTitle>
        <Stack direction={'row'} spacing={1}>
          <NoAccountsIcon sx={{ fontSize: '2rem' }} />
          <Typography variant="h6">Confirm Action</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        {hasErrors && <Alert severity="error">Failed to delete reference.</Alert>}

        <DialogContentText>Are you sure you want to delete this reference?</DialogContentText>
        <br />
        <DialogContentText>First Name: {firstName}</DialogContentText>
        <DialogContentText>Last Name: {lastName}</DialogContentText>
        <DialogContentText>Phone: {phone}</DialogContentText>
        <DialogContentText>Email: {email}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button disabled={deleting} variant="outlined" color="primary" onClick={() => onClose()}>
          Cancel
        </Button>

        <LoadingButton
          loading={deleting}
          loadingPosition="start"
          startIcon={<DeleteIcon />}
          variant="contained"
          color="error"
          onClick={async () => {
            try {
              setHasErrors(false);
              setDeleting(true);

              await dispatch(deleteApplicationReferenceThunk({ applicationId, referenceId })).unwrap();

              setDeleting(false);

              dispatch(fetchApplicationById(applicationId)); // Fetch latest current application data

              onClose();
            } catch (err) {
              setDeleting(false);
              setHasErrors(true);
              console.log('Deleting application reference error: ', err);
            }
          }}
        >
          {deleting ? 'Deleting...' : 'Confirm'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteReferenceConfirmModal;
