import { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import SaveIcon from '@mui/icons-material/Save';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddCardIcon from '@mui/icons-material/AddCard';
import { differenceInDays, formatISO, parseISO } from 'date-fns';

import NavBar from 'components/NavBar';
import { UnderNavBarContainer, FlexRow } from 'components/Layouts';
import { storeApplication, fetchApplicationById, updateApprovedApplication } from 'store/sagas/applications';
import { LINKS } from 'constants/menus';
import { STATUS_LABELS } from 'constants/status';
import { useFormie } from 'components/Formie';

import { buildBreadcrumbs, calculateProgress, createAllValues } from './utils';
import { prepareContractFormConfiguration, initialValues } from './formInfo';
import Sidebar from './sidebar';
import InsuranceInformation from './insuranceInformation';
import OtherCharges from './otherCharges';
import ContractInformation from './contractInformation';
import UnitUnpaidBalance from './unitUnpaidBalance';

import { useIsMount } from './../utils';
import LendingOptions from './../ViewApp/LendingOptions';
import { SECTIONS as VIEW_APP_SECTIONS } from '../ViewApp';
import { roundAccurately } from 'constants/formatters';

export const SECTIONS = {
  LENDING_OPTIONS: 'lending_options',
  UNPAID_BALANCE: 'unpaid_balance',
  OTHER_CHARGES: 'other_charges',
  OVERVIEW: 'overview',
  INSURANCE_INFORMATION: 'insurance_information',
};

const ContractPrep = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentApplication = useSelector((state) => state.applications.currentApplication);
  const [initialLoad, setInitialLoad] = useState(true); // Flag that determines this is the first load of the component
  const [showESC, setShowESC] = useState(false);
  const [showPPM, setShowPPM] = useState(false);
  const [showGAP, setShowGAP] = useState(false);
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [shouldExit, setShouldExit] = useState(false);
  const [toPaymentProfile, setToPaymentProfile] = useState(false);
  const [customFieldsArray, setCustomFieldsArray] = useState([null]);
  const { id: urlAppId } = useParams();
  const [searchParams] = useSearchParams();
  const activeSection = searchParams.get('section');
  const [backButton, breadcrumbs] = buildBreadcrumbs({ currentApplication });
  let isMount = useIsMount();
  const selectedLendingOption =
    currentApplication.lending_options && currentApplication.lending_options.length
      ? currentApplication.lending_options.find((opt) => opt.selected_by_dealer)
        ? currentApplication.lending_options.find((opt) => opt.selected_by_dealer)
        : {}
      : {};
  const formie = useFormie({
    configuration: prepareContractFormConfiguration,
    initialValues,
  });
  const { values } = formie;
  const { progress, incompleteActiveRequiredFields } = calculateProgress({
    values,
    showESC,
    showPPM,
    showGAP,
    showCustomFields,
    customFieldsArray,
  });
  let allValues = createAllValues({ values, showESC, showPPM, showGAP, showCustomFields, selectedLendingOption, progress });

  const resetLocalState = () => {
    setShowESC(false);
    setShowPPM(false);
    setShowGAP(false);
    setShowCustomFields(false);
    setShouldExit(false);
    setCustomFieldsArray([null]);
  };

  const addCustomField = () => {
    const length = customFieldsArray.length;
    if (length < 4) {
      setCustomFieldsArray(Array.from({ length: length + 1 }, () => null));
    }
  };

  const removeCustomField = () => {
    const length = customFieldsArray.length;
    if (length > 0) {
      formie.removeCustomFieldValues(length);
      setCustomFieldsArray(Array.from({ length: length - 1 }, () => null));
    }
  };

  const storeFromCurrentApplication = () => {
    if (shouldExit) navigate(LINKS.DEALER.VIEW_APPLICATION(urlAppId));

    if (toPaymentProfile) navigate(LINKS.DEALER.VIEW_APPLICATION(urlAppId, VIEW_APP_SECTIONS.PAYMENT_PROFILES));

    resetLocalState();

    const { contract_values, dates, unit, trade_unit, applicant, co_applicant, signing } = currentApplication;

    let hasCF1, hasCF2, hasCF3, hasCF4;

    /**
     * !Important
     * The idea is, after initial load, the form components we have on the UX should contain the latest data.
     * Therefore it has higher precedence over data coming from db.
     * This means data that is not saved yet to the db.
     */
    if (!initialLoad && values && values.contract_values) {
      setShowESC(values.contract_values.esc_cost || values.contract_values.esc_term || values.contract_values.esc_company ? true : false);
      setShowPPM(values.contract_values.ppm_cost || values.contract_values.ppm_term || values.contract_values.ppm_company ? true : false);
      setShowGAP(values.contract_values.gap_cost || values.contract_values.gap_term || values.contract_values.gap_company ? true : false);

      hasCF1 =
        values.contract_values.custom_field_1_for || values.contract_values.custom_field_1_to || values.contract_values.custom_field_1_cost ? true : false;
      hasCF2 =
        values.contract_values.custom_field_2_for || values.contract_values.custom_field_2_to || values.contract_values.custom_field_2_cost ? true : false;
      hasCF3 =
        values.contract_values.custom_field_3_for || values.contract_values.custom_field_3_to || values.contract_values.custom_field_3_cost ? true : false;
      hasCF4 =
        values.contract_values.custom_field_4_for || values.contract_values.custom_field_4_to || values.contract_values.custom_field_4_cost ? true : false;
    } else {
      setShowESC(!!(contract_values && (contract_values.esc_cost || contract_values.esc_term || contract_values.esc_company)));
      setShowPPM(!!(contract_values && (contract_values.ppm_cost || contract_values.ppm_term || contract_values.ppm_company)));
      setShowGAP(!!(contract_values && (contract_values.gap_cost || contract_values.gap_term || contract_values.gap_company)));

      hasCF1 = !!(contract_values && (contract_values.custom_field_1_for || contract_values.custom_field_1_to || contract_values.custom_field_1_cost));
      hasCF2 = !!(contract_values && (contract_values.custom_field_2_for || contract_values.custom_field_2_to || contract_values.custom_field_2_cost));
      hasCF3 = !!(contract_values && (contract_values.custom_field_3_for || contract_values.custom_field_3_to || contract_values.custom_field_3_cost));
      hasCF4 = !!(contract_values && (contract_values.custom_field_4_for || contract_values.custom_field_4_to || contract_values.custom_field_4_cost));
    }

    if (hasCF1 || hasCF2 || hasCF3 || hasCF4) {
      setShowCustomFields(true);
      setCustomFieldsArray(Array.from({ length: hasCF1 + hasCF2 + hasCF3 + hasCF4 }, () => null));
    }

    formie.setAllValues({
      applicant,
      co_applicant,
      unit,
      trade_unit,
      contract_values:
        !initialLoad && values && values.contract_values
          ? /**
             * !Important
             * The idea is, after initial load, the form components we have on the UX should contain the latest data.
             * Therefore it has higher precedence over data coming from db.
             * This means data that is not saved yet to the db.
             */
            {
              ...contract_values,
              // Un-paid Balance
              sales_tax: values.contract_values.sales_tax,
              cash_down: values.contract_values.cash_down,

              // Other Charges
              // -- Government Fees
              title_fees: values.contract_values.title_fees,
              doc_fee: values.contract_values.doc_fee,
              license_reg_fees: values.contract_values.license_reg_fees,
              // -- Extended Service Contract
              esc_cost: values.contract_values.esc_cost,
              esc_term: values.contract_values.esc_term,
              esc_company: values.contract_values.esc_company,
              // -- Prepaid Maintenance Contract
              ppm_cost: values.contract_values.ppm_cost,
              ppm_term: values.contract_values.ppm_term,
              ppm_company: values.contract_values.ppm_company,
              // -- GAP Contract
              gap_cost: values.contract_values.gap_cost,
              gap_term: values.contract_values.gap_term,
              gap_company: values.contract_values.gap_company,
              // -- Custom Fields
              custom_field_1_for: values.contract_values.custom_field_1_for,
              custom_field_1_to: values.contract_values.custom_field_1_to,
              custom_field_1_cost: values.contract_values.custom_field_1_cost,
              custom_field_2_for: values.contract_values.custom_field_2_for,
              custom_field_2_to: values.contract_values.custom_field_2_to,
              custom_field_2_cost: values.contract_values.custom_field_2_cost,
              custom_field_3_for: values.contract_values.custom_field_3_for,
              custom_field_3_to: values.contract_values.custom_field_3_to,
              custom_field_3_cost: values.contract_values.custom_field_3_cost,
              custom_field_4_for: values.contract_values.custom_field_4_for,
              custom_field_4_to: values.contract_values.custom_field_4_to,
              custom_field_4_cost: values.contract_values.custom_field_4_cost,

              // Insurance Information
              insurance_company: values.contract_values.insurance_company,
              insurance_agent: values.contract_values.insurance_agent,
              insurance_company_phone: values.contract_values.insurance_company_phone,
            }
          : contract_values,
      dates: {
        ...dates,
        contract_date: dates?.contract_date || formatISO(new Date()),
      },
      signing,
      helper_values: {
        days_to_first_payment:
          dates?.contract_date && dates?.payment_start_date ? differenceInDays(parseISO(dates.payment_start_date), parseISO(dates.contract_date)) : 30,
      },
    });
  };

  useEffect(() => {
    if (urlAppId != 'new') {
      dispatch(fetchApplicationById(urlAppId));
    } else {
      dispatch(storeApplication({}));
    }

    return () => {
      dispatch(storeApplication({}));
    };
  }, [urlAppId]);

  useEffect(() => {
    if (!isMount) {
      if (currentApplication?.status?.name && currentApplication?.status?.name != STATUS_LABELS.APPROVED) {
        navigate(LINKS.DEALER.VIEW_APPLICATION(urlAppId));
      } else {
        storeFromCurrentApplication();
        setInitialLoad(false); // After loading application data from db, mark initial load to false.
      }
    }
  }, [currentApplication]);

  const save = ({ exit, toPaymentProfile }) => {
    setShouldExit(exit);
    setToPaymentProfile(toPaymentProfile);

    const trade_allowance = allValues?.trade_unit?.trade_allowance;
    const payoff_amount = allValues?.trade_unit?.payoff_amount;
    const trade_unit = {
      lienholder: allValues?.trade_unit?.lienholder,
      payoff_amount,
      trade_allowance,
      equity: roundAccurately(trade_allowance - payoff_amount, 2),
    };

    const data = {
      contract_values: allValues.contract_values,
      unit: {
        price: allValues.unit?.price,
      },
      ...(allValues?.trade_unit?.id ? trade_unit : {}),
      dates: {
        contract_date: allValues?.dates?.contract_date,
        payment_start_date: allValues?.dates?.payment_start_date,
        last_payment_date: allValues?.dates?.last_payment_date,
      },
      signing: allValues?.signing,
    };

    dispatch(
      updateApprovedApplication({
        applicationId: urlAppId,
        data,
      }),
    );
  };

  const navBarActions = [
    {
      title: 'Continue',
      action: () => save({ toPaymentProfile: true }),
      icon: <AddCardIcon size="small" />,
      disabled: !allValues.helper_values.ready_to_continue,
    },
    {
      title: 'Save',
      action: () => save({ exit: false }),
      icon: <SaveIcon size="small" />,
    },
    {
      title: 'Save & Exit',
      action: () => save({ exit: true }),
      icon: <ExitToAppIcon size="small" />,
    },
  ];

  return (
    <Fragment>
      <NavBar backButton={backButton} breadcrumbs={breadcrumbs} actions={navBarActions} />
      <LinearProgress variant="determinate" value={progress * 100} />
      <UnderNavBarContainer padding="0px" withProgress>
        <FlexRow fullHeight padding="0px">
          <Sidebar appId={urlAppId} activeSection={activeSection} SECTIONS={SECTIONS} />

          <FlexRow alignItems="center" overflowScroll fullHeight flexColumn padding="20px 20px 0px">
            <ContractInformation formie={formie} allValues={allValues} incompleteActiveRequiredFields={incompleteActiveRequiredFields} progress={progress} />
            {(activeSection === SECTIONS.OVERVIEW || activeSection === SECTIONS.LENDING_OPTIONS) && (
              <LendingOptions
                canEdit={currentApplication?.status?.name == STATUS_LABELS.APPROVED}
                lendingOptions={currentApplication.lending_options ? currentApplication.lending_options : []}
              />
            )}
            {(activeSection === SECTIONS.OVERVIEW || activeSection === SECTIONS.UNPAID_BALANCE) && <UnitUnpaidBalance allValues={allValues} formie={formie} />}
            {(activeSection === SECTIONS.OVERVIEW || activeSection === SECTIONS.OTHER_CHARGES) && (
              <OtherCharges
                allValues={allValues}
                showESC={showESC}
                setShowESC={setShowESC}
                showPPM={showPPM}
                setShowPPM={setShowPPM}
                showGAP={showGAP}
                setShowGAP={setShowGAP}
                showCustomFields={showCustomFields}
                setShowCustomFields={setShowCustomFields}
                customFieldsArray={customFieldsArray}
                addCustomField={addCustomField}
                removeCustomField={removeCustomField}
                formie={formie}
              />
            )}

            {(activeSection === SECTIONS.OVERVIEW || activeSection === SECTIONS.INSURANCE_INFORMATION) && <InsuranceInformation formie={formie} />}
          </FlexRow>
        </FlexRow>
      </UnderNavBarContainer>
    </Fragment>
  );
};

export default ContractPrep;
