import { TYPES } from 'components/Formie/inputs';
import { stringNotEmpty, validateNotNull, validatePastDate110, validatePhone, validatePositiveInteger } from 'components/Formie/validators';

const buildBackendProductFields = (pathPrefix, labelPrefix) => ({
  [`${pathPrefix}_cost`]: {
    type: TYPES.currency,
    label: `${labelPrefix} Cost`,
    dataPath: `contract_values.${pathPrefix}_cost`,
    validator: validatePositiveInteger,
    required: true,
    relatedTo: `${pathPrefix}`,
  },
  [`${pathPrefix}_term`]: {
    type: TYPES.integer,
    label: `${labelPrefix} Term`,
    dataPath: `contract_values.${pathPrefix}_term`,
    validator: validatePositiveInteger,
    required: true,
    relatedTo: `${pathPrefix}`,
  },
  [`${pathPrefix}_company`]: {
    type: TYPES.text,
    label: `${labelPrefix} Company`,
    dataPath: `contract_values.${pathPrefix}_company`,
    validator: stringNotEmpty,
    required: true,
    relatedTo: `${pathPrefix}`,
  },
});

const buildCustomFields = (num) => ({
  [`custom_field_${num}_cost`]: {
    type: TYPES.currency,
    label: `Custom Field ${num} - Cost`,
    dataPath: `contract_values.custom_field_${num}_cost`,
    validator: validatePositiveInteger,
    required: true,
    relatedTo: `custom_${num}`,
  },
  [`custom_field_${num}_for`]: {
    type: TYPES.text,
    label: `Custom Field ${num} - For`,
    dataPath: `contract_values.custom_field_${num}_for`,
    validator: stringNotEmpty,
    required: true,
    relatedTo: `custom_${num}`,
  },
  [`custom_field_${num}_to`]: {
    type: TYPES.text,
    label: `Custom Field ${num} - To`,
    dataPath: `contract_values.custom_field_${num}_to`,
    validator: stringNotEmpty,
    required: true,
    relatedTo: `custom_${num}`,
  },
});

export const initialValues = {
  dates: {
    contract_date: null,
  },
  contract_values: {
    sales_tax: null,
    cash_down: null,
    title_fees: null,
    doc_fee: null,
    license_reg_fees: null,
    esc_company: null,
    esc_cost: null,
    esc_term: null,
    gap_company: null,
    gap_cost: null,
    gap_term: null,
    ppm_company: null,
    ppm_cost: null,
    ppm_term: null,
    custom_field_1_to: null,
    custom_field_1_for: null,
    custom_field_1_cost: null,
    custom_field_2_to: null,
    custom_field_2_for: null,
    custom_field_2_cost: null,
    custom_field_3_to: null,
    custom_field_3_for: null,
    custom_field_3_cost: null,
    custom_field_4_to: null,
    custom_field_4_for: null,
    custom_field_4_cost: null,
    insurance_company: null,
    insurance_company_phone: null,
    insurance_agent: null,
  },

  helper_values: {
    days_to_first_payment: 30,
  },

  unit: {
    price: null,
  },

  trade_unit: {
    trade_allowance: null,
    payoff_amount: null,
    lienholder: null,
  },
};

export const prepareContractFormConfiguration = {
  dates: {
    contract_date: {
      type: TYPES.date,
      dataPath: 'dates.contract_date',
      required: true,
      validator: validatePastDate110,
      label: 'Contract Date',
    },
  },
  contract_values: {
    sales_tax: {
      type: TYPES.currency,
      label: 'Sales Tax',
      required: true,
      validator: validatePositiveInteger,
      dataPath: 'contract_values.sales_tax',
    },
    cash_down: {
      type: TYPES.currency,
      label: 'Cash Down',
      required: true,
      validator: validatePositiveInteger,
      dataPath: 'contract_values.cash_down',
    },
    title_fees: {
      type: TYPES.currency,
      label: 'Title Fee',
      required: true,
      validator: validatePositiveInteger,
      dataPath: 'contract_values.title_fees',
    },
    doc_fee: {
      type: TYPES.currency,
      label: 'Document Fee',
      required: true,
      validator: validatePositiveInteger,
      dataPath: 'contract_values.doc_fee',
    },
    license_reg_fees: {
      type: TYPES.currency,
      label: 'License & Registration Fee',
      required: true,
      validator: validatePositiveInteger,
      dataPath: 'contract_values.license_reg_fees',
    },
    ...buildBackendProductFields('esc', 'Service Contract'),
    ...buildBackendProductFields('ppm', 'PPM Contract'),
    ...buildBackendProductFields('gap', 'GAP Contract'),
    ...buildCustomFields('1'),
    ...buildCustomFields('2'),
    ...buildCustomFields('3'),
    ...buildCustomFields('4'),
    insurance_company: {
      type: TYPES.text,
      label: 'Company',
      required: true,
      validator: stringNotEmpty,
      dataPath: 'contract_values.insurance_company',
    },
    insurance_company_phone: {
      type: TYPES.phone,
      label: 'Phone Number',
      required: true,
      validator: validatePhone,
      dataPath: 'contract_values.insurance_company_phone',
    },
    insurance_agent: {
      type: TYPES.text,
      label: 'Agent',
      required: true,
      validator: stringNotEmpty,
      dataPath: 'contract_values.insurance_agent',
    },
  },

  helper_values: {
    days_to_first_payment: {
      type: TYPES.basicSelect,
      label: 'Days to First Payment',
      dataPath: 'helper_values.days_to_first_payment',
      required: true,
      validator: validateNotNull,
      options: [{ value: 30, label: '30' }],
    },
  },

  unit: {
    price: {
      type: TYPES.currency,
      label: 'Sales Price',
      dataPath: 'unit.price',
      required: true,
      validator: validatePositiveInteger,
    },
  },

  trade_unit: {
    trade_allowance: {
      type: TYPES.currency,
      label: 'Trade Allowance',
      dataPath: 'trade_unit.trade_allowance',
      required: true,
      validator: validatePositiveInteger,
      relatedTo: 'trade_unit',
    },
    payoff_amount: {
      type: TYPES.currency,
      label: 'Payoff Amount',
      dataPath: 'trade_unit.payoff_amount',
      validator: validatePositiveInteger,
      relatedTo: 'trade_unit',
    },
    lienholder: {
      type: TYPES.text,
      label: 'Lienholder',
      validator: stringNotEmpty,
      dataPath: 'trade_unit.lienholder',
      relatedTo: 'trade_unit',
    },
  },
};
