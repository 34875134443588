import { useEffect, Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Divider from '@mui/material/Divider';
import DataObjectIcon from '@mui/icons-material/DataObject';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import FlagIcon from '@mui/icons-material/Flag';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { Card, FlexRow, BasicMenu, ListItem } from 'components/Layouts';
import { fetchDocumentTemplates, archiveDocumentTemplate } from 'store/sagas/admin';
import DocumentTemplateModal from './documentTemplatesModal';

const DocumentTemplates = () => {
  const dispatch = useDispatch();

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const document_templates = useSelector((state) => state.admin?.document_templates) || [];
  const loading = useSelector((state) => state.admin?.loading?.document_templates);

  useEffect(() => {
    dispatch(fetchDocumentTemplates());
  }, []);

  return (
    <Card margin="0px" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0px 0px 20px" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Document Templates</Typography>
        <Button onClick={() => setSelectedTemplate({})} startIcon={<AddIcon />}>
          Add
        </Button>
      </FlexRow>
      {loading ? (
        <FlexRow padding="20px" margin="0px" justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexRow>
      ) : (
        <Fragment>
          <Divider />
          {document_templates.map((template) => {
            let secondaryTextArr = [];
            if (template.path) secondaryTextArr.push(`Path: ${template.path}`);
            if (template.remote_storage_url) secondaryTextArr.push(`Remote URL: ${template.remote_storage_url}`);

            return (
              <ListItem
                key={template.id}
                primaryText={template.name}
                secondaryText={secondaryTextArr.length ? secondaryTextArr.join(' | ') : 'No template stored in the system'}
                icon={<InsertDriveFileIcon color="primary" sx={{ fontSize: '40px' }} />}
                stackArray={[
                  {
                    display: template.is_fillable,
                    label: `${template.document_template_fields.length} Fillable Fields`,
                    icon: <DataObjectIcon />,
                    color: 'default',
                    tip: 'A document can have fillable fields that will be used by the PDF service. It can also include signature fields.',
                  },
                  {
                    display: true,
                    label: `${template.document_template_values.length} Potential Values`,
                    icon: <SmartToyIcon />,
                    color: 'default',
                    tip: 'Our documents are sent through a document-parsing AI to extract values from them. We can document what those potential values are here, for use in auto-completing stipulations.',
                  },
                  {
                    display: template.is_signable,
                    label: 'Signable',
                    icon: <HistoryEduIcon />,
                    color: 'default',
                    tip: 'This document template will show up as a signing document if triggered by a stipulation.',
                  },
                  {
                    display: template.is_state_specific,
                    label: `State: ${template.state}`,
                    icon: <FlagIcon />,
                    color: 'default',
                    tip: 'This document template is built specifically to be compliant in the laws of one particular state.',
                  },
                ]}
                action={
                  <BasicMenu
                    menuItems={[
                      { onClick: () => setSelectedTemplate(template), icon: <EditIcon />, label: 'Edit' },
                      { onClick: () => dispatch(archiveDocumentTemplate({ templateId: template.id })), icon: <DeleteIcon />, label: 'Delete' },
                    ]}
                  />
                }
              ></ListItem>
            );
          })}
        </Fragment>
      )}

      {!!selectedTemplate && <DocumentTemplateModal open={!!selectedTemplate} documentTemplate={selectedTemplate} onClose={() => setSelectedTemplate(null)} />}
    </Card>
  );
};

export default DocumentTemplates;
