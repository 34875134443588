import { useNavigate } from 'react-router-dom';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import { LINKS } from '../../../constants/menus';

import { UtilitySideBarItem, UtilitySidebar } from '../../../components/Layouts';

const Sidebar = ({ activeSection, SECTIONS, appId }) => {
  const navigate = useNavigate();

  return (
    <UtilitySidebar>
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.PREPARE_CONTRACT(appId, SECTIONS.OVERVIEW))}
        active={activeSection === SECTIONS.OVERVIEW}
        icon={<HomeOutlinedIcon />}
        label="Overview"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.PREPARE_CONTRACT(appId, SECTIONS.LENDING_OPTIONS))}
        active={activeSection === SECTIONS.LENDING_OPTIONS}
        icon={<TaskOutlinedIcon />}
        label="Lending Options"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.PREPARE_CONTRACT(appId, SECTIONS.UNPAID_BALANCE))}
        active={activeSection === SECTIONS.UNPAID_BALANCE}
        icon={<PersonOutlineIcon />}
        label="Unpaid Balance"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.PREPARE_CONTRACT(appId, SECTIONS.OTHER_CHARGES))}
        active={activeSection === SECTIONS.OTHER_CHARGES}
        icon={<PeopleOutlineIcon />}
        label="Other Charges"
      />
      <UtilitySideBarItem
        onClick={() => navigate(LINKS.DEALER.PREPARE_CONTRACT(appId, SECTIONS.INSURANCE_INFORMATION))}
        active={activeSection === SECTIONS.INSURANCE_INFORMATION}
        icon={<BusinessIcon />}
        label="Insurance Information"
      />
    </UtilitySidebar>
  );
};

export default Sidebar;
