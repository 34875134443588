import { useSearchParams } from 'react-router-dom';

import { SectionContainer, SectionContentContainer, ViewLoanPageLayout } from '../styled-components';
import SetupTerms from './SetupTerms';

const Terms = () => {
  const [searchParams] = useSearchParams();

  const SECTIONS = {
    SETUP_TERMS: 'setup_terms',
    TOOLS: 'tools',
  };

  const sectionObjs = [
    { label: 'Setup Terms', value: SECTIONS.SETUP_TERMS, component: <SetupTerms /> },
    { label: 'Tools', value: SECTIONS.TOOLS },
  ];

  const defaultSection = SECTIONS.SETUP_TERMS;
  const activeSection = searchParams.get('section') || defaultSection;
  const activeComponent = sectionObjs.find((section) => section.value === activeSection)?.component;

  return (
    <ViewLoanPageLayout defaultItem={defaultSection} menuItems={sectionObjs}>
      <SectionContainer>
        <SectionContentContainer fullWidth={sectionObjs.find((section) => section.value === activeSection)?.fullWidth}>
          {activeComponent ? activeComponent : <div>Error</div>}
        </SectionContentContainer>
      </SectionContainer>
    </ViewLoanPageLayout>
  );
};

export default Terms;
