import { createAsyncThunk } from '@reduxjs/toolkit';

import { FeeAPI } from 'store/api';

export const fetchLoanFeesThunk = createAsyncThunk(
  'fees/fetchLoanFees',
  async ({ subdata, loanId, limit = 30, sort = 'created_at|ASC', offset = 1 }, thunkAPI) => {
    try {
      let queryString = `?limit=${limit}&sort=${sort}&offset=${offset}`;

      if (subdata && subdata?.length && subdata.length > 0) {
        queryString = `${queryString}&subdata=${subdata.join(',')}`;
      }

      const { loanFees, metadata } = await FeeAPI.fetchLoanFees({ queryString, loanId });

      /**
       * This will dispatch action creator with type of "fees/fetchLoanFees/fulfilled" and payload of response.
       * We are not using this dispatched action atm.
       */
      return { fees: loanFees, metadata };
    } catch (err) {
      /**
       * This will dispatch action creator with type of "fees/fetchLoanFees/rejected" and payload of err.
       * We are not using this dispatched action atm.
       */
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const reverseLoanFeeThunk = createAsyncThunk('fees/reverseLoanFee', async ({ loanId, feeId }, thunkAPI) => {
  try {
    const { loanFee } = await FeeAPI.reverseLoanFee({ feeId, loanId });

    /**
     * This will dispatch action creator with type of "fees/reverseLoanFee/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { fee: loanFee };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "fees/reverseLoanFee/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const createLoanFeeThunk = createAsyncThunk('fees/createLoanFee', async ({ loanId, data }, thunkAPI) => {
  try {
    const { loanFee } = await FeeAPI.createLoanFee({ loanId, data });

    /**
     * This will dispatch action creator with type of "fees/createLoanFee/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { fee: loanFee };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "fees/createLoanFee/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});

export const editLoanFeeThunk = createAsyncThunk('fees/editLoanFee', async ({ loanId, data, feeId }, thunkAPI) => {
  try {
    const { loanFee } = await FeeAPI.editLoanFee({ loanId, data, feeId });

    /**
     * This will dispatch action creator with type of "fees/editLoanFee/fulfilled" and payload of response.
     * We are not using this dispatched action atm.
     */
    return { fee: loanFee };
  } catch (err) {
    /**
     * This will dispatch action creator with type of "fees/editLoanFee/rejected" and payload of err.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(err);
  }
});
