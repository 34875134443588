import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SummarizeIcon from '@mui/icons-material/SummarizeOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { useNavigate } from 'react-router-dom';

import { LINKS } from 'constants/menus';
import { UtilitySidebar, UtilitySideBarItem } from 'components/Layouts';

const Sidebar = ({ sectionCanShow, activeSection, SECTIONS, appId }) => {
  const navigate = useNavigate();

  return (
    <UtilitySidebar aria-label="Application View Sidebar">
      {[
        { section: SECTIONS.OVERVIEW, icon: <HomeOutlinedIcon />, label: 'Overview', canShow: true },
        { section: SECTIONS.APP_INFO, icon: <ArticleOutlinedIcon />, label: 'Application Info', canShow: true },
        { section: SECTIONS.LENDING_OPTIONS, icon: <TaskOutlinedIcon />, label: 'Lending Options', canShow: sectionCanShow.lendingOptions },
        { section: SECTIONS.STIPS_AND_DOCS, icon: <DriveFolderUploadOutlinedIcon />, label: 'Stips & Docs', canShow: sectionCanShow.stipsAndDocs },
        { section: SECTIONS.CONTRACT_PREP_OVERVIEW, icon: <SummarizeIcon />, label: 'Contract Prep', canShow: sectionCanShow.contractPrepOverview },
        { section: SECTIONS.PAYMENT_PROFILES, icon: <CreditCardIcon />, label: 'Payment Profiles', canShow: sectionCanShow.paymentProfile },
        { section: SECTIONS.VERIFY, icon: <VerifiedUserOutlinedIcon />, label: 'Verify', canShow: sectionCanShow.verify },
        { section: SECTIONS.REFERENCES, icon: <PersonSearchIcon />, label: 'References', canShow: sectionCanShow.references },
        { section: SECTIONS.SIGNING, icon: <HistoryEduIcon />, label: 'Signing', canShow: sectionCanShow.signing },
      ]
        .filter((item) => item.canShow)
        .map(({ section, icon, label }, i) => (
          <UtilitySideBarItem
            key={section}
            onClick={() => navigate(LINKS.LENDER.APPLICATIONS.VIEW_ONE(appId, section))}
            active={activeSection === section}
            icon={icon}
            label={label}
          />
        ))}
    </UtilitySidebar>
  );
};

export default Sidebar;
