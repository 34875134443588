import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { Card, FlexRow } from 'components/Layouts';
import { COLORS } from 'constants/styles';
import { currencyFormatter } from 'constants/formatters';

const UnitUnpaidBalance = ({ formie, allValues }) => {
  const {
    contract_values: { unpaid_balance, apr, total_down_payment, cash_down, required_amount_down },
    helper_values: { net_trade_in, total_down_fits_approval },
    unit: { condition, make, model },
    trade_unit: { id: tradeId, make: tradeMake, condition: tradeCondition, model: tradeModel },
  } = allValues;

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Unpaid Balance
        </Typography>
        <Typography variant="h5">{currencyFormatter.format(unpaid_balance)}</Typography>
      </FlexRow>

      <Typography variant="overline" display="block" style={{ marginTop: '10px', color: COLORS.primary }}>
        Unit
      </Typography>

      <Typography variant="caption" display="block">
        {`${condition} ${make} ${model}`}
      </Typography>
      {formie.buildFields({
        fields: [{ dataPath: 'unit.price' }, { dataPath: 'contract_values.sales_tax' }],
        fieldsPerRow: 2,
      })}
      {formie.buildFields({
        fields: [{ dataPath: 'contract_values.cash_down' }],
      })}

      {tradeId && (
        <Fragment>
          <Typography variant="overline" display="block" style={{ marginTop: '20px', color: COLORS.primary }}>
            Trade-In
          </Typography>

          <Typography variant="caption" display="block">
            {`${tradeCondition} ${tradeMake} ${tradeModel}`}
          </Typography>

          {formie.buildFields({
            fields: [{ dataPath: 'trade_unit.trade_allowance' }, { dataPath: 'trade_unit.payoff_amount' }],
            fieldsPerRow: 2,
          })}
          {formie.buildFields({
            fields: [{ dataPath: 'trade_unit.lienholder' }],
          })}

          {net_trade_in > 0 && (
            <FlexRow margin="12px 0px 0px" padding="0">
              <Alert sx={{ width: '100%' }} severity="info">
                <AlertTitle>Trade Equity</AlertTitle>
                {`${currencyFormatter.format(net_trade_in)} is being added to the down payment due to Trade Equity. This reduces the unpaid balance.`}
              </Alert>
            </FlexRow>
          )}
        </Fragment>
      )}

      {apr && (
        <FlexRow margin="12px 0px 0px" padding="0">
          <Alert sx={{ width: '100%' }} severity={total_down_fits_approval ? 'success' : 'error'}>{`The total down payment of ${currencyFormatter.format(
            total_down_payment,
          )} (${currencyFormatter.format(cash_down)} cash down payment and ${currencyFormatter.format(net_trade_in)} trade equity) is ${
            total_down_fits_approval ? 'greater than or equal to' : 'less than'
          } the required amount of ${currencyFormatter.format(required_amount_down)}.`}</Alert>
        </FlexRow>
      )}
    </Card>
  );
};

export default UnitUnpaidBalance;
