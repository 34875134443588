import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { Card, FlexRow } from 'components/Layouts';

const InsuranceInformation = ({ formie }) => {
  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined">
      <FlexRow padding="0" margin="0" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Insurance Information
        </Typography>
      </FlexRow>

      <FlexRow margin="12px 0px 0px" padding="0">
        <Alert sx={{ width: '100%' }} severity="info">
          <AlertTitle>Lender Requires Insurance</AlertTitle>
          The lender requires that the applicant has insurance before they will fund this loan. The applicant will sign an agreement to provide insurance during
          the signing ceremony.
        </Alert>
      </FlexRow>

      {formie.buildFields({
        fields: [
          { dataPath: 'contract_values.insurance_company' },
          { dataPath: 'contract_values.insurance_agent' },
          { dataPath: 'contract_values.insurance_company_phone' },
        ],
        fieldsPerRow: 3,
      })}
    </Card>
  );
};

export default InsuranceInformation;
