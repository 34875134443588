import { Typography, List, ListItem, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import { Card, FlexRow } from 'components/Layouts';
import { formatDateOnly, formatCurrencyForDataGrid } from 'constants/formatters';

export const TilaCard = ({ label, value, background }) => {
  return (
    <Card variant="outlined" backgroundColor={background || null}>
      <FlexRow padding="0px" margin="0px" flexColumn alignItems="center" justifyContent="center">
        <Typography align="center" variant="h6">
          {value}
        </Typography>

        <Typography align="center" variant="caption">
          {label}
        </Typography>
      </FlexRow>
    </Card>
  );
};

export const LabeledListItem = ({ label, value, noDivider }) => {
  return (
    <>
      <ListItem>
        <Grid sx={{ width: '100%', p: 0, m: 0 }} container spacing={1}>
          <Grid display="flex" alignItems="flex-end" sx={{ m: 0, p: 0 }} xs={5}>
            <Typography variant="caption">{label}</Typography>
          </Grid>

          <Grid display="flex" alignItems="flex-start" sx={{ m: 0, p: 0 }} xs={7}>
            <Typography variant="body1">{value}</Typography>
          </Grid>
        </Grid>
      </ListItem>

      {!noDivider && <Divider />}
    </>
  );
};

export const DetailsList = ({ list }) => {
  return (
    <Grid xs={6}>
      <List dense disablePadding>
        {list.map((item) => (
          <LabeledListItem noDivider={item.noDivider} key={`detailsList-${item.label}`} label={item.label} value={item.value} />
        ))}
      </List>
    </Grid>
  );
};

export const parsePaymentFrequency = (frequency) => {
  const frequencies = {
    ['monthly']: 'Monthly',
    ['semi-monthly']: 'Semi-Monthly',
    ['bi-weekly']: 'Bi-Weekly',
    ['weekly']: 'Weekly',
  };

  const prettyFrequency = frequencies[frequency];

  return prettyFrequency || '-';
};

export const columns = [
  { field: 'id', headerAlign: 'left', align: 'left', type: 'string', headerName: '#', width: 30 },
  {
    field: 'date',
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    headerName: 'Due Date',
    minWidth: 100,
    valueFormatter: (params) => formatDateOnly(params.value),
  },
  {
    field: 'startingPrincipal',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Starting Principal',
    minWidth: 135,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'accruedInterest',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Accrued Interest',
    minWidth: 130,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'payment',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Payment Amount',
    minWidth: 135,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'interestPayment',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Interest Payment',
    minWidth: 130,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
  {
    field: 'principalPayment',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    headerName: 'Principal Payment',
    minWidth: 140,
    flex: 1,
    valueFormatter: formatCurrencyForDataGrid,
  },
];
