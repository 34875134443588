import { Fragment, useState } from 'react';
import copy from 'copy-to-clipboard';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Card } from 'components/Layouts';
import DataListItem from './../dataListItem';
import Applicant from './applicant';
import Unit from './unit';

const AppInfo = ({ hasCoApplicant, hasTradeIn, currentApplication, overviewShouldShow, setActiveSectionToAppInfo }) => {
  const [copyTooltipText, setCopyTooltipText] = useState('Click to copy');

  const { applicant, unit, trade_unit, co_applicant, id, contract_values } = currentApplication;

  const applicantName = applicant ? (applicant.first_name && applicant.last_name ? `${applicant.first_name} ${applicant.last_name}` : applicant.email) : '';

  const coApplicantName = co_applicant
    ? co_applicant.first_name && co_applicant.last_name
      ? `${co_applicant.first_name} ${co_applicant.last_name}`
      : co_applicant.email
    : '';

  return overviewShouldShow ? (
    <Card margin="0px 0px 20px" padding="20px 20px 0px" fullwidth variant="outlined" aria-label="Application info.">
      <Typography style={{ margin: '0px' }} variant="h5">
        Application Info
      </Typography>

      <List>
        {[
          { title: 'Applicant', data: applicantName, onClick: setActiveSectionToAppInfo },
          { title: 'Co-Applicant', data: coApplicantName, onClick: hasCoApplicant && setActiveSectionToAppInfo },
          {
            title: 'Unit',
            onClick: setActiveSectionToAppInfo,
            data: (
              <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                <Typography>{unit?.vin}</Typography>
                <Tooltip title={copyTooltipText} onOpen={() => setCopyTooltipText('Click to copy')}>
                  <IconButton
                    size="small"
                    aria-label="copy"
                    onClick={(e) => {
                      copy(unit?.vin);
                      setCopyTooltipText('Copied');
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ContentCopyIcon fontSize="16px" />
                  </IconButton>
                </Tooltip>
              </Stack>
            ),
          },
          {
            title: 'Trade-In',
            data: hasTradeIn ? (
              <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                <Typography>{trade_unit?.vin}</Typography>
                <Tooltip title={copyTooltipText} onOpen={() => setCopyTooltipText('Click to copy')}>
                  <IconButton
                    size="small"
                    aria-label="copy"
                    onClick={(e) => {
                      copy(trade_unit?.vin);
                      setCopyTooltipText('Copied');
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ContentCopyIcon fontSize="16px" />
                  </IconButton>
                </Tooltip>
              </Stack>
            ) : (
              ''
            ),
            noDivider: true,
          },
        ].map(({ title, data, adornment, noDivider, onClick, tooltip }, i) => (
          <DataListItem key={`${data}-${i}`} title={title} data={data} adornment={adornment} noDivider={noDivider} onClick={onClick} tooltip={tooltip} />
        ))}
      </List>
    </Card>
  ) : (
    <Fragment>
      <Applicant applicant={applicant} appId={id} creditScore={contract_values?.credit_score} />
      {hasCoApplicant && <Applicant isCoApplicant={true} applicant={co_applicant} />}
      <Unit unit={unit} />
      {hasTradeIn && <Unit unit={trade_unit} isTradeIn />}
    </Fragment>
  );
};

export default AppInfo;
