import { TYPES } from 'components/Formie/inputs';
import { stringNotEmpty, validatePhone, validateEmail } from 'components/Formie/validators';

export const newReferenceFormConfig = {
  first_name: {
    type: TYPES.text,
    label: 'First Name',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'first_name',
  },
  last_name: {
    type: TYPES.text,
    label: 'Last Name',
    required: true,
    validator: stringNotEmpty,
    dataPath: 'last_name',
  },
  phone: {
    type: TYPES.phone,
    label: 'Phone',
    required: true,
    validator: validatePhone,
    dataPath: 'phone',
  },
  email: {
    type: TYPES.email,
    label: 'Email',
    required: true,
    validator: validateEmail,
    dataPath: 'email',
  },
};

export const initialValues = {
  first_name: null,
  last_name: null,
  phone: null,
  email: null,
};
