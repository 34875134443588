import { Outlet, createBrowserRouter } from 'react-router-dom';

import App from './App';
import { AppProtection, TypeProtection, SuperAdminProtection, AdminProtection } from 'components/ProtectedRoute';
import DealerDashboard from 'views/Dealer/Dashboard';
import LenderDashboard from 'views/Lender/Dashboard';
import EditApp from 'views/Dealer/EditApp';
import DealerViewApp from 'views/Dealer/ViewApp';
import LenderViewApp from 'views/Lender/ViewApp';
import ErrorRoute from 'views/Error';
import Login from 'views/Login';
import DealerApplications from 'views/Dealer/Applications';
import LenderApplications from 'views/Lender/Applications';
import ContractPrep from 'views/Dealer/PrepareContract';
import DoneSigning from 'views/Dealer/DoneSigning';
import DocumentTemplates from 'views/SuperAdmin/DocumentTemplates';
import DealerSettings from 'views/Dealer/Settings';
import LenderSettings from 'views/Lender/Settings';
import DealerUsers from 'views/Dealer/Settings/Users';
import StipulationTemplates from 'views/Lender/Settings/StipulationTemplates';
import LendingRules from 'views/Lender/Settings/LendingRules';
import OrganizationDetails from 'views/Lender/Settings/OrganizationDetails';
import LenderUsers from 'views/Lender/Settings/Users';
import CreditReport from 'views/CreditReport';
import AllLoans from 'views/Lender/Loans/AllLoans';
import AddLoan from 'views/Lender/Loans/AddLoan';
import ViewLoan from 'views/Lender/Loans/ViewLoan';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorRoute />,
    children: [
      {
        index: true,
        element: <div>index</div>, // This should ask if the user is logged in, and forward them to dashboard if they are.
      },
      {
        path: 'done-signing',
        element: <DoneSigning />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'app',
        element: <AppProtection />,
        children: [
          {
            path: 'super-admin',
            element: <SuperAdminProtection />,
            children: [
              {
                path: 'document-templates',
                element: <DocumentTemplates />,
              },
            ],
          },
          {
            path: 'account/settings',
            element: <div>account settings</div>,
          },
          {
            path: 'dealer',
            element: <TypeProtection type="dealer" />,
            children: [
              {
                path: 'dashboard',
                element: <DealerDashboard />,
              },
              {
                path: 'application/:id/edit',
                element: <EditApp />,
              },
              {
                path: 'application/:id/prepare-contract',
                element: <ContractPrep />,
              },
              {
                path: 'application/:id',
                element: <DealerViewApp />,
              },
              {
                path: 'applications',
                element: <DealerApplications />,
              },
              {
                path: 'admin',
                element: <AdminProtection />,
                children: [
                  {
                    path: 'settings',
                    element: <DealerSettings />,
                    children: [
                      {
                        path: 'users',
                        element: <DealerUsers />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'lender',
            element: <TypeProtection type="lender" />,
            children: [
              {
                path: 'dashboard',
                element: <LenderDashboard />,
              },
              {
                path: 'application/:id',
                element: <LenderViewApp />,
              },
              {
                path: 'application/:id/credit-report',
                element: <CreditReport />,
              },
              {
                path: 'applications',
                element: <LenderApplications />,
              },
              {
                path: 'loans',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <AllLoans />,
                  },
                  {
                    path: 'add',
                    element: <AddLoan />,
                  },
                  {
                    path: ':loanId/view/:section',
                    element: <ViewLoan />,
                  },
                ],
              },
              {
                path: 'admin',
                element: <AdminProtection />,
                children: [
                  {
                    path: 'settings',
                    element: <LenderSettings />,
                    children: [
                      {
                        path: 'stipulation-templates',
                        element: <StipulationTemplates />,
                      },
                      {
                        path: 'lending-rules',
                        element: <LendingRules />,
                      },
                      {
                        path: 'organization-details',
                        element: <OrganizationDetails />,
                      },
                      {
                        path: 'users',
                        element: <LenderUsers />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default routes;
