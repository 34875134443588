import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import { CircularProgress, Backdrop, Typography, Button } from '@mui/material';
import { parse } from 'date-fns';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';

import { useFormie } from 'components/Formie';
import { setAlert } from 'store/sagas/app';
import PaymentProfileCard from './PaymentProfileCard';
import PaymentProfileDrawer from './PaymentProfileDrawer';
import {
  paymentProfileConfiguration,
  paymentProfileInitialValues,
  creditCardPaymentProfileInitialValues,
  creditCardPaymentProfileConfiguration,
} from '../formInfo';
import { COLORS } from 'constants/styles';
import {
  addBankPaymentProfileThunk,
  unlinkPaymentProfileThunk,
  editPaymentProfileThunk,
  linkExistingPaymentProfileThunk,
  addCreditCardPaymentProfileThunk,
  swapPaymentProfileThunk,
} from 'store/thunks/paymentProfile';
import { FlexRow } from 'components/Layouts';

const PaymentProfiles = ({ paymentProfiles, rehydrateOnboarding, customers, customerPaymentProfiles }) => {
  const dispatch = useDispatch();

  const { loanId } = useParams();
  const [open, setOpen] = useState(false);
  const [profileType, setProfileType] = useState(null);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = (type) => {
    setOpen(true);
    setProfileType(type);
  };

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      setEditing(false);
      setProfileType(null);
      resetForm();
      resetFormCC();
    }
  };

  const addCreditCardPaymentProfile = async () => {
    setLoading(true);
    setOpen(false);
    try {
      await dispatch(
        addCreditCardPaymentProfileThunk({
          data: {
            customer_id: creditCardFormie?.values?.paymentProfile?.customer_id,
            profile_name: creditCardFormie?.values?.paymentProfile?.profile_name,
            account_number: creditCardFormie?.values?.paymentProfile?.account_number,
            account_first_name: creditCardFormie?.values?.paymentProfile?.account_first_name,
            account_last_name: creditCardFormie?.values?.paymentProfile?.account_last_name,
            cvv_code: creditCardFormie?.values?.paymentProfile?.cvv_code,
            expiry_date: parse(creditCardFormie?.values?.paymentProfile?.expiry_date, 'MMyy', new Date()),
            card_zip: creditCardFormie?.values?.paymentProfile?.card_zip,
          },
          loanId,
        }),
      ).unwrap();
      await rehydrateOnboarding();
      resetForm();
      resetFormCC();
      setEditing(false);
    } catch (err) {
      setOpen(true);
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to add credit card. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const addOrEditBankPaymentProfile = async () => {
    setLoading(true);
    setOpen(false);
    try {
      if (editing) {
        await dispatch(
          editPaymentProfileThunk({
            data: {
              account_type: formie?.values?.paymentProfile?.account_type,
              account_first_name: formie?.values?.paymentProfile?.account_first_name,
              account_last_name: formie?.values?.paymentProfile?.account_last_name,
              account_number: formie?.values?.paymentProfile?.account_number,
              routing_number: formie?.values?.paymentProfile?.routing_number,
              institution_name: formie?.values?.paymentProfile?.institution_name,
              profile_name: formie?.values?.paymentProfile?.profile_name,
            },
            customerId: formie?.values?.paymentProfile?.customer_id,
            paymentProfileId: formie?.values?.paymentProfile?.id,
          }),
        ).unwrap();
      } else {
        await dispatch(
          addBankPaymentProfileThunk({
            data: {
              customer_id: formie?.values?.paymentProfile?.customer_id,
              account_type: formie?.values?.paymentProfile?.account_type,
              account_first_name: formie?.values?.paymentProfile?.account_first_name,
              account_last_name: formie?.values?.paymentProfile?.account_last_name,
              account_number: formie?.values?.paymentProfile?.account_number,
              routing_number: formie?.values?.paymentProfile?.routing_number,
              institution_name: formie?.values?.paymentProfile?.institution_name,
              profile_name: formie?.values?.paymentProfile?.profile_name,
            },
            loanId,
          }),
        ).unwrap();
      }
      await rehydrateOnboarding();
      resetForm();
      resetFormCC();
      setEditing(false);
    } catch (err) {
      setOpen(true);
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to ${!!editing ? 'edit' : 'add'} payment profile. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const unlinkPaymentProfile = async (paymentProfileId) => {
    setLoading(true);
    try {
      await dispatch(
        unlinkPaymentProfileThunk({
          paymentProfileId,
          loanId,
        }),
      ).unwrap();
      await rehydrateOnboarding();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to remove payment profile. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const linkPaymentProfile = async (customerId, paymentProfileId) => {
    setLoading(true);
    try {
      await dispatch(
        linkExistingPaymentProfileThunk({
          paymentProfileId,
          customerId,
          loanId,
        }),
      ).unwrap();
      await rehydrateOnboarding();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to link payment profile. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleStartEdit = (paymentProfile, profileType) => {
    setOpen(true);
    setEditing(true);
    setProfileType(profileType);
    formie.setAllValues({
      paymentProfile: {
        id: paymentProfile?.id,
        customer_id: paymentProfile?.customer_id,
        account_type: paymentProfile?.account_type,
        account_first_name: paymentProfile?.account_first_name,
        account_last_name: paymentProfile?.account_last_name,
        account_number: paymentProfile?.account_number,
        routing_number: paymentProfile?.routing_number,
        institution_name: paymentProfile?.institution_name,
        profile_name: paymentProfile?.profile_name,
      },
    });
  };

  const swapPaymentProfileRoles = async (backupId) => {
    setLoading(true);
    try {
      await dispatch(
        swapPaymentProfileThunk({
          paymentProfileId: backupId,
          loanId,
          data: { profile_type: 1 },
        }),
      ).unwrap();
      await rehydrateOnboarding();
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: `Unable to swap payment profiles. Try again later.`,
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const formie = useFormie({
    configuration: paymentProfileConfiguration(
      customers
        ? customers
            .map((customer) => ({ value: customer?.id, label: `${customer?.first_name} ${customer?.last_name}` }))
            .filter((id) => !!id)
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    ),
    initialValues: paymentProfileInitialValues,
    setLoading,
    onSubmit: addOrEditBankPaymentProfile,
  });

  const creditCardFormie = useFormie({
    configuration: creditCardPaymentProfileConfiguration(
      customers
        ? customers
            .map((customer) => ({ value: customer?.id, label: `${customer?.first_name} ${customer?.last_name}` }))
            .filter((id) => !!id)
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    ),
    initialValues: creditCardPaymentProfileInitialValues,
    onSubmit: addCreditCardPaymentProfile,
  });

  const { handleSubmit, resetForm } = formie;
  const { handleSubmit: handleSubmitCC, resetForm: resetFormCC } = creditCardFormie;

  const primaryPaymentProfile = paymentProfiles?.find((profile) => profile?.loans_payment_profiles?.profile_type === 1);
  const backupPaymentProfile = paymentProfiles?.find((profile) => profile?.loans_payment_profiles?.profile_type > 1);

  const primaryCustomer = customers && customers.length ? customers.find((customer) => customer?.loans_customers?.customer_role === 'primary-debtor') : null;
  const secondaryCustomer =
    customers && customers.length ? customers.find((customer) => customer?.loans_customers?.customer_role === 'secondary-debtor') : null;
  const primaryPaymentProfileCustomer = customers?.find((customer) => customer?.id === primaryPaymentProfile?.customer_id);
  const backupPaymentProfileCustomer = customers?.find((customer) => customer?.id === backupPaymentProfile?.customer_id);

  const allPrimaryCustomerPaymentProfiles = primaryCustomer ? customerPaymentProfiles.filter((profile) => profile.customer_id === primaryCustomer?.id) : [];
  const allSecondaryCustomerPaymentProfiles = secondaryCustomer
    ? customerPaymentProfiles.filter((profile) => profile.customer_id === secondaryCustomer?.id)
    : [];

  const numberOfPaymentProfiles = paymentProfiles?.length;
  const bothPaymentProfileAreBankAccounts =
    numberOfPaymentProfiles === 2 && primaryPaymentProfile?.account_type !== 'credit' && backupPaymentProfile?.account_type !== 'credit';

  return (
    <>
      <FlexRow padding="0px" margin="32px 0px 0px" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">How will the customers pay?</Typography>

        {bothPaymentProfileAreBankAccounts && (
          <Button onClick={() => backupPaymentProfile && swapPaymentProfileRoles(backupPaymentProfile?.id)} startIcon={<SwapHorizOutlinedIcon />}>
            Swap Roles
          </Button>
        )}
      </FlexRow>

      <Grid sx={{ mt: 0 }} container spacing={2}>
        {/* Primary Profile */}
        <Grid xs={6}>
          <PaymentProfileCard
            paymentProfile={primaryPaymentProfile}
            disableButton={!customers || customers?.length < 1}
            profileType={1}
            handleOpen={handleOpen}
            customer={primaryPaymentProfileCustomer}
            handleStartEdit={handleStartEdit}
          />
        </Grid>

        {/* Secondary Profile - only show this if there is already a primary payment profile. */}
        {primaryPaymentProfile && (
          <Grid xs={6}>
            <PaymentProfileCard
              handleStartEdit={handleStartEdit}
              customer={backupPaymentProfileCustomer}
              paymentProfile={backupPaymentProfile}
              profileType={2}
              handleOpen={handleOpen}
              unlinkPaymentProfile={() => unlinkPaymentProfile(backupPaymentProfile?.id)}
            />
          </Grid>
        )}
      </Grid>

      <PaymentProfileDrawer
        profileType={profileType}
        handleSubmit={handleSubmit}
        formie={formie}
        handleClose={handleClose}
        open={open}
        handleOpen={handleOpen}
        editing={editing}
        primaryCustomer={primaryCustomer}
        secondaryCustomer={secondaryCustomer}
        allPrimaryCustomerPaymentProfiles={allPrimaryCustomerPaymentProfiles}
        allSecondaryCustomerPaymentProfiles={allSecondaryCustomerPaymentProfiles}
        linkPaymentProfile={linkPaymentProfile}
        creditCardFormie={creditCardFormie}
        handleSubmitCC={handleSubmitCC}
      />

      <Backdrop sx={{ color: COLORS.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PaymentProfiles;
