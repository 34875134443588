import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StarIcon from '@mui/icons-material/Star';

import { Card, FlexRow } from 'components/Layouts';
import { ActionMenu } from 'components/Menu';

const parseAccountType = (type) => {
  if (!type) return '';

  const typeMap = [
    { value: 'checking', label: 'Checking' },
    { value: 'savings', label: 'Savings' },
    { value: 'credit', label: 'Credit Card' },
  ];

  return typeMap.find((map) => map.value === type)?.label;
};

const LabeledData = ({ value, label, topMargin }) => {
  return (
    <FlexRow padding="0px" margin={topMargin ? '15px 0px 0px' : '0px'} alignItems="flex-start" flexColumn>
      <Typography noWrap sx={{ ml: '5px' }} variant="caption">
        {label}
      </Typography>

      {typeof value === 'string' ? (
        <Typography noWrap sx={{ ml: '5px' }} variant="body1">
          {value}
        </Typography>
      ) : (
        value
      )}
    </FlexRow>
  );
};

const PaymentProfileCard = ({ profile, handleStartEdit, makePrimaryProfile, unlinkPaymentProfile }) => {
  const isCreditCard = profile.accountType === 'credit';

  let CardLogo = null;
  if (isCreditCard) {
    if (profile.institutionName === 'American Express')
      CardLogo = <img alt="card-logo" style={{ height: '40px', marginTop: '-8px' }} src="/images/american_express.svg" />;
    if (profile.institutionName === 'Visa')
      CardLogo = <img alt="card-logo" style={{ height: '40px', marginTop: '-8px', marginLeft: '-8px' }} src="/images/visa.svg" />;
    if (profile.institutionName === 'Mastercard')
      CardLogo = <img alt="card-logo" style={{ height: '40px', marginTop: '-8px' }} src="/images/master_card.svg" />;
    if (profile.institutionName === 'Discover')
      CardLogo = <img alt="card-logo" style={{ height: '40px', marginTop: '-8px', marginLeft: '6px' }} src="/images/discover.svg" />;
    if (profile.institutionName === 'JCB') CardLogo = <img alt="card-logo" style={{ height: '40px', marginTop: '-5px' }} src="/images/jcb.svg" />;
    if (profile.institutionName === 'Diners Club')
      CardLogo = <img alt="card-logo" style={{ height: '40px', marginTop: '-8px', marginLeft: '-10px' }} src="/images/diners-club.svg" />;
  }

  const formattedAccountType = parseAccountType(profile.accountType);
  const isPrimaryProfile = profile.profileType === 1 ? true : false;
  const accountName = `${profile.accountFirstName} ${profile.accountLastName}`;
  const customerName = `${profile.belongsTo?.first_name} ${profile.belongsTo?.last_name}`;
  const last4AccountNumber = profile && profile?.account_number?.slice(-4);

  const options = [
    { value: 'Edit', callback: () => handleStartEdit(profile), show: !isCreditCard },
    { value: 'Make Primary', callback: () => makePrimaryProfile(profile.id), show: !isPrimaryProfile && !isCreditCard },
    { value: 'Remove', callback: () => unlinkPaymentProfile(profile.id), show: !isPrimaryProfile },
  ].filter((opt) => opt.show);

  return (
    <Card margin="0px" padding="0px" variant="outlined">
      {/* Title */}
      <FlexRow padding="10px 10px 5px" margin="0px" borderBottom justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{profile.profileName ? profile.profileName : `${profile.institutionName} ...${last4AccountNumber}`}</Typography>

        <div>
          <Chip {...(isPrimaryProfile ? { icon: <StarIcon />, color: 'primary' } : {})} size="small" label={isPrimaryProfile ? 'Primary' : 'Backup'} />
          <ActionMenu options={options} />
        </div>
      </FlexRow>

      {/* Content Container */}
      <div style={{ padding: '0px 0px 10px' }}>
        {/* Details */}
        {!isCreditCard ? (
          <Grid container spacing={1}>
            <Grid xs={6}>
              <LabeledData label="Name on Account" value={accountName} />
            </Grid>

            <Grid xs={6}>
              <LabeledData label="Account Number" value={profile.accountNumber} />
            </Grid>

            <Grid xs={6}>
              <LabeledData label="Account Type" value={formattedAccountType} />
            </Grid>

            <Grid xs={6}>
              <LabeledData label="Routing Number" value={profile.routingNumber} />
            </Grid>

            <Grid xs={6}>
              <LabeledData label="Belongs to" value={<Chip icon={<PersonIcon />} label={customerName} />} />
            </Grid>

            <Grid xs={6}>
              <LabeledData label="Financial Institution" value={profile.institutionName} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid xs={6}>
              <LabeledData label="Name on Account" value={accountName} />
            </Grid>
            <Grid xs={6}>
              <LabeledData label="Account Number" value={profile.accountNumber} />
            </Grid>

            <Grid xs={6}>
              <LabeledData label="Expiration" value={profile.expirationDate} />
            </Grid>
            <Grid xs={6}>
              <LabeledData label="CVV" value={profile.cvcCvv} />
            </Grid>

            <Grid xs={6}>
              <LabeledData label="Belongs to" value={<Chip icon={<PersonIcon />} label={customerName} />} />
            </Grid>

            <Grid xs={6}>
              <LabeledData label="Card Type" value={CardLogo} />
            </Grid>
          </Grid>
        )}
      </div>
    </Card>
  );
};

export const PaymentProfileCardForDrawer = ({ paymentProfile, linkFn }) => {
  const isCreditCard = paymentProfile?.account_type === 'credit';

  let CardLogo = null;
  if (isCreditCard) {
    if (paymentProfile?.institution_name === 'American Express')
      CardLogo = <img alt="card-logo" style={{ height: '40px' }} src="/images/american_express.svg" />;
    if (paymentProfile?.institution_name === 'Visa') CardLogo = <img alt="card-logo" style={{ height: '40px' }} src="/images/visa.svg" />;
    if (paymentProfile?.institution_name === 'Mastercard') CardLogo = <img alt="card-logo" style={{ height: '40px' }} src="/images/master_card.svg" />;
    if (paymentProfile?.institution_name === 'Discover') CardLogo = <img alt="card-logo" style={{ height: '40px' }} src="/images/discover.svg" />;
    if (paymentProfile?.institution_name === 'JCB') CardLogo = <img alt="card-logo" style={{ height: '40px' }} src="/images/jcb.svg" />;
    if (paymentProfile?.institution_name === 'Diners Club') CardLogo = <img alt="card-logo" style={{ height: '40px' }} src="/images/diners-club.svg" />;
  }

  let formattedAccountType = null;
  if (paymentProfile?.account_type === 'checking') formattedAccountType = 'Checking';
  if (paymentProfile?.account_type === 'savings') formattedAccountType = 'Savings';
  if (isCreditCard) formattedAccountType = 'Credit Card';

  const last4AccountNumber = paymentProfile && paymentProfile?.account_number?.slice(-4);

  return (
    <Card margin="0px 0px 16px" padding="0px" variant="outlined">
      {/* Title */}
      <FlexRow padding="10px 10px 5px" margin="0px" borderBottom justifyContent={'space-between'} alignItems="center">
        <Typography variant="h6" color="primary">
          {paymentProfile?.profile_name ? paymentProfile?.profile_name : `${paymentProfile?.institution_name} ...${last4AccountNumber}`}
        </Typography>
        {!isCreditCard ? <AccountBalanceIcon color="primary" sx={{ mr: '8px' }} /> : CardLogo}
      </FlexRow>

      {/* Content Container */}
      <div style={{ padding: '10px' }}>
        {/* Details */}
        <Grid container spacing={1}>
          <Grid xs={6}>
            <LabeledData label="Name on Account" value={`${paymentProfile?.account_first_name} ${paymentProfile?.account_last_name}`} />
          </Grid>

          <Grid xs={6}>
            <LabeledData label="Account Number" value={paymentProfile?.account_number} />
          </Grid>

          {isCreditCard ? (
            <Grid xs={6}>
              <LabeledData label="CVV/CVC" value={paymentProfile?.cvc_cvv} />
            </Grid>
          ) : (
            <Grid xs={6}>
              <LabeledData label="Account Type" value={formattedAccountType} />
            </Grid>
          )}

          {isCreditCard ? (
            <Grid xs={6}>
              <LabeledData label="Expiration" value={paymentProfile?.expiry_date} />
            </Grid>
          ) : (
            <Grid xs={6}>
              <LabeledData label="Routing Number" value={paymentProfile?.routing_number} />
            </Grid>
          )}
        </Grid>
      </div>

      <FlexRow borderedTop padding="5px 10px" margin="0px" justifyContent="flex-end" alignItems="center">
        <Button onClick={linkFn} variant="contained">
          Link
        </Button>
      </FlexRow>
    </Card>
  );
};

export default PaymentProfileCard;
