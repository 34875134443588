import { createContext, useState } from 'react';

const ReferencesContext = createContext({});

export const ReferencesContextProvider = ({ children }) => {
  const [referencesSectionProcessing, setReferencesSectionProcessing] = useState(false);

  return <ReferencesContext.Provider value={{ referencesSectionProcessing, setReferencesSectionProcessing }}>{children}</ReferencesContext.Provider>;
};

export default ReferencesContext;
