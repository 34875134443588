import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  link_to_photo: '',
  is_super_admin: false,
  profiles: [],
  active_profile: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserInfo() {},
    storeUserInfo(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    logUserIn() {},
    logUserOut() {},
    setActiveProfile() {},
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;

// Extract and export each action creator by name
export const { fetchUserInfo, storeUserInfo, logUserIn, logUserOut, setActiveProfile, storeActiveProfile } = actions;

// Export the reducer, either as a default or named export
export default reducer;
