import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { format, parseISO } from 'date-fns';
import Alert from '@mui/material/Alert';

import NavBar from 'components/NavBar';
import { FlexRow, CreditReportContentContainer, Card } from 'components/Layouts';
import { storeApplication, fetchApplicationById } from 'store/sagas/applications';
import { fetchApplicantCreditReportThunk } from 'store/thunks/applicant';
import { setAlert } from 'store/sagas/app';

import { buildBreadcrumbs } from './utils';
import DeterminateProgressWithLabel from './DeterminateProgressWithLabel';
import Tradeline from './Tradeline';
import CardTable from './CardTable';

const CreditReport = () => {
  const dispatch = useDispatch();
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [creditReport, setCreditReport] = useState({});

  const { addresses, credit, employmentHistory, identities, inquiries, meta, tradeline } = creditReport;
  const openInstallmentTradelines = tradeline?.filter((line) => line.open && line.revolvingOrInstallment === 'I');
  const openRevolvingTradelines = tradeline?.filter((line) => line.open && line.revolvingOrInstallment === 'R');
  const closedTradelines = tradeline?.filter((line) => !line.open);

  const { id } = useParams();
  const currentApplication = useSelector((state) => state.applications.currentApplication);
  const applicantId = currentApplication?.applicant?.id;

  const fetchCreditReport = async () => {
    setLoading(true);
    try {
      const { creditReport } = await dispatch(fetchApplicantCreditReportThunk({ applicantId })).unwrap();
      if (!creditReport) {
        setCreditReport({});
      } else {
        setCreditReport(creditReport);
      }
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: 'Unable to find credit report for this applicant. Try again later.',
          showing: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (applicantId) {
      fetchCreditReport();
    }
  }, [applicantId]);

  useEffect(() => {
    dispatch(fetchApplicationById(id));
    setInitialLoad(false);

    return () => {
      dispatch(storeApplication({}));
    };
  }, [id]);

  const [backButton, breadcrumbs] = buildBreadcrumbs({ currentApplication });

  if (loading) {
    return (
      <CreditReportContentContainer>
        <CircularProgress />
      </CreditReportContentContainer>
    );
  }

  if (!meta?.reportDate || !applicantId) {
    return (
      <CreditReportContentContainer>
        <Alert severity="error">No Credit Report Available</Alert>
      </CreditReportContentContainer>
    );
  }

  return (
    <Fragment>
      <NavBar backButton={backButton} breadcrumbs={breadcrumbs} />
      <CreditReportContentContainer>
        {/* Used to keep all the content in the center with a max width of 1080px. */}
        <div style={{ width: '100%', maxWidth: '1080px', padding: 0, margin: 0 }}>
          {/* Name, Credit Report Type, Date of Report */}
          <Typography variant="h4">{`${currentApplication?.applicant?.first_name} ${currentApplication?.applicant?.last_name} ${credit?.model?.description}`}</Typography>
          <Typography variant="caption">{`Report generated on ${format(parseISO(meta.reportDate), 'PP')}`}</Typography>

          {/* Grid Container */}
          <Grid sx={{ mt: '16px' }} container spacing={2}>
            {/* Credit Score */}
            <Grid xs={6}>
              <Typography variant="h6">Score</Typography>
              <Card variant="outlined" background>
                <FlexRow padding={0} margin={0} alignItems="center" fullWidth justifyContent="center">
                  <DeterminateProgressWithLabel value={((credit?.score - 300) / (850 - 300)) * 100} label={credit?.score} />
                </FlexRow>
              </Card>
            </Grid>

            {/* Credit Score Percentile */}
            <Grid xs={6}>
              <Typography variant="h6">Percentile</Typography>
              <Card variant="outlined" background>
                <FlexRow padding={0} margin={0} alignItems="center" fullWidth justifyContent="center">
                  <DeterminateProgressWithLabel value={credit?.percentile} label={`${credit?.percentile}%`} />
                </FlexRow>
              </Card>
            </Grid>

            {/* Credit Score Factors */}
            <Grid sx={{ mt: 2 }} xs={12}>
              <Typography variant="h6">Factors</Typography>
              <CardTable
                headers={[
                  { heading: 'Code', dataKey: 'code' },
                  { heading: 'Description', dataKey: 'description' },
                ]}
                data={credit?.factors?.map(({ factor }) => ({
                  code: factor?.code,
                  description: factor?.description,
                }))}
              />
            </Grid>

            {/* Identities Found on Credit Report */}
            <Grid sx={{ mb: -2, mt: 2 }} xs={12}>
              <Typography variant="h6">Alternate Identities</Typography>
            </Grid>
            {identities?.map((identity, i) => (
              <Grid key={`${identity?.firstName}-${i}`} xs={4}>
                <Card variant="outlined" background>
                  <Typography component="p">
                    {identity?.middleName
                      ? `${identity?.firstName} ${identity?.middleName} ${identity?.lastName}`
                      : `${identity?.firstName} ${identity?.lastName}`}
                  </Typography>

                  <Typography sx={{ mt: 1 }} component="p" variant="caption">{`Type: ${identity?.type?.description}`}</Typography>
                </Card>
              </Grid>
            ))}

            {/* Addresses Found on Credit Report */}
            <Grid sx={{ mb: -2, mt: 2 }} xs={12}>
              <Typography variant="h6">Addresses</Typography>
            </Grid>
            {addresses?.map((address, i) => (
              <Grid key={`${address?.address_1}-${i}`} xs={4}>
                <Card variant="outlined" background>
                  <Typography component="p">{address?.address_1}</Typography>
                  {address?.address_2 && <Typography component="p">{address?.address_2}</Typography>}
                  <Typography component="p">{`${address?.city}, ${address?.state} ${address?.zip}`}</Typography>

                  <Typography sx={{ mt: 1 }} component="p" variant="caption">{`Dwelling Type: ${address?.dwellingType?.description}`}</Typography>
                  <Typography component="p" variant="caption">{`Reported: ${format(parseISO(address?.reported), 'PP')}`}</Typography>
                  <Typography component="p" variant="caption">{`Updated: ${format(parseISO(address?.updated), 'PP')}`}</Typography>
                </Card>
              </Grid>
            ))}

            {/* Employment History Found on Credit Report */}
            <Grid sx={{ mb: -2, mt: 2 }} xs={12}>
              <Typography variant="h6">Employment History</Typography>
            </Grid>
            {employmentHistory?.map((employer, i) => (
              <Grid key={`${employer?.name}-${i}`} xs={4}>
                <Card variant="outlined" background>
                  <Typography component="p">{employer?.name}</Typography>

                  <Typography sx={{ mt: 1 }} component="p" variant="caption">{`Reported: ${format(parseISO(employer?.reported), 'PP')}`}</Typography>
                  <Typography component="p" variant="caption">{`Updated: ${format(parseISO(employer?.updated), 'PP')}`}</Typography>
                </Card>
              </Grid>
            ))}

            {/* Inquiries */}
            <Grid sx={{ mt: 2 }} xs={12}>
              <Typography variant="h6">Inquiries</Typography>
              <CardTable
                headers={[
                  { heading: 'Inquirer', dataKey: 'inquirer' },
                  { heading: 'Type of Inquiry', dataKey: 'type' },
                  { heading: 'Kind of Business', dataKey: 'kob' },
                  { heading: 'Date', dataKey: 'date' },
                ]}
                data={inquiries.map(({ inquirer, date, kob, type }) => ({
                  inquirer,
                  date: format(parseISO(date), 'PP'),
                  kob: kob?.description,
                  type: type?.description,
                }))}
              />
            </Grid>

            {/* Open Installment Tradelines */}
            <Grid sx={{ mb: -2, mt: 2 }} xs={12}>
              <Typography variant="h6">Open Installment Tradelines</Typography>
            </Grid>
            {openInstallmentTradelines.map((line, i) => (
              <Tradeline key={`${line?.accountNumber}-${i}`} tradelineData={line} />
            ))}

            {/* Open Revolving Tradelines */}
            <Grid sx={{ mb: -2, mt: 2 }} xs={12}>
              <Typography variant="h6">Open Revolving Tradelines</Typography>
            </Grid>
            {openRevolvingTradelines.map((line, i) => (
              <Tradeline key={`${line?.accountNumber}-${i}`} tradelineData={line} />
            ))}

            {/* Closed Tradelines */}
            <Grid sx={{ mb: -2, mt: 2 }} xs={12}>
              <Typography variant="h6">Closed Tradelines</Typography>
            </Grid>
            {closedTradelines.map((line, i) => (
              <Tradeline key={`${line?.accountNumber}-${i}`} tradelineData={line} />
            ))}
          </Grid>
        </div>
      </CreditReportContentContainer>
    </Fragment>
  );
};

export default CreditReport;
