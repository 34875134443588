import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import isEqual from 'lodash/isEqual';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { FlexRow } from 'components/Layouts';
import { useBuildFormik, buildFormikFields } from 'components/Formiks - Deprecated';

import { possibleValuesFormInfo } from './formInfo';
import { COLORS } from 'constants/styles';

const EditPossibleValue = ({ setEditingPossibleValue, possibleValue, setPossibleValue, addPossibleValue }) => {
  const cancelEdit = () => {
    setEditingPossibleValue(false);
    setPossibleValue({});
  };

  const initialValues = { ...possibleValue, possible_keys: possibleValue.possible_keys ? possibleValue.possible_keys.join(',') : '' };

  const formik = useBuildFormik({
    formInfo: Object.values(possibleValuesFormInfo),
    onSubmit: (values) => {
      handleSave(values);
    },
    values: initialValues,
  });

  const handleSave = (values) => {
    if (values.possible_keys) {
      const cleanedString = values.possible_keys.replace(/[^a-zA-Z0-9.,_]/g, '');
      const splitString = cleanedString.split(',');
      values.possible_keys = splitString;
    } else {
      values.possible_keys = [];
    }

    addPossibleValue(values);
  };

  return (
    <FlexRow rounded style={{ background: COLORS.backgroundColor }} outlined margin="0" padding="20px" flexColumn>
      <Typography variant="h6">{initialValues.id ? 'Edit Possible Value' : 'Add Possible Value'}</Typography>

      <FlexRow padding="0" margin="0">
        <Alert sx={{ width: '100%' }} severity="warning">
          <AlertTitle>Rules</AlertTitle>
          <ul>
            <li>- "Name" is simply an identifier for the possible value.</li>
            <li>- "Possible Keys" are the keys that the system should look for after we sending the document to Butler.AI to extract values.</li>
          </ul>
        </Alert>
      </FlexRow>

      {buildFormikFields({
        formik,
        fields: Object.values(possibleValuesFormInfo),
        fieldsPerRow: 2,
      })}

      <FlexRow margin="0" padding="10px 0px 0px" justifyContent="flex-end">
        <Button onClick={cancelEdit}>Cancel</Button>
        <Button onClick={formik.handleSubmit} disabled={isEqual(initialValues, formik.values)} sx={{ marginLeft: '10px' }} variant="contained">
          Save
        </Button>
      </FlexRow>
    </FlexRow>
  );
};

export default EditPossibleValue;
