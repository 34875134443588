import { takeLatest, call, put } from 'redux-saga/effects';

import ApplicationAPI from 'store/api/origination/application';
import DealerAPI from 'store/api/core/dealer';
import LenderAPI from 'store/api/core/lender';
import StipulationAPI from 'store/api/origination/stipulation';
import { setAlertSaga } from 'store/sagas/app/sagas';
import {
  updateApplication,
  updateApplicationStatus,
  storeApplication,
  fetchDealerApplications,
  fetchLenderApplications,
  storeApplications,
  storeApplicationMetadata,
  fetchApplicationById,
  updateLendingOption,
  addLendingOption,
  generateDocuments,
  updateStipulation,
  createApplication,
  submitApplication,
  uploadDocumentToStipulation,
  editUploads,
  deleteUpload,
  deleteDocumentFromStipulation,
  updateApprovedApplication,
  fetchApplicationTypes,
  storeApplicationTypes,
  startLoader,
  stopLoader,
  storeLendingOptions,
  setShouldFetchApplications,
} from './index';
import { logUserOut } from '../user/index';
import { startAppLoading, stopAppLoading } from '../app';

export function* updateApplicationSaga({ payload: { data, applicationId } }) {
  try {
    yield put(startAppLoading());
    const { application } = yield call(ApplicationAPI.updateApplication, { data, applicationId });
    yield put(storeApplication(application));
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Application saved.',
        showing: true,
      },
    });
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Error. Please try again later.',
          showing: true,
        },
      });
      err.tip = 'Error at updateApplicationSaga';
      console.log(err);
    }
  }
}

export function* updateApplicationStatusSaga({ payload: { data, applicationId } }) {
  try {
    yield put(startAppLoading());
    const { application } = yield call(ApplicationAPI.updateApplicationStatus, { data, applicationId });
    yield put(storeApplication(application));
    yield put(setShouldFetchApplications(true));
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Application status updated.',
        showing: true,
      },
    });
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Error. Please try again later.',
          showing: true,
        },
      });
      err.tip = 'Error at updateApplicationStatusSaga';
      console.log(err);
    }
  }
}

export function* updateApprovedApplicationSaga({ payload: { data, applicationId } }) {
  try {
    yield put(startAppLoading());
    const { application } = yield call(ApplicationAPI.updateApprovedApplication, { data, applicationId });
    yield put(storeApplication(application));
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Application saved.',
        showing: true,
      },
    });
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Error. Please try again later.',
          showing: true,
        },
      });
      err.tip = 'Error at updateApprovedApplicationSaga';
      console.log(err);
    }
  }
}

export function* createApplicationSaga({ payload: { data } }) {
  try {
    yield put(startAppLoading());
    const { application } = yield call(ApplicationAPI.createApplication, { data });
    yield put(storeApplication(application));
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Application saved.',
        showing: true,
      },
    });
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Error. Please try again later.',
          showing: true,
        },
      });
      err.tip = 'Error at createApplicationSaga';
      console.log(err);
    }
  }
}

export function* fetchDealerApplicationsSaga({ payload }) {
  try {
    yield put(startAppLoading());
    const { applications, metadata } = yield call(DealerAPI.fetchDealerApplications, { ...payload });
    const valToStore = !applications ? [] : !applications.length ? [applications] : applications;
    yield put(storeApplications(valToStore));
    yield put(storeApplicationMetadata(metadata));
    yield put(setShouldFetchApplications(false));
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      err.tip = 'Error at fetchDealerApplicationsSaga';
      console.log(err);
    }
  }
}

export function* fetchLenderApplicationsSaga({ payload }) {
  try {
    yield put(startAppLoading());
    const { applications, metadata } = yield call(LenderAPI.fetchLenderApplications, { ...payload });
    const valToStore = !applications ? [] : !applications.length ? [applications] : applications;
    yield put(storeApplications(valToStore));
    yield put(storeApplicationMetadata(metadata));
    yield put(setShouldFetchApplications(false));
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      err.tip = 'Error at fetchLenderApplicationsSaga';
      console.log(err);
    }
  }
}

export function* fetchApplicationByIdSaga({ payload }) {
  try {
    yield put(startAppLoading());
    const { application } = yield call(ApplicationAPI.fetchApplicationById, { id: payload });
    yield put(storeApplication(application));
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      err.tip = 'Error at fetchApplicationByIdSaga';
      console.log(err);
    }
  }
}

export function* updateLendingOptionSaga({ payload }) {
  try {
    yield put(startAppLoading());
    const { applicationId, lendingOptionId, ...data } = payload;
    const { application } = yield call(ApplicationAPI.updateLendingOption, { applicationId, lendingOptionId, data });

    yield put(storeLendingOptions(application.lending_options));
    yield put(storeApplication(application));

    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      err.tip = 'Error at updateLendingOptionSaga';
      console.log(err);
    }
  }
}

export function* addLendingOptionSaga({ payload }) {
  try {
    yield put(startAppLoading());
    const { applicationId, ...data } = payload;
    const {
      application: { lending_options },
    } = yield call(ApplicationAPI.addLendingOption, { applicationId, data });
    yield put(storeLendingOptions(lending_options));
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      err.tip = 'Error at addLendingOptionSaga';
      console.log(err);
    }
  }
}

export function* generateDocumentsSaga({ payload: { data, applicationId } }) {
  try {
    yield put(startAppLoading('This might take a while...'));
    const { application } = yield call(ApplicationAPI.generateDocuments, { data, applicationId });
    yield put(storeApplication(application));
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      err.tip = 'Error at generateDocumentsSaga';
      console.log(err);
    }
  }
}

export function* updateStipulationSaga({ payload: { data, stipulationId, applicationId } }) {
  try {
    yield put(startAppLoading());
    yield call(ApplicationAPI.updateStipulation, { data, stipulationId });
    const { application } = yield call(ApplicationAPI.fetchApplicationById, { id: applicationId });
    yield put(storeApplication(application));
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      err.tip = 'Error at updateStipulationSaga';
      console.log(err);
    }
  }
}

export function* submitApplicationSaga({ payload: { data, applicationId } }) {
  try {
    yield put(startAppLoading());
    const { application } = yield call(ApplicationAPI.submitApplication, { data, applicationId });
    yield put(storeApplication(application));
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        // Means the application has underwriting issues and was not submitted but was just saved.
        message: application?.underwriting?.underwriting_status ? 'Application saved.' : 'Application submitted.',
        showing: true,
      },
    });
    yield put(stopAppLoading());
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Error. Please try again later.',
          showing: true,
        },
      });
      err.tip = 'Error at submitApplicationSaga';
      console.log(err);
    }
  }
}

export function* uploadDocumentToStipulationSaga({ payload: { file, stipulationId, applicationId } }) {
  try {
    yield put(editUploads({ id: stipulationId }));
    const formData = new FormData();
    formData.append('file', file, file.name);
    yield call(StipulationAPI.uploadDocumentToStipulation, { formData, stipulationId });
    yield put(deleteUpload({ id: stipulationId }));
    yield call(setAlertSaga, {
      payload: {
        type: 'success',
        message: 'Document Uploaded.',
        showing: true,
      },
    });
    yield call(fetchApplicationByIdSaga, {
      payload: applicationId,
    });
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Document upload failed. Please try another document.',
          showing: true,
        },
      });
      yield put(deleteUpload({ id: stipulationId }));
      err.tip = 'Error at uploadDocumentToStipulationSaga';
      console.log(err);
    }
  }
}

export function* deleteDocumentFromStipulationSaga({ payload: { stipulationId, applicationId } }) {
  try {
    yield put(startAppLoading());
    yield call(StipulationAPI.deleteDocumentFromStipulation, { stipulationId });
    yield call(fetchApplicationByIdSaga, {
      payload: applicationId,
    });
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopAppLoading());
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Document delete failed. Please try again later.',
          showing: true,
        },
      });
      err.tip = 'Error at deleteDocumentFromStipulationSaga';
      console.log(err);
    }
  }
}

export function* fetchApplicationTypesSaga() {
  try {
    yield put(startLoader('application_types'));
    const { application_types } = yield call(ApplicationAPI.fetchApplicationTypes);
    yield put(storeApplicationTypes(application_types));
    yield put(stopLoader('application_types'));
  } catch (err) {
    if (err.response.status === 401) {
      // If unauthorized response then logout user.
      return yield put(logUserOut());
    } else {
      yield put(stopLoader('application_types'));
      yield call(setAlertSaga, {
        payload: {
          type: 'error',
          message: 'Failed to fetch application types. Please try again later.',
          showing: true,
        },
      });
      err.tip = 'Error at fetchTypesSaga';
      console.log(err);
    }
  }
}

export const applicationsSagas = [
  takeLatest(updateApplication.type, updateApplicationSaga),
  takeLatest(updateApplicationStatus.type, updateApplicationStatusSaga),
  takeLatest(updateApprovedApplication.type, updateApprovedApplicationSaga),
  takeLatest(fetchDealerApplications.type, fetchDealerApplicationsSaga),
  takeLatest(fetchLenderApplications.type, fetchLenderApplicationsSaga),
  takeLatest(fetchApplicationById.type, fetchApplicationByIdSaga),
  takeLatest(updateLendingOption.type, updateLendingOptionSaga),
  takeLatest(addLendingOption.type, addLendingOptionSaga),
  takeLatest(generateDocuments.type, generateDocumentsSaga),
  takeLatest(updateStipulation.type, updateStipulationSaga),
  takeLatest(createApplication.type, createApplicationSaga),
  takeLatest(submitApplication.type, submitApplicationSaga),
  takeLatest(uploadDocumentToStipulation.type, uploadDocumentToStipulationSaga),
  takeLatest(deleteDocumentFromStipulation.type, deleteDocumentFromStipulationSaga),
  takeLatest(fetchApplicationTypes.type, fetchApplicationTypesSaga),
];

export default applicationsSagas;
