import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import { DrawerContainer, DrawerHeaderContainer, DrawerContentContainer, DrawerActionsContainer } from 'components/Drawer';
import { PaymentProfileAPI } from 'store/api';
import { setAlert } from 'store/sagas/app';
import { COLORS } from 'constants/styles';
import { PaymentProfileCardForDrawer } from './PaymentProfileCard';
import { Card, FlexRow } from 'components/Layouts';

const PaymentProfileDrawer = ({
  handleClose,
  open,
  formie,
  handleSubmit,
  editing,
  primaryCustomer,
  secondarycustomer,
  allPrimaryCustomerPaymentProfiles,
  allSecondaryCustomerPaymentProfiles,
  linkPaymentProfile,
  creditCardFormie,
  handleSubmitCC,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [accountType, setAccountType] = useState('bank'); // Can be bank or credit.

  const verb = editing ? 'Edit' : 'Add';
  const title = `${verb} Payment Method`;

  const lookupRoutingNumber = async (routingNumber) => {
    if (!routingNumber) return;

    setLoading(true);
    try {
      const { institution } = await PaymentProfileAPI.lookupRoutingNumber({ routingNumber });
      if (institution?.name) {
        formie.handleChange('paymentProfile.institution_name', institution?.name);
      } else {
        formie.handleChange('paymentProfile.institution_name', null);
      }
    } catch (err) {
      !err?.response?.status === 404
        ? dispatch(
            setAlert({
              type: 'error',
              message: `Unable to lookup routing number. Try again later.`,
              showing: true,
            }),
          )
        : dispatch(
            setAlert({
              type: 'warning',
              message: `No bank found with that routing number.`,
              showing: true,
            }),
          );
    } finally {
      setLoading(false);
    }
  };

  const chooseAddNewPaymentProfile = (accountType) => {
    setAccountType(accountType);
    setDeclined(true);
  };

  useEffect(() => {
    if (!open) {
      setDeclined(false);
      setAccountType('bank');
    }
  }, [open]);

  const { paymentProfile } = formie?.values;
  const { paymentProfile: paymentProfileCC } = creditCardFormie?.values;
  const disabled =
    !paymentProfile?.customer_id ||
    !paymentProfile?.account_type ||
    !paymentProfile?.account_first_name ||
    !paymentProfile?.account_last_name ||
    !paymentProfile?.account_number ||
    !paymentProfile?.routing_number ||
    !paymentProfile?.institution_name ||
    !paymentProfile?.profile_name;

  const disabledCC =
    !paymentProfileCC?.customer_id ||
    !paymentProfileCC?.profile_name ||
    !paymentProfileCC?.account_number ||
    !paymentProfileCC?.account_first_name ||
    !paymentProfileCC?.account_last_name ||
    !paymentProfileCC?.cvv_code ||
    !paymentProfileCC?.expiry_date ||
    !paymentProfileCC?.card_zip;

  return (
    <Drawer onClose={handleClose} open={open} anchor="right" ModalProps={{ disableEscapeKeyDown: true }}>
      {/* Used to set the width of the drawer.  */}
      <DrawerContainer>
        {/* Header and Title */}
        <DrawerHeaderContainer>
          <Typography align="center" variant="h5">
            {title}
          </Typography>
        </DrawerHeaderContainer>

        {/* Show screen to add from existing payment profiles already linked to the customer add beginning of workflow if not editing. */}
        {!editing && !declined ? (
          <DrawerContentContainer background>
            {/* Show the profiles for the primary customer. */}
            {allPrimaryCustomerPaymentProfiles && allPrimaryCustomerPaymentProfiles.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mb: '8px' }}>{`${primaryCustomer?.first_name} ${primaryCustomer?.last_name}'s Payment Profiles`}</Typography>
                <div style={{ margin: '0px', padding: '0px 10px', boxSizing: 'border-box' }}>
                  {allPrimaryCustomerPaymentProfiles.map((profile) => (
                    <PaymentProfileCardForDrawer linkFn={() => linkPaymentProfile(primaryCustomer.id, profile.id)} key={profile?.id} paymentProfile={profile} />
                  ))}
                </div>
              </>
            )}

            {/* Show the profiles for the secondary customer if there is one. */}
            {allSecondaryCustomerPaymentProfiles && allSecondaryCustomerPaymentProfiles.length > 0 && (
              <div>
                <Typography variant="h6" sx={{ mb: '8px' }}>{`${secondarycustomer?.first_name} ${secondarycustomer?.last_name}'s Payment Profiles`}</Typography>
                <div style={{ margin: '0px', padding: '0px 10px', boxSizing: 'border-box' }}>
                  {allSecondaryCustomerPaymentProfiles.map((profile) => (
                    <PaymentProfileCardForDrawer
                      linkFn={() => linkPaymentProfile(secondarycustomer.id, profile.id)}
                      key={profile?.id}
                      paymentProfile={profile}
                    />
                  ))}
                </div>
              </div>
            )}

            {/* If none of these, give them the option to choose from bank account or credit card. */}
            <Typography variant="h6" sx={{ mb: '8px' }}>
              Don't see what you need?
            </Typography>
            <FlexRow gap="8px" margin="0px" padding="0px 10px">
              {/* Always allow adding bank account */}
              <Card margin="0px" padding="0px" fullwidth variant="outlined">
                <FlexRow padding="8px" margin="0px" justifyContent="center" alignItems="center" fullHeight>
                  <Button onClick={() => chooseAddNewPaymentProfile('bank')} startIcon={<AccountBalanceOutlinedIcon />}>
                    Add Bank Account
                  </Button>
                </FlexRow>
              </Card>

              {/* Only allow adding credit card if this is the backup payment method. */}
              <Card margin="0px" padding="0px" fullwidth variant="outlined">
                <FlexRow padding="8px" margin="0px" justifyContent="center" alignItems="center" fullHeight>
                  <Button onClick={() => chooseAddNewPaymentProfile('credit')} startIcon={<CreditCardOutlinedIcon />}>
                    Add Credit Card
                  </Button>
                </FlexRow>
              </Card>
            </FlexRow>
          </DrawerContentContainer>
        ) : (
          // If they have declined to add from existing profiles, or are editing, show this content.
          <DrawerContentContainer>
            {/* If adding a credit card, show this. */}
            {accountType === 'credit' ? (
              <>
                <Typography sx={{ fontWeight: 700, mb: '12px' }}>Who should we link this card to?</Typography>
                {creditCardFormie.buildField('paymentProfile.customer_id')}

                <Typography sx={{ fontWeight: 700, mt: '32px' }}>What name is on the card?</Typography>
                {creditCardFormie.buildFields({
                  fields: [{ dataPath: 'paymentProfile.account_first_name' }, { dataPath: 'paymentProfile.account_last_name' }],
                  fieldsPerRow: 2,
                })}

                <Typography sx={{ fontWeight: 700, mt: '32px' }}>What are the card details?</Typography>
                {creditCardFormie.buildFields({
                  fields: [
                    { dataPath: 'paymentProfile.account_number' },
                    { dataPath: 'paymentProfile.cvv_code' },
                    { dataPath: 'paymentProfile.expiry_date' },
                    { dataPath: 'paymentProfile.card_zip' },
                  ],
                  fieldsPerRow: 2,
                })}

                <Typography sx={{ fontWeight: 700, mb: '12px', mt: '32px' }}>What should we call this payment method?</Typography>
                {creditCardFormie.buildField('paymentProfile.profile_name')}
              </>
            ) : (
              // If editing a bank account or adding a bank account, show this.
              <>
                {/* You can't edit who the payment method is linked to here, so don't show this during edit. */}
                {!editing && (
                  <>
                    <Typography sx={{ fontWeight: 700, mb: '12px' }}>Who should we link this payment method to?</Typography>
                    {formie.buildField('paymentProfile.customer_id')}
                  </>
                )}

                <Typography sx={{ fontWeight: 700, mb: '12px', mt: !editing ? '32px' : '0px' }}>What type of account is this?</Typography>
                {formie.buildField('paymentProfile.account_type')}

                <Typography sx={{ fontWeight: 700, mt: '32px' }}>What name is on the account?</Typography>
                {formie.buildFields({
                  fields: [{ dataPath: 'paymentProfile.account_first_name' }, { dataPath: 'paymentProfile.account_last_name' }],
                  fieldsPerRow: 2,
                })}

                <Typography sx={{ fontWeight: 700, mt: '32px' }}>What are the account details?</Typography>
                {formie.buildFields({
                  fields: [
                    { dataPath: 'paymentProfile.account_number' },
                    { dataPath: 'paymentProfile.routing_number', extraProps: { additionalBlurFunction: lookupRoutingNumber } },
                  ],
                  fieldsPerRow: 2,
                })}

                <div style={{ margin: '0px', padding: '0px', marginTop: '12px' }}>{formie.buildField('paymentProfile.institution_name')}</div>

                <Typography sx={{ fontWeight: 700, mb: '12px', mt: '32px' }}>What should we call this payment method?</Typography>
                {formie.buildField('paymentProfile.profile_name')}
              </>
            )}
          </DrawerContentContainer>
        )}

        <DrawerActionsContainer>
          <Button onClick={handleClose} sx={{ mr: '16px' }}>
            Cancel
          </Button>

          {/* Only show the add/edit button if they are in the add/edit workflow. */}
          {/* TODO: change handle submit based on if I'm using the CC or the Bank Account Workflow. Same with disabled. */}
          {!(!editing && !declined) &&
            (accountType === 'credit' ? (
              <Button onClick={handleSubmitCC} disabled={disabledCC} variant="contained">
                Add
              </Button>
            ) : (
              <Button onClick={handleSubmit} disabled={disabled} variant="contained">
                {editing ? 'Edit' : 'Add'}
              </Button>
            ))}
        </DrawerActionsContainer>
      </DrawerContainer>

      <Backdrop sx={{ color: COLORS.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Drawer>
  );
};

export default PaymentProfileDrawer;
