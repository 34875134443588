import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { formatISO } from 'date-fns';

import { useFormie } from 'components/Formie';
import { TYPES } from 'components/Formie/inputs';
import { validateEmail, validateOTP, validatePhone } from 'components/Formie/validators';
import { FlexRow } from 'components/Layouts';
import { formatPhoneNumberV2 } from 'constants/formatters';

const SCREENS = {
  TRIGGER: 'trigger',
  CODE: 'code',
};

// Accepts phone and email as channels.
const VerifyModal = ({ channel, to, contactName, sendOTP, checkOTP, open, readOnly = false, handleSuccess, handleClose }) => {
  const [screen, setScreen] = useState(SCREENS.TRIGGER);
  const [loading, setLoading] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [startError, setStartError] = useState(false);

  const formie = useFormie({
    configuration: {
      phone: {
        type: TYPES.phone,
        dataPath: 'phone',
        required: true,
        validator: validatePhone,
        label: 'Phone Number',
        readOnly,
      },
      email: {
        type: TYPES.email,
        dataPath: 'email',
        required: true,
        validator: validateEmail,
        label: 'Email Address',
        readOnly,
      },
      code: {
        type: TYPES.OTP,
        dataPath: 'code',
        required: true,
        validator: validateOTP,
        label: 'Code',
      },
      phoneChannel: {
        type: TYPES.toggle,
        dataPath: 'phoneChannel',
        required: true,
        options: [
          {
            value: 'sms',
            label: 'Text',
          },
          {
            value: 'call',
            label: 'Call',
          },
        ],
      },
    },

    initialValues: {
      phone: channel === 'phone' ? to : null,
      email: channel === 'email' ? to : null,
      code: null,
      phoneChannel: 'sms', // Can be SMS or Call
    },
  });

  const hasError = channel === 'phone' ? formie?.errors?.phone : channel === 'email' ? formie?.errors?.email : false;
  const codeHasError = formie?.errors?.code;
  const hasValue = channel === 'phone' ? !!formie?.values?.phone : channel === 'email' ? !!formie?.values?.email : false;
  const codeHasValue = !!formie?.values?.code;

  const formattedTo = channel === 'phone' ? formatPhoneNumberV2(formie?.values?.phone) : formie?.values?.email;

  const handleTriggerVerify = async () => {
    setCodeError(false);
    setStartError(false);
    setLoading(true);

    try {
      const response = await sendOTP({
        to: channel === 'phone' ? formie.values.phone : formie.values.email,
        timestamp: formatISO(new Date()),
        channel: channel === 'phone' ? formie.values.phoneChannel : channel,
      });

      if (response?.sent) {
        setScreen(SCREENS.CODE);
      }
    } catch (err) {
      setStartError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckOtp = async () => {
    setLoading(true);

    const response = await checkOTP({
      to: channel === 'phone' ? formie.values.phone : formie.values.email,
      code: formie.values.code,
      channel: channel === 'phone' ? formie.values.phoneChannel : channel,
    });

    setLoading(false);

    if (response?.verified) {
      handleClose();
      handleSuccess();
    } else {
      setCodeError(true);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" disableEscapeKeyDown open={open}>
      {loading ? (
        <FlexRow padding="50px 0px" justifyContent="center" alignItems="center">
          <CircularProgress color="primary" />
        </FlexRow>
      ) : (
        <>
          {screen === SCREENS.TRIGGER && (
            <>
              <DialogTitle>{channel === 'phone' ? 'Verify Phone Number' : 'Verify Email Address'}</DialogTitle>

              <DialogContent>
                <DialogContentText sx={{ m: '0px 0px 20px' }}>
                  {`By clicking "Send Code", you warrant that ${
                    contactName ? contactName : 'this contact'
                  } has consented to receiving a one-time passcode at ${formattedTo} from Trak Tech.`}
                </DialogContentText>

                {startError && (
                  <Alert sx={{ mb: '20px' }} severity="error">
                    That didn't work! Double check your contact details and try again.
                  </Alert>
                )}

                {channel === 'email' && formie.buildField('email')}
                {channel === 'phone' &&
                  formie.buildFields({
                    fields: [{ dataPath: 'phone' }, { dataPath: 'phoneChannel' }],
                    fieldsPerRow: 2,
                  })}
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>

                <Button onClick={handleTriggerVerify} disabled={hasError || !hasValue} variant="contained">
                  Send Code
                </Button>
              </DialogActions>
            </>
          )}

          {screen === SCREENS.CODE && (
            <>
              <DialogTitle>Enter Code</DialogTitle>

              <DialogContent>
                <DialogContentText sx={{ m: '0px 0px 20px' }}>Enter the one time passcode you received and press "Verify".</DialogContentText>
                {codeError && (
                  <Alert sx={{ mb: '10px' }} severity="error">
                    That didn't work! Try a different code, or press "Send new code" to try again.
                  </Alert>
                )}
                {formie.buildField('code')}
              </DialogContent>

              <DialogActions>
                <FlexRow padding="0px" margin="0px" justifyContent="space-between" alignItems="center">
                  <Button onClick={handleTriggerVerify}>Send New Code</Button>
                  <Button disabled={codeHasError || !codeHasValue} onClick={handleCheckOtp} variant="contained">
                    Verify
                  </Button>
                </FlexRow>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default VerifyModal;
