import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { baseTextField, baseSelect } from 'components/Formiks - Deprecated/constants';
import { FlexRow } from 'components/Layouts';
import { Typography } from '@mui/material';
import validators from 'components/Formiks - Deprecated/validators';

export const OPERATIONS = [
  { label: 'Greater than', id: '>' },
  { label: 'Greater than or equal to', id: '>=' },
  { label: 'Equal To', id: '===' },
  { label: 'Not Equal To', id: '!==' },
  { label: 'Less than or equal to', id: '<=' },
  { label: 'Less Than', id: '<' },
  { label: 'Exists', id: 'exists' },
  { label: 'Does not exist', id: 'does not exist' },
];

export const stipulationTemplateFormInfo = (document_templates) => ({
  description: {
    ...baseTextField,
    label: 'Description',
    errorText: 'Please provide a valid description.',
    parameterName: 'description',
    multiline: true,
  },
  document_template_id: {
    ...baseSelect,
    label: 'Document Template',
    errorText: 'Please select a Document Template.',
    parameterName: 'document_template_id',
    options: document_templates || [],
    customRenderOption: (props, option) => {
      return (
        <Box component="li" {...props} key={option.id}>
          <FlexRow padding="0" margin="0" alignItems="center">
            <InsertDriveFileIcon color="primary" sx={{ fontSize: '30px' }} />

            <FlexRow padding="0" margin="0px 0px 0px 10px" flexColumn>
              <Typography sx={{ fontWeight: 500 }} variant="body1">
                {option.label}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="flex-start">
                {[
                  { display: option.is_signable, label: 'Signable', color: 'default' },
                  { display: option.is_fillable, label: `Fillable (${option.document_template_fields.length} Fields)`, color: 'default' },
                  { display: option.is_state_specific, label: `State: ${option.state}`, color: 'default' },
                ]
                  .filter((chip) => chip.display)
                  .map((chip) => (
                    <Chip key={chip.label} color={chip.color} label={chip.label} size="small" />
                  ))}
              </Stack>
            </FlexRow>
          </FlexRow>
        </Box>
      );
    },
  },
  name: {
    ...baseTextField,
    label: 'Name',
    errorText: 'Please provide a valid name.',
    parameterName: 'name',
  },
});

export const triggerFormInfo = (applicationShape, position, showCompareValue) => ({
  attribute: {
    ...baseSelect,
    label: 'Attribute',
    errorText: 'Please select an attribute',
    parameterName: `stipulation_template_triggers[${position}].attribute`,
    options: applicationShape ? applicationShape.map((attribute) => ({ id: attribute, label: attribute })) : [],
  },
  operation: {
    ...baseSelect,
    label: 'Compare Operation',
    errorText: 'Please select an operation',
    parameterName: `stipulation_template_triggers[${position}].operation`,
    options: OPERATIONS,
  },
  compare_value: {
    ...baseTextField,
    label: 'Value',
    errorText: 'Please provide a valid value.',
    parameterName: `stipulation_template_triggers[${position}].compare_value`,
    required: showCompareValue,
    validator: showCompareValue ? validators.required.textField : validators.textField,
  },
});

export const fulfillmentFormInfo = (applicationShape, position) => ({
  field: {
    ...baseSelect,
    label: 'Field',
    errorText: 'Please select a field',
    parameterName: `stipulation_template_fulfillments[${position}].field`,
    options: [],
  },
  operation: {
    ...baseSelect,
    label: 'Compare Operation',
    errorText: 'Please select an operation',
    parameterName: `stipulation_template_fulfillments[${position}].operation`,
    options: OPERATIONS,
  },
  field_value: {
    ...baseTextField,
    label: 'Value',
    errorText: 'Please provide a valid value.',
    parameterName: `stipulation_template_fulfillments[${position}].field_value`,
  },
});
