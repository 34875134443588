import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { initializeStore } from 'store';
import routes from './Routes.js';
import { COLORS } from './constants/styles';

import './index.css';

const { primary, secondary, error, warning, info, success } = COLORS;

let theme = createTheme({
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.backgroundColor,
          '&.Mui-focused': {
            backgroundColor: COLORS.backgroundColor,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
    warning: {
      main: warning,
    },
    info: {
      main: info,
    },
    success: {
      main: success,
    },
    white: {
      main: '#fff',
      contrastText: '#000',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = initializeStore();

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <RouterProvider router={routes} />
    </Provider>
  </ThemeProvider>,
);
