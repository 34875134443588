import { Typography } from '@mui/material';

import ListItem from 'components/ListItem';
import { currencyFormatter } from 'constants/formatters';
import { Card } from 'components/Layouts';

const PastDue = ({ totalAmountPastDue, numberPaymentsPastDue, oldestPastDueDaysOld }) => {
  return (
    <Card fullHeight variant="outlined" padding="10px 20px">
      <Typography variant="h6" mb="8px">
        Past due
      </Typography>

      <ListItem
        value={totalAmountPastDue ? currencyFormatter.format(totalAmountPastDue) : '-'}
        label="Amount"
        valueColor={totalAmountPastDue && totalAmountPastDue > 0 ? 'error' : 'success'}
      />
      <ListItem value={oldestPastDueDaysOld} label="Days (oldest)" valueColor={oldestPastDueDaysOld && oldestPastDueDaysOld > 0 ? 'error' : 'success'} />
      <ListItem value={numberPaymentsPastDue} label="# Late Periods" valueColor={numberPaymentsPastDue && numberPaymentsPastDue > 0 ? 'error' : 'success'} />
    </Card>
  );
};

export default PastDue;
