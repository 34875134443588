import { METHODS, request } from 'services/axios';

const URIS = {
  FETCH_USER: () => `/activeUser`,
  UPDATE_ACTIVE_PROFILE: ({ id }) => `/users/${id}/update-active-profile`,
};

export default {
  fetchUserInfo: () =>
    request({
      url: URIS.FETCH_USER(),
      method: METHODS.GET,
    }),

  updateActiveProfile: ({ id, data }) =>
    request({
      url: URIS.UPDATE_ACTIVE_PROFILE({ id }),
      method: METHODS.POST,
      data,
    }),
};
