import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getDealerLendersThunk = createAsyncThunk('dealer/lenders', async ({ dealerId, acceptingApplication }, thunkAPI) => {
  try {
    const response = await axios.get(
      typeof acceptingApplication === 'boolean'
        ? `${API_BASE_URL}/dealers/${dealerId}/lenders?acceptingApplication=${acceptingApplication}`
        : `${API_BASE_URL}/dealers/${dealerId}/lenders`,
      { withCredentials: true },
    );

    /**
     * This will dispatch action creator with type of "dealer/lenders/fulfilled" and payload of response.data.lenders.
     * We are not using this dispatched action atm.
     */
    return response.data.lenders;
  } catch (err) {
    /**
     * This is the reason why we are doing this
     *
     * https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
     */
    const errorMessage = err instanceof Error ? err.message : String(err);

    /**
     * This will dispatch action creator with type of "dealer/lenders/rejected" and payload of errorMessage.
     * We are not using this dispatched action atm.
     */
    return thunkAPI.rejectWithValue(errorMessage);
  }
});
